import React, { useMemo, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    useMediaQuery,
    useTheme,
    Typography,
    Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Close } from '@mui/icons-material';
import { ConfirmDialog } from '../../../ui';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import { useDispatch } from 'react-redux';
import { storeChoosenTemplate } from '../../slices/templateSlice';
import PreviewTable from './PreviewTable';

const useStyles = makeStyles()((theme) => ({
    dialogContainer: {
        padding: '4%',
    },
    templateName: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
    dialogHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

function PreviewDialog({
    showPreview,
    setShowPreview,
    template,
    requirementId = undefined,
    loadRequirementData = undefined,
    isRequirementCreating = false,
    setOpen,
    setTemplates,
    handleCloseTemplateSelection,
    existingTemplateId = null,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [showConfirmation, setShowConfirmation] = useState(false);
    const setAlert = useSetRecoilState(alertState);
    const [applyingTemplate, setApplyingTemplate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const confirmationText = useMemo(() => {
        if (existingTemplateId !== template._id) {
            return 'Are you sure you want to apply this template? All your logistic data will be lost';
        }

        return 'Are you sure you want to apply this template?';
    }, [existingTemplateId, template._id]);

    const handleClose = () => {
        setShowPreview(false);
    };

    const handleApplyTemplate = () => {
        setShowConfirmation(true);
    };

    const onCloseConfirmation = () => {
        setShowConfirmation(false);
    };

    // update the requirement with the new template and preserve the logistics data
    const updateAndPreserveLogisticsData = async ({
        templateId,
        requirementId,
    }) => {
        try {
            const baseURL =
                '/api/client-requirements/update-requirements-by-logistics-id';
            const searchParams = new URLSearchParams({
                templateId,
                requirementId,
            });
            const apiEndPoint = `${baseURL}?${searchParams.toString()}`;
            return await axios.get(apiEndPoint);
        } catch (e) {
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: "Oops! Something wne't worng.",
            });
        }
    };

    // update the requirement with the new template and update the logistics data
    const updateLogisticsData = async () => {
        // apply new logistics template to the current requirement
        const dbRes = await axios.put('/api/client-requirements', {
            id: requirementId,
            taskCategories: template.taskCategories,
            templateId: template._id,
        });

        // once the new template is applied the new logistics data is updated with the collaborators
        // update the logistics data and apply the collaborators
        await updateAndPreserveLogisticsData({
            templateId: template._id,
            requirementId,
        });

        return dbRes;
    };

    const onConfirm = async () => {
        try {
            if (!requirementId || !loadRequirementData) return;
            // const url = '/api/client-requirements';
            setApplyingTemplate(true);

            const dbRes =
                existingTemplateId === template._id
                    ? await updateAndPreserveLogisticsData({
                          templateId: template._id,
                          requirementId,
                      })
                    : await updateLogisticsData();

            if (dbRes.status === 200) {
                setAlert({
                    show: true,
                    severity: severity.SUCCESS,
                    message: 'Template applied',
                });

                await loadRequirementData(requirementId);
            }
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({
                show: true,
                severity: severity.ERROR,
                message,
            });
        } finally {
            handleClose();
            handleCloseTemplateSelection();
            setShowConfirmation(false);
            setApplyingTemplate(false);
        }
    };

    const handleSelectTemplate = () => {
        dispatch(storeChoosenTemplate(template));
        handleClose();
        setAlert({
            show: true,
            severity: severity.SUCCESS,
            message: 'Template selected',
        });
        setOpen(false);
    };

    const handleClone = async () => {
        const { _id } = template;
        try {
            setLoading(true);
            const baseURL = '/api/client-requirements/clone-template';
            const searchParams = new URLSearchParams();
            searchParams.append('templateId', _id);
            const url = `${baseURL}?${searchParams.toString()}`;
            const { data, status } = await axios.get(url);
            if (status === 201) {
                const { template } = data;
                setTemplates((prev) => {
                    return [...prev, template];
                });
                setAlert({
                    message: 'Requirement cloned. Please rename it.',
                    show: true,
                    severity: severity.SUCCESS,
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            <ConfirmDialog
                open={showConfirmation}
                onCancle={onCloseConfirmation}
                onClose={onCloseConfirmation}
                title={confirmationText}
                onConfirm={onConfirm}
                isLoading={applyingTemplate}
            />

            <Dialog open={showPreview} scroll="paper" fullWidth maxWidth="md">
                <Box className={classes.dialogContainer}>
                    <Box className={classes.dialogHeader}>
                        <Typography className={classes.templateName}>
                            {template.name}
                        </Typography>
                        <div>
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="small"
                                onClick={handleClone}
                                disabled={loading}
                            >
                                {loading ? 'Please wait' : 'Clone'}
                            </Button>
                            <IconButton size="small" onClick={handleClose}>
                                <Close
                                    fontSize={isXSDevice ? 'small' : 'medium'}
                                />
                            </IconButton>
                        </div>
                    </Box>

                    <DialogContent dividers>
                        {template.taskCategories.map((category, cIdx) => (
                            <PreviewTable
                                key={category.categoryName + '-' + cIdx}
                                category={category}
                            />
                        ))}
                    </DialogContent>

                    <DialogActions className={classes.actions}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                            size={isXSDevice ? 'small' : 'medium'}
                        >
                            Cancel
                        </Button>
                        {!isRequirementCreating ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleApplyTemplate}
                                size={isXSDevice ? 'small' : 'medium'}
                            >
                                Apply
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSelectTemplate}
                                size={isXSDevice ? 'small' : 'medium'}
                            >
                                SELECT
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    );
}

export default PreviewDialog;
