import React, { useState } from 'react';

import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';
import { ListSearchAndSelect } from '../ui';

import { useSMEsSearch2 } from './useSMEsSearch2';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const SMEsSearchAndSelect2 = ({
    title,
    subTitle,
    selected,

    setSmeModal,
    onClickSelected,
    selectedListView,
    selectedWithinSelected,
    // showSelectionWithinSelection,
    column,
    // addedSearchTerms,
    // setAddedSearchTerms,
    // skillErr,
    // setSkillErr,
    // resetError = undefined,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const setAlert = useSetRecoilState(alertState);

    const { results, hasMore, loading, error } = useSMEsSearch2(
        searchQuery,
        pageNumber,
        5,
        column
    );

    const [
        selectionInviteBtnLoadingIds,
        setSelectionInviteBtnLoadingIds,
    ] = useState([]);
    console.log(selectionInviteBtnLoadingIds);

    const handleSelect = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (prev.smes.findIndex((elem) => elem._id === item._id) === -1) {
                return { ...prev, smes: [item, ...prev.smes] };
            } else {
                return prev;
            }
        });
    };

    const handleDelete = (itemToDelete) => {
        setSmeModal((prev) => ({
            ...prev,
            smes: prev.smes.filter((item) => item._id !== itemToDelete._id),
        }));

        if (selectedWithinSelected)
            setSmeModal((prev) => {
                return {
                    ...prev,
                    assignedSMEs: prev.assignedSMEs?.filter(
                        (s) => s._id !== itemToDelete._id
                    ),
                };
            });
    };

    const handleSelectWithinSelect = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (
                prev.assignedSMEs.findIndex((elem) => elem._id === item._id) ===
                -1
            ) {
                return { ...prev, assignedSMEs: [item, ...prev.assignedSMEs] };
            } else {
                return {
                    ...prev,
                    assignedSMEs: prev.assignedSMEs?.filter(
                        (s) => s._id !== item._id
                    ),
                };
            }
        });
    };

    const getDisplayText = (item) => `${item.firstName} ${item.lastName}`;

    const showSelectionInviteBtn = (item) => {
        return item.inviteStatus === 'pending';
    };

    const onClickSelectionInviteBtn = async (item) => {
        console.log(item);

        try {
            if (item.email) {
                setSelectionInviteBtnLoadingIds((prev) => [...prev, item._id]);

                const res = await axios.post(`/api/admin/invite/user/`, {
                    email: item.email,

                    sendInvite: true,
                });

                setAlert({
                    message: 'Invite sent successfully.',
                    show: true,
                    severity: severity.SUCCESS,
                });
                setSelectionInviteBtnLoadingIds((prev) =>
                    prev.filter((id) => id !== item._id)
                );
            } else {
                setSelectionInviteBtnLoadingIds((prev) =>
                    prev.filter((id) => id !== item._id)
                );
                // console.log(linkedin, city, country, time_zone);
                setAlert({
                    message: 'Email not found.',
                    show: true,
                    severity: severity.ERROR,
                });
            }
        } catch (e) {
            setSelectionInviteBtnLoadingIds((prev) =>
                prev.filter((id) => id !== item._id)
            );
            console.log(e.response?.data?.message);
            if (e.response?.data?.message)
                setAlert({
                    message: e?.response?.data?.message,
                    show: true,
                    severity: severity.ERROR,
                });
        }
    };

    const selectionInviteBtnTooltip = 'Send invite.';

    return (
        <ListSearchAndSelect
            heading={title}
            subHeading={subTitle}
            list={results}
            selectedList={selected}
            handleItemSelect={handleSelect}
            handleItemDelete={(data) => {
                console.log(data);
                handleDelete(data);
            }}
            idField="_id"
            subTextField="email"
            paginationMode="server"
            search={searchQuery}
            setSearch={setSearchQuery}
            setPageNumber={setPageNumber}
            loading={loading}
            hasMore={hasMore}
            getDisplayText={getDisplayText}
            onClickSelected={onClickSelected}
            selectedListView={selectedListView}
            selectedWithinSelected={selectedWithinSelected}
            showSelectionWithinSelection={column === 'shortListedSmes2'}
            handleSelectWithinSelect={handleSelectWithinSelect}
            selectedWithinSelectedTooltip="Check to assign this instructor to the requirement."
            showSelectionActionBtn={showSelectionInviteBtn}
            onClickSelectionActionBtn={onClickSelectionInviteBtn}
            selectionActionBtnTooltip={selectionInviteBtnTooltip}
            selectionActionBtnIcon={MailOutlineIcon}
            selectionActionBtnLoadingIds={selectionInviteBtnLoadingIds}
        />
    );
};
