import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { CloseIcon, ShareIcon } from '../../../icons';
import { makeStyles } from 'tss-react/mui';
import RequirementSumamrySimpleField from '../ClientRequirements/RequirementSumamrySimpleField';
import moment from 'moment';
import GuidanceTutorials from './GuidanceTutorials';
import GuidanceTemplates from './GuidanceTemplates';
import GuidanceDuscussions from './GuidanceDuscussions';
import ShareModal from './ShareModal';

const useStyles = makeStyles()((theme) => {
    return {
        dialogHeader: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        fieldContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: theme.spacing(1),
            flexDirection: 'column',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                rowGap: '0.5rem',
                columnGap: '1.5rem',
            },
        },
        tabContainer: {
            borderBottom: '1px solid',
            borderColor: theme.palette.divider,
        },
        dialogPaper: {
            minHeight: '80vh',
            maxHeight: '80vh',
        },
    };
});

function GuidanceDetailsDialog({
    open,
    handleClose,
    guidance,
    fetchGuidance,
    logisticsTemplateId,
    hideCTA,
    isInstructor,
}) {
    const { classes } = useStyles();
    const [activeTabVal, setActiveTabVal] = useState(0);
    const [showShareModal, setShowShareModal] = useState(false);

    const handleTabChange = (_, newValue) => {
        setActiveTabVal(newValue);
    };

    const onClose = () => {
        handleClose();
    };

    const handleShare = () => {
        setShowShareModal(true);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            fullWidth
            maxWidth={'md'}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="guidance-details-dialog"
            aria-describedby="guidance-details-dialog"
        >
            <DialogTitle
                id="guidance-details-dialog"
                className={classes.dialogHeader}
            >
                <span>
                    {guidance?.title}{' '}
                    {showShareModal && (
                        <ShareModal
                            open={showShareModal}
                            handleClose={() => setShowShareModal(false)}
                            guidanceId={guidance._id}
                        />
                    )}
                    {!isInstructor && (
                        <IconButton size="small" onClick={handleShare}>
                            <Tooltip title="Share" placement="top-start">
                                <ShareIcon fontSize="small" />
                            </Tooltip>
                        </IconButton>
                    )}
                </span>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon fontSize={'small'} />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers={true}>
                <Box mt={2} className={classes.fieldContainer}>
                    <RequirementSumamrySimpleField
                        label={'Category'}
                        value={guidance?.category}
                    />
                    <RequirementSumamrySimpleField
                        label={'Purpose'}
                        value={guidance?.purpose}
                    />
                    <RequirementSumamrySimpleField
                        label={'Last updated'}
                        value={moment(guidance?.updatedAt).format(
                            'DD-MMM-YYYY'
                        )}
                    />
                    <RequirementSumamrySimpleField
                        label={'Created on'}
                        value={moment(guidance?.createdAt).format(
                            'DD-MMM-YYYY'
                        )}
                    />
                </Box>

                <Box mt={2} className={classes.tabContainer}>
                    <Tabs
                        value={activeTabVal}
                        onChange={handleTabChange}
                        aria-label="guidance tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Tutorials" value={0} />
                        <Tab label="Summary" value={1} />
                        <Tab label="Documents" value={2} />
                        <Tab label="Discussion" value={3} />
                    </Tabs>
                </Box>

                <Box my={2} py={2} px={1}>
                    {activeTabVal === 1 && (
                        <Typography variant="body1">
                            {guidance?.summary}
                        </Typography>
                    )}

                    {activeTabVal === 0 && (
                        <GuidanceTutorials
                            tutorials={guidance?.tutorials}
                            guidanceId={guidance?._id}
                            fetchGuidance={fetchGuidance}
                            logisticsTemplateId={logisticsTemplateId}
                            hideCTA={hideCTA}
                        />
                    )}

                    {activeTabVal === 2 && (
                        <GuidanceTemplates
                            templates={guidance?.templates}
                            guidanceId={guidance?._id}
                            hideCTA={hideCTA}
                        />
                    )}

                    {activeTabVal === 3 && (
                        <GuidanceDuscussions
                            guidance={guidance}
                            isInstructor={isInstructor}
                        />
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default GuidanceDetailsDialog;
