import { useMemo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

/* const variables = {
    FEEDS_WITH_COMMENTS: 'Feeds with comments',
    ALL_FEEDS: 'All feeds',
}; */

function FeedCommentSwitch({
    showFeedsWithComments,
    setShowFeedsWithComments,
    labelPlacement = 'end',
}) {
    const tooltipText = useMemo(() => {
        if (showFeedsWithComments) {
            return 'OFF to see all posts';
        }
        return 'ON to show feed with comments only';
    }, [showFeedsWithComments]);

    const handleChange = (e) => {
        setShowFeedsWithComments(() => e.target.checked);
    };

    return (
        <Tooltip placement="bottom-start" title={tooltipText}>
            <FormControlLabel
                label={'With comments'}
                labelPlacement={labelPlacement}
                control={
                    <Switch
                        checked={showFeedsWithComments}
                        onChange={handleChange}
                        color={'secondary'}
                    />
                }
            />
        </Tooltip>
    );
}

export default FeedCommentSwitch;
