import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Checkbox, ListItemText, MenuItem } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    label: {
        fontWeight: 500,
    },
    optionsWrapper: {
        borderRadius: theme.spacing(0.25),
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        '& > input': {
            outline: 'none',
            border: 'none',
            flexGrow: 1,
            padding: 0,
            margin: 0,
        },
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
        },
    },
    options: {
        position: 'absolute',
        top: 'calc(100% + 5px)',
        left: '0',
        backgroundColor: '#fff',
        zIndex: 10,
        height: '250px',
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            left: '-30%',
            width: '86vw',
        },
    },
}));

function DrawerMultiSelect({
    label,
    isLoading,
    selectedOptTxt,
    options,
    handleChangeOpt,
    selectedOptions,
    fitWidth = false,
    minWidth = 'auto',
}) {
    const { classes } = useStyles();
    const optionContainerRef = useRef();
    const [showTemplateOptions, setShowTemplateOptions] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                optionContainerRef.current &&
                !optionContainerRef.current.contains(event.target)
            ) {
                setShowTemplateOptions(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <label htmlFor="template" className={classes.label}>
                {label}:
            </label>
            <Box
                ref={optionContainerRef}
                className={classes.optionsWrapper}
                onClick={(e) => {
                    e.stopPropagation();
                    setShowTemplateOptions(true);
                }}
                sx={{
                    outline: (theme) =>
                        showTemplateOptions
                            ? `2px solid ${theme.palette.secondary.main}`
                            : `1px solid ${theme.palette.grey['400']}`,
                }}
            >
                <input
                    id="template"
                    type="text"
                    value={isLoading ? 'loading...' : selectedOptTxt}
                    name="logisticsTemplates"
                    readOnly={true}
                    disabled={isLoading}
                    style={{ minWidth: minWidth }}
                />
                <KeyboardArrowDown
                    fontSize="small"
                    onClick={(e) => {
                        if (!isLoading) {
                            e.stopPropagation();
                            setShowTemplateOptions(true);
                        }
                    }}
                />
                {showTemplateOptions && (
                    <Box
                        boxShadow={3}
                        sx={{
                            ...(fitWidth
                                ? {
                                      width: '100% !important',
                                      left: '0 !important',
                                  }
                                : {}),
                        }}
                        className={classes.options}
                    >
                        {options.map((option, idx) => (
                            <MenuItem
                                key={`${idx} - ${option.value}`}
                                onClick={() => handleChangeOpt(option.value)}
                                disabled={isLoading}
                                disableGutters
                            >
                                <Checkbox
                                    checked={
                                        selectedOptions.indexOf(option.value) >
                                        -1
                                    }
                                    size="small"
                                />
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: '14px',
                                    }}
                                    primary={option.label}
                                />
                            </MenuItem>
                        ))}
                    </Box>
                )}
            </Box>
        </>
    );
}

export default DrawerMultiSelect;
