import React from 'react';
import {
    ButtonBase,
    Card,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Edit } from '@mui/icons-material';
import TemplateImg from '../../../img/template.png';
import { useState } from 'react';
import PreviewDialog from './PreviewDialog';

const useStyles = makeStyles()((theme) => ({
    card: {
        padding: theme.spacing(2),
        display: 'grid',
        placeItems: 'center',
    },
    item: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        '& > .editBtn': {
            position: 'absolute',
            top: 0,
            right: 0,
            fontSize: '1.25rem',
            [theme.breakpoints.up('sm')]: {
                visibility: 'hidden',
            },
        },
        '&:hover .editBtn': {
            visibility: 'visible',
        },
    },
    templateName: {
        wordBreak: 'break-word',
    },
}));

function TemplateItem({
    handleSelectTemplate,
    template,
    requirementId = undefined,
    loadRequirementData = undefined,
    isRequirementCreating = false,
    setOpen,
    setTemplates,
    handleCloseTemplateSelection,
    existingTemplateId = null,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [showPreview, setShowPreview] = useState(false);

    /**
     *
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
     */
    const onEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectTemplate(template);
    };

    const onPreviewTemplate = () => {
        setShowPreview(true);
    };

    return (
        <React.Fragment>
            {showPreview && (
                <PreviewDialog
                    showPreview={showPreview}
                    setShowPreview={setShowPreview}
                    template={template}
                    requirementId={requirementId}
                    loadRequirementData={loadRequirementData}
                    isRequirementCreating={isRequirementCreating}
                    setOpen={setOpen}
                    setTemplates={setTemplates}
                    handleCloseTemplateSelection={handleCloseTemplateSelection}
                    existingTemplateId={existingTemplateId}
                />
            )}

            <Card elevation={2} className={classes.card}>
                <ButtonBase
                    className={classes.item}
                    onClick={onPreviewTemplate}
                >
                    <IconButton
                        size="small"
                        onClick={onEdit}
                        className="editBtn"
                    >
                        <Tooltip placement="left-start" title="Edit template">
                            <Edit fontSize={isXSDevice ? 'small' : 'medium'} />
                        </Tooltip>
                    </IconButton>

                    <img
                        src={TemplateImg}
                        height={100}
                        width={100}
                        alt={template.name}
                    />
                    <Typography
                        variant="body1"
                        className={classes.templateName}
                    >
                        {template.name}
                    </Typography>
                </ButtonBase>
            </Card>
        </React.Fragment>
    );
}

export default TemplateItem;
