import {
    Box,
    Divider,
    Icon,
    IconButton,
    PageLoader,
    Typography,
    Tooltip,
} from '../ui';
import { EmojiEmotions } from '@mui/icons-material';
import { useStyles } from './styles';
import { useMemo } from 'react';
import { ClapsIcons } from '../icons';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { ReplyBox } from './ReplyBox';
import { UserDisplay } from './UserDisplay';
import { ReportBox } from './ReportBox';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deleteCommentSelector } from './recoil';
import ReactHTMLParser from 'react-html-parser';
import { VisibilityButton } from '../ui';
import { CommentMoreOptions } from './CommentMoreOptions';
import { useEffectSkipFirst } from '../util';
import { visibilities } from '../util/visibility';
import { CommentsInput } from './CommentsInput';
import Attachments from './CommentAttachment/Attachments';
import {
    COMMENT,
    reset,
    setCommentId,
    setType,
} from './slices/commentAttachmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AzureBlobStorage } from '../util/BlobStorage';
import { ArrowBackRounded } from '@mui/icons-material';
import { EmojiPicker } from '../ui';
import FeedCardHeader from '../admin/components/FeedsAndActivity/FeedCardHeader';
import { roleViewState } from '../navigation/recoil/roleView';

export const CommentItem = ({
    comment,
    user,
    setOpenAccessBlock,
    starweaverAdmin,
    defaultOpenReplies = false,
    setComments = undefined,
    mentionables = [],
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    afterCommentAdded = undefined,
    visibilityOptions,
    clientId,
    showAttachments = false,
    showCommentAttachments = false,
    navigateToComment,
    replyToComment,
    showRemindOption = false,
    placeholder,
}) => {
    // console.log(user);
    const [roleView] = useRecoilState(roleViewState);
    const [usersUpVoted, setUsersUpVoted] = useState([]);
    const [openReport, setOpenReport] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showAddReply, setShowAddReply] = useState(false);
    const [replies, setReplies] = useState(defaultOpenReplies);
    const deleteCommentFromState = useSetRecoilState(deleteCommentSelector);
    const { classes } = useStyles();
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedVisbilityOption, setSelectedVisbilityOption] = useState(
        comment.visibility || visibilities.EVERYONE.visibility
    );
    const dispatch = useDispatch();
    const commentAttachments = useSelector((state) => state.commentAttachments);
    const [selectedUsers, setSelectedUsers] = useState(
        comment.selected_users || []
    );
    const [isDeleting, setIsDeleting] = useState(false);

    const [showEditor, setShowEditor] = useState(true);

    const [isHovering, setIsHovering] = useState(false);

    const [reactionLoading, setReactionLoading] = useState(false);

    const [hoveredEmoji, setHoveredEmoji] = useState(null);

    const [
        clientRequirementsActivity,
        setClientRequirementsActivity,
    ] = useState(null);

    useEffect(() => {
        if (commentAttachments.closeEditor) {
            dispatch(reset());
            setShowEdit(false);
        }
    }, [commentAttachments.closeEditor, dispatch]);

    const blockAccess = () => {
        setOpenAccessBlock(true);
    };

    const repliesLength = useMemo(() => {
        if (comment) {
            return comment.replies?.length;
        }
    }, [comment]);

    useEffect(() => {
        if (comment) {
            setUsersUpVoted(comment.users_up_votes || []);
        }
    }, [comment]);

    useEffectSkipFirst(() => {
        handleVisbilityChange();
    }, [selectedVisbilityOption]);

    const fetchClientRequirementActivity = useCallback(async () => {
        try {
            const clientRequirementsActivityId =
                comment.clientRequirementsActivity;
            if (!clientRequirementsActivityId) return;

            const baseURL = '/api/client-requirements/activities';
            const params = new URLSearchParams();

            // if (requirementId) {
            //     params.append('selectedRequirementId', requirementId);
            // }
            params.append('page', 0);
            params.append('limit', 1);
            if (clientRequirementsActivityId) {
                params.append('feedId', clientRequirementsActivityId);
            }

            // if (isInstructorView) {
            //     params.append('asInstructor', 1);
            // }

            // if (selectedRole) {
            //     params.append('selectedRole', selectedRole);
            // }

            // if (showFeedsWithComments || onlyComments) {
            //     params.append('feedsWithComments', showFeedsWithComments);
            // }

            const url = `${baseURL}?${params.toString()}`;
            const res = await axios.get(url);

            const data = res.data;
            const activity = data.activity;
            // console.log(activity);

            if (activity?.length) {
                setClientRequirementsActivity(activity[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }, [comment.clientRequirementsActivity]);

    useEffect(() => {
        fetchClientRequirementActivity();
    }, [fetchClientRequirementActivity]);

    const onUsersDialogClose = () => {
        handleVisbilityChange();
    };

    const liked = useMemo(() => {
        return usersUpVoted.some((id) => id === user?._id);
    }, [usersUpVoted, user]);

    const totalLikes = useMemo(() => {
        return usersUpVoted.length;
    }, [usersUpVoted]);

    const groupedReactions = useMemo(() => {
        const reactionMap = {};
        comment.reactions.forEach((reaction) => {
            if (!reactionMap[reaction.emoji]) {
                reactionMap[reaction.emoji] = [];
            }
            reactionMap[reaction.emoji].push(reaction);
        });
        return reactionMap;
    }, [comment.reactions]);
    // console.log(groupedReactions);

    // functions

    const toggleReply = () => {
        if (!user) {
            blockAccess();
            return;
        }
        setShowAddReply((prevState) => !prevState);
    };

    const openReplies = () => {
        if (comment?.replies) {
            setReplies(true);
        }
    };

    const handleToggleReplies = () => {
        setReplies((prevState) => !prevState);
    };

    const hideReply = () => {
        setShowAddReply(false);
    };

    const toggleReport = () => {
        hideReply();
        setOpenReport((prevState) => !prevState);
    };

    const handleLike = async () => {
        if (!user) {
            blockAccess();
            return;
        }
        if (comment && user && !isProcessing) {
            setIsProcessing(true);

            if (liked) {
                // dislike
                try {
                    const {
                        data: { disliked },
                    } = await axios.post(
                        `/api/content/${comment._id}/comment/dislike`,
                        {}
                    );
                    console.log('disliked--> ' + disliked);
                } catch (error) {
                    console.log(error.message);
                }
                setUsersUpVoted((prevState) =>
                    prevState.filter((id) => id !== user?._id)
                );
                setComments((prev) => {
                    return prev.map((cmt) => {
                        if (cmt._id === comment._id) {
                            return {
                                ...cmt,
                                users_up_votes: cmt.users_up_votes.filter(
                                    (id) => id !== user?._id
                                ),
                            };
                        } else {
                            return { ...cmt };
                        }
                    });
                });
            } else {
                // like
                try {
                    const { data } = await axios.post(
                        `/api/content/${comment._id}/comment/like`,
                        {}
                    );
                    console.log('liked--> ' + data.liked);
                } catch (error) {
                    console.log(error.message);
                }
                setUsersUpVoted((prevState) => [...prevState, user?._id]);

                setComments((prev) => {
                    return prev.map((cmt) => {
                        if (cmt._id === comment._id) {
                            return {
                                ...cmt,
                                users_up_votes: [
                                    ...cmt.users_up_votes,
                                    user?._id,
                                ],
                            };
                        } else {
                            return { ...cmt };
                        }
                    });
                });
            }
            setIsProcessing(false);
        } else {
            console.log('fields not found');
        }
    };

    const handleAddReaction = async (commentId) => {
        if (!user) {
            blockAccess();
            return;
        }
        try {
            const reaction = '👍'; // Example reaction, you can make this dynamic
            await axios.post(`/api/content/comments/${commentId}/reaction`, {
                emoji: reaction,
            });
            // Update comment reactions state here if needed
        } catch (error) {
            console.error('Error adding reaction', error);
        }
    };

    const deleteFiles = async (
        size,
        fileNames,
        blobStorage,
        containerName,
        path
    ) => {
        try {
            if (size === 0) return;
            const promisses = new Array(size);
            for (const file of fileNames) {
                promisses.push(
                    blobStorage.deleteFile(file.actual, containerName, path)
                );
            }
            await Promise.allSettled(promisses);
        } catch (e) {
            console.log(e);
        }
    };

    const deleteCommentAttachments = async (comment) => {
        const blobStorage = new AzureBlobStorage();
        const containerName = 'comments';
        const { fileNames, size } = await blobStorage.listFilesFromFolder(
            containerName,
            comment._id + '/'
        );
        await deleteFiles(
            size,
            fileNames,
            blobStorage,
            containerName,
            comment._id
        );
    };

    const deleteReplyAttachments = async (comment) => {
        if (comment.replies?.length === 0) return;
        const blobStorage = new AzureBlobStorage();
        const containerName = 'comments';
        for (const reply of comment.replies) {
            if (reply?.hasFiles === true) {
                const {
                    fileNames,
                    size,
                } = await blobStorage.listFilesFromFolder(
                    containerName,
                    `${comment._id}/replies/${reply._id}/`
                );
                await deleteFiles(
                    size,
                    fileNames,
                    blobStorage,
                    containerName,
                    `${comment._id}/replies/${reply._id}`
                );
            }
        }
    };

    const deleteAllAttachments = async (comment) => {
        try {
            await Promise.allSettled([
                deleteCommentAttachments(comment),
                deleteReplyAttachments(comment),
            ]);
        } catch (e) {
            console.log(e);
        }
    };

    const deleteComment = async () => {
        if (starweaverAdmin || isCurrentUser) {
            try {
                // deleteCommentFromState(comment._id);
                setIsDeleting(true);
                if (setComments === undefined) {
                    throw new Error('setComment is undefined');
                }

                if (comment?.hasFiles === true) {
                    await deleteAllAttachments(comment);
                }

                const {
                    data: { deleted, commentId },
                } = await axios.post(
                    `/api/content/${comment._id}/comment/delete`,
                    {}
                );

                if (deleted === true) {
                    setComments((prev) => {
                        const comments = prev.filter(
                            (comment) => comment?._id !== commentId
                        );
                        if (afterCommentAdded) afterCommentAdded(comments);
                        return comments;
                    });
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const onEditOtpClick = async () => {
        setShowEdit(true);
        setSelectedVisbilityOption(
            comment.visibility || visibilities.EVERYONE.visibility
        );
    };

    const reportComment = async ({ reason, reportText }) => {
        if (!user) {
            blockAccess();
            return;
        }
        try {
            const {
                data: { reported },
            } = await axios.post(
                `/api/content/${comment._id}/comment/report/`,
                { reason, reportText, userId: user._id }
            );
            console.log('reported--> ' + reported);
            deleteCommentFromState(comment._id);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleVisbilityChange = async () => {
        if (!user) {
            blockAccess();
            return;
        }
        try {
            if (comment && user && !isProcessing) {
                setIsProcessing(true);

                await axios.post(
                    `/api/content/${comment._id}/comment/visbility`,
                    {
                        commentId: comment._id,
                        visibility: selectedVisbilityOption,
                        selectedUsers,
                        clientId,
                    }
                );

                setComments((prev) => {
                    return prev.map((cmt) => {
                        if (cmt._id === comment._id) {
                            return {
                                ...cmt,
                                visibility: selectedVisbilityOption,
                                selected_users: selectedUsers,
                                client_id: clientId,
                            };
                        } else {
                            return { ...cmt };
                        }
                    });
                });

                setIsProcessing(false);
            } else {
                console.log('fields not found');
            }
        } catch (error) {
            console.log(error.message);
            setIsProcessing(false);
        }
    };

    const handleSaveEditedComment = async ({
        comment: comment_text,
        visibility,
        hasFiles = false,
    }) => {
        const payload = {
            _id: comment._id,
            comment_text,
            visibility,
            hasFiles,
        };
        try {
            setLoading(true);
            const { status } = await axios.patch(
                '/api/content/edit-comment',
                payload
            );
            if (status === 200) {
                if (commentAttachments.isFile && comment._id) {
                    dispatch(setCommentId(comment._id));
                    dispatch(setType(COMMENT));
                }

                setComments((comments) => {
                    return comments.map((comment) => {
                        if (comment._id === payload._id) {
                            return {
                                ...comment,
                                comment_text,
                                visibility,
                                isEdited: true,
                                hasFiles,
                            };
                        } else {
                            return comment;
                        }
                    });
                });

                if (commentAttachments.isFile === false) {
                    setShowEdit(false);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleEmojiClick = async (emoji) => {
        if (!user) {
            blockAccess();
            return;
        }
        setReactionLoading(true);
        try {
            // Make the API request to update the reaction in the backend
            const { data } = await axios.post(
                `/api/content/comments/${comment._id}/reaction`,
                {
                    emoji,
                }
            );

            // Update the state with the new reactions from the response
            setComments((prev) => {
                return prev.map((cmt) => {
                    if (cmt._id === comment._id) {
                        return {
                            ...cmt,
                            reactions: data.reactions,
                        };
                    } else {
                        return { ...cmt };
                    }
                });
            });
        } catch (error) {
            console.error('Error adding reaction', error);
        } finally {
            setReactionLoading(false);
        }
    };

    const onCancelEdit = () => {
        setShowEdit(false);
    };

    const isCurrentUser = comment.created_by === user?._id;

    return (
        <Box
            mb={3}
            pl={1}
            display="flex"
            flexDirection="column"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <Box mb={1}>
                {/* header section */}
                {!showEdit && (
                    <Box display="flex" alignItems="flex-start">
                        <UserDisplay
                            response={comment}
                            userId={comment.created_by}
                        />
                        {isCurrentUser && (
                            <Box mt={-1}>
                                <VisibilityButton
                                    selectedVisbilityOption={
                                        selectedVisbilityOption
                                    }
                                    setSelectedVisbilityOption={
                                        setSelectedVisbilityOption
                                    }
                                    selectedUsers={selectedUsers}
                                    setSelectedUsers={setSelectedUsers}
                                    visibilityOptions={visibilityOptions}
                                    // clientId={clientId}
                                    onUsersDialogClose={onUsersDialogClose}
                                />
                            </Box>
                        )}
                        <Box ml="auto">
                            <Box display="flex" alignItems="center">
                                <Box
                                    style={{
                                        opacity: isHovering ? 1 : 0,
                                        transition: 'opacity 0.3s',
                                    }}
                                >
                                    <EmojiPicker
                                        onEmojiClick={handleEmojiClick}
                                        disabled={reactionLoading}
                                    />
                                </Box>
                                <Tooltip title="Reply">
                                    <IconButton
                                        onClick={() => replyToComment(comment)}
                                        className={classes.action}
                                        size="small"
                                    >
                                        <ArrowBackRounded fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                <CommentMoreOptions
                                    toggleOpenReport={toggleReport}
                                    deleteComment={deleteComment}
                                    starweaverAdmin={starweaverAdmin}
                                    blockAccess={blockAccess}
                                    user={user}
                                    isCurrentUser={isCurrentUser}
                                    isReviewOwner={user?.isReviewOwner}
                                    editComment={onEditOtpClick}
                                    commentId={comment._id}
                                    showRemindOption={showRemindOption}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}

                {clientRequirementsActivity && (
                    <Box
                        mb={1}
                        ml={5}
                        className={classes.clientRequirementsActivity}
                    >
                        <FeedCardHeader
                            isInstructorView={!user.isAdmin}
                            row={clientRequirementsActivity}
                            // selectedRole={selectedRole}
                            // showEditor={showEditor}
                            // handleToggleEditor={handleToggleEditor}
                            // handleHideCkEditor={handleHideCkEditor}
                            hideCollapseBtn
                            hideCommentBtn
                        />
                    </Box>
                )}

                {/* Display reply_to comment */}
                {comment.reply_to && (
                    <Box
                        mb={1}
                        ml={5}
                        className={classes.replyToBox}
                        onClick={() => navigateToComment(comment.reply_to._id)}
                    >
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <UserDisplay
                                response={comment.reply_to}
                                userId={comment.reply_to.created_by}
                            />
                            <Box ml={2}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                        maxHeight: '120px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 3,
                                    }}
                                >
                                    {ReactHTMLParser(
                                        comment.reply_to.comment_text
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* header section */}
                {!showEdit && (
                    <Box mb={1} ml={5}>
                        <Typography
                            variant="body2"
                            className={classes.commentTextWrapper}
                        >
                            {ReactHTMLParser(comment.comment_text)}
                        </Typography>
                    </Box>
                )}
                {showEdit && (
                    <Box mb={1}>
                        <CommentsInput
                            blockAccess={blockAccess}
                            user={user}
                            finalFunction={handleSaveEditedComment}
                            placeholder={
                                placeholder
                                    ? placeholder
                                    : user?.is_instructor &&
                                      roleView.selectedView === 'instructor'
                                    ? 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use / to tag tasks | Use ~ to tag a course'
                                    : 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use # to tag teams | Use / to tag tasks | Use ~ to tag a course'
                            }
                            mentionables={mentionables}
                            tasks={tasks}
                            teams={teams}
                            tasksOutputTemplate={tasksOutputTemplate}
                            // showCkEditor
                            showEditor={showEditor}
                            setShowEditor={setShowEditor}
                            visibilityOptions={visibilityOptions}
                            clientId={clientId}
                            handleCloseEdit={onCancelEdit}
                            defaultVisibility={selectedVisbilityOption}
                            existingCommentText={comment.comment_text}
                            buttonText={showEdit ? 'Update' : 'Comment'}
                            loading={loading}
                            showCommentAttachments={showCommentAttachments}
                            hideCourseTagging={user?.is_instructor}
                        />
                    </Box>
                )}

                {showAttachments && comment?.hasFiles === true && (
                    <Box mb={1} ml={5}>
                        <Attachments commentId={comment?._id} type={COMMENT} />
                    </Box>
                )}

                {/* comment action */}
                <Box display="flex" alignItems="center" position="relative">
                    {Object.entries(groupedReactions).map(
                        ([emoji, reactions]) => (
                            <Box
                                key={emoji}
                                onMouseEnter={() => setHoveredEmoji(emoji)}
                                onMouseLeave={() => setHoveredEmoji(null)}
                                position="relative"
                            >
                                <IconButton
                                    className={classes.action}
                                    size="small"
                                    onClick={() => {
                                        handleEmojiClick(emoji);
                                    }}
                                    disabled={reactionLoading}
                                >
                                    <Typography variant="h6" component="span">
                                        {emoji}{' '}
                                        {reactions.length > 1
                                            ? reactions.length
                                            : null}
                                    </Typography>
                                </IconButton>
                                {hoveredEmoji === emoji && (
                                    <Box
                                        position="absolute"
                                        top="100%"
                                        left="10%"
                                        transform="translateX(-10%)"
                                        bgcolor="background.paper"
                                        boxShadow={3}
                                        p={1}
                                        zIndex={1}
                                        minWidth={200}
                                    >
                                        {reactions.map((reaction) => (
                                            <UserDisplay
                                                key={reaction.user._id}
                                                response={reaction}
                                                userId={reaction.user._id}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        )
                    )}

                    {/* Likes is depricated, use reactions instead */}
                    {totalLikes > 0 && (
                        <>
                            <IconButton
                                // onClick={handleLike}
                                className={classes.action}
                                size="small"
                            >
                                {liked ? (
                                    <ClapsIcons
                                        color="secondary"
                                        fontSize="small"
                                    />
                                ) : (
                                    <ClapsIcons fontSize="small" />
                                )}
                            </IconButton>
                            <Typography variant="body2">
                                {totalLikes}
                            </Typography>
                        </>
                    )}
                    {/* <Tooltip title="Reply">
                        <IconButton
                            onClick={() => replyToComment(comment)}
                            className={classes.action}
                            size="small"
                        >
                            <ArrowBackRounded fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                </Box>
                {/* ReplyBox as been depricated! */}
                <ReplyBox
                    blockAccess={blockAccess}
                    user={user}
                    showAddReply={showAddReply}
                    starweaverAdmin={starweaverAdmin}
                    showReplies={replies}
                    commentUserName={comment.user_name}
                    hideAddReplies={hideReply}
                    showAllReplies={openReplies}
                    commentId={comment._id}
                    replies={comment.replies}
                    setComments={setComments}
                    mentionables={mentionables}
                    tasks={tasks}
                    teams={teams}
                    tasksOutputTemplate={tasksOutputTemplate}
                    showCommentAttachments={showCommentAttachments}
                    showAttachments={showAttachments}
                />
                <ReportBox
                    reportResponse={reportComment}
                    openReport={openReport}
                />
            </Box>
            <Divider />
            {isDeleting && <PageLoader open={isDeleting} />}
        </Box>
    );
};
