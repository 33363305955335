import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { CloseIcon } from '../../../icons';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';

const useStyles = makeStyles()((theme) => ({
    dialogtitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            flexDirection: 'row',
        },
    },
    inputWrappwer: {
        flex: 1,
    },
}));

function AddGuidanceDialog({
    handleClose,
    open,
    fetchGuidance,
    logisticsTemplateId,
    selectedRows = [],
    isEdit = false,
    rows = [],
}) {
    const { classes } = useStyles();
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [purpose, setPurpose] = useState('');
    const [priority, setPriority] = useState('');
    const [summary, setSummary] = useState('');
    const [selectedTypes, setSelectedTypes] = useState([]);
    const setAlert = useSetRecoilState(alertState);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [purposes, setPurposes] = useState([]);
    const [logisticsTemplateOptions, setLogisticsTemplateOptions] = useState(
        []
    );

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const { data } = await axios.get(
                    '/api/guidance/all-guidance-options'
                );
                const {
                    data: { templates },
                } = await axios.get(
                    '/api/client-requirements/get-logistic-templates'
                );

                const { categories, purposes } = data.allOptions;
                setCategories(categories);
                setPurposes(purposes);

                setLogisticsTemplateOptions(
                    templates
                        .filter((template) => template?.status === 'LIVE')
                        .reduce((prev, curr) => {
                            prev = prev.concat({
                                label: curr.name,
                                value: curr._id,
                            });
                            return prev;
                        }, [])
                );
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (isEdit && selectedRows.length === 1) {
            const guidance = rows.find((row) => row._id === selectedRows[0]);
            setTitle(guidance.title);
            setCategory(guidance.category);
            setSummary(guidance.summary);
            setPurpose(guidance.purpose);
            setSelectedTypes(guidance.logisticsTemplates);
        }
    }, [isEdit, rows, selectedRows, selectedRows.length]);

    const hideFieldsOnEdit = useMemo(() => {
        return isEdit && selectedRows.length > 1;
    }, [isEdit, selectedRows.length]);

    const handleCreateGuidance = async () => {
        try {
            setIsLoading(true);
            const payload = {
                category,
                purpose,
                priority,
                summary,
                title,
                logisticsTemplates: selectedTypes,
            };
            await axios.post('/api/guidance/add-guidance', payload);
            handleClose();
            await fetchGuidance(logisticsTemplateId);
            setAlert({
                show: true,
                message: 'New guidance added',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if (e.response.status === 422) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.ERROR,
                });
            } else {
                setAlert({
                    show: true,
                    message: "Something wen't wrong",
                    severity: severity.ERROR,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'title') {
            setTitle(value);
        } else if (name === 'purpose') {
            setPurpose(value);
        } else if (name === 'category') {
            setCategory(value);
        } else {
            setSummary(value);
        }
    };

    const onClose = () => {
        if (isLoading) return;
        handleClose();
    };

    const handleChangeType = (e) => {
        const {
            target: { value },
        } = e;
        setSelectedTypes(value);
    };

    const handleUpdateGuidance = async () => {
        try {
            setIsLoading(true);

            const payload = {
                guidanceIds: selectedRows,
                ...(summary.trim().length > 0 ? { summary } : {}),
                ...(category.trim().length > 0 ? { category } : {}),
                ...(purpose.trim().length > 0 ? { purpose } : {}),
                ...(title.trim().length > 0 ? { title } : {}),
                ...(selectedTypes.length > 0
                    ? { logisticsTemplates: selectedTypes }
                    : {}),
            };

            await axios.patch('/api/guidance/bulk-update', payload);

            await fetchGuidance(logisticsTemplateId);
            handleClose();
            setAlert({
                show: true,
                message: 'Successfully updated guidance',
                severity: severity.SUCCESS,
            });
        } catch (e) {
            if (e.response.status === 400 || e.response.status === 422) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.ERROR,
                });
            }
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePriorityChange = (e) => {
        setPriority(e.target.value);
    };

    return (
        <Dialog
            maxWidth={'sm'}
            scroll="paper"
            onClose={onClose}
            open={open}
            fullWidth
        >
            <DialogTitle sx={{ width: '100%' }} className={classes.dialogtitle}>
                {isEdit ? 'Update' : 'Add'} Guidance
                <IconButton size="small" onClick={onClose} disabled={isLoading}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {!hideFieldsOnEdit && (
                    <Box>
                        <TextField
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            type="text"
                            label="Title"
                            value={title}
                            name={'title'}
                            onChange={handleChange}
                            required={!isEdit}
                        />
                    </Box>
                )}

                <Box mt={2}>
                    <FormControl fullWidth required={!isEdit} color="secondary">
                        <InputLabel id="categoryLabel">Category</InputLabel>
                        <Select
                            labelId="categoryLabel"
                            id="categoryLabel-simple-select"
                            value={category}
                            label="Category"
                            name={'category'}
                            onChange={handleChange}
                        >
                            {categories.map((c) => (
                                <MenuItem key={c} value={c}>
                                    {c}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box mt={2}>
                    <FormControl fullWidth required={!isEdit} color="secondary">
                        <InputLabel id="purposeLabel">Purpose</InputLabel>
                        <Select
                            labelId="purposeLabel"
                            id="purposeLabel-simple-select"
                            value={purpose}
                            label="purpose"
                            name={'purpose'}
                            onChange={handleChange}
                        >
                            {purposes.map((p) => (
                                <MenuItem key={p} value={p}>
                                    {p}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box mt={2}>
                    <FormControl fullWidth required={!isEdit} color="secondary">
                        <InputLabel id="priorityLabel">Priority</InputLabel>
                        <Select
                            labelId="priorityLabel"
                            id="priorityLabel-simple-select"
                            value={priority}
                            label="Priority"
                            name={'priority'}
                            onChange={handlePriorityChange}
                        >
                            <MenuItem value="Nice to have">
                                <ListItemText primary="Nice to have" />
                            </MenuItem>
                            <MenuItem value="Critical">
                                <ListItemText
                                    primary="Critical"
                                    sx={{ color: 'red' }}
                                />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box mt={2}>
                    <FormControl fullWidth required={!isEdit} color="secondary">
                        <InputLabel id="typeLabel">Templates</InputLabel>
                        <Select
                            labelId="typeLabel"
                            id="typeLabel-simple-select"
                            input={<OutlinedInput label="Types" />}
                            name={'type'}
                            onChange={handleChangeType}
                            multiple
                            value={selectedTypes}
                            renderValue={(selected) =>
                                logisticsTemplateOptions
                                    .filter((opt) =>
                                        selected.includes(opt.value)
                                    )
                                    .map((opt) => opt.label)
                                    .join(', ')
                            }
                        >
                            {logisticsTemplateOptions.map((lt) => (
                                <MenuItem key={lt.value} value={lt.value}>
                                    <Checkbox
                                        checked={
                                            selectedTypes.indexOf(lt.value) > -1
                                        }
                                    />
                                    <ListItemText primary={lt.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {!hideFieldsOnEdit && (
                    <Box width={'100%'} mt={2}>
                        <TextField
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            type="text"
                            label="Summary"
                            multiline
                            maxRows={5}
                            minRows={5}
                            value={summary}
                            name={'summary'}
                            onChange={handleChange}
                            required={!isEdit}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={
                        isEdit ? handleUpdateGuidance : handleCreateGuidance
                    }
                    disabled={isLoading}
                >
                    {isLoading ? 'Please wait' : isEdit ? 'update' : 'create'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddGuidanceDialog;
