import { useRef, useMemo, useEffect } from 'react';
import { Box, PageSearchBox } from '../ui';
import { CommentsInput } from './CommentsInput';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { CommentsDisplay } from './CommentsDisplay';
import { useCallback, useState } from 'react';
import axios from 'axios';
// import { addCommentToSelector } from './recoil';
// import { useSetRecoilState } from 'recoil';
import { LoginModal } from '../auth/LoginModal';
import { useIsAdmin } from '../admin/useIsAdmin';
import { useComments } from './useComments';
import { getScrollableParent } from '../util';
import { useRecoilState } from 'recoil';
import { feedToReplyToState } from '../admin/recoil/state';
import { roleViewState } from '../navigation/recoil/roleView';

export const CommentSection = ({
    courseId,
    courseName,
    setCommentsLength = () => {
        /* console.log('drawer-def') */
    },
    courseSlug,
    mentionables = [],
    tasks = [],
    teams = [],
    tasksOutputTemplate,
    placeholder,
    showAllComments = true,
    afterCommentAdded = undefined,
    showCkEditor = false,
    visibilityOptions,
    clientId,
    defaultVisibility,
    existingCommentText = '',
    hideSearch = false,
    showCommentAttachments = false,
    showAttachments = false,
    hideCourseTagging = false,
    showRemindOption = false,
}) => {
    const { user } = useCurrentUser();
    const [roleView] = useRecoilState(roleViewState);
    const commentInputRef = useRef(null);
    const { isLoading, comments, setComments, updateComments } = useComments(
        courseId
    );
    const [openAccessBlock, setOpenAccessBlock] = useState(false);
    const { starweaverAdmin } = useIsAdmin();
    // const addCommentToSate = useSetRecoilState(addCommentToSelector);
    const [newComment, setNewComment] = useState({});
    const [searchText, setSearchText] = useState('');

    const [showEditor, setShowEditor] = useState(showCkEditor);

    const [commentToReply, setCommentToReply] = useState(null);

    const [_feedToReplyTo, setFeedToReplyTo] = useRecoilState(
        feedToReplyToState
    );

    const feedToReplyTo = useMemo(() => {
        if (_feedToReplyTo) {
            if (/^om-(\w+)/.test(courseId)) {
                const [, requirementId] = /^om-(\w+)/.exec(courseId);
                if (requirementId == _feedToReplyTo.requirementId)
                    return _feedToReplyTo;
                else return null;
            } else return null;
        } else {
            return null;
        }
    }, [_feedToReplyTo, courseId]);

    useEffect(() => {
        if (feedToReplyTo) setShowEditor(true);
        /* setTimeout(() => {
            navigateToCommentInput();
        }, 0); */
    }, [feedToReplyTo]);

    const blockAccess = () => {
        setOpenAccessBlock(true);
    };
    const onClose = () => {
        setOpenAccessBlock(false);
    };

    const uploadComment = useCallback(
        async ({
            userName,
            name,
            comment,
            emails,
            teamIds,
            visibility,
            selectedUsers,
            clientId,
            hasFiles = false,
            commentToReply,
            feedToReplyTo,
        }) => {
            let errorObject = {
                error: true,
                message: '',
            };
            if (!userName) {
                if (!name) {
                    errorObject.message = 'Please enter a display name';
                    return errorObject;
                }
            }
            if (!comment) {
                errorObject.message = 'Please enter a comment';
                return errorObject;
            }
            const commentObject = {
                courseId: courseId,
                courseName: courseName,
                courseSlug: courseSlug,
                userId: user?._id,
                commentText: comment,
                userName: userName ? '' : name,
                mentions: emails,
                mentionedTeamIds: teamIds,
                visibility,
                selectedUsers,
                clientId,
                hasFiles,
                commentToReply,
                feedToReplyTo: feedToReplyTo?._id,
            };
            try {
                const { data } = await axios.post(
                    '/api/content/comment/',
                    commentObject
                );
                // addCommentToSate(data.comment);
                const newComment = data.comment;
                /* STORE THE NEW COMMENT */
                setNewComment({
                    ...newComment,
                    ...(commentToReply ? { reply_to: commentToReply } : {}),
                });

                setCommentToReply(null);
                setFeedToReplyTo(null);
                if (afterCommentAdded !== undefined) {
                    afterCommentAdded([...comments, newComment]);
                }
                return data;
            } catch (error) {
                errorObject.message =
                    'Oops something happened, Please try again later!';
                return errorObject;
            }
        },
        [
            courseId,
            courseName,
            courseSlug,
            user?._id,
            setFeedToReplyTo,
            afterCommentAdded,
            comments,
        ]
    );

    const handleClearSearchText = async () => {
        try {
            if (searchText.trim().length === 0) return;
            setSearchText('');
            await updateComments();
        } catch (e) {
            console.log(e);
        }
    };

    const handleSearchComments = async () => {
        try {
            await updateComments(searchText);
        } catch (e) {
            console.log(e);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchComments();
        }
    };

    const navigateToCommentInput = () => {
        const commentInputElement = commentInputRef.current;

        if (commentInputElement) {
            const offset = 200; // Adjust this value as needed
            const scrollableParent = getScrollableParent(commentInputElement);

            const elementPosition = commentInputElement.getBoundingClientRect()
                .top;
            const parentScrollTop =
                scrollableParent === window
                    ? window.scrollY
                    : scrollableParent.scrollTop;
            const offsetPosition = elementPosition + parentScrollTop - offset;

            scrollableParent.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    const replyToComment = (comment) => {
        setCommentToReply(comment);
        setShowEditor(true);
        navigateToCommentInput();
    };

    return (
        <Box>
            <Box mt={2}>
                {!hideSearch && (
                    <Box mb={3}>
                        <PageSearchBox
                            searchValue={searchText}
                            handleClear={handleClearSearchText}
                            handleKeyPress={handleKeyPress}
                            handleSearch={handleSearchComments}
                            placeholder={'Search comments'}
                            setSearchValue={setSearchText}
                        />
                    </Box>
                )}
                <div ref={commentInputRef}>
                    <CommentsInput
                        courseId={courseId}
                        blockAccess={blockAccess}
                        user={user}
                        finalFunction={uploadComment}
                        placeholder={
                            placeholder
                                ? placeholder
                                : user?.is_instructor &&
                                  roleView.selectedView === 'instructor'
                                ? 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use / to tag tasks | Use ~ to tag a course'
                                : 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use # to tag teams | Use / to tag tasks | Use ~ to tag a course'
                        }
                        mentionables={mentionables}
                        tasks={tasks}
                        teams={teams}
                        tasksOutputTemplate={tasksOutputTemplate}
                        // showCkEditor={showCkEditor}
                        showEditor={showEditor}
                        setShowEditor={setShowEditor}
                        visibilityOptions={visibilityOptions}
                        defaultVisibility={defaultVisibility}
                        clientId={clientId}
                        existingCommentText={existingCommentText}
                        showCommentAttachments={showCommentAttachments}
                        commentToReply={commentToReply}
                        setCommentToReply={setCommentToReply}
                        feedToReplyTo={feedToReplyTo}
                        setFeedToReplyTo={setFeedToReplyTo}
                        hideCourseTagging={hideCourseTagging}
                    />
                </div>
            </Box>
            <Box mt={2}>
                <CommentsDisplay
                    setCommentsLength={setCommentsLength}
                    starweaverAdmin={starweaverAdmin}
                    user={user}
                    courseId={courseId}
                    newComment={newComment}
                    mentionables={mentionables}
                    tasks={tasks}
                    teams={teams}
                    tasksOutputTemplate={tasksOutputTemplate}
                    showAllComments={showAllComments}
                    isLoading={isLoading}
                    comments={comments}
                    setComments={setComments}
                    afterCommentAdded={afterCommentAdded}
                    visibilityOptions={visibilityOptions}
                    clientId={clientId}
                    showAttachments={showAttachments}
                    showCommentAttachments={showCommentAttachments}
                    replyToComment={replyToComment}
                    showRemindOption={showRemindOption}
                    placeholder={
                        placeholder
                            ? placeholder
                            : user?.is_instructor &&
                              roleView.selectedView === 'instructor'
                            ? 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use / to tag tasks | Use ~ to tag a course'
                            : 'Discuss progress, share feedback, and collaborate. Use @ to tag people | Use # to tag teams | Use / to tag tasks | Use ~ to tag a course'
                    }
                />
            </Box>
            <LoginModal
                open={openAccessBlock}
                onRequestClose={onClose}
                signUpLink="/pricing"
            />
        </Box>
    );
};
