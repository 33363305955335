import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Avatar, Box, Typography } from '../ui';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
    jobRole: ({ horizontalLayout }) => ({
        width: '100%',
        cursor: 'pointer',
        minHeight: horizontalLayout ? null : 300,
        height: '100%',
        overflow: 'hidden',
        borderRadius: 60,
        display: 'flex',
        flexDirection: horizontalLayout ? 'row' : 'column',
        alignItems: 'center',
        boxShadow: theme.shadows[10],
    }),
    headerSection: ({ horizontalLayout }) => ({
        backgroundColor: horizontalLayout ? null : '#e5e5e5',
        width: horizontalLayout ? null : '100%',
        height: horizontalLayout ? null : '100px',
        position: 'relative',
    }),
    profileImage: ({ horizontalLayout }) => ({
        position: horizontalLayout ? null : 'absolute',
        top: horizontalLayout ? null : '50%',
        left: horizontalLayout ? null : '50%',
        transform: horizontalLayout ? null : 'translate(-50%, -7%)',
        width: '100px',
        height: '100px',
        boxShadow: theme.shadows[5],
    }),
    subTitle: {
        color: theme.palette.grey[700],
    },
}));

export const ClientRequirementsInstructorDetailItem = ({
    title = '',
    slug = '',
    description = '',
    backgroundImg = '',
    thumbnail = '',
    horizontalLayout = true,
}) => {
    const { classes } = useStyles({ horizontalLayout });

    const getLimitedDescription = useMemo(() => {
        const splittedDesc = description.split(' ');
        const maxWord = 15;

        if (splittedDesc.length > maxWord) {
            return splittedDesc.slice(0, maxWord).join(' ') + '...';
        } else {
            return description;
        }
    }, [description]);

    return (
        <>
            {/* <Link
                to={`/channels/${slug}`}
                style={{
                    display: 'block',
                    height: '100%',
                }}
            > */}
            <Box className={classes.jobRole}>
                <Box
                    mb={horizontalLayout ? 0 : 7}
                    mr={horizontalLayout ? 2 : 0}
                    style={
                        horizontalLayout
                            ? null
                            : {
                                  backgroundImage: `url(${backgroundImg})`,
                                  backgroundPosition: 'bottom',
                              }
                    }
                    className={classes.headerSection}
                >
                    <Avatar className={classes.profileImage} src={thumbnail} />
                </Box>
                <Box pt={0} p={horizontalLayout ? 0 : 2}>
                    <Typography
                        variant="h6"
                        style={{
                            color: '#333',
                            textAlign: horizontalLayout ? 'left' : 'center',
                        }}
                    >
                        {title}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        className={classes.subTitle}
                        style={{
                            textAlign: horizontalLayout ? 'left' : 'center',
                        }}
                    >
                        {getLimitedDescription}
                    </Typography>
                </Box>
            </Box>
            {/* </Link> */}
        </>
    );
};
