import { coursesSlice } from '../courses';
import { searchOptionsSlice } from '../search-box';
import couponSlice from '../coupons/Helpers/Store';
//import socketSlice from './socketSlice';
import visibleColsSlice from '../admin/slices/visibleColsSlice';
import templateSlice from '../admin/slices/templateSlice';
import overallAnalyticsSlice from '../admin/slices/analyticsSlice';
import instructorExpertiseSlice from '../instructor-onboarding/slices/instructorOnboarding/instructorExpertiseSlice';
import curriculumBuilderSlice from '../course-builder/slices/curriculumSlice';
import tabSlice from '../constellations/slices/tabSlice';
import requirementSummaryReducer from '../admin/slices/requirementSummarySlice';
import filtersReducer from '../admin/slices/filtersSlice';
import commentAttachmentsReducer from '../comments/slices/commentAttachmentSlice';
import expertSummaryReducer from '../instructor/slices/expertSummarySlice';
import expertProjectModalReducer from '../admin/slices/expertProjectModalSlice';

export const reducers = {
    courses: coursesSlice.reducer,
    searchOptions: searchOptionsSlice.reducer,
    coupons: couponSlice.reducer,
    //socket: socketSlice.reducer,
    visibleCols: visibleColsSlice,
    templates: templateSlice,
    overallAnalytics: overallAnalyticsSlice,
    instructorExpertise: instructorExpertiseSlice,
    curriculumBuilder: curriculumBuilderSlice,
    constellationTabs: tabSlice,
    requirementSummary: requirementSummaryReducer,
    filters: filtersReducer,
    commentAttachments: commentAttachmentsReducer,
    expertSummary: expertSummaryReducer,
    expertProjectModal: expertProjectModalReducer,
};
