import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    columnField: '',
    filterType: '',
    operatorValue: '',
    filterValue: '',
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setColumnField(state, action) {
            state.columnField = action.payload;
        },
        setFilterType(state, action) {
            state.filterType = action.payload;
        },
        setOperatorValue(state, action) {
            state.operatorValue = action.payload;
        },
        setFilterValue(state, action) {
            state.filterValue = action.payload;
        },
        clearFilters(state) {
            state.columnField = '';
            state.filterType = '';
            state.operatorValue = '';
            state.filterValue = '';
        },
    },
});

export const {
    setColumnField,
    setFilterType,
    setOperatorValue,
    setFilterValue,
    clearFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
