import axios from 'axios';
import { useState, useEffect } from 'react';

const searchType = {
    TEXT_MATCHING: 'text-matching',
};

const usePersonaSearch = (query, pageNumber, resultsPerPage) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [results, setResults] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        setResults([]);
    }, [query]);

    useEffect(() => {
        let cancel = () => {};

        setLoading(true);
        setError(false);

        // const url = `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/search`;
        const url = `/api/job-roles/search-job-roles`;
        const params = {
            // [randomAlphaNumericString(6)]: 1, //prevents getting result from WP rocket cache
            query: query,
            page: pageNumber,
            // t: 'job-role',
            // s: 'any',
            status: 'all',
            type: searchType.TEXT_MATCHING,
        };

        axios({
            method: 'GET',
            url,
            params,
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
            .then((res) => {
                const jobRoles = res.data?.jobRoles ?? [];
                // Filter out job roles where the 'domain' field is empty or missing
                const filteredJobRoles = jobRoles.filter(
                    (job) => job.domain && job.domain.trim() !== ''
                );

                setResults((prevResult) => [
                    ...prevResult,
                    ...filteredJobRoles,
                ]);
                setHasMore(
                    filteredJobRoles.length < resultsPerPage ? false : true
                );
                setLoading(false);
            })
            .catch((e) => {
                if (axios.isCancel(e)) return;
                setError(true);
                setLoading(false);
            });

        return () => cancel();
    }, [query, pageNumber, resultsPerPage]);

    return { loading, error, results, hasMore, setResults };
};

export default usePersonaSearch;
