import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import axios from 'axios';
import moment from 'moment';
import {
    Box,
    Button,
    Grid,
    TextField,
    Container,
    CircularProgress,
} from '../ui';

import { MobileOtpVerification } from '../verify-mobile-number/MobileOtpVerification';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { ZoneData } from '../instructor-onboarding/ZoneData';
import { CountryData } from '../instructor-onboarding/CountryData';
import { async } from 'q';

//import { useCurrentUser } from '../my-account/useCurrentUser';

const heroValue = {
    heading: 'GET STARTED NOW',
};

export const SMEForm = ({
    setShowSMEForm,
    setSmeModal,
    setSMEIdToEdit,
    smeIdToEdit,
}) => {
    let navigate = useNavigate();
    const { classes } = useStyles();

    // const [edit, setEdit] = useState(true);
    const [saving, setSaving] = useState(false);

    const [savedSkypeId, setSavedSkypeId] = useState('');
    const [savedCountry, setSavedCountry] = useState('');
    const [savedInitialContact, setSavedInitialContact] = useState('');
    const [savedFollowUp, setSavedFollowUp] = useState('');
    const [savedTimeZone, setSavedTimeZone] = useState('');
    const [savedMobileNumber, setSavedMobileNumber] = useState('');
    const [savedCity, setSavedCity] = useState('');
    const [savedLinkedin, setSavedLinkedin] = useState('');
    const [savedTwitter, setSavedTwitter] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [skype_id, setSkype] = useState(savedSkypeId);
    const [city, setCity] = useState(savedCity);
    const [country, setCountry] = useState(savedCountry);
    const [initialContact, setInitialContact] = useState(savedInitialContact);
    const [followUp, setFollowUp] = useState(savedFollowUp);
    const [time_zone, setZone] = useState(savedTimeZone);
    const [twitter, setTwitter] = useState(savedTwitter);
    const [linkedin, setLinkdin] = useState(savedLinkedin);
    const [mobile_number, setMobileNumber] = useState(savedMobileNumber);
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    // const [enableMobileVerification, setEnableVerification] = useState(true);
    // const [mobileVerificationStatus, setMobileVerificationStatus] = useState(
    //     savedMobileNumber !== '' ? 'approved' : ''
    // );

    // const [countryCode, setCountryCode] = useState('');
    // const [verifyLinkdin, setVerifyLinkdin] = useState(linkedin);
    const [error, setError] = useState('');
    //const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        (async () => {
            if (smeIdToEdit) {
                const res = await axios.get(
                    `/api/smes/by-id?id=${smeIdToEdit}`
                );
                const sme = res.data;
                console.log(sme);
                if (!sme) return;
                setFirstName(sme.firstName);
                setLastName(sme.lastName);
                setCountry(sme.country);
                setInitialContact(
                    sme?.initialContact
                        ? moment(sme?.initialContact).format('YYYY-MM-DD')
                        : ''
                );
                setFollowUp(
                    sme?.followUp
                        ? moment(sme?.followUp).format('YYYY-MM-DD')
                        : ''
                );
                setLinkdin(sme.linkedin);
                setEmail(sme.email);
                setMobileNumber(sme.mobile_number);
                setNotes(sme.notes);
            }
        })();
    }, [smeIdToEdit]);

    const reg = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
    // const setLinkDinUrl = (e) => {
    //     setVerifyLinkdin((prev) => e.target.value);
    //     // console.log(e.target.value);
    //     if (reg.test(e.target.value || verifyLinkdin)) {
    //         setLinkdin(e.target.value || verifyLinkdin);
    //         setError('LinkedIn URL is correct');
    //     } else {
    //         setError('LinkedIn URL is wrong');
    //     }
    // };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMobileChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };

    const handleChangeCountry = (event) => {
        if (event.target.value === '') {
            setCountry('');
        } else if (event.target.value === '-----------------------------') {
            setCountry('');
        } else {
            setCountry(event.target.value);
        }
    };

    const handleChangeInitialContact = (e) => {
        setInitialContact(e.target.value);
    };

    const handleChangeFollowUp = (e) => {
        setFollowUp(e.target.value);
    };

    const handleLinkedinChange = (e) => {
        setLinkdin(e.target.value);
    };

    const handleSMEAdd = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (prev.smes.findIndex((elem) => elem._id === item._id) === -1) {
                return { ...prev, smes: [item, ...prev.smes] };
            } else {
                return prev;
            }
        });
    };

    const handleSMEEditAdd = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            const filteredSMEs = prev.smes.filter(
                (elem) => elem._id != item._id
            );

            return { ...prev, smes: [item, ...filteredSMEs] };
        });
    };

    // const handleChangeCountryCode = (event) => {
    //     setCountryCode(event.target.value);
    // };

    // const handleChangeZone = (event) => {
    //     if (event.target.value === '') {
    //         setCountry('');
    //     } else {
    //         setZone(event.target.value);
    //     }
    // };

    // const createAccount = () => {
    //     setComp('one');
    // };

    const save = async () => {
        try {
            if (!email.includes('@')) {
                alert('Provide a valid email');
                return;
            }
            if (
                firstName &&
                email &&
                // linkedin &&

                country
            ) {
                setSaving(true);

                // const authtoken = await user.getIdToken();
                console.log(linkedin);
                const res = await axios.post(
                    `/api/smes`,
                    {
                        firstName,
                        lastName,
                        email,
                        country,
                        mobile_number,
                        linkedin,
                        notes,
                        followUp,
                        initialContact,
                    }
                    // {
                    //     headers: {
                    //         authtoken,
                    //     },
                    // }
                );

                setSavedSkypeId(skype_id);
                setSavedCountry(country);
                setSavedInitialContact(initialContact);
                setSavedFollowUp(followUp);
                setSavedTimeZone(time_zone);

                setSavedCity(city);
                setSavedLinkedin(linkedin);
                setSavedTwitter(twitter);

                handleSMEAdd(res.data);

                setSaving(false);
                setShowSMEForm(false);
                // setEdit(false);
            } else {
                console.log(linkedin, city, country, time_zone);
                alert('Fill all the Required Fields');
            }
        } catch (e) {
            setSaving(false);
            console.log(e.response?.data?.message);
            if (e.response?.data?.message) alert(e.response?.data?.message);
        }
    };

    const edit = async () => {
        try {
            if (!email.includes('@')) {
                alert('Provide a valid email');
                return;
            }
            if (
                firstName &&
                email &&
                // linkedin &&

                country
            ) {
                setSaving(true);

                // const authtoken = await user.getIdToken();
                console.log(linkedin);
                const res = await axios.put(
                    `/api/smes`,
                    {
                        id: smeIdToEdit,
                        firstName,
                        lastName,
                        email,
                        country,
                        mobile_number,
                        linkedin,
                        notes,
                        followUp,
                        initialContact,
                    }
                    // {
                    //     headers: {
                    //         authtoken,
                    //     },
                    // }
                );

                setSavedSkypeId(skype_id);
                setSavedCountry(country);
                setSavedInitialContact(initialContact);
                setSavedFollowUp(followUp);
                setSavedTimeZone(time_zone);

                setSavedCity(city);
                setSavedLinkedin(linkedin);
                setSavedTwitter(twitter);

                handleSMEEditAdd(res.data);

                setSaving(false);
                setShowSMEForm(false);
                // setEdit(false);
            } else {
                console.log(linkedin, city, country, time_zone);
                alert('Fill all the Required Fields');
            }
        } catch (e) {
            setSaving(false);
            console.log(e.response?.data?.message);
            if (e.response?.data?.message) alert(e.response?.data?.message);
        }
    };

    // const enableEdit = () => {
    //     setEdit(true);
    // };

    const cancleEdit = () => {
        // setEdit(false);
        setSkype(savedSkypeId);
        setCountry(savedCountry);
        setZone(savedTimeZone);

        setCity(savedCity);
        setLinkdin(savedLinkedin);
        setTwitter(savedTwitter);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (smeIdToEdit) edit();
        else save();
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={firstName}
                        onChange={handleFirstNameChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="First Name (required)"
                        name="first name"
                        color="secondary"
                        helperText={error}
                        //disabled={user.user_firstName !== ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={lastName}
                        onChange={handleLastNameChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Last Name "
                        name="last name"
                        color="secondary"
                        helperText={error}
                        //disabled={user.user_lastName !== ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={email}
                        onChange={handleEmailChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email (required)"
                        name="email"
                        color="secondary"
                        helperText={error}
                        //disabled={user.user_lastName !== ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={mobile_number}
                        onChange={handleMobileChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Mobile number"
                        name="mobile_number"
                        color="secondary"
                        helperText={error}
                        //disabled={user.user_lastName !== ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={linkedin}
                        onChange={handleLinkedinChange}
                        variant="outlined"
                        margin="normal"
                        // value={linkedin}
                        // required
                        fullWidth
                        label="Linkedin Profile"
                        name="linkedin"
                        // autoComplete="email"
                        color="secondary"
                        helperText={error}
                        // disabled={!edit}
                    />
                </Grid>
                {/*  <Grid item xs={12}>
                    <TextField
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Twitter Profile (optional)"
                        name="email"
                        autoComplete="twitter"
                        color="secondary"
                        placeholder="optional"
                        // disabled={!edit}
                    />
                </Grid> */}

                {/* <Grid item xs={12}>
                    <TextField
                        value={skype_id}
                        onChange={(e) => setSkype(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Skype ID (optional)"
                        name="skype"
                        autoComplete="email"
                        color="secondary"
                        placeholder="Optional"
                        // disabled={!edit}
                    />
                </Grid> */}
                <Grid item xs={12} md={6}>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        required
                        label="Country (required)"
                        value={country}
                        onChange={handleChangeCountry}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Please select your country"
                        variant="outlined"
                        color="secondary"
                        // disabled={!edit}
                        fullWidth
                    >
                        {CountryData.map((option) => (
                            <option key={option.code} value={option.name}>
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>

                <Grid
                    item
                    container
                    sm={12}
                    md={6}
                    justifyContent={'space-between'}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.initialContactInput}
                    >
                        <TextField
                            name="initialContact"
                            label="Initial contact"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={initialContact}
                            onChange={handleChangeInitialContact}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.followUpInput}>
                        <TextField
                            name="followUp"
                            label="Planned follow up"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={followUp}
                            onChange={handleChangeFollowUp}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        value={notes}
                        onChange={handleNotesChange}
                        variant="outlined"
                        margin="normal"
                        // value={linkedin}
                        // required
                        multiline
                        maxRows={4}
                        fullWidth
                        label="Notes"
                        name="notes"
                        // autoComplete="email"
                        color="secondary"
                        helperText={error}
                        // disabled={!edit}
                    />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <div>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            label="Time Zone (required)"
                            required
                            value={time_zone}
                            onChange={handleChangeZone}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Please select your time zone"
                            variant="outlined"
                            color="secondary"
                            // disabled={!edit}
                        >
                            {ZoneData.map((option) => (
                                <option key={option.label} value={option.label}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </div>
                </Grid> */}

                {/* <Grid item xs={12}>
                    <TextField
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="city"
                        label="City (required)"
                        name="City"
                        autoComplete="email"
                        color="secondary"
                        // disabled={!edit}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <Box
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                        }}
                    >
                        <Box>
                            <Button
                                onClick={() => {
                                    setShowSMEForm(false);
                                    setSMEIdToEdit(null);
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </Box>

                        <Box style={{ marginLeft: 'auto' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={saving}
                            >
                                {saving ? (
                                    <CircularProgress size="1.9em" />
                                ) : true ? (
                                    <>Save</>
                                ) : (
                                    <>Edit</>
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

const useStyles = makeStyles()((theme) => ({
    radio: {
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
    },
    dashBoardHeading: {
        width: '600px',
        margin: '40px auto',
    },
    item: {
        flexBasis: '33%',
    },
    dob: {
        width: '200px',
    },
    backBtnContainer: {
        width: 150,
        marginLeft: '20%',
        fontSize: '5px',
    },
    dashBoardHeading: {
        width: '600px',
        margin: '40px auto',
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    leftRightMainContainer: {
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
        },
    },
    leftMainContainer: {
        width: 300,
        [theme.breakpoints.down('lg')]: {
            width: 300,
            margin: '20px auto',
        },
    },
    rightMainContainer: {
        width: 800,
        [theme.breakpoints.down('lg')]: {
            width: 500,
            margin: '20px auto',
        },
    },
    followUpInput: {
        [theme.breakpoints.up('sm')]: {
            margin: 0,
            paddingLeft: theme.spacing(1),
        },
    },
    initialContactInput: {
        margin: theme.spacing(1, 0, 3, 0),
        [theme.breakpoints.up('sm')]: {
            margin: 0,
            paddingRight: theme.spacing(1),
        },
    },
}));
