import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AddIcon, IconButton, MoreHorizonIcon } from '../icons';
import {
    Box,
    Button,
    Checkbox,
    ConfirmDialog,
    FormControlLabel,
    Loading,
    Menu,
    MenuItem,
    Modal,
    TextField,
    Typography,
} from '../ui';

const useStyles = makeStyles()((theme) => ({
    experienceCard: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
    },
    menuButton: {
        position: 'absolute',
        top: 10,
        right: 20,
        minWidth: '32px',
        minHeight: '32px',
    },
    description: {
        padding: '10px 0px 0px 0px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        color: '#555',
        transition: 'max-height 0.3s ease-in-out',
    },
    readMore: {
        color: '#0a66c2',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
        marginTop: '4px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '24px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        minWidth: '400px',
    },
    formControl: {
        marginBottom: '16px',
        width: '100%',
    },
    errorText: {
        color: 'red',
        fontSize: '0.875rem',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: theme.spacing(2),
        flexWrap: 'wrap',
    },
    instructions: {
        maxWidth: '80%',
        marginInline: 'auto',
        color: theme.palette.grey['800'],
    },
    instructionsAddBtn: {
        marginTop: theme.spacing(2),
    },
}));

const months = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: new Date(0, i).toLocaleString('default', { month: 'long' }),
}));
const years = Array.from({ length: 50 }, (_, i) => ({
    value: new Date().getFullYear() - i,
    label: new Date().getFullYear() - i,
}));

export const ProfileExperience = ({
    userDetails,
    onContinue = undefined,
    onBack = undefined,
}) => {
    const userId = userDetails?._id;
    const { classes } = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        title: '',
        company: '',
        currentRole: false,
        startDateMonth: '',
        startDateYear: '',
        endDateMonth: '',
        endDateYear: '',
        description: '',
    });
    const [errors, setErrors] = useState({});

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const fetchWorkExperiences = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                `/api/instructors/work-experience/${userId}`
            );
            setWorkExperiences(response.data.workExperiences);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching user experiences:', error);
        }
    };

    useEffect(() => {
        fetchWorkExperiences();
    }, [userId]);

    const handleEdit = (experience) => {
        setSelectedExperience(experience);
        setFormData({
            title: experience.title,
            company: experience.company,
            currentRole: experience.currentRole,
            startDateMonth: experience.startDate.month.toString(),
            startDateYear: experience.startDate.year.toString(),
            endDateMonth: experience.endDate?.month?.toString() || '',
            endDateYear: experience.endDate?.year?.toString() || '',
            description: experience.description,
        });
        setModalOpen(true);
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        if (!formData.company)
            newErrors.company = 'Company or Organization is required';
        if (!formData.startDateMonth)
            newErrors.startDateMonth = 'Start month is required';
        if (!formData.startDateYear)
            newErrors.startDateYear = 'Start year is required';
        if (
            !formData.currentRole &&
            (!formData.endDateMonth || !formData.endDateYear)
        ) {
            newErrors.endDate = 'End date is required unless currently working';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            currentRole: e.target.checked,
            endDateMonth: '',
            endDateYear: '',
        }));
        setErrors((prev) => ({ ...prev, endDate: '' }));
    };

    const handleClose = (e) => {
        setModalOpen(false);
        setSelectedExperience(null);
    };

    const handleSave = async () => {
        if (validate()) {
            const formattedData = {
                title: formData.title,
                company: formData.company,
                currentRole: formData.currentRole,
                startDate: {
                    month: parseInt(formData.startDateMonth, 10),
                    year: parseInt(formData.startDateYear, 10),
                },
                endDate: formData.currentRole
                    ? null
                    : {
                          month: parseInt(formData.endDateMonth, 10),
                          year: parseInt(formData.endDateYear, 10),
                      },
                description: formData.description,
            };
            try {
                if (selectedExperience) {
                    await axios.put(
                        `/api/instructors/edit-work-experience/${userId}/${selectedExperience._id}`,
                        formattedData
                    );
                } else {
                    const response = await axios.put(
                        `/api/instructors/create-work-experience/${userId}`,
                        formattedData
                    );
                }

                await fetchWorkExperiences();
                setModalOpen(false);
                setSelectedExperience(null);
                setFormData({
                    title: '',
                    company: '',
                    currentRole: false,
                    startDateMonth: '',
                    startDateYear: '',
                    endDateMonth: '',
                    endDateYear: '',
                    description: '',
                });
            } catch (error) {
                console.error('Error saving experience:', error);
            }
        }
    };

    const deleteWorkExperience = async () => {
        try {
            await axios.delete(
                `/api/instructors/delete-work-experience/${userId}/${selectedExperience._id}`
            );
            setSelectedExperience(null);
            setOpenConfirmDialog(false);
            await fetchWorkExperiences();
        } catch (error) {
            console.error('Error deleting job role:', error);
        }
    };

    if (isLoading) return <Loading />;

    return (
        <Box data-id="container">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: '16px', fontWeight: 'bold' }}
            >
                <Typography
                    variant="h5"
                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                    Work Experience
                </Typography>
                {workExperiences.length > 0 && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            setFormData({
                                title: '',
                                company: '',
                                currentRole: false,
                                startDateMonth: '',
                                startDateYear: '',
                                endDateMonth: '',
                                endDateYear: '',
                                description: '',
                            });
                            setModalOpen(true);
                        }}
                        size="small"
                        style={{ marginLeft: 'auto' }}
                    >
                        <AddIcon /> Add Experience
                    </Button>
                )}
            </Box>

            {workExperiences.map((experience, index) => {
                return (
                    <Box
                        key={experience._id || index}
                        className={classes.experienceCard}
                        position="relative"
                    >
                        <IconButton
                            className={classes.menuButton}
                            size="small"
                            onClick={handleMenuOpen}
                        >
                            <MoreHorizonIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleEdit(experience);
                                    handleMenuClose();
                                }}
                            >
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setSelectedExperience(experience);
                                    setOpenConfirmDialog(true);
                                    handleMenuClose();
                                }}
                            >
                                Delete
                            </MenuItem>
                        </Menu>

                        <Typography variant="h6" fontWeight="bold">
                            {experience.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontWeight="500"
                            color="textSecondary"
                        >
                            {experience.company}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {
                                months.find(
                                    (m) =>
                                        m.value === experience.startDate.month
                                )?.label
                            }{' '}
                            {experience.startDate.year} -{' '}
                            {experience.currentRole
                                ? 'Present'
                                : `${
                                      months.find(
                                          (m) =>
                                              m.value ===
                                              experience.endDate?.month
                                      )?.label || ''
                                  } 
                                ${experience.endDate?.year || ''}`}
                        </Typography>

                        <Typography
                            variant="body2"
                            className={classes.description}
                            style={{
                                WebkitLineClamp: expanded ? 'unset' : 1,
                                overflow: expanded ? 'visible' : 'hidden',
                            }}
                        >
                            {experience.description}
                        </Typography>

                        {experience.description.length > 100 && (
                            <Typography
                                className={classes.readMore}
                                onClick={() => setExpanded(!expanded)}
                            >
                                {expanded ? 'Show Less' : 'Read More'}
                            </Typography>
                        )}
                    </Box>
                );
            })}

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box className={classes.modalContent}>
                    <Typography variant="h6" style={{ marginBottom: '16px' }}>
                        {selectedExperience
                            ? `Update Experience`
                            : `Add Experience`}
                    </Typography>

                    <TextField
                        label="Title"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                        error={!!errors.title}
                        helperText={errors.title}
                        className={classes.formControl}
                    />

                    <TextField
                        label="Company or Organization"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        required
                        error={!!errors.company}
                        helperText={errors.company}
                        className={classes.formControl}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.currentRole}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label="I am currently working in this role"
                    />

                    <Box display="flex" gap="16px">
                        <TextField
                            select
                            label="Start Date (Month)"
                            name="startDateMonth"
                            value={formData.startDateMonth}
                            onChange={handleInputChange}
                            required
                            error={!!errors.startDateMonth}
                            helperText={errors.startDateMonth}
                            className={classes.formControl}
                        >
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            label="Start Date (Year)"
                            name="startDateYear"
                            value={formData.startDateYear}
                            onChange={handleInputChange}
                            required
                            error={!!errors.startDateYear}
                            helperText={errors.startDateYear}
                            className={classes.formControl}
                        >
                            {years.map((year) => (
                                <MenuItem key={year.value} value={year.value}>
                                    {year.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                    <Box display="flex" gap="16px">
                        <TextField
                            select
                            label="End Date (Month)"
                            name="endDateMonth"
                            value={formData.endDateMonth}
                            onChange={handleInputChange}
                            error={!!errors.endDate}
                            helperText={
                                formData.currentRole ? '' : errors.endDate
                            }
                            disabled={formData.currentRole}
                            className={classes.formControl}
                        >
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            label="End Date (Year)"
                            name="endDateYear"
                            value={formData.endDateYear}
                            onChange={handleInputChange}
                            error={!!errors.endDate}
                            helperText={
                                formData.currentRole ? '' : errors.endDate
                            }
                            disabled={formData.currentRole}
                            className={classes.formControl}
                        >
                            {years.map((year) => (
                                <MenuItem key={year.value} value={year.value}>
                                    {year.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                    <TextField
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        multiline
                        rows={4}
                        className={classes.formControl}
                    />
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Box>
            </Modal>

            <ConfirmDialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={deleteWorkExperience}
                title="Are you sure you want to delete this work experience?"
                content="Are you sure you want to delete the selected work experiences?"
                confirmButtonText="Delete"
            />

            {workExperiences.length === 0 && (
                <Box
                    textAlign={'center'}
                    data-id="instructions"
                    className={classes.instructions}
                >
                    <Typography variant="h6">
                        Add Your First Work Experience
                    </Typography>
                    <Typography variant="body1">
                        Your journey starts here! Add your past roles and
                        highlight your achievements to enhance your profile.
                    </Typography>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AddIcon />}
                        className={classes.instructionsAddBtn}
                        onClick={() => {
                            setFormData({
                                title: '',
                                company: '',
                                currentRole: false,
                                startDateMonth: '',
                                startDateYear: '',
                                endDateMonth: '',
                                endDateYear: '',
                                description: '',
                            });
                            setModalOpen(true);
                        }}
                    >
                        Add Experience
                    </Button>
                </Box>
            )}

            <Box className={classes.buttonGroup}>
                {onBack && (
                    <Button variant="text" color="primary" onClick={onBack}>
                        Back
                    </Button>
                )}
                {onContinue && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onContinue}
                    >
                        Continue
                    </Button>
                )}
            </Box>
        </Box>
    );
};
