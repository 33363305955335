import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FeedText from './FeedText';
import moment from 'moment';
import { FileCopy } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { getBaseURL } from '../../../util';
import { alertState, severity } from '../../../app/recoil';
import { useSetRecoilState } from 'recoil';
import { deepPurple } from '@mui/material/colors';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import { COMMENTS } from '../../requirementConstants';
import CommentIcon from '@mui/icons-material/Comment';
import { useNavigate } from 'react-router-dom';
import { feedToReplyToState } from '../../recoil/state';
import { CloseIcon } from '../../../icons';

const useStyles = makeStyles()((theme) => ({
    mainContent: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        backgroundColor: theme.palette.grey['100'],
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
    },
    avatarContainer: {
        alignSelf: 'flex-start',
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    iconGroup: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        alignSelf: 'flex-start',
    },
    arrowIcon: {
        transition: 'transform 150ms linear',
    },
    active: {
        transform: 'rotate(-180deg)',
    },
    deactivate: {
        transform: 'rotate(0deg)',
    },
}));

function FeedCardHeader({
    row,
    isInstructorView,
    selectedRole,
    showEditor,
    handleToggleEditor,
    handleHideCkEditor,
    hideCollapseBtn,
    hideCommentBtn,
    hideCopyBtn,
    onClose,
}) {
    const { classes } = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const setAlert = useSetRecoilState(alertState);

    const setFeedToReplyTo = useSetRecoilState(feedToReplyToState);

    const adminOMDetailsPath = '/admin/opportunity-management/details/';
    const instructorOMDetailsPath =
        '/profile/instructor-dashboard/opportunity-management/details/';

    const OMDetailsPath = isInstructorView
        ? instructorOMDetailsPath
        : selectedRole
        ? `/${selectedRole}/opportunity-management/details/`
        : adminOMDetailsPath;

    const userFirstLetter = useMemo(() => {
        return row.user.firstName.charAt(0).toUpperCase();
    }, [row.user.firstName]);

    const dateTimeStr = useMemo(() => {
        return moment(row.updatedAt).format('DD-MMM-YYYY, hh:mm A');
    }, [row.updatedAt]);

    const requirementCommentsPath = useMemo(() => {
        const url = `${OMDetailsPath}${row?.requirementId}${`/${COMMENTS}`}`;
        return url;
    }, [OMDetailsPath, row?.requirementId]);

    const goToComments = () => {
        setFeedToReplyTo(row);
        navigate(requirementCommentsPath);
    };

    const handleCopyLink = async () => {
        const pathname = location.pathname;
        let baseURL = getBaseURL(pathname);
        const pageRoute = `${baseURL}?feed_id=${row._id}`;
        if ('clipboard' in navigator) {
            // for modern browsers
            await navigator.clipboard.writeText(pageRoute);
        } else {
            // for internet explorer
            document.execCommand('copy', true, pageRoute);
        }
        // show success alert
        setAlert({
            show: true,
            message: 'Copied to clipboard',
            severity: severity.SUCCESS,
        });
    };

    const handleCollapse = () => {
        handleToggleEditor();
        handleHideCkEditor();
    };

    return (
        <div className={classes.mainContent}>
            <div className={classes.avatarContainer}>
                <Avatar className={classes.purple}>{userFirstLetter}</Avatar>
            </div>
            <div>
                <FeedText
                    row={row}
                    isInstructorView={isInstructorView}
                    selectedRole={selectedRole}
                    OMDetailsPath={OMDetailsPath}
                />
                <Typography variant="caption">{dateTimeStr}</Typography>
            </div>

            <div className={classes.iconGroup}>
                {row?.field !== COMMENTS && !hideCommentBtn ? (
                    <div>
                        <Tooltip title={'Comment'}>
                            <IconButton size="small" onClick={goToComments}>
                                <CommentIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : (
                    <></>
                )}

                {!hideCollapseBtn ? (
                    <div className={classes.foldContainer}>
                        <Tooltip
                            placement="top"
                            title={showEditor ? 'Hide' : 'Show'}
                        >
                            <IconButton size="small" onClick={handleCollapse}>
                                <ArrowDropDownCircleRoundedIcon
                                    fontSize="small"
                                    color="primary"
                                    className={`${classes.arrowIcon} ${
                                        showEditor
                                            ? classes.active
                                            : classes.deactivate
                                    }`}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}

                {!hideCopyBtn && (
                    <div className={classes.copyIcon}>
                        <Tooltip title={'Copy link'}>
                            <IconButton size="small" onClick={handleCopyLink}>
                                <FileCopy fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}

                {onClose ? (
                    <div>
                        <Tooltip title={'Close'}>
                            <IconButton size="small" onClick={onClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default FeedCardHeader;
