import {
    Autocomplete,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon } from '../../../icons';

const useStyles = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

function AddNewDialog({
    open,
    handleClose,
    type,
    setShowConfigureDialog,
    guidanceOptId,
}) {
    const { classes } = useStyles();
    const [categories, setCategories] = useState([]);
    const [purposes, setPurposes] = useState([]);
    const setAlert = useSetRecoilState(alertState);
    const [isLoading, setIsLoading] = useState(false);

    const title = useMemo(() => {
        const firstChar = type.charAt(0);
        const rest = type.slice(1);
        return firstChar.toUpperCase() + rest;
    }, [type]);

    const onClose = () => {
        if (isLoading) return;
        handleClose();
        setShowConfigureDialog(true);
    };

    const onAddNew = (_, newValues) => {
        if (type === 'categories') {
            setCategories(newValues);
        } else {
            setPurposes(newValues);
        }
    };

    const handleAdd = async () => {
        try {
            setIsLoading(true);
            const payload = {
                _id: guidanceOptId,
                ...(type === 'categories' ? { categories } : { purposes }),
            };

            const url =
                type === 'categories'
                    ? '/api/guidance/add-categories'
                    : '/api/guidance/add-purposes';

            await axios.post(url, payload);
            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: `Added new ${type}`,
            });
            onClose();
        } catch (e) {
            if (e.response.status === 400 || e.response.status === 422) {
                setAlert({
                    show: true,
                    severity: severity.ERROR,
                    message: e.response.data.message,
                });
            }
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth={'sm'}
            scroll="paper"
            onClose={onClose}
            open={open}
            fullWidth
        >
            <DialogTitle className={classes.header}>
                Add {title}
                <IconButton size="small" onClick={onClose} disabled={isLoading}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Autocomplete
                    clearIcon
                    options={[]}
                    freeSolo
                    multiple
                    size="medium"
                    value={type === 'categories' ? categories : purposes}
                    onChange={onAddNew}
                    renderTags={(value, props) =>
                        value.map((option, index) => (
                            <Chip label={option} {...props({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            id="tags"
                            name="tags"
                            placeholder={`Write the ${type} and press enter to add`}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            {...params}
                        />
                    )}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleAdd}
                    disabled={isLoading}
                >
                    {isLoading ? 'Please wait' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddNewDialog;
