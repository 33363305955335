import React, { useCallback, useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Card, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DATE, STRING, ARRAY, BOOLEAN } from '../util/types';
import {
    DataGridPremium,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { Box } from '@mui/material';
import { LinearLoader } from '../util';

let filterTimeoutId = null;

const useStyles = makeStyles()((theme) => ({
    root: { marginTop: 40 },
    card: {
        padding: theme.spacing(2),
        borderRadius: '0.375rem',
    },
    truncatedText: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

const UserChanges = ({ id }) => {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [filters, setFilters] = useState({
        columnField: '',
        operatorValue: '',
        value: '',
    });
    const theme = useTheme();
    const isSMDevice = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const params = new URLSearchParams();
            params.append('selectedUserId', id);
            params.append('page', page);
            params.append('limit', limit);

            if (filters.value) {
                const stringifiedFilters = JSON.stringify(filters);
                params.append('filter', stringifiedFilters);
            }

            const url = `/api/users/changes?${params.toString()}`;
            const res = await axios.get(url);

            setRows(res.data.changes);
            setRowCount(res.data.count);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, [id, limit, page, filters]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(() => {
        return [
            {
                field: 'category',
                headerName: 'Category',
                ...(isSMDevice ? { width: 200 } : { flex: 1 }),
                filterable: true,
                sortable: false,
            },
            {
                field: 'label',
                headerName: 'Field',
                ...(isSMDevice ? { width: 200 } : { flex: 1 }),
                filterable: true,
                sortable: false,
            },
            {
                field: 'user',
                headerName: 'Updated By',
                ...(isSMDevice ? { width: 200 } : { flex: 1 }),
                valueFormatter: (value) => {
                    return `${value.firstName} ${value.lastName}`;
                },
                filterable: true,
                sortable: false,
            },
            {
                field: 'value',
                headerName: 'Updated To',
                ...(isSMDevice ? { width: 200 } : { flex: 1 }),
                display: 'flex',
                renderCell: ({ value, row }) => {
                    // Handle different data types
                    if (row.type === DATE) {
                        return moment(value).format('DD-MMM-YYYY');
                    }

                    if (row.type === BOOLEAN) {
                        return value ? 'Yes' : 'No';
                    }

                    if (row.type === ARRAY) {
                        return Array.isArray(value) ? value.join(', ') : '';
                    }

                    if (row.type === STRING) {
                        return (
                            <span className={classes.truncatedText}>
                                {value}
                            </span>
                        );
                    }

                    return (
                        <span className={classes.truncatedText}>{value}</span>
                    );
                },
                filterable: false,
                sortable: false,
            },
            {
                field: 'updatedAt',
                headerName: 'Updated At',
                ...(isSMDevice ? { width: 200 } : { flex: 1 }),
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
                filterable: false,
                sortable: false,
            },
        ];
    }, [classes.truncatedText, isSMDevice]);

    const onFilterChange = React.useCallback((filterModel) => {
        if (filterModel?.items?.length === 0) return removeFilters();
        const { columnField, operatorValue, value } = filterModel?.items[0];
        if (!value) {
            return removeFilters();
        }
        if (filterTimeoutId) {
            window.clearTimeout(filterTimeoutId);
        }
        filterTimeoutId = window.setTimeout(() => {
            setPage(0);
            setFilters({ columnField, operatorValue, value });
        }, [2000]);
    }, []);

    const removeFilters = () => {
        setFilters({ columnField: '', operatorValue: '', value: '' });
    };

    return (
        <Box>
            <Card elevation={2} className={classes.card}>
                {isLoading && <LinearLoader />}
                <DataGridPremium
                    autoHeight
                    rows={rows}
                    columns={columns}
                    rowHeight={40}
                    paginationModel={{ pageSize: limit, page: page }}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    onPaginationModelChange={({ page, pageSize }) => {
                        setPage(page);
                        setLimit(pageSize);
                    }}
                    rowCount={rowCount}
                    pagination
                    paginationMode="server"
                    slots={{
                        toolbar: CustomToolBar,
                    }}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    loading={isLoading}
                    style={{ minHeight: '180px' }}
                />
            </Card>
        </Box>
    );
};

const CustomToolBar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
};

export default UserChanges;
