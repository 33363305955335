import { /* useEffect, */ useMemo /* useRef */ } from 'react';
import {
    Drawer,
    Box,
    /*  Typography, */ Divider /* IconButton */,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import SummaryDrawerHeader from './SummaryDrawerHeader';
import { useSelector, useDispatch } from 'react-redux';
import RequirementSummary from './RequirementSummary';
import StreamDrawerContent from '../OMDFeeds/StreamDrawerContent';
import { useTADs } from '../../hooks/useTADs';
import { useCurrentUser } from '../../../my-account/useCurrentUser';
import { getUniqueObjectsByField, roleList } from '../../../util';
import { useTeams } from '../../useTeams';
import { visibilities } from '../../../util/visibility';
import { useIsAdmin } from '../../useIsAdmin';
import { requirementTasksOutputTemplateInit } from '../../../comments/templates';
// import LinkIcon from '@mui/icons-material/Link';
import SummaryHeading from './SummaryHeading';
// import { OMLogisticSection } from '../../OMLogisticSection';
import {
    // fetchRequirementById,
    hideRequirementSummary,
} from '../../slices/requirementSummarySlice';
import profilePicPlaceholder from '../../../img/profilePicPlaceholder.jpg';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
    boxContainer: {
        width: '100vw',
        paddingTop: theme.spacing(8),
        paddingInline: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            width: 'min(70vw, 850px)',
            paddingTop: theme.spacing(12),
            paddingInline: theme.spacing(2),
        },
    },
    drawer: {
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey['400'],
            borderRadius: '1rem',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.grey['300'],
        },
    },
}));

function RequirementSummaryDrawer({ isInstructor, isPipeline = false }) {
    const location = useLocation();
    const container =
        window !== undefined ? () => window.document.body : undefined;
    // const drawerRef = useRef(null);
    const theme = useTheme();
    const { classes } = useStyles();
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const { admins: tads } = useTADs();
    const { starweaverAdmin } = useIsAdmin();
    const { user: userDetails, isLoading: isUserLoading } = useCurrentUser();
    const { teams, isLoading: isLoadingTeams } = useTeams();
    const dispatch = useDispatch();

    /* useEffect(() => {
        function handleClickOutside(e) {
            if (drawerRef.current && !drawerRef.current.contains(e.target)) {
                dispatch(hideRequirementSummary());
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]); */

    const mentionables = useMemo(() => {
        const {
            shortListedSmes = [],
            smes = [],
            collaborators = [],
            addedCollaborators = [],
        } = requirementSummary.requirementData;

        const allAssociates = [
            ...shortListedSmes,
            ...smes,
            ...collaborators,
            ...addedCollaborators,
            ...tads,
        ];

        const uniqueAssociates = getUniqueObjectsByField(
            allAssociates,
            'email'
        );

        return (
            uniqueAssociates
                .filter(({ email }) => userDetails?.user_email !== email)
                .map(
                    ({
                        _id,
                        firstName,
                        lastName,
                        email,
                        profilePicUrl,
                        profilePicUrlWP,
                    }) => ({
                        id: _id,
                        name: `${firstName} ${lastName}`,
                        _name: `${firstName} ${lastName}`,
                        email,
                        profilePicUrl:
                            profilePicUrl ||
                            profilePicUrlWP ||
                            profilePicPlaceholder,
                    })
                ) || []
        );
    }, [requirementSummary.requirementData, tads, userDetails?.user_email]);

    const allTasks = useMemo(() => {
        const tasks = [];

        // Traverse through each category
        requirementSummary.requirementData?.taskCategories?.forEach(
            (category) => {
                // Traverse through each task in the category
                category?.tasks
                    ?.filter(({ isActive, isVisibleToInstructor }) =>
                        isInstructor
                            ? isActive && isVisibleToInstructor
                            : isActive
                    )
                    ?.forEach(({ _id, name }) => {
                        tasks?.push({ id: _id, name, _name: name });
                    });
            }
        );

        return tasks;
    }, [requirementSummary.requirementData?.taskCategories, isInstructor]);

    const visibilityOptions = useMemo(() => {
        return isInstructor
            ? [
                  {
                      option: visibilities.STARWEAVER_TEAM.visibility,
                      icon: visibilities.STARWEAVER_TEAM.icon,
                  },
              ]
            : [
                  {
                      option: visibilities.STARWEAVER_TEAM_AND_SMES.visibility,
                      icon: visibilities.STARWEAVER_TEAM_AND_SMES.icon,
                  },
                  {
                      option: visibilities.STARWEAVER_TEAM.visibility,
                      icon: visibilities.STARWEAVER_TEAM.icon,
                  },
                  ...(starweaverAdmin &&
                  requirementSummary.requirementData?.clientId
                      ? [
                            {
                                option: visibilities.CLIENT.visibility,
                                icon: visibilities.CLIENT.icon,
                            },
                        ]
                      : []),
                  {
                      option: visibilities.ADMINS.visibility,
                      icon: visibilities.ADMINS.icon,
                  },

                  ...(starweaverAdmin
                      ? [
                            {
                                option: visibilities.EVERYONE.visibility,
                                icon: visibilities.EVERYONE.icon,
                            },
                            {
                                option: visibilities.SELECTED_USERS.visibility,
                                icon: visibilities.SELECTED_USERS.icon,
                            },
                            {
                                option: visibilities.ONLY_ME.visibility,
                                icon: visibilities.ONLY_ME.icon,
                            },
                        ]
                      : []),
              ];
    }, [
        requirementSummary.requirementData?.clientId,
        starweaverAdmin,
        isInstructor,
    ]);

    const isLoading = useMemo(() => {
        if (isUserLoading || isLoadingTeams) {
            return true;
        }
        return false;
    }, [isLoadingTeams, isUserLoading]);

    const showDrawerContent = useMemo(() => {
        if (
            !isLoading &&
            Object.keys(requirementSummary.requirementData).length > 0
        ) {
            return true;
        }
        return false;
    }, [isLoading, requirementSummary.requirementData]);

    const allCollaborators = useMemo(() => {
        let collaborators = [];

        roleList.forEach(({ field }) => {
            if (requirementSummary.requirementData[field])
                collaborators = [
                    ...collaborators,
                    ...requirementSummary.requirementData[field],
                ];
        });

        return getUniqueObjectsByField(collaborators, '_id');
    }, [requirementSummary.requirementData]);

    /**
     * this makes sure that logistics tab in drawer is not visible in open requirements same as details page
     */
    /* const hideLogisticsTab = useMemo(() => {
        if (isInstructor) {
            const isInProgress = location.pathname.includes('in-progress');
            const isCompleted = location.pathname.includes('completed');
            if (isInProgress || isCompleted) {
                return false;
            }

            return true;
        }

        return false;
    }, [isInstructor, location.pathname]); */

    const handleClose = () => {
        dispatch(hideRequirementSummary());
    };

    return (
        <Drawer
            container={container}
            variant="persistent"
            anchor={theme.direction === 'rtl' ? 'left' : 'right'}
            open={requirementSummary.showSummary}
            ModalProps={{
                keepMounted: true,
            }}
            classes={{
                paper: classes.drawer,
            }}
            onClose={handleClose}
            // ref={drawerRef}
        >
            {showDrawerContent && (
                <Box className={classes.boxContainer}>
                    <SummaryDrawerHeader handleClose={handleClose} />

                    <Divider />

                    <SummaryHeading isInstructor={isInstructor} />

                    <RequirementSummary isPipeline={isPipeline} />

                    <Box mt={2} pb={2}>
                        <StreamDrawerContent
                            requirementId={
                                requirementSummary.requirementData
                                    ?.requirementId
                            }
                            isInstructorView={isInstructor}
                            // selectedRole={selectedRole}
                            title={requirementSummary.requirementData?.title}
                            mentionables={mentionables}
                            allTasks={allTasks}
                            teams={teams}
                            visibilityOptions={visibilityOptions}
                            clientId={
                                requirementSummary.requirementData?.clientId
                            }
                            isInstructor={isInstructor}
                            showCloseBtn={false}
                            showCkEditor={requirementSummary.isTaskColClicked}
                            existingCommentText={
                                requirementSummary.isTaskColClicked
                                    ? requirementTasksOutputTemplateInit(
                                          requirementSummary.requirementData
                                              ?.requirementId,
                                          requirementSummary.taskData.name
                                      )
                                    : ''
                            }
                            showLogistics={
                                requirementSummary.isTaskColClicked
                                    ? false
                                    : true
                            }
                            starweaverAdmin={starweaverAdmin}
                            allCollaborators={allCollaborators}
                            team={{
                                ...roleList.reduce((prev, curr) => {
                                    const key = curr.field;
                                    prev[key] =
                                        requirementSummary.requirementData[
                                            key
                                        ] ?? [];
                                    return prev;
                                }, {}),
                            }}
                            isPipeline={isPipeline}
                        />
                    </Box>

                    {/* {requirementSummary.isPercentageColClicked && (
                        <Box mt={2} pb={2}>
                            <OMLogisticSection
                                requirement={requirementSummary.requirementData}
                                // setRequirement={setRequirement}
                                loadRequirementData={(requirementId) =>
                                    dispatch(
                                        fetchRequirementById(requirementId)
                                    )
                                }
                                isInstructor={isInstructor}
                                starweaverAdmin={starweaverAdmin}
                                // updateRequirement={updateRequirement}
                                allCollaborators={allCollaborators}
                                isInDrawer={true}
                            />
                        </Box>
                    )} */}
                </Box>
            )}
        </Drawer>
    );
}

export default RequirementSummaryDrawer;
