import { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import hcSeries from 'highcharts/modules/series-label';
import exporting from 'highcharts/modules/exporting';
import hcExport from 'highcharts/modules/export-data';
import hcAccess from 'highcharts/modules/accessibility';

if (typeof Highcharts === 'object') {
    hcSeries(Highcharts);
    exporting(Highcharts);
    hcAccess(Highcharts);
    hcExport(Highcharts);
}

function MultiAxisChart({
    title,
    subtitle,
    seriesData,
    categories,
    tooltip = undefined,
    yAxis,
}) {
    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                zooming: {
                    type: 'xy',
                },
            },
            title: {
                align: 'center',
                text: title,
            },
            subtitle: {
                align: 'center',
                text: subtitle && subtitle.length > 0 ? subtitle : '',
            },
            xAxis: {
                categories,
                crosshair: true,
            },
            credits: {
                enabled: false,
            },
            yAxis,
            ...(tooltip !== undefined ? { tooltip } : {}),
            legend: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                    pointStart: 0,
                },
            },
            series: seriesData,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 1000,
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom',
                            },
                        },
                    },
                ],
            },
        };
    }, [categories, seriesData, subtitle, title, tooltip, yAxis]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
}

export { MultiAxisChart };
