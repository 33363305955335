import { useEffect, useCallback, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    // DisplayAllFilters,
    FormControl,
    IconButton,
    MenuItem,
    // Paper,
    // Popper,
    Select,
    SelectOptions,
    TextField,
    Typography,
    Tooltip,
} from '..';
import { makeStyles } from 'tss-react/mui';
import { AND, OR } from '../../util';
import {
    STRING,
    DATE,
    BOOLEAN,
    TASK,
    TIMESTAMP_PRESET,
} from '../../util/types';
import {
    AddIcon,
    RemoveIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '../../icons';
import { sentenceCase } from '../../util';
import { Info } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { DragIndicatorRounded } from '@mui/icons-material';
import { KeyboardDoubleArrowLeftRounded } from '@mui/icons-material';
import { arraysEqual } from '../../util';
// import InfoDialog from '../../admin/components/RequirementsWithSelectedCol/InfoDialog';

const TEXT_OPTIONS = [
    {
        value: 'contains',
        label: 'Contains',
    },
    {
        value: 'equals',
        label: 'Equals',
    },
    {
        value: 'startsWith',
        label: 'Starts with',
    },
    {
        value: 'endsWith',
        label: 'Ends with',
    },
    {
        value: 'doesNotContain',
        label: 'Does not contain',
    },
    {
        value: 'notEmpty',
        label: 'Not empty',
    },
];
const BOOLEAN_OPTIONS = [
    {
        value: 'is',
        label: 'Is',
    },
    // {
    //     value: 'isNot',
    //     label: 'Is not',
    // },
];

const TIMESTAMP_PRESET_OPTIONS = [
    {
        value: 'last24Hrs',
        label: 'Last 24 hours',
    },
    {
        value: 'last3Days',
        label: 'Last 3 Days',
    },
    {
        value: 'last7Days',
        label: 'Last 7 Days',
    },
    {
        value: 'last15Days',
        label: 'Last 15 Days',
    },
];

const DATE_OPTIONS = [
    {
        value: 'is',
        label: 'Is',
    },
    {
        value: 'isNot',
        label: 'Is not',
    },
    {
        value: 'isAfter',
        label: 'Is after',
    },
    {
        value: 'isOnAfter',
        label: 'Is on or after',
    },
    {
        value: 'isBefore',
        label: 'Is on or before',
    },
    {
        value: 'isOnBefore',
        label: 'Is before',
    },
    {
        value: 'last7days',
        label: 'Last 7 days',
    },
    {
        value: 'last10days',
        label: 'Last 10 days',
    },
    {
        value: 'last15days',
        label: 'Last 15 days',
    },
];

const TASK_OPTIONS = [
    {
        value: 'completedOn',
        label: 'Completed on',
    },
    {
        value: 'completedAfter',
        label: 'Completed after',
    },
    {
        value: 'completedOnAfter',
        label: 'Completed on or after',
    },
    {
        value: 'completedBefore',
        label: 'Completed before',
    },
    {
        value: 'completedOnBefore',
        label: 'Completed on or before',
    },

    {
        value: 'isDone',
        label: 'Is completed',
        type: BOOLEAN,
    },
    {
        value: 'is',
        label: 'Due on',
    },
    {
        value: 'isNot',
        label: 'Is not due on',
    },
    {
        value: 'isAfter',
        label: 'Is due after',
    },
    {
        value: 'isOnAfter',
        label: 'Is due on or after',
    },
    {
        value: 'isBefore',
        label: 'Is due before',
    },
    {
        value: 'isOnBefore',
        label: 'Is due on or before',
    },
    {
        value: 'isOverdue',
        label: 'Is overdue',
    },
    {
        value: 'oneDayRemaining',
        label: '1 day remaining',
    },
    {
        value: 'threeOrLessDaysRemaining',
        label: '3 days or less remaining',
    },
    {
        value: 'sevenOrLessDaysRemaining',
        label: '7 days or less remaining',
    },
    {
        value: 'completedIn',
        label: 'Completed In',
        type: TIMESTAMP_PRESET,
    },
];

export const FiltersSelect = ({
    filters,
    selectedFilters,
    setSelectedFilters,
    allSelectedFilters,
    disabled,
    size,

    filterGroupIndexes,

    // dragItem,
    // dragOverItem,
    dragStart,
    dragEnter,
    drop,
    dragOverItemPosition,
    isNotValidGroup,

    noAndOrMixing,
    // creator,
    // createdAt,
}) => {
    // console.log(dragOverItemPosition);
    // console.log(allSelectedFilters);
    // console.log(filterGroupIndexes);
    const { classes } = useStyles();
    const theme = useTheme();

    // const dragItem = useRef();
    // const dragOverItem = useRef();

    // const infoModalRef = useRef(null);
    // const unUsedFilterOptions = useMemo(
    //     () =>
    //         filters.filter(
    //             ({ value }) =>
    //                 ![
    //                     ...new Set(
    //                         selectedFilters.map(
    //                             ({ columnField }) => columnField
    //                         )
    //                     ),
    //                 ].includes(value)
    //         ),
    //     [filters, selectedFilters]
    // );

    const [showFilterGroupControl, setShowFilterGroupControl] = useState(null);

    const hideValueField = useCallback((operatorValue) => {
        const conditions = [
            'notEmpty',
            'last7days',
            'last10days',
            'last15days',
            'isOverdue',
            'oneDayRemaining',
            'threeOrLessDaysRemaining',
            'sevenOrLessDaysRemaining',
        ];
        if (conditions.includes(operatorValue)) {
            return true;
        }
        return false;
    }, []);

    const getFilterOptionByColumnField = (columnField) =>
        filters.find((field) => field.value === columnField);

    const OPERATOR_OPTIONS = useCallback(
        (index) => {
            let currentFieldType = filters[0].fieldType;

            if (index !== undefined) {
                currentFieldType = getFilterOptionByColumnField(
                    selectedFilters[index].columnField
                )?.fieldType;
            }

            switch (currentFieldType) {
                case STRING:
                    return TEXT_OPTIONS;
                case DATE:
                    return DATE_OPTIONS;
                case TASK:
                    return TASK_OPTIONS;
                case BOOLEAN:
                    return BOOLEAN_OPTIONS;
                case TIMESTAMP_PRESET:
                    return TIMESTAMP_PRESET_OPTIONS;

                default:
                    return [];
            }
        },
        [filters, getFilterOptionByColumnField, selectedFilters]
    );

    const filterValueInput = useCallback(
        (filterValue, index, operatorValue) => {
            let currentField = filters[0];

            if (index !== undefined) {
                currentField = getFilterOptionByColumnField(
                    selectedFilters[index].columnField
                );
            }

            const currentFieldType = currentField?.fieldType;
            const currentFieldLabel = sentenceCase(`${currentField?.label}`);
            const currentFieldValueToSet = currentField?.valueToCheck || 'none';

            const operatorOptions = getOperatorOptionsByColumnField(
                currentField?.value
            );
            const operatorOption = operatorOptions?.find(
                ({ value }) => value === operatorValue
            );

            const operatorOptionType = operatorOption?.type;

            switch (currentFieldType) {
                case STRING:
                    return (
                        <TextField
                            size={size || 'normal'}
                            fullWidth
                            value={filterValue}
                            onChange={(e) => {
                                if (index !== undefined) {
                                    handleFilterValueChange(
                                        e.target.value,
                                        index,
                                        filterGroupIndexes
                                    );
                                }
                            }}
                            margin="normal"
                            color="secondary"
                            variant="outlined"
                            label="Value"
                            style={{
                                minWidth: 210,
                                // padding: 0,
                            }}
                            disabled={disabled}
                            onDragStart={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            draggable
                        />
                    );
                case DATE:
                    return (
                        <TextField
                            size={size || 'normal'}
                            fullWidth
                            value={filterValue}
                            variant="outlined"
                            color="secondary"
                            label="Date"
                            type="date"
                            onChange={(e) => {
                                if (index !== undefined) {
                                    handleFilterValueChange(
                                        e.target.value,
                                        index,
                                        filterGroupIndexes
                                    );
                                }
                            }}
                            margin="normal"
                            defaultValue={new Date()}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                minWidth: 210,
                                // padding: 0,
                            }}
                            disabled={disabled}
                            onDragStart={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            draggable
                        />
                    );
                case TASK: {
                    if (operatorOptionType === BOOLEAN)
                        return (
                            <Box
                                display={'flex'}
                                alignItems="center"
                                onDragStart={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                draggable
                            >
                                {/* SELECT */}
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    style={{ marginTop: '7px' }}
                                >
                                    <Select
                                        labelId="select is done"
                                        id="isDoneSelect"
                                        value={
                                            filterValue ? filterValue : false
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            handleFilterValueChange(
                                                value,
                                                index,
                                                filterGroupIndexes
                                            );
                                        }}
                                        disabled={disabled}
                                    >
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={true}>Yes</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        );
                    else if (operatorOptionType === TIMESTAMP_PRESET) {
                        return (
                            <Box
                                display={'flex'}
                                alignItems="center"
                                onDragStart={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                draggable
                            >
                                {/* SELECT */}
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    style={{ marginTop: '7px' }}
                                >
                                    <Select
                                        labelId="select is done"
                                        id="isDoneSelect"
                                        value={filterValue}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            handleFilterValueChange(
                                                value,
                                                index,
                                                filterGroupIndexes
                                            );
                                        }}
                                        disabled={disabled}
                                    >
                                        {TIMESTAMP_PRESET_OPTIONS.map(
                                            (preset) => {
                                                return (
                                                    <MenuItem
                                                        key={preset.value}
                                                        value={preset.value}
                                                    >
                                                        {preset.label}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        );
                    }

                    return (
                        <TextField
                            size={size || 'normal'}
                            fullWidth
                            value={filterValue}
                            variant="outlined"
                            color="secondary"
                            label="Date"
                            type="date"
                            onChange={(e) => {
                                if (index !== undefined) {
                                    handleFilterValueChange(
                                        e.target.value,
                                        index,
                                        filterGroupIndexes
                                    );
                                }
                            }}
                            margin="normal"
                            defaultValue={new Date()}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                minWidth: 210,
                                // padding: 0,
                            }}
                            disabled={disabled}
                            onDragStart={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            draggable
                        />
                    );
                }

                case BOOLEAN:
                    return (
                        <Box
                            display={'flex'}
                            alignItems="center"
                            style={{
                                minWidth: 210,
                                // padding: 0,
                            }}
                            onDragStart={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            draggable
                            border={'2px solid red'}
                        >
                            <Typography style={{ marginRight: '8px' }}>
                                {' '}
                                {currentFieldLabel}{' '}
                            </Typography>
                            <Checkbox
                                checked={filterValue}
                                required
                                onChange={(e) => {
                                    if (index !== undefined) {
                                        handleFilterValueChange(
                                            e.target.checked,
                                            index,
                                            filterGroupIndexes
                                        );
                                    }
                                }}
                                disabled={disabled}
                            />
                        </Box>
                    );

                default:
                    return (
                        <TextField
                            size={size || 'normal'}
                            value={filterValue}
                            onChange={(e) => {}}
                            color="secondary"
                            variant="outlined"
                            label="Value"
                            margin="normal"
                            style={{
                                minWidth: 210,
                            }}
                            disabled={disabled}
                            onDragStart={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            draggable
                        />
                    );
            }
        },
        [selectedFilters, filters]
    );

    const findTargetFilterGroup = (
        initialFilterGroup,
        filterGroupIndexes,
        parentRank = 0
    ) => {
        // If filterGroupIndexes is specified, find the target filter group
        let targetFilterGroup = initialFilterGroup;

        if (filterGroupIndexes) {
            let currentFilterGroup = targetFilterGroup;
            for (let i = 0; i < filterGroupIndexes.length - parentRank; i++) {
                const index = filterGroupIndexes[i];
                targetFilterGroup = currentFilterGroup[index].filterGroup;
                currentFilterGroup = targetFilterGroup;
            }
        }

        return targetFilterGroup;
    };

    const handleAdd = (index, filterGroupIndexes) => {
        let newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;

        // newFilters.push({
        //     label: unUsedFilterOptions[0].label,
        //     columnField: unUsedFilterOptions[0].value,
        //     operatorValue: getOperatorOptionsByColumnField(
        //         unUsedFilterOptions[0].value
        //     )[0].value,
        //     fieldType: unUsedFilterOptions[0].fieldType,
        //     value: '',
        // });

        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );

        const filterCopy = {
            label: filters[0].label,
            columnField: filters[0].value,
            operatorValue: getOperatorOptionsByColumnField(filters[0].value)[0]
                .value,
            fieldType: filters[0].fieldType,
            value: '',
            logicalConnective:
                targetFilterGroup[targetFilterGroup.length - 1]
                    ?.logicalConnective || AND,
        };

        // const filterCopy = { ...targetFilterGroup[index] };

        targetFilterGroup.splice(index + 1, 0, filterCopy);

        setSelectedFilters(newFilters);
    };

    const handleRemove = (index, filterGroupIndexes, _allSelectedFilters) => {
        // console.log(_allSelectedFilters, allSelectedFilters);
        let newFilters = [...(_allSelectedFilters || allSelectedFilters)];
        let targetFilterGroup = newFilters;
        let parentFilterGroup = newFilters;
        // console.log(targetFilterGroup, parentFilterGroup, filterGroupIndexes);
        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );
        parentFilterGroup = findTargetFilterGroup(
            parentFilterGroup,
            filterGroupIndexes,
            1
        );

        // console.log(targetFilterGroup, parentFilterGroup, filterGroupIndexes);

        // If there is the only one filter in the filter group then remove the parent filter group
        if (targetFilterGroup.length === 1 && filterGroupIndexes?.length) {
            handleRemove(
                filterGroupIndexes[filterGroupIndexes.length - 1],
                filterGroupIndexes?.length > 1
                    ? filterGroupIndexes.slice(0, -1)
                    : null,
                _allSelectedFilters
            );
            return;
        }

        targetFilterGroup.splice(index, 1);
        if (targetFilterGroup.length === 1)
            targetFilterGroup[0].logicalConnective = AND;

        setSelectedFilters(newFilters);

        // setSelectedFilters((prev) => {
        //     if (filterGroupIndexes) {
        //         let newFilters = [...prev];
        //         let targetFilterGroup = newFilters;

        //         targetFilterGroup = findTargetFilterGroup(targetFilterGroup, filterGroupIndexes);
        //         targetFilterGroup.splice(index, 1);
        //         if (targetFilterGroup.length === 1)
        //             targetFilterGroup[0].logicalConnective = AND;

        //         // return prev;
        //         return newFilters;
        //     } else {
        //         const newFilters = prev?.filter((_p, i) => i !== index);
        //         if (newFilters.length === 1)
        //             newFilters[0].logicalConnective = AND;
        //         // return prev;
        //         return newFilters;
        //     }
        // });
    };

    const getOperatorOptionsByColumnField = (columnField) => {
        const filterOption = getFilterOptionByColumnField(columnField);
        const fieldType = filterOption?.fieldType || STRING;

        let ret = [];
        switch (fieldType) {
            case STRING:
                ret = TEXT_OPTIONS;
                break;
            case DATE:
                ret = DATE_OPTIONS;
                break;
            case TASK:
                ret = TASK_OPTIONS;
                break;
            case BOOLEAN:
                ret = BOOLEAN_OPTIONS;
                break;
            case TIMESTAMP_PRESET:
                ret = TIMESTAMP_PRESET_OPTIONS;
                break;
            default:
                ret = TEXT_OPTIONS;
                break;
        }

        return ret;
    };

    const handleFilterFieldChange = (
        columnField,
        index,
        filterGroupIndexes
    ) => {
        const newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;

        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );

        targetFilterGroup[index].columnField = columnField;

        const filterOption = getFilterOptionByColumnField(columnField);

        const fieldType = filterOption?.fieldType;
        const fieldLable = filterOption?.label;

        targetFilterGroup[index].fieldType = fieldType;
        targetFilterGroup[index].label = fieldLable;
        targetFilterGroup[index].value = '';

        const operatorOptions = getOperatorOptionsByColumnField(columnField);

        targetFilterGroup[index].operatorValue = operatorOptions[0].value;

        setSelectedFilters(newFilters);
    };

    const handleFilterOperatorChange = (
        operatorValue,
        index,
        filterGroupIndexes
    ) => {
        const newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;

        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );

        const columnField = targetFilterGroup[index].columnField;
        const operatorOptions = getOperatorOptionsByColumnField(columnField);
        const prevOperatorValue = targetFilterGroup[index].operatorValue;

        const operatorOption = operatorOptions.find(
            ({ value }) => value === operatorValue
        );
        const prevOperatorOption = operatorOptions.find(
            ({ value }) => value === prevOperatorValue
        );

        if (operatorOption?.type !== prevOperatorOption?.type) {
            targetFilterGroup[index].value = '';
        }

        targetFilterGroup[index].operatorValue = operatorValue;
        setSelectedFilters(newFilters);
        // setSelectedFilters((prev) => {
        //     const newFilters = [...prev];
        //     newFilters[index].operatorValue = operatorValue;
        //     return newFilters;
        // });
    };

    const handleFilterValueChange = (value, index, filterGroupIndexes) => {
        setSelectedFilters((prev) => {
            const newFilters = [...prev];
            let targetFilterGroup = newFilters;
            targetFilterGroup = findTargetFilterGroup(
                targetFilterGroup,
                filterGroupIndexes
            );

            targetFilterGroup[index].value = value;
            return newFilters;
        });
    };

    const handleLogicalConnectiveChange = (
        logicalConnective,
        index,
        filterGroupIndexes
    ) => {
        const newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;
        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );

        const newLogicalConnective = logicalConnective === OR ? AND : OR;

        targetFilterGroup[index].logicalConnective = newLogicalConnective;

        if (noAndOrMixing) {
            for (let i = 0; i < targetFilterGroup.length; i++) {
                targetFilterGroup[i].logicalConnective = newLogicalConnective;
            }
        } else
            targetFilterGroup[index].logicalConnective = newLogicalConnective;

        setSelectedFilters(newFilters);

        // if (index === 0)
        //     setSelectedFilters((prev) => {
        //         const newFilters = [...prev];
        //         let targetFilterGroup = newFilters;
        //         targetFilterGroup = findTargetFilterGroup(
        //             targetFilterGroup,
        //             filterGroupIndexes
        //         );
        //         targetFilterGroup[index].logicalConnective = AND;
        //         return newFilters;
        //     });
        // else
        //     setSelectedFilters((prev) => {
        //         const newFilters = [...prev];
        //         let targetFilterGroup = newFilters;
        //         targetFilterGroup = findTargetFilterGroup(
        //             targetFilterGroup,
        //             filterGroupIndexes
        //         );
        //         targetFilterGroup[index].logicalConnective =
        //             logicalConnective === OR ? AND : OR;
        //         return newFilters;
        //     });
    };

    // const handleCreateGroup = (index, filterGroupIndexes) => {
    //     const newFilters = [...allSelectedFilters];
    //     let targetFilterGroup = newFilters;

    //     targetFilterGroup = findTargetFilterGroup(
    //         targetFilterGroup,
    //         filterGroupIndexes
    //     );
    //     console.log(targetFilterGroup[index]);

    //     targetFilterGroup[index].filterGroup = [
    //         { ...targetFilterGroup[index] },
    //     ];

    //     setSelectedFilters(newFilters);
    // };

    const handleCreateGroup = (index, filterGroupIndexes) => {
        const newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;

        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );

        // Create a new group for filters above
        if (index > 0) {
            const aboveGroup = targetFilterGroup.slice(0, index);
            targetFilterGroup.splice(0, index, {
                filterGroup: aboveGroup,
                logicalConnective: AND,
            });
            index = 1; // Adjust the index since above filters were grouped
        }

        // Create a new group for the selected filter (if it's not already part of a group)
        targetFilterGroup[index].filterGroup = [
            { ...targetFilterGroup[index] },
        ];

        // Create a new group for filters below
        if (index < targetFilterGroup.length - 1) {
            const belowGroup = targetFilterGroup.slice(index + 1);
            targetFilterGroup.splice(
                index + 1,
                targetFilterGroup.length - (index + 1),
                {
                    filterGroup: belowGroup,
                    logicalConnective: AND,
                }
            );
        }

        setSelectedFilters(newFilters);
    };

    const handleRemoveFromGroup = (index, filterGroupIndexes) => {
        let newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;
        let parentFilterGroup = newFilters;

        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );
        parentFilterGroup = findTargetFilterGroup(
            parentFilterGroup,
            filterGroupIndexes,
            1
        );

        // console.log(targetFilterGroup, parentFilterGroup, filterGroupIndexes);

        const filterCopy = { ...targetFilterGroup[index] };

        if (noAndOrMixing && parentFilterGroup.length)
            filterCopy.logicalConnective =
                parentFilterGroup[0].logicalConnective || AND;

        parentFilterGroup.splice(
            filterGroupIndexes[filterGroupIndexes.length - 1] + 1,
            0,
            filterCopy
        );

        // parentFilterGroup.push(filterCopy);
        // console.log(newFilters);
        // setSelectedFilters(newFilters);

        // setTimeout(() => {
        handleRemove(index, filterGroupIndexes, newFilters);
        // }, 0);
    };

    const handleRemoveFromGroupAll = (index, filterGroupIndexes) => {
        let newFilters = [...allSelectedFilters];
        let targetFilterGroup = newFilters;
        // let parentFilterGroup = newFilters;

        targetFilterGroup = findTargetFilterGroup(
            targetFilterGroup,
            filterGroupIndexes
        );
        // parentFilterGroup = findTargetFilterGroup(
        //     parentFilterGroup,
        //     filterGroupIndexes,
        //     1
        // );

        // console.log(targetFilterGroup, parentFilterGroup, filterGroupIndexes);

        const filterGroupCopy = [...targetFilterGroup[index]?.filterGroup];

        if (noAndOrMixing && targetFilterGroup.length) {
            for (let i = 0; i < filterGroupCopy.length; i++) {
                filterGroupCopy[i].logicalConnective =
                    targetFilterGroup[0].logicalConnective || AND;
            }
        }

        targetFilterGroup.splice(index + 1, 0, ...filterGroupCopy);

        // parentFilterGroup.push(filterCopy);
        // console.log(newFilters);
        // setSelectedFilters(newFilters);

        // setTimeout(() => {
        handleRemove(index, filterGroupIndexes, newFilters);
        // }, 0);
    };

    // const dragStart = (e, index) => {
    //     dragItem.current = index;
    // };

    // const dragEnter = (e, index) => {
    //     dragOverItem.current = index;
    // };

    // const drop = async (e) => {
    //     console.log(dragItem.current, dragOverItem.current);
    //     const newFilters = [...allSelectedFilters];
    //     let targetFilterGroup = newFilters;

    //     targetFilterGroup = findTargetFilterGroup(targetFilterGroup);
    //     const dragItemContent = targetFilterGroup[dragItem.current];
    //     targetFilterGroup.splice(dragItem.current, 1);
    //     targetFilterGroup.splice(dragOverItem.current, 0, dragItemContent);

    //     const tempLogicalConnective =
    //         targetFilterGroup[dragItem.current].logicalConnective;
    //     targetFilterGroup[dragItem.current].logicalConnective =
    //         targetFilterGroup[dragOverItem.current].logicalConnective;
    //     targetFilterGroup[
    //         dragOverItem.current
    //     ].logicalConnective = tempLogicalConnective;

    //     dragItem.current = null;
    //     dragOverItem.current = null;
    //     setSelectedFilters(newFilters);
    // };

    // const handleShowInfo = () => {
    //     if (infoModalRef.current) {
    //         infoModalRef.current.showModal();
    //     }
    // };

    return (
        <Box className={classes.root}>
            {/* <InfoDialog
                createdAt={createdAt}
                creator={creator}
                modalRef={infoModalRef}
            /> */}
            {!filterGroupIndexes && (
                <Box className={classes.titleHeading}>
                    <Typography variant="h6">Specify Criteria</Typography>
                </Box>
            )}

            {selectedFilters?.length ? (
                selectedFilters?.map(
                    (
                        {
                            columnField,
                            operatorValue,
                            value,
                            logicalConnective,
                            filterGroup,
                        },
                        index
                    ) => {
                        return (
                            <>
                                <Box
                                    // display={'flex'}
                                    // alignItems="center"
                                    key={index}
                                    // className={classes.filter}
                                    position="relative"
                                    mt={2}
                                >
                                    {filterGroup?.length ? (
                                        <>
                                            <Box
                                                ml={4}
                                                mt={5}
                                                mb={3}
                                                pl={2}
                                                borderLeft={`2px solid ${
                                                    isNotValidGroup
                                                        ? theme.palette.error
                                                              .light
                                                        : theme.palette.primary
                                                              .light
                                                }`}
                                                position="relative"
                                                onDragStart={(e) => {
                                                    e.stopPropagation();
                                                    dragStart(
                                                        e,
                                                        index,
                                                        filterGroupIndexes
                                                    );
                                                }}
                                                onDragEnter={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    dragEnter(
                                                        e,
                                                        index,
                                                        filterGroupIndexes
                                                    );
                                                    // console.log(
                                                    //     dragOverItemPosition?.filterGroupIndexes,
                                                    //     filterGroupIndexes
                                                    // );
                                                }}
                                                onDragEnd={drop}
                                                onDragOver={(e) => {
                                                    e.stopPropagation();
                                                    e.dataTransfer.dropEffect =
                                                        'move';
                                                    e.preventDefault();
                                                }}
                                                draggable
                                            >
                                                {/* <>{filterGroup?.length}</>
                                                <br />
                                                <>
                                                    {filterGroup[0]?.filterGroup
                                                        ?.length
                                                        ? 'has filter group'
                                                        : 'does not have filter group'}
                                                </>
                                                <br /> */}
                                                <FiltersSelect
                                                    filters={filters}
                                                    selectedFilters={
                                                        filterGroup
                                                    }
                                                    allSelectedFilters={
                                                        allSelectedFilters
                                                    }
                                                    setSelectedFilters={
                                                        setSelectedFilters
                                                    }
                                                    disabled={disabled}
                                                    size="small"
                                                    filterGroupIndexes={
                                                        filterGroupIndexes
                                                            ? filterGroupIndexes.concat(
                                                                  [index]
                                                              )
                                                            : [index]
                                                    }
                                                    dragStart={dragStart}
                                                    dragEnter={dragEnter}
                                                    drop={drop}
                                                    isNotValidGroup={
                                                        filterGroup?.length ==
                                                            1 &&
                                                        filterGroup[0]
                                                            ?.filterGroup
                                                            ?.length
                                                    }
                                                    noAndOrMixing={
                                                        noAndOrMixing
                                                    }
                                                />

                                                {!isNotValidGroup ? (
                                                    <Box
                                                        className={
                                                            classes.filterGroupControl
                                                        }
                                                        // style={{
                                                        //     opacity:
                                                        //         showFilterGroupControl ===
                                                        //         index
                                                        //             ? 1
                                                        //             : 0,
                                                        // }}
                                                        // onMouseEnter={() => {
                                                        //     setShowFilterGroupControl(
                                                        //         index
                                                        //     );
                                                        // }}
                                                        // onMouseLeave={() => {
                                                        //     setShowFilterGroupControl(
                                                        //         null
                                                        //     );
                                                        // }}
                                                    >
                                                        <Box
                                                            style={{
                                                                cursor: 'grab',
                                                            }}
                                                            width="fit-content"
                                                        >
                                                            <DragIndicatorRounded size="small" />
                                                        </Box>

                                                        {filterGroup?.length >
                                                            1 && (
                                                            <>
                                                                <Box
                                                                    style={{
                                                                        cursor:
                                                                            'pointer',
                                                                    }}
                                                                    width="fit-content"
                                                                    onClick={() => {
                                                                        handleRemoveFromGroupAll(
                                                                            index,
                                                                            filterGroupIndexes
                                                                        );
                                                                    }}
                                                                    onDragStart={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                    }}
                                                                    draggable
                                                                >
                                                                    <Tooltip
                                                                        title="Remove all filters from group"
                                                                        placement="right"
                                                                    >
                                                                        <KeyboardDoubleArrowLeftRounded />
                                                                    </Tooltip>
                                                                </Box>
                                                                <Box
                                                                    style={{
                                                                        cursor:
                                                                            'pointer',
                                                                    }}
                                                                    width="fit-content"
                                                                    onClick={() => {
                                                                        handleRemove(
                                                                            index,
                                                                            filterGroupIndexes
                                                                        );
                                                                    }}
                                                                    onDragStart={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                    }}
                                                                    draggable
                                                                >
                                                                    <Tooltip
                                                                        title="Delete group"
                                                                        placement="right"
                                                                    >
                                                                        <RemoveIcon color="error" />
                                                                    </Tooltip>
                                                                </Box>
                                                            </>
                                                        )}

                                                        <Box
                                                            style={{
                                                                cursor:
                                                                    'pointer',
                                                            }}
                                                            width="fit-content"
                                                            onClick={() => {
                                                                handleAdd(
                                                                    index,
                                                                    filterGroupIndexes
                                                                );
                                                            }}
                                                            onDragStart={(
                                                                e
                                                            ) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                            }}
                                                            draggable
                                                        >
                                                            <Tooltip
                                                                title="Add new filter after group"
                                                                placement="right"
                                                            >
                                                                <AddIcon color="secondary" />
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box
                                                display={'flex'}
                                                alignItems="center"
                                                className={classes.filter}
                                                onDragStart={(e) => {
                                                    e.stopPropagation();
                                                    dragStart(
                                                        e,
                                                        index,
                                                        filterGroupIndexes
                                                    );
                                                }}
                                                onDragEnter={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    dragEnter(
                                                        e,
                                                        index,
                                                        filterGroupIndexes
                                                    );
                                                    // console.log(
                                                    //     dragOverItemPosition?.filterGroupIndexes,
                                                    //     filterGroupIndexes
                                                    // );
                                                }}
                                                onDragEnd={drop}
                                                onDragOver={(e) => {
                                                    e.stopPropagation();
                                                    e.dataTransfer.dropEffect =
                                                        'move';
                                                    e.preventDefault();
                                                }}
                                                draggable
                                                borderTop={
                                                    dragOverItemPosition?.index ===
                                                        index &&
                                                    filterGroupIndexesEqual(
                                                        dragOverItemPosition?.filterGroupIndexes,
                                                        filterGroupIndexes
                                                    )
                                                        ? '2px solid lightGrey'
                                                        : null
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        position: 'absolute',
                                                        left: '-15px',
                                                        marginTop: 10,
                                                        cursor: 'grab',
                                                    }}
                                                    // onDragStart={(e) => {
                                                    //     dragStart(
                                                    //         e,
                                                    //         index,
                                                    //         filterGroupIndexes
                                                    //     );
                                                    // }}
                                                    // draggable
                                                >
                                                    <DragIndicatorRounded size="small" />
                                                </Box>
                                                <SelectOptions
                                                    index={index}
                                                    filterGroupIndexes={
                                                        filterGroupIndexes
                                                    }
                                                    value={columnField}
                                                    // setValue={setFilterField}
                                                    options={
                                                        /* [
                                                    getFilterOptionByColumnField(columnField),
                                                    ...unUsedFilterOptions,
                                                ] */ filters
                                                    }
                                                    title="Column"
                                                    handleChangeInIndex={
                                                        handleFilterFieldChange
                                                    }
                                                    disabled={disabled}
                                                    size={size || 'normal'}
                                                />
                                                {columnField && (
                                                    <SelectOptions
                                                        index={index}
                                                        filterGroupIndexes={
                                                            filterGroupIndexes
                                                        }
                                                        value={operatorValue}
                                                        // setValue={setFilterOperator}
                                                        options={OPERATOR_OPTIONS(
                                                            index
                                                        )}
                                                        title="Condition"
                                                        handleChangeInIndex={
                                                            handleFilterOperatorChange
                                                        }
                                                        disabled={disabled}
                                                        size={size || 'normal'}
                                                    />
                                                )}
                                                {/* value input */}
                                                <Box m={1}>
                                                    {operatorValue &&
                                                        !hideValueField(
                                                            operatorValue
                                                        ) &&
                                                        filterValueInput(
                                                            value,
                                                            index,
                                                            operatorValue
                                                        )}
                                                </Box>

                                                <Box
                                                    onDragStart={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                    draggable
                                                >
                                                    <Tooltip
                                                        title="Delete filter"
                                                        placement="bottom"
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                handleRemove(
                                                                    index,
                                                                    filterGroupIndexes
                                                                );
                                                            }}
                                                            disabled={disabled}
                                                            size="large"
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>

                                                <Box
                                                    onDragStart={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                    draggable
                                                >
                                                    <Tooltip
                                                        title="Add new filter"
                                                        placement="bottom"
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                handleAdd(
                                                                    index,
                                                                    filterGroupIndexes
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            color="secondary"
                                                            disabled={
                                                                !columnField ||
                                                                !operatorValue ||
                                                                // !value ||
                                                                disabled
                                                            }
                                                            size="large"
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>

                                                {filterGroupIndexes && (
                                                    <Box
                                                        onDragStart={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                        }}
                                                        draggable
                                                    >
                                                        <Tooltip
                                                            title="Remove filter from group"
                                                            placement="bottom"
                                                        >
                                                            <IconButton
                                                                onClick={() => {
                                                                    handleRemoveFromGroup(
                                                                        index,
                                                                        filterGroupIndexes
                                                                    );
                                                                }}
                                                                disabled={
                                                                    disabled
                                                                }
                                                                size="large"
                                                            >
                                                                <ChevronLeftIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                )}

                                                <Box
                                                    onDragStart={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                    draggable
                                                >
                                                    <Tooltip
                                                        title="Add filter to new group"
                                                        placement="bottom"
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                handleCreateGroup(
                                                                    index,
                                                                    filterGroupIndexes
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            disabled={disabled}
                                                            size="large"
                                                        >
                                                            <ChevronRightIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    {index !== 0 && (
                                        <Box
                                            className={classes.connectiveBtn}
                                            top={
                                                filterGroup?.length ? -35 : -20
                                            }
                                            onDragStart={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            draggable
                                        >
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    handleLogicalConnectiveChange(
                                                        logicalConnective,
                                                        index,
                                                        filterGroupIndexes
                                                    );
                                                }}
                                            >
                                                {logicalConnective === OR
                                                    ? 'or'
                                                    : 'and'}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </>
                        );
                    }
                )
            ) : (
                <Button
                    variant="outlined"
                    onClick={() => {
                        setSelectedFilters([
                            {
                                label: 'Title',
                                columnField: 'title',
                                operatorValue: 'contains',
                                fieldType: STRING,
                                logicalConnective: AND,
                                value: '',
                            },
                        ]);
                    }}
                    startIcon={<AddIcon />}
                >
                    Add filter
                </Button>
            )}
        </Box>
    );
};

const useStyles = makeStyles()((theme) => ({
    // root: {
    //     backgroundColor: theme.palette.background.paper,
    // },
    titleHeading: {
        // paddingBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filter: {
        position: 'relative',
    },
    connectiveBtn: {
        position: 'absolute',
        // top: -20,
        left: 0,
    },

    filterGroupControl: {
        position: 'absolute',
        top: 10,
        left: -30,
        minWidth: 35,
        height: '90%',
    },
}));

function filterGroupIndexesEqual(filterGroupIndexes1, filterGroupIndexes2) {
    if (!filterGroupIndexes1 && !filterGroupIndexes2) return true;

    if (filterGroupIndexes1 && !filterGroupIndexes2) return false;

    if (!filterGroupIndexes1 && filterGroupIndexes2) return false;

    return arraysEqual(filterGroupIndexes1, filterGroupIndexes2);
}
