import React, { useEffect, useState, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import axios from 'axios';
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography,
    CircularProgress,
    MenuItem,
    Checkbox,
    ListItemText,
    Switch,
    Autocomplete,
    Chip,
    Badge,
    Avatar,
} from '@mui/material';
import { alertState, severity } from '../app/recoil';
import { CKEditor } from 'ckeditor4-react';
import { makeStyles } from 'tss-react/mui';
import { useInstructorProfile } from './useInstructorProfile';
import { languageOptions } from '../util/languageOptions';
import { CountryData } from '../instructor-onboarding/CountryData';
import { ZoneData } from '../instructor-onboarding/ZoneData';
import { ChannelStatus } from '../admin/ChannelStatus';
import { EditIcon } from '../icons';
import { COLLABORATOR } from '../auth/roles';
import { ProfileExperience } from './ProfileExperience';
import { nameToSlug, randomAlphaNumericString } from '../util';
import { AzureBlobStorage } from '../util/BlobStorage';
import { TYPES } from '../my-account/utils';
import { Loading } from '../ui';
import PersonaSearchAndSelect from '../persona/PersonaSearchAndSelect';

const useStyles = makeStyles()((theme) => ({
    inputContainer: {
        paddingBottom: '0.5rem',
        marginBottom: '0.5rem',
        minHeight: 55,
        '& .input': {
            [theme.breakpoints.up('sm')]: {
                maxWidth: '200px',
            },
        },
        '& .stats-text': {
            flexGrow: 1,
            textAlign: 'right',
            [theme.breakpoints.up('sm')]: {
                flexGrow: 0,
                textAlign: 'left',
            },
        },
    },
    label: {
        fontWeight: 'bold',
        minWidth: 120,
    },
    avatar: {
        width: 300,
        height: 300,
        [theme.breakpoints.down('md')]: {
            width: 250,
            height: 250,
        },
        [theme.breakpoints.down('sm')]: {
            width: 200,
            height: 200,
        },
    },
    edit: {
        height: 30,
        width: 30,
        minWidth: 30,
        borderRadius: '100vmax',
    },
    editIcon: {
        fontSize: '1rem',
    },
    formControl: {
        marginBottom: '20px',
        width: '100%',
    },
    errorText: {
        color: 'red',
        fontSize: '0.875rem',
    },
}));

export const ProfileInfo = ({
    userDetails,
    onBack,
    afterSave,
    saveBtnText,
    setUserDetails = () => {},
    showBasisInfo = true,
}) => {
    const [userWPProfile, setUserWPProfile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { classes } = useStyles();
    const setSnackbar = useSetRecoilState(alertState);
    const [edit, setEdit] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});

    const [selectedImage, setSelectedImage] = useState(null);
    const [profilePic, setProfilePic] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [slug, setSlug] = useState('');
    const syncSlug = useRef(true);
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [time_zone, setTimeZone] = useState('');
    const [phone, setPhone] = useState('');
    const [linkedin, setLinkdin] = useState('');
    const [admin, setAdmin] = useState(false);
    const [instructor, setInstructor] = useState(false);
    const [collaborator, setCollaborator] = useState(false);
    const [availability, setAvailability] = useState('');
    const [tags, setTags] = useState([]);
    const [tagLine, setTagline] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [domains, setDomains] = useState([]);
    const { intro, setIntro } = useInstructorProfile(userDetails);
    const [selectedPersonas, setSelectedPersonas] = useState([]);
    const [channelStatus, setChannelStatus] = useState('');
    const availabilityOptions = [
        {
            label: 'Fully Available/Can Work Full-Time (40 hrs/week)',
            value: 'Fully Available/Can Work Full-Time (40 hrs/week)',
        },
        {
            label: 'Nearly Full-Time/Highly Available (30 - 40 hrs/week)',
            value: 'Nearly Full-Time/Highly Available (30 - 40 hrs/week)',
        },
        {
            label: 'Available for Significant Hours (20-29 hrs/week)',
            value: 'Available for Significant Hours (20-29 hrs/week)',
        },
        {
            label: 'Consistently Available Part-Time (10-19 hrs/week)',
            value: 'Consistently Available Part-Time (10-19 hrs/week)',
        },
        {
            label: 'Regularly Available/Moderate Hours (5-10 hrs/week)',
            value: 'Regularly Available/Moderate Hours (5-10 hrs/week)',
        },
        {
            label: 'Limited Availability/Somewhat Dependable (1-4 hrs/week)',
            value: 'Limited Availability/Somewhat Dependable (1-4 hrs/week)',
        },
        {
            label: 'Occasionally Available/Infrequent (<1 hr/week)',
            value: 'Occasionally Available/Infrequent (<1 hr/week)',
        },
        {
            label: 'Rarely Available/Minimal Commitment',
            value: 'Rarely Available/Minimal Commitment',
        },
        {
            label: 'Very Limited Availability/Unreliable',
            value: 'Very Limited Availability/Unreliable',
        },
        {
            label: 'Not Available at All/Unavailable',
            value: 'Not Available at All/Unavailable',
        },
    ];
    const domainOptions = [
        { label: 'Data Science', value: 'Data Science' },
        { label: 'Cybersecurity', value: 'Cybersecurity' },
        { label: 'DevOps', value: 'DevOps' },
        { label: 'Networking', value: 'Networking' },
        { label: 'UI/UX', value: 'UI/UX' },
        { label: 'Cloud', value: 'Cloud' },
        { label: 'Full Stack', value: 'Full Stack' },
        { label: 'Agility', value: 'Agility' },
        { label: 'Business', value: 'Business' },
        { label: 'Finance', value: 'Finance' },
    ];

    const validateFields = () => {
        const newErrors = {};

        if (showBasisInfo && !firstName.trim()) {
            newErrors.firstName = 'First Name is required';
        }
        if (showBasisInfo && !lastName.trim()) {
            newErrors.lastName = 'Last Name is required';
        }
        if (showBasisInfo && !email.trim()) {
            newErrors.email = 'Email is required';
        }
        if (showBasisInfo && !country) {
            newErrors.country = 'Country is required';
        }
        if (showBasisInfo && !city.trim()) {
            newErrors.city = 'City is required';
        }
        if (showBasisInfo && !time_zone) {
            newErrors.time_zone = 'Time Zone is required';
        }
        if (showBasisInfo && !phone.trim()) {
            newErrors.phone = 'Phone Number is required';
        }
        if (!languages || languages.length === 0) {
            newErrors.languages = 'Languages are required';
        }
        if (!domains || domains.length === 0) {
            newErrors.domains = 'Domains are required';
        }
        if (selectedPersonas.length === 0) {
            newErrors.selectedPersonas = "Personas can't left empty";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        if (selectedPersonas.length > 0) {
            setErrors((prevErrors) => {
                if ('selectedPersonas' in prevErrors) {
                    const { selectedPersonas, ...rest } = prevErrors;
                    return rest;
                }
                return prevErrors;
            });
        }
    }, [selectedPersonas.length, setErrors]);

    useEffect(() => {
        (async function () {
            try {
                if (!userDetails) return;

                if (userDetails.slug) {
                    syncSlug.current = false;
                    setSlug(userDetails.slug);
                }

                setFirstName(userDetails.user_firstName);
                setLastName(userDetails.user_lastName);
                setEmail(userDetails.user_email);
                setCountry(
                    CountryData.find(
                        (c) => c.name === userDetails.user_country
                    ) || null
                );
                setCity(userDetails.user_city);
                setTimeZone(
                    ZoneData.find(
                        (zone) => zone.label === userDetails.time_zone
                    ) || null
                );
                setPhone(userDetails.user_phone);
                setLinkdin(userDetails.user_linkedInUrl);
                setAdmin(userDetails.isAdmin);
                setInstructor(userDetails.is_instructor);
                setCollaborator(userDetails.roles?.includes(COLLABORATOR));
                setAvailability(userDetails.availability || '');
                setDomains(userDetails.domains || []);
                setLanguages(userDetails.languages || []);
                setTags(userDetails.tags || []);
                setTagline(userDetails.instructor_details.tag_line || '');
                setProfilePic(
                    userDetails?.profilePic ||
                        userDetails?.instructor_details?.channelProfilePic
                );

                // Fetch related data
                const [techbytesRes, jobRolesRes] = await Promise.all([
                    axios.get(
                        `/api/techbytes?instructorIds=${userDetails._id}`
                    ),
                    axios.get(
                        `/api/job-roles?instructorIds=${userDetails._id}`
                    ),
                ]);

                const relatedArticles = techbytesRes.data.articles;
                const relatedJobTitles = jobRolesRes.data.jobRoles;

                setSelectedPersonas(relatedJobTitles);
                setUserWPProfile({
                    bannerPic:
                        userDetails?.instructor_details?.channelBannerPic,
                    bio: userDetails?.instructor_details?.intro,
                    email: userDetails?.user_email,
                    id: userDetails?.instructorPostId,
                    intro: userDetails?.instructor_details?.intro,
                    name:
                        userDetails?.user_firstName +
                        ' ' +
                        userDetails?.user_lastName,
                    post_type: 'instructor',
                    profilePic:
                        userDetails?.instructor_details?.channelProfilePic,
                    profilePicNoBackground:
                        userDetails?.instructor_details?.channelProfilePic,
                    relatedArticles,
                    relatedJobTitles,
                    slug: userDetails?.slug,
                    status:
                        userDetails?.instructor_details?.channelStatus ||
                        'draft',
                    tagLine: userDetails?.instructor_details?.tag_line,
                });

                setChannelStatus(
                    userDetails?.instructor_details?.channelStatus || 'draft'
                );
            } catch (e) {
                console.log(e);
            }
        })();
    }, [userDetails]);

    const handleProfilePicChange = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setProfilePic(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const uploadImg = async (selectedImg, containerName, dirName) => {
        if (
            selectedImg &&
            typeof selectedImg !== 'string' &&
            TYPES.includes(selectedImg.type)
        ) {
            const azureBlobStorage = new AzureBlobStorage();
            const uploadedFiles = await azureBlobStorage.uploadFiles(
                [selectedImg],
                containerName,
                null,
                null,
                dirName,
                randomAlphaNumericString(4) + selectedImg.name,
                {},
                false
            );

            if (uploadedFiles.fileNames.length > 0) {
                return uploadedFiles.fileNames[0].url;
            }
        }
        return null;
    };

    const handleChangeCountry = (event, newValue) => {
        setCountry(newValue);
    };

    const handleChannelStatusChange = (value) => {
        setChannelStatus(value);
    };

    const handleChannelStatusChangeSwitch = (e) => {
        setChannelStatus(e.target.checked ? 'publish' : 'draft');
    };

    useEffect(() => {
        if (syncSlug.current) {
            const newSlug = nameToSlug(`${firstName} ${lastName}`);
            setSlug(newSlug);
        }
    }, [firstName, lastName]);

    const save = async () => {
        try {
            setSaving(true);

            if (userDetails?.is_instructor) await saveChannelStatus();

            const roles = [];

            if (collaborator) roles.push(COLLABORATOR);

            let newProfilePicUrl = await uploadImg(
                selectedImage,
                'instructors',
                'media/profile-pictures'
            );

            const payload = {
                id: userDetails?._id,
                firstName,
                lastName,
                slug,
                email,
                country: country?.name || '',
                city,
                time_zone: time_zone?.label || '',
                phone,
                linkedin,
                admin,
                instructor,
                roles,
                availability,
                languages: languages,
                domains: domains,
                instructor_details: { intro, tag_line: tagLine },
                tags,
            };

            if (newProfilePicUrl) {
                payload.profilePic = newProfilePicUrl;
            }

            await axios.post('/api/job-roles/set-instructor-job-roles', {
                userId: userDetails._id,
                jobRoleIds: selectedPersonas.map((persona) => persona._id),
            });

            const res = await axios.put(`/api/admin/user-profile`, payload);

            const adminChanged = userDetails?.isAdmin !== admin;
            const instructorChanged = userDetails?.is_instructor !== instructor;
            const rolesChanged =
                JSON.stringify(userDetails?.roles || []) !==
                JSON.stringify(roles);

            setUserDetails(res.data);

            setSnackbar({
                show: true,
                severity: severity.SUCCESS,
                message: 'Successfully updated.',
            });
            setSaving(false);
            if (afterSave) afterSave();

            if (adminChanged || instructorChanged || rolesChanged) {
                window.location.reload();
            }
        } catch (error) {
            setSaving(false);
            setSnackbar({
                show: true,
                severity: severity.ERROR,
                message: 'Something went wrong',
            });
        }
    };

    const saveChannelStatus = async () => {
        try {
            try {
                await axios.post('/api/admin/update-channel-status', {
                    channelStatus: channelStatus || 'draft',
                    userId: userDetails._id,
                });
            } catch (error) {}
        } catch (error) {}
    };

    const enableEdit = () => {
        setEdit(true);
    };

    const initializeIntro = ({ editor }) => {
        editor.setData(intro);
    };

    const handleTagLineInput = (e) => {
        setTagline(e.target.value);
    };

    const handleDomainChange = (event) => {
        const value = event.target.value;
        setDomains(typeof value === 'string' ? value.split(',') : value);
        if ('domains' in errors) {
            setErrors((errors) => {
                delete errors.domains;
                return errors;
            });
        }
    };

    const handleLanguageChange = (event, newValue) => {
        setLanguages(newValue.map((option) => option.code));
        if ('languages' in errors) {
            setErrors((errors) => {
                delete errors.languages;
                return errors;
            });
        }
    };

    const handleChangeTimeZone = (event, newValue) => {
        setTimeZone(newValue);
    };

    if (isLoading) return <Loading />;

    return (
        <>
            {showBasisInfo && (
                <Box style={{ borderBottom: '3px dashed #bababa' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Grid
                                container
                                direction="row"
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Badge
                                    sx={{ boxShadow: 'none' }}
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent={
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            component="label"
                                            className={`${classes.edit}`}
                                        >
                                            <EditIcon
                                                className={classes.editIcon}
                                            />
                                            <input
                                                type="file"
                                                accept="image/*"
                                                hidden
                                                onChange={(event) => {
                                                    setSelectedImage(
                                                        event.target.files[0]
                                                    );
                                                    handleProfilePicChange(
                                                        event.target.files[0]
                                                    );
                                                }}
                                            />
                                        </Button>
                                    }
                                >
                                    <Avatar
                                        variant="square"
                                        src={profilePic}
                                        alt="Profile Pic"
                                        className={`${classes.avatar}`}
                                    />
                                </Badge>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color="secondary"
                                        label="First Name"
                                        name="firstName"
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                        }}
                                        required
                                        size="small"
                                        error={!!errors.firstName}
                                        helperText={errors.firstName}
                                        className={classes.formControl}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color="secondary"
                                        label="Last Name"
                                        name="lastName"
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                        }}
                                        required
                                        size="small"
                                        error={!!errors.lastName}
                                        helperText={errors.lastName}
                                        className={classes.formControl}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                color="secondary"
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                required
                                size="small"
                                error={!!errors.email}
                                helperText={errors.email}
                                className={classes.formControl}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        options={CountryData}
                                        getOptionLabel={(option) =>
                                            option?.name || ''
                                        }
                                        value={country}
                                        onChange={handleChangeCountry}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                error={!!errors.country}
                                                helperText={errors.country}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                <Typography>
                                                    {option.name}
                                                </Typography>
                                            </Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color="secondary"
                                        label="City"
                                        name="city"
                                        value={city}
                                        onChange={(e) => {
                                            setCity(e.target.value);
                                        }}
                                        required
                                        size="small"
                                        error={!!errors.city}
                                        helperText={errors.city}
                                        className={classes.formControl}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        options={ZoneData}
                                        getOptionLabel={(option) =>
                                            option?.label || ''
                                        }
                                        value={time_zone}
                                        onChange={handleChangeTimeZone}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Time Zone"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                error={!!errors.time_zone}
                                                helperText={errors.time_zone}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                <Typography>
                                                    {option.label}
                                                </Typography>
                                            </Box>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color="secondary"
                                        label="Phone Number"
                                        name="phone"
                                        value={phone}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                        }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        required
                                        size="small"
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        className={classes.formControl}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                color="secondary"
                                label="Linkedin Profile"
                                name="linkedin"
                                value={linkedin}
                                onChange={(e) => {
                                    setLinkdin(e.target.value);
                                }}
                                required
                                size="small"
                                error={!!errors.linkedin}
                                helperText={errors.linkedin}
                                className={classes.formControl}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        className="switch-container"
                                    >
                                        <Box mr={1}>
                                            <Typography
                                                className={classes.label}
                                            >
                                                Admin:{' '}
                                            </Typography>
                                        </Box>

                                        <Box
                                            minHeight={40}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Switch
                                                checked={admin}
                                                onChange={(e) => {
                                                    setAdmin(e.target.checked);
                                                }}
                                                color="secondary"
                                                inputProps={{
                                                    'aria-label': 'admin',
                                                }}
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        className="switch-container"
                                    >
                                        <Box mr={1}>
                                            <Typography
                                                className={classes.label}
                                            >
                                                Instructor:{' '}
                                            </Typography>
                                        </Box>
                                        <Box
                                            minHeight={40}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Switch
                                                checked={instructor}
                                                onChange={(e) => {
                                                    setInstructor(
                                                        e.target.checked
                                                    );
                                                }}
                                                color="secondary"
                                                inputProps={{
                                                    'aria-label': 'instructor',
                                                }}
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        className="switch-container"
                                    >
                                        <Box mr={1}>
                                            <Typography
                                                className={classes.label}
                                            >
                                                Collaborator:{' '}
                                            </Typography>
                                        </Box>
                                        <Box
                                            minHeight={40}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {' '}
                                            <Switch
                                                checked={collaborator}
                                                onChange={(e) => {
                                                    setCollaborator(
                                                        e.target.checked
                                                    );
                                                }}
                                                color="secondary"
                                                inputProps={{
                                                    'aria-label':
                                                        'collaborator',
                                                }}
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            {userDetails?.is_instructor && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    className={classes.inputContainer}
                                >
                                    <Box mr={1}>
                                        <Typography className={classes.label}>
                                            Channel Status:{' '}
                                        </Typography>
                                    </Box>

                                    <ChannelStatus
                                        userProfile={userDetails}
                                        userWPProfile={userWPProfile}
                                        // fileData={fileData}
                                        handleChannelStatusChange={
                                            handleChannelStatusChange
                                        }
                                        handleChannelStatusChangeSwitch={
                                            handleChannelStatusChangeSwitch
                                        }
                                        channelStatus={channelStatus}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Typography
                style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    fontWeight: 800,
                }}
                variant="body2"
            >
                Availability:
            </Typography>
            <Box>
                <TextField
                    color="secondary"
                    select
                    name="availability"
                    value={availability || ''}
                    onChange={(e) => {
                        setAvailability(e.target.value);
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    required
                    size="small"
                    error={!!errors.time_zone}
                    helperText={errors.time_zone}
                    className={classes.formControl}
                >
                    <option value="" disabled>
                        Select availability
                    </option>
                    {availabilityOptions.map((option) => (
                        <option key={option.label} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography
                        style={{
                            marginTop: '20px',
                            marginBottom: '10px',
                            fontWeight: 800,
                        }}
                        variant="body2"
                    >
                        Languages:
                    </Typography>
                    <Box>
                        <Autocomplete
                            multiple
                            options={languageOptions}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.nativeName})`
                            }
                            value={languageOptions.filter((option) =>
                                languages.includes(option.code)
                            )}
                            onChange={handleLanguageChange}
                            disableCloseOnSelect
                            filterSelectedOptions
                            renderOption={(props, option, { selected }) => (
                                <Box component="li" {...props}>
                                    <Checkbox checked={selected} />
                                    <ListItemText
                                        primary={`${option.name} (${option.nativeName})`}
                                    />
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    name="language"
                                    color="secondary"
                                    error={errors.languages}
                                    size="small"
                                    fullWidth
                                    placeholder="Select languages"
                                    helperText={errors.languages || ''}
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        style={{
                            marginTop: '20px',
                            marginBottom: '10px',
                            fontWeight: 800,
                        }}
                        variant="body2"
                    >
                        Domains:
                    </Typography>
                    <Box>
                        <TextField
                            value={domains}
                            onChange={handleDomainChange}
                            select
                            variant="outlined"
                            name="domain"
                            color="secondary"
                            size="small"
                            fullWidth
                            className="input"
                            error={errors.domains}
                            helperText={errors.domains || ''}
                            SelectProps={{
                                multiple: true,
                                renderValue: (selected) => selected.join(', '),
                            }}
                            placeholder="Select domains"
                            dropd
                        >
                            {domainOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    <Checkbox
                                        checked={
                                            domains.indexOf(option.value) > -1
                                        }
                                    />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
            </Grid>
            <Typography
                style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    fontWeight: 800,
                }}
                variant="body2"
            >
                Tags:​
            </Typography>
            <Typography>
                <Autocomplete
                    clearIcon
                    options={[]}
                    freeSolo
                    multiple
                    size="small"
                    value={tags}
                    onChange={(e, newValue) => {
                        setTags(newValue);
                    }}
                    renderTags={(value, props) =>
                        value.map((option, index) => (
                            <Chip
                                label={option}
                                {...props({ index })}
                                size="small"
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            name="tags"
                            placeholder="Write the tag and press enter to add"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            fullWidth
                            style={{
                                minWidth: 200,
                            }}
                            {...params}
                        />
                    )}
                />
            </Typography>
            <Typography
                style={{
                    marginTop: '20px',
                    marginBottom: '10px',
                    fontWeight: 800,
                }}
                variant="body2"
            >
                Tagline:
            </Typography>
            <Box>
                <TextField
                    color="secondary"
                    variant="outlined"
                    value={tagLine}
                    fullWidth
                    required
                    size="small"
                    onChange={handleTagLineInput}
                    disabled={!edit}
                    error={!!errors.tagLine}
                    helperText={errors.tagLine}
                />
            </Box>
            <Typography
                style={{ marginTop: '20px', fontWeight: 800 }}
                variant="body2"
            >
                About
            </Typography>
            <Box
                style={{
                    marginBottom: '30px',
                }}
            >
                <CKEditor
                    onInstanceReady={initializeIntro}
                    onChange={(event) => {
                        return setIntro(event.editor.getData());
                    }}
                    readOnly={!edit}
                />
            </Box>

            <ProfileExperience userDetails={userDetails} />
            <PersonaSearchAndSelect
                selectedPersonas={selectedPersonas}
                setSelectedPersonas={setSelectedPersonas}
            />
            {errors?.selectedPersonas && (
                <Typography color={'error'} component={'small'}>
                    {errors.selectedPersonas}
                </Typography>
            )}

            <Box display="flex" mt={4}>
                {onBack && (
                    <Button
                        onClick={onBack}
                        variant="contained"
                        color="primary"
                    >
                        Back
                    </Button>
                )}
                <Box style={{ marginLeft: 'auto' }}>
                    <Button
                        onClick={() => {
                            const isValid = validateFields();
                            if (isValid) {
                                window.scroll(0, 0);
                                if (edit) save();
                                else enableEdit();
                            }
                        }}
                        variant="contained"
                        color="secondary"
                        disabled={saving}
                    >
                        {saving ? (
                            <CircularProgress size="1.9em" />
                        ) : edit ? (
                            <>{saveBtnText || 'Save'}</>
                        ) : (
                            <>Edit</>
                        )}
                    </Button>
                </Box>
            </Box>
        </>
    );
};
