import {
    Box,
    TextField,
    useTheme,
    useMediaQuery,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useEffect } from 'react';
import { useMemo, useState } from 'react';

const useStyles = makeStyles()((theme) => ({
    addTaskForm: {
        '& .form-group': {
            marginBottom: theme.spacing(1),
            flexGrow: 1,
        },
        '& .form-group label': {
            fontSize: '1.1rem',
        },
    },
    groupWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
}));

function AddTaskFrom({
    taskCategories,
    selectedCategoryIdx = 0,
    newTask,
    setNewTask,
    isTaskEdit,
}) {
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [isCheckpoint, setIsCheckpoint] = useState(false);
    const { classes } = useStyles();

    useEffect(() => {
        if (isTaskEdit && newTask.checkpointName !== null) {
            setIsCheckpoint(true);
        }
    }, [isTaskEdit, newTask.checkpointName]);

    const selectedCategoryName = useMemo(() => {
        return taskCategories[selectedCategoryIdx].categoryName;
    }, [selectedCategoryIdx, taskCategories]);

    const handleIsCheckpoint = (e) => {
        const checked = e.target.checked;

        if (checked === false) {
            setNewTask((prev) => {
                return {
                    ...prev,
                    checkpointName: null,
                };
            });
        }

        setIsCheckpoint(checked);
    };

    const handleChange = (e) => {
        setNewTask((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleChecked = (e) => {
        setNewTask((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked,
            };
        });
    };

    return (
        <form className={classes.addTaskForm}>
            <div className="form-group">
                <label htmlFor="selectedCategoryName">
                    Selected category name
                </label>
                <TextField
                    id="selectedCategoryName"
                    name="selectedCategoryName"
                    type="text"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    size={isXSDevice ? 'small' : 'medium'}
                    value={selectedCategoryName}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </div>

            <div className="form-group">
                <label htmlFor="taskName">New task name</label>
                <TextField
                    id="taskName"
                    name="name"
                    type="text"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    size={isXSDevice ? 'small' : 'medium'}
                    value={newTask.name}
                    onChange={handleChange}
                />
            </div>

            <div className="form-group">
                <label htmlFor="taskType">Task type</label>
                <TextField
                    id="taskType"
                    name="typeOfTask"
                    type="text"
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    size={isXSDevice ? 'small' : 'medium'}
                    value={newTask.typeOfTask}
                    onChange={handleChange}
                />
            </div>

            <div className={classes.groupWrapper}>
                <div className="form-group">
                    <label htmlFor="duration">Duration</label>
                    <TextField
                        id="duration"
                        name="duration"
                        type="number"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size={isXSDevice ? 'small' : 'medium'}
                        value={newTask.duration}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="days"># Days</label>
                    <TextField
                        id="days"
                        name="numOfDaysBeforeDelivery"
                        type="number"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size={isXSDevice ? 'small' : 'medium'}
                        value={newTask.numOfDaysBeforeDelivery}
                        onChange={handleChange}
                    />
                </div>
            </div>

            {isCheckpoint && (
                <div className="form-group">
                    <label htmlFor="checkpointName">Checkpoint name</label>
                    <TextField
                        id="checkpointName"
                        name="checkpointName"
                        type="text"
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        size={isXSDevice ? 'small' : 'medium'}
                        value={
                            newTask.checkpointName === null
                                ? ''
                                : newTask.checkpointName
                        }
                        onChange={handleChange}
                    />
                </div>
            )}

            <div className="from-group switch-group">
                <FormControlLabel
                    control={
                        <Switch
                            checked={newTask.isVisibleToInstructor}
                            name="isVisibleToInstructor"
                            size={isXSDevice ? 'small' : 'medium'}
                            onChange={handleChecked}
                        />
                    }
                    label="Show to instructor"
                />

                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={newTask.isSimplified}
                            name="isSimplified"
                            size={isXSDevice ? 'small' : 'medium'}
                            onChange={handleChecked}
                        />
                    }
                    label="Show in simplified view"
                /> */}
            </div>

            <div className="form-group">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCheckpoint}
                            onChange={handleIsCheckpoint}
                            value={isCheckpoint}
                            size={isXSDevice ? 'small' : 'medium'}
                        />
                    }
                    label="Set this task as checkpoint task"
                />
            </div>
        </form>
    );
}

export default AddTaskFrom;
