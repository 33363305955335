import { Box, Skeleton, Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// import { InstructorProjects } from '../../admin/components/InstructorManagement/InstructorProjectDialog';
import UserChanges from '../../admin/UserChanges';
import { CommentSection } from '../../comments/CommentSection';
import { ProfileExperties } from '../../instructor-dashboard/ProfileExperties';
import { ProfileInfo } from '../../instructor-dashboard/ProfileInfo';
import { ProfileServices } from '../../instructor-dashboard/ProfileServices';
import { visibilities } from '../../util/visibility';

export default function ExpertSummaryDrawerTabs() {
    const expertSummary = useSelector((state) => state.expertSummary);
    const [activeTabValue, setActiveTabValue] = useState('profile');

    const tabs = useMemo(() => {
        return [
            {
                label: 'PROFILE',
                value: 'profile',
                component: (
                    <ProfileInfo
                        userDetails={expertSummary.expertData}
                        isAdmin={true}
                        showBasisInfo={false}
                    />
                ),
            },
            {
                label: 'EXPERTISE & SERVICES',
                value: 'expertiseServices',
                component: (
                    <>
                        <ProfileServices
                            userDetails={expertSummary.expertData}
                            isAdmin={true}
                            showAlert={true}
                        />
                        <ProfileExperties
                            userDetails={expertSummary.expertData}
                            isAdmin={true}
                        />
                    </>
                ),
            },
            // {
            //     label: 'PROJECTS',
            //     value: 'projects',
            //     component: (
            //         <InstructorProjects
            //             handleExport={() => console.log('test')}
            //             smeEmail={expertSummary.expertData?.user_email}
            //         />
            //     ),
            // },
            {
                label: 'NOTES',
                value: 'notes',
                component: (
                    <CommentSection
                        courseId={`sme-notes-${expertSummary.expertData?._id}`}
                        courseSlug={''}
                        courseName={`sme-${
                            expertSummary.expertData?.user_firstName
                        } ${expertSummary.expertData?.user_lastName || ''}`}
                        visibilityOptions={[
                            {
                                option: visibilities.STARWEAVER_TEAM.visibility,
                                icon: visibilities.STARWEAVER_TEAM.icon,
                            },
                            {
                                option: visibilities.ONLY_ME.visibility,
                                icon: visibilities.ONLY_ME.icon,
                            },
                        ]}
                        defaultVisibility={
                            visibilities.STARWEAVER_TEAM.visibility
                        }
                        showCommentAttachments={false}
                        showAttachments={false}
                        hideCourseTagging={true}
                        showRemindOption={false}
                        placeholder="Add notes"
                    />
                ),
            },
            {
                label: 'LOGS',
                value: 'logs',
                component: <UserChanges id={expertSummary.expertData?._id} />,
            },
        ];
    }, [expertSummary.expertData]);

    const component = useMemo(() => {
        const tab = tabs.find((tab) => tab.value === activeTabValue);
        return tab?.component;
    }, [activeTabValue, tabs]);

    const handleChangeTab = (_, newValue) => {
        setActiveTabValue(newValue);
    };

    return (
        <Box>
            <Tabs
                value={activeTabValue}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                textColor="secondary"
                indicatorColor="secondary"
            >
                {tabs.map((tab) => (
                    <Tab key={tab.label} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
            <Box mt={2} py={2}>
                {expertSummary.isLoading ? (
                    <Box>
                        {new Array(10).fill(0).map((_, idx) => {
                            return (
                                <Skeleton
                                    key={`loading-${idx}`}
                                    variant="rectangular"
                                    width={'100%'}
                                    height={'5'}
                                    sx={{ mt: (theme) => theme.spacing(2) }}
                                />
                            );
                        })}
                    </Box>
                ) : (
                    <>{component}</>
                )}
            </Box>
        </Box>
    );
}
