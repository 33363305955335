import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloseIcon } from '../../../icons';

const useStyles = makeStyles()((theme) => ({
    header: {
        position: 'sticky',
        top: 55,
        backgroundColor: '#fff',
        zIndex: 5,
        paddingBlock: theme.spacing(1.25),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow:
            '0px 2px 0px 0 rgb(0 0 0 / 0.1), 0 1px 0px 0px rgb(0 0 0 / 0.1)',
        [theme.breakpoints.up('md')]: {
            top: 63,
        },
    },
    closeButton: {
        marginLeft: 'auto',
        marginBlock: theme.spacing(0.25),
    },
}));

function SummaryDrawerHeader({ handleClose, title = '' }) {
    const { classes } = useStyles();
    return (
        <Box textAlign={'right'} className={classes.header}>
            {title.length > 0 && <Typography variant="h6">{title}</Typography>}
            <IconButton
                className={classes.closeButton}
                size="small"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );
}

export default SummaryDrawerHeader;
