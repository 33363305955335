import { useEffect, useState } from 'react';
import axios from 'axios';

export const useInstructorProfile = (userDetails) => {
    // console.log(userDetails);
    const [isLoading, setIsLoading] = useState(true);
    const [tagLine, setTagline] = useState(
        userDetails?.instructor_details?.tag_line || ''
    );
    const [intro, setIntro] = useState(
        userDetails?.instructor_details?.intro || ''
    );
    const [name, setName] = useState(
        userDetails?.user_firstName + ' ' + userDetails?.user_lastName || ''
    );
    const [profilePic, setProfilePic] = useState(
        userDetails?.profilePic ||
            userDetails?.instructor_details?.channelProfilePic ||
            null
    );
    const [bannerPic, setbannerPic] = useState(null);
    const [id, setId] = useState(userDetails?.instructorPostId || null);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [domains, setDomains] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [tags, setTags] = useState([]);
    const [availability, setAvailability] = useState('');
    const [selectedPersonas, setSelectedPersonas] = useState([]);

    useEffect(() => {
        if (!userDetails /* || !userDetails.is_instructor */) return;
        (async () => {
            try {
                // setIsLoading(true);
                // const res = await axios.get(
                //     `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/wp-json/headless/instructor/${userDetails.user_email}?status=any&nowprocket=1`
                // );
                // setId(res.data.id);
                // setTagline(res.data.tagLine);
                // setIntro(res.data.intro);
                // setProfilePic(res.data.profilePic);
                // setbannerPic(res.data.bannerPic);
                // setName(res.data.name);
                // setRelatedArticles(res.data.relatedArticles);
                // setIsLoading(false);
                const jobRolesRes = await axios.get(
                    `/api/job-roles?instructorIds=${userDetails._id}`
                );
                setSelectedPersonas(jobRolesRes.data.jobRoles);
                setTags(userDetails.tags || []);
                setAvailability(userDetails.availability || '');
                setId(userDetails.instructorPostId);
                setTagline(userDetails.instructor_details.tag_line);
                setIntro(userDetails.instructor_details.intro);
                setDomains(userDetails.domains || []);
                setLanguages(userDetails.languages || []);
                setProfilePic(
                    userDetails.profilePic ||
                        userDetails.instructor_details.channelProfilePic
                );
                setbannerPic(userDetails.instructor_details.channelBannerPic);
                setName(
                    userDetails.user_firstName + ' ' + userDetails.user_lastName
                );
                setRelatedArticles([]);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [userDetails]);

    return {
        userDetails,
        isLoading,
        setIsLoading,
        id,
        name,
        availability,
        setAvailability,
        tags,
        setTags,
        tagLine,
        setTagline,
        intro,
        setIntro,
        domains,
        setDomains,
        languages,
        setLanguages,
        profilePic,
        bannerPic,
        setProfilePic,
        relatedArticles,
        setRelatedArticles,
        selectedPersonas,
        setSelectedPersonas,
    };
};
