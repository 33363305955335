import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon, SearchIcon } from '../../../icons';
import { roleList } from '../../../util';
import ClientOptions from './ClientOptions';
import { useSelector, useDispatch } from 'react-redux';
import {
    setColumnField,
    setFilterType,
    setOperatorValue,
    setFilterValue,
    clearFilters,
} from '../../slices/filtersSlice';

const useStyles = makeStyles()((theme) => ({
    header: {
        padding: theme.spacing(2, 3, 1),
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem;',
        fontWeight: 600,
        margin: 0,
        padding: 0,
    },
    closeBtn: {
        marginLeft: 'auto',
    },
    filterForm: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '0.5rem',
    },
    formAction: {
        marginTop: '1rem',
        flexBasis: '100%',
        display: 'flex',
        gap: '0.5rem',
        '& > *:first-child': {
            marginLeft: 'auto',
        },
    },
    input: {
        border: 0,
        outline: '1.5px solid #9ca3af',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        padding: '0.5rem',
        borderRadius: '0.25rem',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 'unset',
            flexGrow: 1,
        },
        '&:focus': {
            outline: '2px solid #f97316',
        },
    },
}));

const commonFilterOperators = [
    {
        name: 'Contains',
        value: 'contains',
    },
    {
        name: 'Does not contain',
        value: 'doesNotContain',
    },
    {
        name: 'Equals',
        value: 'equals',
    },
    {
        name: 'Starts with',
        value: 'startsWith',
    },
    {
        name: 'Ends with',
        value: 'endsWith',
    },
    {
        name: 'Is empty',
        value: 'isEmpty',
    },
    {
        name: 'Is not empty',
        value: 'isNotEmpty',
    },
];

const durationFilterOperators = [
    {
        name: 'Equals',
        value: 'equals',
    },
    {
        name: 'Greater than',
        value: 'greaterThan',
    },
    {
        name: 'Less than',
        value: 'lessThan',
    },
];

const dateFilterOperators = [
    {
        name: 'Is',
        value: 'is',
    },
    {
        name: 'Is not',
        value: 'isNot',
    },
    {
        name: 'Is after',
        value: 'isAfter',
    },
    {
        name: 'Is on after',
        value: 'isOnAfter',
    },
    {
        name: 'Is on before',
        value: 'isOnBefore',
    },
    {
        name: 'Is before',
        value: 'isBefore',
    },
];

const numericFields = [
    'duration',
    'interestedInstructors',
    'smeOpeningBal',
    'additionalAdv',
    'royaltyEarned',
    'smeEndingBal',
    'payableToSME',
    'paidToSME',
    'year',
    'projects',
];

const listFields = [
    'tags',
    'smes',
    'applicantNames',
    'collaborators',
    'addedCollaborators',
    'contractNumbers',
    'requirement.collaborators',
    ...roleList.map((role) => role.field),
];

const dateFields = [
    'expectedEndDate',
    'lastActivity',
    'payoutDate',
    'signedIn',
    'current_plan_end_date',
];
const selectionFields = ['client_name', 'client', 'channelStatus'];

const TableFilterModal = ({ open, columns, filters, setFilters, setOpen }) => {
    const setAlert = useSetRecoilState(alertState);
    const { classes } = useStyles();
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedOperator, setSelectedOperator] = useState('');
    const [filterWriteValue, setFilterWriteValue] = useState('');
    const dispatch = useDispatch();
    const { columnField, filterType, operatorValue, filterValue } = useSelector(
        (state) => state.filters
    );

    const getFilterOperators = useCallback((field) => {
        if (numericFields.includes(field)) return durationFilterOperators;
        if (listFields.includes(field))
            return [commonFilterOperators[0], commonFilterOperators[1]];
        if (dateFields.includes(field)) return dateFilterOperators;
        if (selectionFields.includes(field)) {
            return commonFilterOperators.filter(
                (filter) => filter.value === 'equals'
            );
        }

        return commonFilterOperators;
    }, []);

    const filterOptions = useMemo(() => {
        const initialValue = {
            columns: [],
            filters: [],
        };

        return columns.reduce((prev, curr) => {
            if (curr.hide === true || curr.filterable === false) return prev;
            return {
                columns: prev.columns.concat([
                    {
                        headerName: curr?.headerName ?? curr?.title,
                        field: curr?.field ?? curr?.fieldName,
                    },
                ]),
                filters: prev.filters.concat([
                    {
                        field: curr?.field ?? curr?.fieldName,
                        operators: getFilterOperators(
                            curr?.field ?? curr?.fieldName
                        ),
                    },
                ]),
            };
        }, initialValue);
    }, [columns, getFilterOperators]);

    const operators = useMemo(() => {
        const operatorData = filterOptions.filters.filter(
            (item) => item.field === selectedColumn
        );
        if (operatorData.length === 0) return {};
        const data = operatorData[0];
        return data;
    }, [filterOptions.filters, selectedColumn]);

    const getInputType = useCallback((field) => {
        if (numericFields.includes(field)) return 'number';
        if (dateFields.includes(field)) return 'date';
        return 'text';
    }, []);

    const getPlaceholder = useCallback((field) => {
        if (field === 'duration') return 'Hr(s)';
        if (field === 'interestedInstructors') return '# Interests';
        return 'Filter value';
    }, []);

    const showFilterValueInput = useMemo(() => {
        if (['isEmpty', 'isNotEmpty'].includes(selectedOperator)) return false;
        return true;
    }, [selectedOperator]);

    const resetState = () => {
        setSelectedColumn('');
        setSelectedOperator('');
        setFilterWriteValue('');
    };

    const handleClear = () => {
        resetState();
        dispatch(clearFilters());
        // localStorage.removeItem('tableFilters');

        setFilters({
            columnField: '',
            filterType: '',
            operatorValue: '',
            value: '',
        });
    };

    const handleClose = () => {
        resetState();
        setOpen(false);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} e
     */
    const handleColSelect = (e) => {
        setSelectedColumn(e.target.value);
        setSelectedOperator('');
        setFilterWriteValue('');
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} e
     */
    const handleOperatorSelect = (e) => {
        setSelectedOperator(e.target.value);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleChangeFilterValue = (e) => {
        let type = e.target.type;
        let value =
            type === 'date'
                ? moment(e.target.value).format('YYYY-MM-DD')
                : e.target.value;
        setFilterWriteValue(value);
    };

    const validate = () => {
        const errors = [];

        if (showFilterValueInput && filterWriteValue.trim().length === 0)
            errors.push({
                message: "Filter value can't left emtpy",
                param: 'filterWriteValue',
            });

        return { isError: errors.length > 0, errors };
    };

    /**
     *
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    const handleSearch = (e) => {
        e.preventDefault();
        const { isError, errors } = validate();

        if (isError)
            return setAlert({
                show: true,
                severity: severity.WARNING,
                message: errors[0].message,
            });

        const filterState = {
            columnField:
            selectedColumn !== 'duration' ? selectedColumn : 'hours',
            filterType: getInputType(selectedColumn),
            operatorValue: selectedOperator,
            value: showFilterValueInput ? filterWriteValue : ' ',
        };

        dispatch(setColumnField(filterState.columnField));
        dispatch(setFilterType(filterState.filterType));
        dispatch(setOperatorValue(filterState.operatorValue));
        dispatch(setFilterValue(filterState.value));

        // localStorage.setItem('tableFilters', JSON.stringify(filterState));
        setFilters(filterState);
        handleClose();
    };

    useEffect(() => {
        if (filters?.columnField) setSelectedColumn(filters?.columnField);
        if (filters?.filterType) setFilterType(filters?.filterType);
        if (filters?.operatorValue) setSelectedOperator(filters?.operatorValue);
        if (filters?.value) setFilterWriteValue(filters?.value);
    }, [filters]);

    return (
        <div>
            <Dialog
                aria-labelledby="filter-dialog"
                open={open}
                maxWidth="sm"
                fullWidth
            >
                {/* Dialog header */}
                <div className={classes.header}>
                    {/* Title */}
                    <p className={classes.title}>Apply Filters</p>

                    {/* Close button */}
                    <div className={classes.closeBtn}>
                        <IconButton size="small" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </div>
                </div>

                {/* Dialog content */}
                <DialogContent>
                    <form
                        onSubmit={handleSearch}
                        className={classes.filterForm}
                        autoComplete="off"
                    >
                        {/* Column selection */}
                        <select
                            name="column"
                            id="column"
                            aria-label="column name"
                            onChange={handleColSelect}
                            value={selectedColumn}
                            className={classes.input}
                            required
                        >
                            <option disabled selected value={''}>
                                -- Select a column --
                            </option>
                            {filterOptions.columns.map((col, idx) => (
                                <option
                                    key={`${col.field}-${idx}`}
                                    value={col.field}
                                >
                                    {col.headerName}
                                </option>
                            ))}
                        </select>

                        {/* Operator selection */}
                        <select
                            name="operators"
                            id="operators"
                            aria-label="operator name"
                            onChange={handleOperatorSelect}
                            value={selectedOperator}
                            className={classes.input}
                            required
                        >
                            <option disabled selected value={''}>
                                -- Select an operator --
                            </option>
                            {operators?.operators?.map((operator, idx) => (
                                <option
                                    key={`${operator.field}-${idx}`}
                                    value={operator.value}
                                >
                                    {operator.name}
                                </option>
                            ))}
                        </select>

                        {showFilterValueInput && (
                            <input
                                type={getInputType(selectedColumn)}
                                name="filterValue"
                                id="filterValue"
                                aria-label="filter value"
                                autoComplete="off"
                                value={filterWriteValue}
                                onChange={handleChangeFilterValue}
                                placeholder={getPlaceholder(selectedColumn)}
                                className={classes.input}
                                required={
                                    selectedColumn !== 'client_name' ||
                                    selectedColumn !== 'client'
                                }
                                step="any"
                                hidden={
                                    selectedColumn === 'client_name' ||
                                    selectedColumn === 'client' ||
                                    selectedColumn === 'channelStatus'
                                }
                            />
                        )}

                        {/* --- CLIENT NAME DROPDOWN --- */}
                        {(selectedColumn === 'client_name' ||
                            selectedColumn === 'client') && (
                            <ClientOptions
                                setSelectedClientName={(value) => {
                                    setFilterWriteValue(value);
                                    dispatch(setFilterValue(value));
                                }}
                                selectedClientName={filterValue}
                            />
                        )}

                        {selectedColumn === 'channelStatus' && (
                            <select
                                className={classes.input}
                                value={filterWriteValue}
                                onChange={(e) => {
                                    setFilterWriteValue(e.target.value);
                                    dispatch(setFilterValue(e.target.value));
                                }}
                                required
                            >
                                <option value="" disabled>
                                    --- Select status ---
                                </option>
                                <option value="publish">Published</option>
                                <option value="draft">Draft</option>
                            </select>
                        )}

                        <div className={classes.formAction}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CloseIcon />}
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                startIcon={<SearchIcon />}
                            >
                                Search
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

TableFilterModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default TableFilterModal;
