import { Box, Skeleton, Typography } from '@mui/material';
import { getPercentageCompletedText, roleList } from '../../../util';
import { useDispatch } from 'react-redux';
import {
    displayRequirementSummary,
    fetchRequirementById,
    setPercentageColClickedStatus,
    setTaskColClickedStatus,
} from '../../slices/requirementSummarySlice';
import { resetExpertModalState } from '../../slices/expertProjectModalSlice';

function PercentageCompleteProgress({
    row,
    isInsights = false,
    loading = false,
    disableDrawer = false,
}) {
    const dispatch = useDispatch();

    const handleClick = () => {
        if (disableDrawer) return;
        dispatch(setTaskColClickedStatus(false));
        dispatch(setPercentageColClickedStatus(true));
        if (isInsights) {
            dispatch(fetchRequirementById(row._id));
        } else {
            dispatch(
                displayRequirementSummary({
                    requirementId: row._id,
                    _id: row._id,
                    id: row._id,
                    title: row.title,
                    smes: row?.smes ?? [],
                    SMEengagements: row?.SMEengagements ?? [],
                    instructionalDesigners: row?.instructionalDesigners ?? [],
                    logisticsOperations: row?.logisticsOperations ?? [],
                    status: row?.status,
                    expectedEndDate: row?.expectedEndDate,
                    clientId: row?.clientId?._id,
                    shortListedSmes: row?.shortListedSmes,
                    collaborators: row?.collaborators,
                    addedCollaborators: row?.addedCollaborators,
                    taskCategories: row?.taskCategories,
                    templateId: row?.templateId,
                    templateName: row?.templateDetails?.name,
                    contractNumber: row?.contractNumber,
                    ...roleList.reduce((prev, curr) => {
                        const key = curr.field;
                        prev[key] = row[key] ?? [];
                        return prev;
                    }, {}),
                    outlineFormat: row?.outlineFormat ?? 'Module-Grid',
                    hours: row?.hours ?? 0,
                    minutes: row?.minutes ?? 0,
                    domain: row?.domain,
                })
            );
        }
        dispatch(resetExpertModalState());
    };

    if (loading) {
        return <Skeleton variant="text" width="100%" />;
    }

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            onClick={handleClick}
            sx={{ cursor: 'pointer' }}
        >
            <Box
                style={{
                    border: '1px solid #a6a6a6',
                    width: '100%',
                    height: '5px',
                    borderRadius: '100vmax',
                    overflow: 'hidden',
                    backgroundColor: '#f6f6f6',
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#ea7112',
                        height: '100%',
                        width: row?.percentageCompleted
                            ? row.percentageCompleted + '%'
                            : 0,
                        borderRadius: '100vmax',
                    }}
                />
            </Box>
            <Box mt={0.25}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ width: '100%' }}
                >
                    {getPercentageCompletedText({
                        percentageCompleted: row?.percentageCompleted,
                        notDueTasksCount: row?.notDueTasksCount,
                        overDueTasksCount: row?.overDueTasksCount,
                        totalTasks: row?.totalTasks,
                    })}
                </Typography>
            </Box>
        </Box>
    );
}

export default PercentageCompleteProgress;
