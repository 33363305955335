import React, { useCallback, useEffect, useState } from 'react';
import Feeds from '../FeedsAndActivity/Feeds';
import { useCurrentUser } from '../../../my-account/useCurrentUser';
import axios from 'axios';
import { useQueryParams } from '../../../util/useQueryParams';
import { useTeams } from '../../useTeams';
import Box from '@mui/material/Box';
import FeedCommentSwitch from '../FeedsAndActivity/FeedCommentSwitch';

function OMDFeeds({
    requirementId,
    isInstructorView,
    selectedRole,
    hideSwitch = false,
    onlyComments = false,
}) {
    const { user: userDetails, isLoading: isUserLoading } = useCurrentUser();
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(10);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const { feed_id } = useQueryParams();
    const [showFeedsWithComments, setShowFeedsWithComments] = useState(false);
    const { teams, isLoading: isLoadingTeams } = useTeams();

    const fetchData = useCallback(async () => {
        try {
            if (isUserLoading || !userDetails || !requirementId) return;
            setIsLoading(true);
            const baseURL = '/api/client-requirements/activities';
            const params = new URLSearchParams();
            params.append('page', page);
            params.append('limit', limit);
            if (userDetails?.client_id) {
                params.append('clientId', userDetails?.client_id);
            }
            if (requirementId) {
                params.append('selectedRequirementId', requirementId);
            }
            if (feed_id) {
                params.append('feedId', feed_id);
            }

            if (isInstructorView) {
                params.append('asInstructor', 1);
            }

            if (selectedRole) {
                params.append('selectedRole', selectedRole);
            }

            if (showFeedsWithComments || onlyComments) {
                params.append('feedsWithComments', showFeedsWithComments);
            }

            const url = `${baseURL}?${params.toString()}`;
            const res = await axios.get(url);
            setRows(res.data.activity);
            setRowCount(res.data.count);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [
        isUserLoading,
        userDetails,
        requirementId,
        page,
        limit,
        feed_id,
        isInstructorView,
        selectedRole,
        showFeedsWithComments,
        onlyComments,
    ]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Box>
            {!hideSwitch && (
                <Box display="flex" justifyContent="flex-end" mb={4}>
                    <FeedCommentSwitch
                        showFeedsWithComments={showFeedsWithComments}
                        setShowFeedsWithComments={setShowFeedsWithComments}
                        labelPlacement="start"
                    />
                </Box>
            )}

            <Feeds
                rows={rows}
                rowCount={rowCount}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                loading={isLoading}
                userDetails={userDetails}
                setRows={setRows}
                isInstructorView={isInstructorView}
                selectedRole={selectedRole}
                teams={teams}
            />
        </Box>
    );
}

export default OMDFeeds;
