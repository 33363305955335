import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    showSummary: false,
    isLoading: false,
    applicantsModalData: { isOpen: false, instructorIds: [], id: null },
    expertData: {},
    projectModal: { isOpen: false, expertUserId: null },
    showChat: false,
};

export const fetchExpertById = createAsyncThunk(
    'fetchExportById',
    async (expertUserId) => {
        const axiosRes = await axios.get(
            '/api/admin/user-profile?id=' + expertUserId
        );
        return axiosRes.data;
    }
);

export const expertSummarySlice = createSlice({
    name: 'expertSummary',
    initialState,
    reducers: {
        toggleShowSummary: (state, action) => {
            state.showSummary = action.payload;
            if (action.payload === false) {
                state.expertData = {};
                state.showChat = false;
            }
        },
        setApplicantsModal: (state, action) => {
            state.applicantsModalData = {
                id: action.payload.id,
                instructorIds: action.payload.instructorIds,
                isOpen: action.payload.isOpen,
            };
        },
        resetStore: (state) => {
            state.showSummary = false;
            state.isLoading = false;
            state.applicantsModalData = {
                isOpen: false,
                instructorIds: [],
                id: null,
            };
            state.expertData = {};
            state.showChat = false;
            // state.isProjectsModalVisible = false;
        },
        toggleShowChat: (state, action) => {
            state.showChat = action.payload;
        },
        setProjectsModalVisible: (state, action) => {
            state.projectModal = {
                isOpen: action.payload.isOpen,
                expertUserId: action.payload.expertUserId,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchExpertById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchExpertById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.expertData = action.payload;
            state.showSummary = true;
        });
        builder.addCase(fetchExpertById.rejected, (state) => {
            state.isLoading = false;
            state.expertData = {};
            state.showSummary = false;
        });
    },
});

export const {
    toggleShowSummary,
    setApplicantsModal,
    resetStore,
    setProjectsModalVisible,
    toggleShowChat,
} = expertSummarySlice.actions;
export default expertSummarySlice.reducer;
