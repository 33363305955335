import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import {
    // Anchor,
    Box,
    Button,
    CircularProgress,
    Typography,
    Loading,
    // Card,
    // Container,
    // HeadingSection,
    SelectCards,
    TextField,
} from '../ui';

import { MenuBook } from '@mui/icons-material';
import {
    // DirectionsWalkIcon,
    BuildIcon,
    ContactsIcon,
    LaptopChromebookIcon,
    LiveTvIcon,
    NoteIcon,
    // CreateNewFolderIcon,
    SupervisedUserCircleIcon,
} from '../icons';

import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../app/recoil';

const useStyles = makeStyles()((theme) => ({
    root: {
        '& > *': {
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: 'repeat(3, 1fr)',
            },
        },
    },
    backBtn: {
        marginLeft: 'auto',
    },
}));

const links = [
    {
        icon: <LiveTvIcon style={{ fontSize: '2rem' }} />,
        text: 'Live Stream',
        info: 'Engage your students with live content when and as you want.',
        value: 'on-air',
    },
    {
        icon: <ContactsIcon style={{ fontSize: '2rem' }} />,
        // text: 'Hackathons/Long-Form Events',
        text: 'Live Long Form Content / Hackathons',
        info:
            'Schedule, promote and deliver engaging long-form training programs, live labs and/or hackathons. Charge separately or add them to your subscription plans.',
        value: 'hackathons',
    },
    {
        icon: <LaptopChromebookIcon style={{ fontSize: '2rem' }} />,
        text: 'On Demand',
        info:
            'Provide students access to course content on-demand, and organize it as you wish.',
        value: 'on-demand',
    },
    {
        icon: <NoteIcon style={{ fontSize: '2rem' }} />,
        text: 'Assessment/Test',
        info:
            'Build your own assessments, quizzes and tests, or curate them from our library of existing content.',
        value: 'assessment', // isExternal: true,
        // isNewTab: true,
    },
    {
        icon: <BuildIcon style={{ fontSize: '2rem' }} />,
        text: 'Labs and Exercises',
        info:
            'Build your own labs and exercises, or curate them from our library of existing content.',
        value: 'labs',
    },
    {
        icon: <MenuBook style={{ fontSize: '2rem' }} />,
        text: 'Coaching & Tutoring',
        info: 'Offer personalized coaching and tutoring.',
        value: 'coaching',
    },
    {
        icon: <SupervisedUserCircleIcon style={{ fontSize: '2rem' }} />,
        text: 'Custom Consulting',
        info:
            'Define one or more projects for hands-on subject matter experts, content providers and others to work on for you: by the hour, by the project or any combination of means.',
        value: 'consulting',
    },
];

export const ProfileServices = ({
    /* user, */ userDetails,
    isAdmin,
    onContinue,
    onBack,
    saveBtnText,
    hideAvailability = false,
    showAlert = false,
}) => {
    const { classes } = useStyles();
    const setAlert = useSetRecoilState(alertState);
    const [isLoading, setIsLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [availability, setAvailability] = useState('');
    const availabilityOptions = [
        {
            label: 'Fully Available/Can Work Full-Time (40 hrs/week)',
            value: 'Fully Available/Can Work Full-Time (40 hrs/week)',
        },
        {
            label: 'Nearly Full-Time/Highly Available (30 - 40 hrs/week)',
            value: 'Nearly Full-Time/Highly Available (30 - 40 hrs/week)',
        },
        {
            label: 'Available for Significant Hours (20-29 hrs/week)',
            value: 'Available for Significant Hours (20-29 hrs/week)',
        },
        {
            label: 'Consistently Available Part-Time (10-19 hrs/week)',
            value: 'Consistently Available Part-Time (10-19 hrs/week)',
        },
        {
            label: 'Regularly Available/Moderate Hours (5-10 hrs/week)',
            value: 'Regularly Available/Moderate Hours (5-10 hrs/week)',
        },
        {
            label: 'Limited Availability/Somewhat Dependable (1-4 hrs/week)',
            value: 'Limited Availability/Somewhat Dependable (1-4 hrs/week)',
        },
        {
            label: 'Occasionally Available/Infrequent (<1 hr/week)',
            value: 'Occasionally Available/Infrequent (<1 hr/week)',
        },
        {
            label: 'Rarely Available/Minimal Commitment',
            value: 'Rarely Available/Minimal Commitment',
        },
        {
            label: 'Very Limited Availability/Unreliable',
            value: 'Very Limited Availability/Unreliable',
        },
        {
            label: 'Not Available at All/Unavailable',
            value: 'Not Available at All/Unavailable',
        },
    ];

    // const [savedSelectedServices, setSavedSelectedServices] = useRecoilState(
    //     instructorServicesState
    // );

    const [selectedServices, setSelectedServices] = useState(
        /* savedSelectedServices */ []
    );

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(
                    `/api/instructors/profile${
                        isAdmin ? `?e=${userDetails.user_email}` : ''
                    }`
                );

                setAvailability(res.data.availability);
                setSelectedServices(
                    res.data.instructor_details?.services || []
                );
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        })();
    }, [isAdmin, userDetails.user_email]);

    useEffect(() => {
        const save = async () => {
            // if (disp.length !== 0 && roles.length !== 0) {
            if (selectedServices.length === 0) return;

            setSaving(true);

            await axios.put(`/api/instructors/profile`, {
                services: selectedServices,
                availability,
                e: isAdmin ? userDetails.user_email : null,
            });

            setSaving(false);
            // setEdit(false);
        };

        save();
    }, [isAdmin, selectedServices, availability, userDetails.user_email]);

    const handleSelect = (item) => {
        let existingSelectedServices = [...selectedServices];

        if (existingSelectedServices.includes(item.value)) {
            existingSelectedServices = existingSelectedServices.filter(
                (service) => service !== item.value
            );
        } else {
            existingSelectedServices.push(item.value);
        }

        if (existingSelectedServices.length === 0 && showAlert) {
            return setAlert({
                show: true,
                message: 'At least one service needs to be selected',
                severity: severity.WARNING,
            });
        }

        setSelectedServices(existingSelectedServices);

        /* setSelectedServices((prev) => {
            const exists = prev.includes(item.value);
            if (exists) return prev.filter((p) => p !== item.value);
            else return [...prev, item.value];
        }); */
    };

    const isSelected = (item) => selectedServices.includes(item.value);

    if (isLoading) return <Loading />;

    return (
        <Box className={classes.root}>
            <SelectCards
                cards={links}
                handleSelect={handleSelect}
                isSelected={isSelected}
            />
            {!hideAvailability && (
                <>
                    <Typography
                        style={{
                            marginTop: '20px',
                            marginBottom: '10px',
                            fontWeight: 'bold',
                        }}
                        variant="body2"
                    >
                        Which type of service commitment do you prefer?
                    </Typography>
                    <Box>
                        <TextField
                            color="secondary"
                            select
                            name="availability"
                            value={availability || ''}
                            onChange={(e) => {
                                setAvailability(e.target.value);
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            fullWidth
                            required
                            size="small"
                        >
                            <option value="" disabled>
                                Select available work hours
                            </option>
                            {availabilityOptions.map((option) => (
                                <option key={option.label} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </>
            )}

            <Box display="flex" mt={6} gap={2} flexWrap={'wrap'}>
                {onBack && (
                    <Button
                        onClick={onBack}
                        // variant="contained"
                        color="primary"
                        className={classes.backBtn}
                    >
                        Back
                    </Button>
                )}

                {onContinue && (
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        // disabled={isSaving}
                        onClick={onContinue}
                    >
                        {false ? (
                            <CircularProgress size="1.9em" />
                        ) : (
                            <>{saveBtnText || 'Save'}</>
                        )}
                    </Button>
                )}
            </Box>
        </Box>
    );
};
