import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../app/recoil';
import { CloseIcon } from '../../icons';
import { useCurrentUser } from '../../my-account/useCurrentUser';
import { LinearLoader } from '../../util';
import { fetchExpertById } from '../slices/expertSummarySlice';
import { metrics } from './ExpertSummaryDrawer';
import { ConfirmDialog } from '../../ui';

const useStyles = makeStyles()((theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableContainer: {
        width: '100%',
        overflow: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        border: `1px solid ${theme.palette.divider}`,
        '& thead td': {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        '& tbody td': {
            padding: theme.spacing(1),
            fontSize: '0.875rem',
            borderBottom: `1px solid ${theme.palette.divider}`,
            textWrap: 'nowrap',
        },
    },
    tableHeader: {
        textWrap: 'nowrap',
        padding: theme.spacing(1),
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    ratingCount: {
        textAlign: 'center',
        fontSize: '0.875rem',
    },
    radioBtn: {
        padding: 0,
        margin: 0,
        height: 16,
        width: 16,
        accentColor: theme.palette.secondary.main,
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

export default function RatingDialog({
    open,
    setOpen,
    expertUserId,
    updateDatagrid = () => {},
}) {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const [ratings, setRatings] = useState({});
    const [loading, setLoading] = useState(false);
    const { user } = useCurrentUser();
    const setAlert = useSetRecoilState(alertState);
    const [isRatingPresent, setIsRatingPresent] = useState(false);
    const [showChallange, setShowChallange] = useState(false);

    useEffect(() => {
        if (!user?._id) return;
        const baseURL = '/api/instructor-rating/get-rating';
        const searchParams = new URLSearchParams({
            instructorId: expertUserId,
            userId: user._id,
        });
        setLoading(true);
        axios
            .get(`${baseURL}?${searchParams.toString()}`)
            .then(({ data }) => {
                if (Object.keys(data?.rating).length > 0) {
                    setRatings(data.rating);
                    setIsRatingPresent(true);
                } else {
                    setRatings({});
                    setIsRatingPresent(false);
                }
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false);
            });
    }, [expertUserId, user?._id]);

    const tableHeaders = useMemo(() => {
        return Object.keys(metrics).reduce((prev, metric) => {
            prev = prev.concat(metrics[metric].label);
            return prev;
        }, []);
    }, []);

    const tableRows = useMemo(() => {
        return new Array(10).fill(0).map((_, i) => {
            return Object.keys(metrics).map((metric) => {
                const params = metrics[metric]['params'];
                return { param: params[i], standard: metric };
            });
        });
    }, []);

    const handleClose = () => {
        if (loading) return;
        dispatch(fetchExpertById(expertUserId));
        setOpen({
            open: false,
            expertUserId: '',
        });
    };

    const handleRatingChange = (e) => {
        const value = e.target.value;
        const [metric, rating] = value.split(':');
        setRatings((prev) => ({ ...prev, [metric]: Number(rating) }));
    };

    const handleRate = async () => {
        try {
            setLoading(true);
            const payload = {
                instructorId: expertUserId,
                ratedBy: user._id,
                metrics: ratings,
            };
            await axios.post('/api/instructor-rating/add-rating', payload);
            setAlert({
                show: true,
                message: 'Ratings added successfully',
                severity: severity.SUCCESS,
            });
            updateDatagrid();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    const handleRemoveRating = async () => {
        try {
            setLoading(true);
            const baseURL = '/api/instructor-rating/remove-user-rating';
            const searchParams = new URLSearchParams({
                userId: user._id,
                expertUserId,
            });
            await axios.delete(`${baseURL}?${searchParams.toString()}`);
            setAlert({
                show: true,
                message: 'Rating removed successfully',
                severity: severity.SUCCESS,
            });
            updateDatagrid();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="rating-dialog"
            aria-describedby="rating-dialog"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                <span className={classes.title}>
                    <span>Add Rating</span>
                    <IconButton onClick={handleClose} disabled={loading}>
                        <CloseIcon />
                    </IconButton>
                </span>
            </DialogTitle>

            <DialogContent dividers={true}>
                {loading && (
                    <div data-id="linear-loader-container">
                        <LinearLoader />
                    </div>
                )}
                <div
                    data-id="table-container"
                    className={classes.tableContainer}
                >
                    <ConfirmDialog
                        title="Are you sure you want to reset your rating?"
                        onCancel={() => setShowChallange(false)}
                        onConfirm={handleRemoveRating}
                        isLoading={loading}
                        open={showChallange}
                    />
                    <table data-id="rating-table" className={classes.table}>
                        <TableHeader tableHeaders={tableHeaders} />
                        <TableBody
                            tableRows={tableRows}
                            handleRatingChange={handleRatingChange}
                            ratings={ratings}
                        />
                    </table>
                </div>
            </DialogContent>

            <DialogActions>
                {isRatingPresent && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowChallange(true)}
                        disabled={loading}
                    >
                        Reset rating
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRate}
                    disabled={Object.keys(ratings).length === 0 || loading}
                >
                    Rate
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const TableHeader = ({ tableHeaders }) => {
    const { classes } = useStyles();
    return (
        <thead>
            <tr data-id="table-header-row">
                <td className={`${classes.tableHeader}`}>Rating</td>
                {tableHeaders.map((label) => (
                    <td key={label} className={`${classes.tableHeader}`}>
                        {label}
                    </td>
                ))}
            </tr>
        </thead>
    );
};

const TableBody = ({ tableRows, ratings, handleRatingChange }) => {
    return (
        <tbody>
            {tableRows.map((row, i) => {
                return (
                    <TableRow
                        row={row}
                        i={i}
                        ratings={ratings}
                        handleRatingChange={handleRatingChange}
                    />
                );
            })}
        </tbody>
    );
};

const TableRow = ({ row, i, ratings, handleRatingChange }) => {
    const { classes } = useStyles();

    return (
        <tr data-id="table-data-row" key={`row-${i}`}>
            <td data-id="table-rating-count" className={classes.ratingCount}>
                {9 - i + 1}
            </td>
            {row.map(({ param, standard }) => {
                return (
                    <td key={`${param}-${standard}`}>
                        <span className={classes.tableCell} display={'flex'}>
                            <input
                                type="radio"
                                className={classes.radioBtn}
                                name={standard}
                                value={`${standard}:${9 - i + 1}`}
                                checked={ratings[standard] === 9 - i + 1}
                                onChange={handleRatingChange}
                                data-id="radio-button"
                            />
                            {param}
                        </span>
                    </td>
                );
            })}
        </tr>
    );
};
