import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    IconButton,
    CircularProgress,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { createSvgIcon } from '@mui/material/utils';
import { Actions, Loading, Typography } from '../ui';
import {
    DataGridPremium,
    GridToolbarContainer,
    useGridApiContext,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { VariablePieChart } from '../custom-components/charts';
import { async } from 'q';
import { getInstructorsByTagsTG } from './getInstructorsByTagsTG';
import { useIsAdmin } from './useIsAdmin';
// import AuthorModal from '../teachinguide/AuthorModal';
import InstructorMatchingOnboardedModal from './InstructorMatchingOnboardedModal';
import { AccessAlarm } from '@mui/icons-material';
import moment from 'moment';
import NewInstructorMatchingOnboardedModal from './NewInstructorMatchingOnboardedModal';
import { CloseIcon, ShareIcon } from '../icons';

import { AccountBoxIcon } from '../icons';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
    tableContainer: {
        height: 280,
        width: '100%',
    },
    dialogAction: {
        marginRight: 'auto',
        marginLeft: '1rem',
        paddingBottom: '0.5rem',
    },
    linkTitle: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: '#1976d2',
        },
    },
    createdOn: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        gap: '0.25rem',
    },
    title: {
        [theme.breakpoints.down('md')]: {
            fontSize: '1.25rem',
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeBtn: {
        alignSelf: 'flex-start',
    },
}));

const InstructorMatchingModal = (props) => {
    const {
        matchingInstructorModal = {
            isOpen: true,
            tags: [],
            // matchedInstructors2: null,
            title: '',
            createdBy: '',
            createdAt: '',
        },
        setMatchingInstructorModal,
        setAllRequirements,
        fetchData,
        setPipileadsData,
        setShowContactInfoDialog,
        setOpenShareModal,
    } = props;
    const { classes } = useStyles();

    const { starweaverAdmin, isLoading: isAdminLoading } = useIsAdmin();

    const [gridRows, setGridRows] = useState([
        // {
        //     id: 1,
        //     slNo: 1,
        //     name: 'fakeName_a',
        //     fit: 0.5,
        //     match: 1.2,
        //     duration: 20,
        //     courses: 23,
        //     students: 100,
        //     ratings: 2.5,
        //     location: 'Japan',
        // },
        // {
        //     id: 2,
        //     slNo: 2,
        //     name: 'fakeName_b',
        //     fit: 0.5,
        //     match: 1.2,
        //     duration: 20,
        //     courses: 23,
        //     students: 100,
        //     ratings: 2.5,
        //     location: 'India',
        // },
        // {
        //     id: 3,
        //     slNo: 3,
        //     name: 'fakeName_c',
        //     fit: 0.5,
        //     match: 1.2,
        //     duration: 20,
        //     courses: 23,
        //     students: 100,
        //     ratings: 2.5,
        //     location: 'USA',
        // },
        // {
        //     id: 4,
        //     slNo: 4,
        //     name: 'fakeName_c',
        //     fit: 0.5,
        //     match: 1.2,
        //     duration: 20,
        //     courses: 23,
        //     students: 100,
        //     ratings: 2.5,
        //     location: 'USA',
        // },
        // {
        //     id: 5,
        //     slNo: 5,
        //     name: 'fakeName_c',
        //     fit: 0.5,
        //     match: 1.2,
        //     duration: 20,
        //     courses: 23,
        //     students: 100,
        //     ratings: 2.5,
        //     location: 'USA',
        // },
        // {
        //     id: 6,
        //     slNo: 6,
        //     name: 'fakeName_c',
        //     fit: 0.5,
        //     match: 1.2,
        //     duration: 20,
        //     courses: 23,
        //     students: 100,
        //     ratings: 2.5,
        //     location: 'USA',
        // },
    ]);

    const [isLoading, setIsLoading] = useState(false);
    const [isShowMoreClicked, setShowMoreClick] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [selectedRow, setSelectedRow] = useState([]);

    const [isAuthorModalOpen, setIsAuthorModalOpen] = useState(false);
    const [authorIdToShow, setAuthorIdToShow] = useState(null);
    const [fetchingInfo, setFetchingInfo] = useState({
        id: null,
        loading: false,
    });

    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name']
            : [],
    });

    // const [chartData, setChartData] = useState([
    //     {
    //         name: 'Chrome',
    //         y: 61.04,
    //         drilldown: 'Chrome',
    //     },
    //     {
    //         name: 'Safari',
    //         y: 9.47,
    //         drilldown: 'Safari',
    //     },
    //     {
    //         name: 'Edge',
    //         y: 9.32,
    //         drilldown: 'Edge',
    //     },
    //     {
    //         name: 'Firefox',
    //         y: 8.15,
    //         drilldown: 'Firefox',
    //     },
    //     {
    //         name: 'Other',
    //         y: 11.02,
    //         drilldown: null,
    //     },
    // ]);

    // HOOKS
    const memoizedRows = useMemo(() => {
        if (isShowMoreClicked) {
            return gridRows;
        } else {
            return gridRows.slice(0, 3);
        }
    }, [gridRows, isShowMoreClicked]);

    const actions = [
        {
            name: 'Assign',
            onClick: () => {},
        },
    ];

    //DIALOG CLOSE HANDLER FUNCTION
    const handleDialogClose = () => {
        setMatchingInstructorModal((prev) => {
            return {
                tags: [],
                isOpen: false,
                id: null,
            };
        });
    };

    useEffect(() => {
        (async () => {
            try {
                // if (matchingInstructorModal.matchedInstructors2?.length) {
                //     setGridRows(matchingInstructorModal.matchedInstructors2);
                //     return;
                // }

                if (matchingInstructorModal?.tags?.length) {
                    setIsLoading(true);
                    let instructors = await getInstructorsByTagsTG(
                        matchingInstructorModal?.tags,
                        100,
                        searchValue
                    );

                    let matchScores = instructors?.map(({ match }) => match);
                    let minMatchScoreRounded =
                        Math.round(Math.min(...matchScores) * 100) / 100;

                    let maxMatchScore = Math.max(...matchScores);

                    let maxMatchScoreTaken = Math.max(0.833, maxMatchScore);

                    // (MATC-ROUNDDOWN(MINN,3))/(Math.max(0.88,MAXX)-ROUNDDOWN(MINN,3))*58+40

                    let rowsToSet = instructors
                        ?.sort(instructorsCompare)
                        ?.map(
                            (
                                {
                                    instructor_id,
                                    instructor_name,
                                    match,
                                    count,
                                    rating,
                                    linkedin,
                                    course_count,
                                },
                                index
                            ) => ({
                                id: instructor_id,
                                slNo: index + 1,
                                _name: `SME${index + 1}`,
                                name: instructor_name,
                                fit:
                                    (((match - minMatchScoreRounded) /
                                        (maxMatchScoreTaken -
                                            minMatchScoreRounded)) *
                                        58 +
                                        40) /
                                    100,
                                courses: count,
                                ratings: rating,
                                linkedin,
                                course_count,
                            })
                        );

                    setGridRows(rowsToSet);
                    setIsLoading(false);
                    const numOfInstructorsFound = instructors?.length || 0;

                    if (
                        matchingInstructorModal.id &&
                        // &&
                        // matchingInstructorModal.numOfMatchedInstructors !==
                        //     numOfInstructorsFound

                        numOfInstructorsFound != 0
                    ) {
                        const res = await axios.put(
                            `/api/client-requirements`,
                            {
                                id: matchingInstructorModal.id,

                                numOfMatchedInstructors: numOfInstructorsFound,
                                // matchedInstructors2: rowsToSet,
                            }
                        );

                        if (setAllRequirements)
                            setAllRequirements((prev) => {
                                const requirementsCopy = [...prev];
                                const requirementToEdit = requirementsCopy.find(
                                    ({ _id }) =>
                                        _id == matchingInstructorModal.id
                                );

                                requirementToEdit.numOfMatchedInstructors = numOfInstructorsFound;
                                // requirementToEdit.matchedInstructors2 = rowsToSet;
                                // const requirementOthers = requirementsCopy.filter(
                                //     ({ _id }) =>
                                //         _id != matchingInstructorModal.id
                                // );

                                // const ret = [
                                //     requirementToEdit,
                                //     ...requirementOthers,
                                // ];

                                return requirementsCopy;
                            });
                    }
                    // console.log(instructors);
                }
            } catch (error) {
                console.log(error);
                // if (matchingInstructorModal.matchedInstructors2?.length)
                //     setGridRows(matchingInstructorModal.matchedInstructors2);
                // else
                // handleDialogClose();
            } finally {
                setIsLoading(false);
            }
        })();
    }, [searchValue]);

    const saveSocialSearchId = async (authorId, socialSearchId) => {
        try {
            const url = 'https://teachinguide.azure-api.net/save-social-id';
            await axios.post(url, { authorId, socialSearchId });
        } catch (e) {
            console.log(e);
        }
    };

    const searchPipileads = async (linkedinURL, authorId, authorName) => {
        try {
            const pipileadsApiKey = process.env.REACT_APP_PIPILEADS_API_KEY;
            const endPoint = `https://pipileads.com/app/api/social-search/?key=${pipileadsApiKey}&option=search&query=${linkedinURL}&email_preference=both`;
            const { data } = await axios.get(endPoint);
            const { social_search_id } = data;
            await saveSocialSearchId(authorId, social_search_id);
            return data;
        } catch (e) {
            console.log(e);
        }
    };

    const retrievePipileads = async (socialSearchId, authorName) => {
        try {
            const pipileadsApiKey = process.env.REACT_APP_PIPILEADS_API_KEY;
            const endPoint = `https://pipileads.com/app/api/social-search/?key=${pipileadsApiKey}&option=retrieve&query=${socialSearchId}`;
            const { data } = await axios.get(endPoint);
            return data;
        } catch (e) {
            console.log(e);
        }
    };

    const handleShowContactInfoDialog = () => {
        setMatchingInstructorModal((prev) => ({ ...prev, isOpen: false }));
        setShowContactInfoDialog(true);
    };

    const getContactDetails = async (row) => {
        try {
            const authorId = row.id;
            const linkedin = row.linkedin;
            const authorName = row.name;
            if (!linkedin) return;
            setFetchingInfo({ id: row.id, loading: true });
            const url = `https://teachinguide.azure-api.net/get-social-search-id/${authorId}`;
            const res = await axios.get(url);
            let data = { authorName, linkedinURL: linkedin };
            if (res.status === 204) {
                // pipileads search api
                const info = await searchPipileads(
                    linkedin,
                    authorId,
                    authorName
                );
                data = { ...data, ...info };
            } else {
                // pipileads retrieve api
                const info = await retrievePipileads(res.data, authorName);
                data = { ...data, ...info };
            }
            // show contact info modal
            setPipileadsData(data);
            handleShowContactInfoDialog();
        } catch (e) {
            console.log(e);
        } finally {
            setFetchingInfo({ id: null, loading: false });
        }
    };

    const gridCols = [
        {
            field: 'slNo',
            headerName: '#',
            width: 50,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: false,
            valueFormatter: (value, row) => {
                return starweaverAdmin ? value : row._name;
            },

            display: 'flex',
            renderCell: (cellValues) => {
                const { row } = cellValues;
                const { _name, id } = row;

                return (
                    <Typography
                        style={{ fontSize: '0.875rem' }}
                        className={classes.linkTitle}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // setIsAuthorModalOpen(true);
                            // setAuthorIdToShow(id);
                            window.open(
                                '/sso-analytics?successPath=/instructor/' + id,
                                '_blank'
                            );
                        }}
                    >
                        {starweaverAdmin ? cellValues.value : _name}
                    </Typography>
                );
            },
        },
        {
            field: 'fit',
            headerName: 'Fit',
            width: 100,
            editable: false,
            valueFormatter: (value) => {
                return (value * 100)?.toFixed(2) + '%';
            },
        },
        {
            field: 'course_count',
            headerName: '# Courses',
            width: 125,
            editable: false,
            valueFormatter: (value) => {
                return value;
            },
        },
        // {
        //     field: 'match',
        //     headerName: 'KW Matches',
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: 'duration',
        //     headerName: '#hrs courses',
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: 'courses',
        //     headerName: '# Matched',
        //     width: 150,
        //     editable: false,
        // },
        // {
        //     field: 'students',
        //     headerName: '# Students',
        //     width: 200,
        //     editable: false,
        // },
        {
            field: 'ratings',
            headerName: 'Rating',
            width: 100,
            editable: false,
            valueFormatter: (value) => {
                return value?.toFixed(2);
            },
        },
        {
            field: 'linkedin',
            headerName: 'LinkedIn',
            width: 300,
            editable: false,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <>
                        {row.linkedin === null ? (
                            <span
                                style={{
                                    color: '#94a3b8',
                                    paddingLeft: '1rem',
                                }}
                            >
                                &#8212;
                            </span>
                        ) : (
                            <a
                                style={{ color: 'blue' }}
                                href={row.linkedin}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {row.linkedin}
                            </a>
                        )}
                    </>
                );
            },
        },
        // {
        //     field: 'location',
        //     headerName: 'Location',
        //     width: 150,
        //     editable: false,
        // },
        {
            field: 'contact',
            headerName: 'Contact',
            width: 150,
            editable: false,
            filterable: false,
            disableExport: true,
            display: 'flex',
            renderCell: ({ row }) => {
                return (
                    <Box pl={2}>
                        <IconButton
                            onClick={() => getContactDetails(row)}
                            disabled={row?.linkedin === null}
                            size="small"
                        >
                            {fetchingInfo.id === row.id &&
                            fetchingInfo.loading ? (
                                <CircularProgress color="secondary" size={20} />
                            ) : (
                                <AccountBoxIcon />
                            )}
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    const handleShowMore = () => {
        setShowMoreClick((prev) => !prev);
    };

    const addSearchParam = (searchValue = '') => {
        setSearchValue(searchValue);
    };

    return (
        <Box>
            <Dialog
                onClose={handleDialogClose}
                aria-labelledby="matching-instructor-data-modal"
                open={matchingInstructorModal.isOpen}
                maxWidth="lg"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle>
                    <Box className={classes.dialogTitle}>
                        <Box>
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="h5"
                                    className={classes.title}
                                >
                                    {matchingInstructorModal.title}
                                </Typography>

                                <IconButton
                                    // onClick={handleLinkClick}
                                    onClick={() => {
                                        setOpenShareModal(true);
                                        setMatchingInstructorModal((prev) => ({
                                            ...prev,
                                            isOpen: false,
                                        }));
                                    }}
                                    size="small"
                                    sx={{ ml: 1 }}
                                    color="primary"
                                >
                                    <ShareIcon color="primary" />
                                </IconButton>
                            </Box>
                            <Typography
                                variant="body1"
                                className={classes.createdOn}
                            >
                                <AccessAlarm fontSize="small" /> Created{' '}
                                {`${
                                    matchingInstructorModal?.createdBy?.length >
                                    0
                                        ? `by ${matchingInstructorModal.createdBy}`
                                        : ''
                                }`}{' '}
                                on{' '}
                                {moment(
                                    matchingInstructorModal?.createdAt
                                ).format('DD-MMM-YYYY')}
                            </Typography>
                        </Box>
                        <IconButton
                            size="small"
                            onClick={handleDialogClose}
                            className={classes.closeBtn}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <NewInstructorMatchingOnboardedModal
                        numOfMatchedOnboardedInstructors={
                            matchingInstructorModal.numOfMatchedOnboardedInstructors
                        }
                        requirementId={matchingInstructorModal.id}
                        addSearchParam={addSearchParam}
                        fetchData={fetchData}
                        title={matchingInstructorModal.title}
                        setAllRequirements={setAllRequirements}
                    />

                    <Box py={2}>
                        {/* {chartData.length > 0 && (
                        <VariablePieChart
                            enableLegend={true}
                            title={'Matched Instructor'}
                            seriesName={'test series name'}
                            chartData={chartData}
                            pointFormat={'<b>Revenue: {point.y}%</b>'}
                        />
                    )} */}

                        <Box display={'flex'} marginBottom={2}>
                            <Typography component="h6" variant="h6">
                                Starweaver Analytics
                                {matchingInstructorModal.numOfMatchedInstructors
                                    ? ` (${matchingInstructorModal.numOfMatchedInstructors}) `
                                    : null}
                            </Typography>

                            {/* <Box marginLeft={'auto'}>
                                <Actions
                                    actions={actions}
                                    disabled={selectedRow.length === 0 || true}
                                />
                            </Box> */}
                        </Box>
                        {isLoading || isAdminLoading ? (
                            <Loading height="300px" />
                        ) : (
                            <Box className={classes.tableContainer}>
                                <DataGridPremium
                                    // autoHeight
                                    rows={memoizedRows}
                                    columns={gridCols}
                                    pageSizeOptions={[]}
                                    hideFooter={true}
                                    hideFooterSelectedRowCount={true}
                                    // disableColumnMenu={true}
                                    disableColumnFilter={true}
                                    disableColumnSelector={true}
                                    rowSelectionModel={selectedRow} //added line
                                    onRowSelectionModelChange={(param) =>
                                        setSelectedRow(
                                            param /* .selectionModel */
                                        )
                                    }
                                    checkboxSelection
                                    pinnedColumns={pinnedColumns}
                                    onPinnedColumnsChange={(
                                        updatedPinnedColumns
                                    ) => {
                                        if (isLargeScreen) {
                                            setPinnedColumns(
                                                updatedPinnedColumns
                                            );
                                        } else {
                                            alert(
                                                'Pinning is not available on small screens.'
                                            );
                                        }
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            title:
                                                matchingInstructorModal.title,
                                        },
                                    }}
                                />
                            </Box>
                        )}
                        {/* <DialogActions>
                            <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={handleShowMore}
                                >
                                    {isShowMoreClicked
                                        ? 'SHOW LESS'
                                        : 'SHOW MORE'}
                                </Button>
                            </Box>
                        </DialogActions> */}
                    </Box>
                </DialogContent>
                <DialogActions />
            </Dialog>
            {/* <AuthorModal
                isOpen={isAuthorModalOpen}
                setIsOpen={setIsAuthorModalOpen}
                authorId={authorIdToShow}
            /> */}
        </Box>
    );
};

export default InstructorMatchingModal;

function CustomToolbar({ title }) {
    const apiRef = useGridApiContext();

    const handleExport = (options) => {
        apiRef.current.exportDataAsCsv(options);
    };

    const ExportIcon = createSvgIcon(
        <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
        'SaveAlt'
    );

    const buttonBaseProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon />,
    };

    const getRows = ({ apiRef }) => {
        if (apiRef?.current?.state?.rowSelection?.length > 0) {
            return apiRef.current.state.rowSelection;
        }

        const rows = apiRef.current?.state?.rows?.dataRowIds ?? [];
        return rows.slice(0, 5);
    };

    return (
        <GridToolbarContainer>
            <Button
                {...buttonBaseProps}
                onClick={() =>
                    handleExport({
                        getRowsToExport: getRows,
                        fileName: `${title}-starweaverAnalytics`,
                    })
                }
                disabled={
                    apiRef?.current?.state?.rows?.dataRowIds?.length === 0
                }
            >
                Export
            </Button>
        </GridToolbarContainer>
    );
}

function instructorsCompare(a, b) {
    if (a.match < b.match) return 1;

    if (a.match > b.match) return -1;

    return 0;
}
