import {
    Box,
    FormControl,
    MenuItem,
    useMediaQuery,
    useTheme,
    Select,
    List,
    ListItem,
    Typography,
    IconButton,
    Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DeleteForever, Edit } from '@mui/icons-material';
import { useMemo } from 'react';

const useStyles = makeStyles()((theme) => ({
    thirdStep: {
        '& label': {
            fontSize: '1.1rem',
        },
    },
    tasks: {
        marginTop: theme.spacing(1),
        height: '280px',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.grey['100'],
    },
    taskItem: {
        fontSize: '1rem',
        padding: theme.spacing(1.2, 1),
        boxShadow: theme.shadows['1'],
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
}));

function ThirdStep({
    taskCategories,
    setIsAddTask,
    selectedCategoryIdx,
    setSelectedCategryIdx,
    onDeleteTask,
    handleEditTask,
    isSaving,
    setIsTaskEdit,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const tasks = useMemo(() => {
        return taskCategories[selectedCategoryIdx].tasks;
    }, [selectedCategoryIdx, taskCategories]);

    const onCategoryChange = (e) => {
        const { value } = e.target;
        setSelectedCategryIdx(value);
    };

    return (
        <Box className={classes.thirdStep}>
            <FormControl size={isXSDevice ? 'small' : 'medium'} fullWidth>
                <label htmlFor="selectedCategory">Selected category</label>
                <Select
                    labelId="selectedCategory"
                    id="selectedCategory"
                    value={selectedCategoryIdx}
                    onChange={onCategoryChange}
                    fullWidth
                    variant="outlined"
                    color="secondary"
                >
                    {taskCategories.map((category, idx) => (
                        <MenuItem key={category + '-' + idx} value={idx}>
                            {category.categoryName}
                        </MenuItem>
                    ))}
                </Select>
                <Box display="flex" justifyContent={'flex-end'} mt={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size={isXSDevice ? 'small' : 'medium'}
                        onClick={() => {
                            setIsAddTask(true);
                            setIsTaskEdit(false);
                        }}
                        disabled={isSaving}
                    >
                        ADD TASK
                    </Button>
                </Box>
            </FormControl>

            <List className={classes.tasks}>
                {tasks.map((task, idx) => (
                    <ListItem
                        key={task.name + '-' + idx}
                        className={classes.taskItem}
                    >
                        <Typography variant="body1">{task.name}</Typography>

                        <Box ml={'auto'}>
                            <IconButton
                                size="small"
                                onClick={() => onDeleteTask(idx)}
                            >
                                <DeleteForever
                                    fontSize={isXSDevice ? 'small' : 'medium'}
                                />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => handleEditTask(idx)}
                            >
                                <Edit
                                    fontSize={isXSDevice ? 'small' : 'medium'}
                                />
                            </IconButton>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default ThirdStep;
