import React, { useMemo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
    Avatar,
    Box,
    IconButton,
    InputBase,
    Typography,
    Button,
    TextField,
    Grid,
    Menu,
    MenuItem,
} from '../ui';
import { EditIcon, MoreHorizonIcon, SendIcon } from '../icons';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { debounce, findInReverse, generateRandomMongoId } from '../util';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { CloseIcon } from '../icons';
import { AvatarBadge } from '../ui/CustomUI';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AttachFileIcon from '@mui/icons-material/AttachFile'; // Add this import
import SelectedFiles from './SelectedFiles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Attachments from './Attachments';
import useFileValidation from './useFileValidation';
import useFileUpload from './useFileUpload';
import axios from 'axios';
import ChatLinkMetaData from './ChatLinkMetaData';
import ChatLinkMetaDataPreview from './ChatLinkMetaDataPreview';
import { ArrowCircleDown, ArrowCircleUp } from '@mui/icons-material';
import GroupChatManageModal from './GroupChatManageModal';
// import { EmojiPicker } from 'emoji-picker-react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShowChat } from '../instructor/slices/expertSummarySlice';

const chatMessageWidth = 350;

const useStyles = makeStyles()((theme) => ({
    root: {
        /* height: '100%' */
        // height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
    },

    dockedContainer: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        // height: '40vh',
        width: chatMessageWidth,
        // zIndex: 20,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px 8px 0 0',
        boxShadow: theme.shadows[5],
    },
    headingContainer: {
        // padding: '4px',
        padding: theme.spacing(1, 0.5, 1, 1.5),
        // display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        // backgroundColor: theme.palette.primary.light,
        backgroundColor: '#fff',
        // borderRadius: '0 8px 0 0',
        borderRadius: '8px 8px 0 0',
        color: '#fff',
        cursor: 'pointer',
        borderBottom: '1px solid #d1d5db',
    },
    headingMeta: {
        color: theme.palette.secondary.main,
    },
    messages: {
        flexDirection: 'column',
        padding: '20px',
        overflow: 'auto',
        backgroundColor: '#fff',
        // borderRadius: '0 0 8px 8px',
        // boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        flex: 1,
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4bfbf',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px #d6d6d6',
            borderRadius: '10px',
        },
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    message: {
        display: 'flex',
        maxWidth: '90%',
        marginBottom: 20,
    },
    // messageAvatar: {
    //     marginBottom: '-10px',
    //     marginRight: '10px',
    // },
    messageData: {
        position: 'relative',
        padding: '10px',
        borderRadius: '10px',
        backgroundColor: '#e9ecef',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
    messageBody: {
        whiteSpace: 'pre-line', // Ensure new lines in message body are displayed
    },
    messageInputContainer: {
        position: 'relative',
        // padding: '10px',
        padding: '12px',
        display: 'flex',
        alignItems: 'start',
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '0 0 8px 0',
        // boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        borderTop: '1px solid #d1d5db',
    },
    messageInput: {
        // padding: '10px',
        // padding: '6px 8px',
        // borderRadius: '20px',
        borderRadius: theme.spacing(0.5),
        flex: 1,
        // marginRight: '10px',
        // boxShadow: theme.shadows[11],
        // backgroundColor: '#f8f9fa',
        // backgroundColor: '#edf3f8',
        // border: '1px solid #dee2e6',
    },
    sendBtn: {
        // padding: '10px',
        padding: '8px',
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    downButton: {
        borderRadius: '50%',
        minHeight: '40px',
        minWidth: '40px',
        padding: '0px',
        backgroundColor: '#ea7112',
    },
    lastRead: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 8,
        marginRight: 8,
    },
    line: {
        flex: 1,
        height: '0.1rem',
        backgroundColor: '#6c757d',
        margin: '0 10px',
    },
    newDay: {
        marginTop: 10,
        marginBottom: 10,
    },
    unreadCount: {
        backgroundColor: '#f44336', // Red background for unread count
        color: 'white',
        borderRadius: '50%',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        display: 'inline-block',
        minWidth: '20px',
        textAlign: 'center',
    },
}));

export const ChatMessages = ({
    chats,
    // selectedChatIndex,
    chatId,
    openedChatIndex,
    closeChat,
    user,
    userByIdUpdated,

    sendMessage,
    seenMessage,
    docked,
    minimized,
    toggleChatMinimized,
    numOfUnReadMessagesByChat,
    // messageInput,
    // setMessageInput,
    height = '75vh',
    setShowChatUsersOnMobile,
}) =>
    // { messagesEl, messageInputEl }
    {
        // console.log(chatId);
        const { classes, cx } = useStyles();

        const messageInputEl = useRef(null);

        const messagesEl = useRef(null);
        // console.log(messagesEl.current?.scrollHeight);

        const prevScrollHeight = useRef(null);
        // console.log(prevScrollHeight.current);

        const [messageInput, setMessageInput] = useState('');
        const [isExpanded, setIsExpanded] = useState(true);
        const [isTextInputExpanded, setIsTextInputExpanded] = useState(false);

        const [loading, setLoading] = useState(false);
        const [url, setUrl] = useState(null);
        const [linkMetaData, setLinkMetaData] = useState(null);

        const attachmentInputRef = useRef(null);
        const [fileContainers, setFileContainers] = useState([]);

        const chatIndex = chats.findIndex((chat) => chat._id == chatId);

        const chat = chats[chatIndex];

        const [showDownButton, setShowDownButton] = useState(false);

        const [newMessage, setNewMessage] = useState(false);

        const [showGroupDialog, setGroupDialog] = useState(false);
        const [groupData, setGroupData] = useState([]);
        const expertSummary = useSelector((state) => state.expertSummary);
        const dispatch = useDispatch();
        // const [showEmojiPicker, setShowEmojiPicker] = useState(false);

        // const [anchorEl, setAnchorEl] = useState(null);
        // const [hoveredMessageId, setHoveredMessageId] = useState(null);

        // console.log(chat);

        useEffect(() => {
            const mappedUsers = chat.users.map((user) => {
                const updatedUser = userByIdUpdated[user.user];
                return {
                    _id: updatedUser?._id,
                    socket_ids: updatedUser?.socket_ids,
                    profilePicUrlWP: updatedUser?.profilePicUrlWP,
                    name: updatedUser?.name,
                    color: updatedUser?.color,
                    lastMessageSeenAt: user.lastMessageSeenAt
                        ? user.lastMessageSeenAt.$date
                        : null,
                };
            });
            setGroupData({
                groupName: chat.groupName,
                userIds: mappedUsers,
                groupThumbnail: chat.groupThumbnail,
            });
        }, [chat, userByIdUpdated]);

        const isSendDisabled = useMemo(() => {
            return (
                messageInput.trim() === '' ||
                fileContainers.some((fc) => !fc.isUploaded)
            );
        }, [messageInput, fileContainers]);

        const { validateFiles } = useFileValidation();

        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
        };
        const toggleTextInputExpand = () => {
            setIsTextInputExpanded(!isTextInputExpanded);
        };

        const myLastMessageSeen = useMemo(() => {
            return findInReverse(chat.messages, (message) => {
                if (message.from.toString() !== user._id.toString()) {
                    return false;
                }

                // Check if all other users have seen this message
                const seenByAll = chat.users.every((userInChat) => {
                    if (userInChat.user.toString() === user._id.toString()) {
                        return true; // Skip the current user
                    }
                    return (
                        userInChat.lastMessageSeenAt &&
                        new Date(userInChat.lastMessageSeenAt) >=
                            new Date(message.sentAt)
                    );
                });

                return seenByAll;
            });
        }, [chat, user._id]);

        const [lastMessageRead, setLastMessageRead] = useState(null);

        useEffect(() => {
            if (user) {
                const currentUserInChat = chat.users.find(
                    (userInChat) =>
                        userInChat.user.toString() === user._id.toString()
                );

                if (!currentUserInChat) {
                    // Current user not found in the chat users list
                    return;
                }

                const foundMessage = findInReverse(chat.messages, (message) => {
                    // if (message.from.toString() === user._id.toString()) {
                    //     // If the message is from the current user, return it immediately
                    //     return true;
                    // }

                    return (
                        message.from.toString() !== user._id.toString() &&
                        (!message.sentAt || // For old messages without sentAt
                            (currentUserInChat.lastMessageSeenAt &&
                                new Date(currentUserInChat.lastMessageSeenAt) >=
                                    new Date(message.sentAt)))
                    );
                });

                if (foundMessage) {
                    setLastMessageRead(foundMessage);
                }
            }
        }, [chat._id, user._id]);

        // const numOfUnReadMessages = useMemo(() => {
        //     if (!user) return 0;

        //     const currentUserInChat = chat.users.find(
        //         (userInChat) =>
        //             userInChat.user.toString() === user._id.toString()
        //     );
        //     if (!currentUserInChat) {
        //         // Current user not found in the chat users list
        //         return 0;
        //     }
        //     let count = 0;
        //     for (let i = chat.messages.length - 1; i >= 0; i--) {
        //         const message = chat.messages[i];
        //         if (message.from.toString() === user._id.toString()) {
        //             // count++;
        //             // continue;
        //             break;
        //         }
        //         if (
        //             !message.sentAt ||
        //             (currentUserInChat.lastMessageSeenAt &&
        //                 new Date(currentUserInChat.lastMessageSeenAt) >=
        //                     new Date(message.sentAt))
        //         ) {
        //             break;
        //         } else {
        //             count++;
        //         }
        //     }

        //     return count;
        // }, [chat, user]);

        // console.log(lastMessageRead);

        // console.log(myLastMessageSeen);

        useEffect(() => {
            scrollDown();
        }, [chatId]);

        useEffect(() => {
            if (messagesEl.current) {
                const {
                    scrollTop,
                    scrollHeight,
                    clientHeight,
                } = messagesEl.current;

                // Check if there is no scroll (content height less than or equal to container height)
                if (scrollHeight <= clientHeight) {
                    debouncedSeenMessage(chatId);
                } else if (
                    scrollTop + clientHeight + 5 <
                    prevScrollHeight.current
                ) {
                    setNewMessage(true);
                } else {
                    scrollDown();
                }
                prevScrollHeight.current = scrollHeight;
            }
        }, [chat?.messages?.length, chatId]);

        useEffect(() => {
            const handleScroll = () => {
                // console.log('Scrolled');

                if (messagesEl.current) {
                    const {
                        scrollTop,
                        scrollHeight,
                        clientHeight,
                    } = messagesEl.current;

                    // console.log(
                    //     scrollTop,
                    //     clientHeight,
                    //     scrollTop + clientHeight,
                    //     scrollHeight
                    // );

                    if (scrollTop + clientHeight + 5 < scrollHeight) {
                        setShowDownButton(true);
                    } else {
                        // console.log('Scrolled to bottom');
                        setShowDownButton(false);
                        setNewMessage(false);
                        // console.log(chatId);
                        // seenMessage(chatId);
                        debouncedSeenMessage(chatId);
                    }
                }
            };

            if (minimized) {
                return;
            } else {
                scrollDown();
            }

            const currentMessagesEl = messagesEl.current;
            if (currentMessagesEl) {
                currentMessagesEl.addEventListener('scroll', handleScroll);
                prevScrollHeight.current = currentMessagesEl.scrollHeight;
            }

            return () => {
                if (currentMessagesEl) {
                    currentMessagesEl.removeEventListener(
                        'scroll',
                        handleScroll
                    );
                }
            };
        }, [chatId, minimized]);

        useFileUpload(fileContainers, setFileContainers, chatId);

        const right = useMemo(() => {
            const chatMessageoffset = 30;
            const chatUsersRightOffset = 30; // value taken from chatUser.js
            const chatUsersWidth = 300; // value taken from chatUser.js
            let value =
                chatUsersRightOffset + chatMessageoffset + chatUsersWidth;
            if (openedChatIndex > 0) {
                value +=
                    openedChatIndex * (chatMessageWidth + chatMessageoffset);
            }
            return value;
        }, [openedChatIndex]);

        const debouncedSeenMessage = debounce((chatId) => {
            // console.log(chatId);
            seenMessage(chatId);
        }, 500);

        const onMessageInputChange = (e) => {
            const inputContext = e.target.value;

            const urlPattern = /(https?:\/\/[^\s]+)/g;

            const links = inputContext.match(urlPattern);
            if (links && links.length > 0) {
                debouncedUrlScrap(links[0]);
            } else {
                setLinkMetaData(null);
            }

            setMessageInput(e.target.value);
        };

        const debouncedUrlScrap = debounce((url) => {
            setUrl(url);
        }, 2000);

        useEffect(() => {
            if (url) {
                console.log(url);
                fetchMetadata(url);
            }
        }, [url]);

        const fetchMetadata = async (url) => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `/api/seo/meta-data-scrap?url=${url}`
                );
                setLinkMetaData(response.data);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
            setLoading(false);
        };

        const sendMessageHandler = () => {
            if (isSendDisabled) return;
            try {
                const attachedFileNames = fileContainers.map(
                    (fc) => fc.file.name
                );
                console.log(chatId);
                sendMessage(
                    messageInput,
                    attachedFileNames,
                    linkMetaData,
                    chatId
                );
                setTimeout(() => {
                    setMessageInput('');
                    setFileContainers([]);
                    setLinkMetaData(null);
                    messageInputEl.current.focus();
                }, 0);
            } catch (error) {
                console.log(error);
            }
        };

        const keyPressHandler = (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                sendMessageHandler();
            }
        };

        const scrollDown = () => {
            setTimeout(() => {
                if (messagesEl.current) {
                    messagesEl.current.scrollTop =
                        messagesEl?.current?.scrollHeight;
                }
            }, 0);
        };

        const storeFileInState = (files) => {
            const fileContainers = Array.from(files).map((file) => ({
                id: generateRandomMongoId(),
                file,
                isUploaded: false,
                isUploading: false,
                percentageUploaded: 0,
            }));
            setFileContainers((prev) => [...prev, ...fileContainers]);

            if (attachmentInputRef.current) {
                attachmentInputRef.current.value = null;
            }
        };

        const handleSelectClick = (e) => {
            e.stopPropagation();
            if (attachmentInputRef.current) {
                attachmentInputRef.current.click();
            }
        };

        const handleFileSelected = (e) => {
            const files = e.target.files;
            if (!files || files.length === 0) return;
            if (!validateFiles(files)) return;
            storeFileInState(files);
        };

        const handleRemoveSelectedFile = (event, id) => {
            event.stopPropagation();
            if (!fileContainers) return;
            setFileContainers((prev) => {
                return prev.filter((file) => file.id !== id);
            });
        };

        const [isDragging, setIsDragging] = useState(false);

        const handleDragOver = (e) => {
            e.preventDefault();
            setIsDragging(true);
        };

        const handleDrop = (e) => {
            e.preventDefault();
            setIsDragging(false);
            const files = e.dataTransfer.files;
            if (!files || files.length === 0) return;
            if (!validateFiles(files)) return;
            storeFileInState(files);
        };

        const handleDragLeave = () => {
            setIsDragging(false);
        };

        const numUnreadMessages = numOfUnReadMessagesByChat[chatId] || 0;

        const otherUserId = chat.users
            .map(({ user }) => user)
            .filter((id) => id != user._id)[0];

        const formatDateTime = (timestamp) => {
            const messageDateTime = moment(timestamp);
            const currentDateTime = moment();

            const timeDifference = currentDateTime.diff(
                messageDateTime,
                'hours'
            );

            if (timeDifference > 24) {
                return messageDateTime.format('MMM D');
            } else {
                return messageDateTime.format('h:mm A');
            }
        };

        // const handleMenuClick = (event) => {
        //     setAnchorEl(event.currentTarget);
        // };

        // const handleMenuClose = () => {
        //     setAnchorEl(null);
        // };

        // const handleMouseEnter = (messageId) => {
        //     setHoveredMessageId(messageId);
        // };

        // const handleMouseLeave = () => {
        //     setHoveredMessageId(null);
        // };

        // const toggleEmojiPicker = () => {
        //     setShowEmojiPicker((prev) => !prev);
        // };

        // const addEmoji = (emojiData) => {
        //     onMessageInputChange({
        //         target: { value: messageInput + emojiData.emoji },
        //     });
        //     setShowEmojiPicker(false);
        // };

        // const handleMarkPinMessage = (messageId) => {
        //     console.log(messageId);
        // };

        // const handleDeleteMessage = (messageId) => {
        //     console.log(messageId);
        // };

        return (
            <Box
                height={height}
                className={cx(classes.root, {
                    [classes.dockedContainer]: docked,
                })}
                style={{
                    ...(minimized
                        ? { height: 'fit-content' }
                        : isExpanded
                        ? ''
                        : { height: '400px' }),
                    right,
                    ...(docked
                        ? {
                              width: isExpanded ? chatMessageWidth : '280px',
                          }
                        : {}),
                }}
                sx={{ zIndex: expertSummary.showChat ? 99999 : 20 }}
            >
                {chats.length !== 0 && chatIndex !== null && (
                    <>
                        <Box
                            className={classes.headingContainer}
                            style={{ ...(minimized ? { margin: 0 } : {}) }}
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleChatMinimized(chatId);
                            }}
                        >
                            <Box display="flex" alignItems={'center'}>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (setShowChatUsersOnMobile)
                                            setShowChatUsersOnMobile(true);
                                    }}
                                    aria-label="back"
                                    size="small"
                                    sx={{
                                        display: {
                                            xs: 'inline-flex',
                                            sm: 'none',
                                        },
                                    }} // Show only on small screens
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                {chat.groupName ? (
                                    <>
                                        <Avatar
                                            style={{
                                                backgroundColor:
                                                    userByIdUpdated[otherUserId]
                                                        ?.color,
                                                marginRight: 5,
                                            }}
                                            src={chat.groupThumbnail}
                                            sx={{ width: 32, height: 32 }}
                                            avatarTxt={chat.groupName}
                                        />
                                        <Typography
                                            style={{
                                                color: '#000',
                                                marginRight: 'auto',
                                                fontSize: '16px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '200px',
                                            }}
                                            variant="h6"
                                            component="h1"
                                        >
                                            {chat.groupName}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <AvatarBadge
                                            style={{
                                                backgroundColor:
                                                    userByIdUpdated[otherUserId]
                                                        ?.color,
                                                marginRight: 5,
                                            }}
                                            src={
                                                userByIdUpdated[otherUserId]
                                                    ?.profilePic ||
                                                userByIdUpdated[otherUserId]
                                                    ?.user_url ||
                                                userByIdUpdated[otherUserId]
                                                    ?.profilePicUrlWP
                                            }
                                            sx={{ width: 32, height: 32 }}
                                            avatarTxt={userByIdUpdated[
                                                otherUserId
                                            ]?.name
                                                ?.charAt(0)
                                                ?.toUpperCase()}
                                            isOnline={
                                                userByIdUpdated[otherUserId]
                                                    ?.socket_ids?.length
                                            }
                                        />
                                        <Typography
                                            style={{
                                                color: '#000',
                                                marginRight: 'auto',
                                                fontSize: '16px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '200px',
                                            }}
                                            variant="h6"
                                            component="h1"
                                        >
                                            {userByIdUpdated[otherUserId]?.name}
                                        </Typography>
                                    </>
                                )}

                                {numUnreadMessages > 0 && (
                                    <Box ml={1}>
                                        <Typography
                                            variant="caption"
                                            className={classes.unreadCount}
                                        >
                                            {numUnreadMessages}
                                        </Typography>
                                    </Box>
                                )}
                                {chat.groupName && user.isAdmin && (
                                    <IconButton
                                        className={classes.iconButton}
                                        aria-label="manage group chat"
                                        size="small"
                                        onClick={() => {
                                            setGroupDialog(true);
                                        }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                                {/* {docked && !minimized && ( */}
                                {docked && (
                                    <>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleExpand();
                                            }}
                                            size="small"
                                        >
                                            {isExpanded ? (
                                                <ArrowCircleDown />
                                            ) : (
                                                <ArrowCircleUp />
                                            )}
                                        </IconButton>
                                        <IconButton
                                            // className={classes.iconButton}
                                            aria-label="search"
                                            size="small"
                                            style={
                                                {
                                                    // color: '#fafafa',
                                                    // marginLeft: 'auto',
                                                }
                                            }
                                        >
                                            {/* <HorizontalRuleIcon /> */}
                                            {!minimized ? (
                                                <ExpandMoreIcon />
                                            ) : (
                                                <ExpandLessIcon />
                                            )}
                                        </IconButton>
                                    </>
                                )}
                                {docked && (
                                    <IconButton
                                        className={classes.iconButton}
                                        aria-label="add user"
                                        // size="medium"
                                        size="small"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            closeChat(chatId);
                                            dispatch(toggleShowChat(false));
                                        }}
                                        // style={{
                                        //     color: '#fafafa',
                                        // }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>

                        {!minimized && (
                            <>
                                <Box
                                    className={classes.messages}
                                    ref={messagesEl}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onDragLeave={handleDragLeave}
                                    style={{
                                        border: isDragging
                                            ? '2px dashed #000'
                                            : '2px dashed #fff',
                                        ...(isTextInputExpanded
                                            ? { display: 'none' }
                                            : { display: 'flex' }),
                                    }}
                                >
                                    {chat.messages.map(
                                        (
                                            {
                                                from,
                                                body,
                                                sentAt,
                                                _id,
                                                attachedFileNames,
                                                linkMetaData,
                                            },
                                            index
                                        ) => {
                                            const messageDate = moment(
                                                sentAt
                                            ).format('YYYY-MM-DD');
                                            const previousMessageDate =
                                                index > 0
                                                    ? moment(
                                                          chat.messages[
                                                              index - 1
                                                          ].sentAt
                                                      ).format('YYYY-MM-DD')
                                                    : null;

                                            const isNewDay =
                                                messageDate !==
                                                previousMessageDate;

                                            return (
                                                <Box
                                                    className={
                                                        classes.messageContainer
                                                    }
                                                    key={_id}
                                                >
                                                    {isNewDay && (
                                                        <Box
                                                            className={
                                                                classes.newDay
                                                            }
                                                            alignSelf="center"
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                fontWeight="bold"
                                                            >
                                                                {moment(
                                                                    sentAt
                                                                ).format(
                                                                    'dddd, MMMM Do YYYY'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}

                                                    <Box
                                                        className={
                                                            classes.message
                                                        }
                                                        style={{
                                                            alignSelf:
                                                                from ===
                                                                user._id
                                                                    ? 'flex-end'
                                                                    : 'flex-start',
                                                        }}
                                                    >
                                                        {_id ===
                                                            myLastMessageSeen?._id && (
                                                            <Box
                                                                alignSelf="flex-end"
                                                                margin="0px 10px 0px -30px"
                                                            >
                                                                {/* <VisibilityIcon
                                                                    fontSize="small"
                                                                    color="disabled"
                                                                /> */}
                                                                <DoneAllIcon
                                                                    fontSize="small"
                                                                    color="success"
                                                                />
                                                            </Box>
                                                        )}
                                                        {/* {_id + ', ' + myLastMessageSeen?._id} */}
                                                        <Box
                                                            display={
                                                                from ===
                                                                user._id
                                                                    ? 'none'
                                                                    : 'block'
                                                            }
                                                            order={
                                                                from ===
                                                                user._id
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            marginLeft={
                                                                from ===
                                                                user._id
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            marginRight={
                                                                from !==
                                                                user._id
                                                                    ? 1
                                                                    : 0
                                                            }
                                                        >
                                                            <AvatarBadge
                                                                style={{
                                                                    backgroundColor:
                                                                        userByIdUpdated[
                                                                            from
                                                                        ]
                                                                            ?.color,
                                                                }}
                                                                src={
                                                                    userByIdUpdated[
                                                                        from
                                                                    ]
                                                                        ?.profilePic ||
                                                                    userByIdUpdated[
                                                                        from
                                                                    ]
                                                                        ?.user_url ||
                                                                    userByIdUpdated[
                                                                        from
                                                                    ]
                                                                        ?.profilePicUrlWP
                                                                }
                                                                avatarTxt={userByIdUpdated[
                                                                    from
                                                                ]?.name
                                                                    ?.charAt(0)
                                                                    ?.toUpperCase()}
                                                                isOnline={
                                                                    userByIdUpdated[
                                                                        from
                                                                    ]
                                                                        ?.socket_ids
                                                                        ?.length
                                                                }
                                                            />
                                                        </Box>

                                                        {/* {_id} */}
                                                        <Box
                                                            display="flex"
                                                            flexDirection={
                                                                'column'
                                                            }
                                                        >
                                                            <Typography
                                                                variant="caption"
                                                                textAlign={
                                                                    from ===
                                                                    user._id
                                                                        ? 'right'
                                                                        : 'left'
                                                                }
                                                            >
                                                                {formatDateTime(
                                                                    sentAt
                                                                )}
                                                            </Typography>
                                                            <Box
                                                                className={`${classes.messageData}`}
                                                                style={{
                                                                    backgroundColor:
                                                                        from ===
                                                                        user._id
                                                                            ? '#e0f7fa'
                                                                            : '#e8f5e9',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                                // onMouseEnter={() =>
                                                                //     handleMouseEnter(
                                                                //         _id
                                                                //     )
                                                                // }
                                                                // onMouseLeave={
                                                                //     handleMouseLeave
                                                                // }
                                                            >
                                                                {/* {hoveredMessageId ===
                                                                    _id && (
                                                                    <IconButton
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            top:
                                                                                '-5px',
                                                                            right:
                                                                                '6px',
                                                                            padding:
                                                                                '0px',
                                                                        }}
                                                                        onClick={
                                                                            handleMenuClick
                                                                        }
                                                                        anchorOrigin={{
                                                                            vertical:
                                                                                'bottom',
                                                                            horizontal:
                                                                                'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical:
                                                                                'top',
                                                                            horizontal:
                                                                                'right',
                                                                        }}
                                                                    >
                                                                        <MoreHorizonIcon />
                                                                    </IconButton>
                                                                )}
                                                                <Menu
                                                                    anchorEl={
                                                                        anchorEl
                                                                    }
                                                                    open={Boolean(
                                                                        anchorEl
                                                                    )}
                                                                    onClose={
                                                                        handleMenuClose
                                                                    }
                                                                >
                                                                    <MenuItem
                                                                        onClick={
                                                                            handleMarkPinMessage
                                                                        }
                                                                    >
                                                                        Pin
                                                                    </MenuItem>
                                                                    {from ===
                                                                    user._id ? (
                                                                        <MenuItem
                                                                            onClick={
                                                                                handleDeleteMessage
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </MenuItem>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </Menu> */}
                                                                <Attachments
                                                                    chatId={
                                                                        chatId
                                                                    }
                                                                    attachedFileNames={
                                                                        attachedFileNames
                                                                    }
                                                                    deletable={
                                                                        from ===
                                                                        user._id
                                                                    }
                                                                />
                                                                <Typography
                                                                    variant="body2"
                                                                    className={
                                                                        classes.messageBody
                                                                    }
                                                                >
                                                                    {linkMetaData && (
                                                                        <ChatLinkMetaData
                                                                            linkMetaData={
                                                                                linkMetaData
                                                                            }
                                                                        />
                                                                    )}
                                                                    {body}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    {_id ===
                                                        lastMessageRead?._id &&
                                                        index !==
                                                            chat.messages
                                                                .length -
                                                                1 && (
                                                            <Box
                                                                // alignSelf="center"
                                                                className={
                                                                    classes.lastRead
                                                                }
                                                                mb={3}
                                                                mt={3}
                                                            >
                                                                <Box
                                                                    className={
                                                                        classes.line
                                                                    }
                                                                ></Box>
                                                                <Typography fontWeight="bold">
                                                                    Last Read
                                                                </Typography>
                                                                <Box
                                                                    className={
                                                                        classes.line
                                                                    }
                                                                ></Box>
                                                            </Box>
                                                        )}
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>

                                <Box>
                                    {fileContainers?.length > 0 && (
                                        <SelectedFiles
                                            fileContainers={fileContainers}
                                            handleRemoveSelectedFile={
                                                handleRemoveSelectedFile
                                            }
                                        />
                                    )}
                                </Box>

                                {loading && <div>Loading...</div>}
                                {linkMetaData && (
                                    <ChatLinkMetaDataPreview
                                        linkMetaData={linkMetaData}
                                    />
                                )}
                                <Box
                                    className={classes.messageInputContainer}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderTop: '1px solid #ddd',
                                        padding: '8px',
                                        ...(isTextInputExpanded
                                            ? { height: '100%' }
                                            : { height: 'fit-content' }),
                                    }}
                                >
                                    <IconButton
                                        onClick={handleSelectClick}
                                        aria-label="attach"
                                        size="small"
                                    >
                                        <AttachFileIcon />
                                    </IconButton>
                                    {/* <IconButton
                                        onClick={toggleEmojiPicker}
                                        aria-label="emoji"
                                        size="small"
                                    >
                                        <span role="img" aria-label="emoji">
                                            😀
                                        </span>{' '}
                                    </IconButton>
                                    {showEmojiPicker && (
                                        <Box
                                            position="absolute"
                                            zIndex={999999999}
                                        >
                                            <EmojiPicker
                                                onEmojiClick={addEmoji}
                                            />{' '}
                                        </Box>
                                    )} */}
                                    <TextField
                                        fullWidth
                                        className={classes.messageInput}
                                        onChange={onMessageInputChange}
                                        onKeyDown={keyPressHandler}
                                        value={messageInput}
                                        inputProps={{ ref: messageInputEl }}
                                        placeholder="Type your message..."
                                        multiline
                                        rows={isTextInputExpanded ? 15 : 3}
                                        variant="outlined"
                                        color="secondary"
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        sendMessageHandler();
                                                    }}
                                                    className={classes.sendBtn}
                                                    aria-label="chat"
                                                    size="small"
                                                    disabled={isSendDisabled}
                                                >
                                                    <SendIcon fontSize="small" />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                    <IconButton
                                        onClick={toggleTextInputExpand}
                                        aria-label="expand"
                                        size="small"
                                        sx={{ marginRight: '8px' }}
                                    >
                                        {isTextInputExpanded ? (
                                            <ExpandMoreIcon />
                                        ) : (
                                            <ExpandLessIcon />
                                        )}
                                    </IconButton>
                                    {showDownButton && (
                                        <Box
                                            position="absolute"
                                            right={'43%'}
                                            top={-50}
                                        >
                                            <Button
                                                className={classes.downButton}
                                                color="primary"
                                                variant="contained"
                                                onClick={scrollDown}
                                            >
                                                <ArrowDownwardIcon fontSize="small" />
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </>
                        )}

                        <input
                            ref={attachmentInputRef}
                            type="file"
                            name="attachments"
                            onChange={handleFileSelected}
                            hidden
                            multiple
                        />
                    </>
                )}
                {showGroupDialog && (
                    <GroupChatManageModal
                        open={showGroupDialog}
                        onClose={() => setGroupDialog(false)}
                        chatId={chat._id}
                        groupData={groupData}
                    />
                )}
            </Box>
        );
    };
