import { useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { new_usersState } from '../recoil/state';
import moment from 'moment';
import { commander_id, voyager_id } from '../../plans/PlanDetails';
import axios from 'axios';

import { addUserToAllUserObject } from '../recoil/selectors';

// can be provided as a parameter or prop
const ADMIN_URL = '/api/admin/users';

export const useAdminUsers = ({
    pageNumber = 1,
    // size = 50,
    size,
    sortBy = 'last_accessed',
    order = 'desc',
    active = true,
    value,
}) => {
    const [users, setUsers] = useRecoilState(new_usersState);
    const addUser = useSetRecoilState(addUserToAllUserObject);
    const [allUsers, setAllUsers] = useState([]);
    const [fetchingAll, setFetchingAll] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const createFilterQuery = useCallback((filters) => {
        const query = [];
        filters.forEach((filter) => {
            query.push(
                `${filter.name}:${filter.type},${filter.operator}:${filter.value}`
            );
        });
        return query.join(',');
    }, []);

    const organizeData = useCallback(
        ({ usersRawData = [] }) => {
            const updatedData = usersRawData.map((user) => {
                let teamObject = {
                    teamName: '',
                    teamIds: [],
                    vertical: '',
                };

                if (user.teams?.length) {
                    const names = [];
                    const teamIds = [];
                    const teamVerticals = [];
                    user.teams.forEach(({ name, _id, vertical }) => {
                        names.push(name);
                        teamIds.push(_id);
                        teamVerticals.push(vertical);
                    });
                    teamObject.teamName = names.join(',');
                    teamObject.teamIds = teamIds;
                    teamObject.vertical = teamVerticals.join(',');
                }
                let managerObject = {
                    managers: [],
                    managersName: [],
                };
                if (user.managers?.length) {
                    const allManagers = [];
                    const names = [];
                    user.managers.forEach(({ name, id }) => {
                        names.push(name);
                        allManagers.push(id);
                    });
                    managerObject.managers = allManagers;
                    managerObject.managersName = names;
                }
                // have to do this cause plan_name field is not consistent right now
                const planType =
                    user.user_current_plan === voyager_id
                        ? user.plan_name
                            ? user.plan_name
                            : 'Voyager'
                        : user.user_current_plan === commander_id
                        ? 'Commander'
                        : 'Astronaut';
                const credits = user.credits_id
                    ? user.credits_id.current_points
                    : 0;
                const userType = user.isAdmin
                    ? 'Admin'
                    : user.is_instructor
                    ? 'Instructor'
                    : user.client_id
                    ? 'Client'
                    : 'Student';
                const saves = user.user_save ? user.user_save : [];
                const certification = user.saved_certification_courses
                    ? user.saved_certification_courses
                    : [];
                const journey = user.saved_journey_courses
                    ? user.saved_journey_courses
                    : [];
                const userSaves = [
                    ...new Set([...saves, ...certification, ...journey]),
                ];
                const country = user.user_country || '';
                const signupDate =
                    user.createdAt ||
                    new Date().setFullYear(new Date().getFullYear() - 1);
                const lastActivity = user.last_accessed || signupDate;
                const newUserData = {
                    _id: user._id,
                    user_email: user.user_email || '',
                    user_firstName: user.user_firstName || '',
                    user_lastName: user.user_lastName || '',
                    user_rsvps_onair: user.user_rsvps_onair || [],
                    user_rsvps_liveLabs: user.user_rsvps_liveLabs || [],
                    user_save: userSaves,
                    phone: user.user_phone,
                    country,
                    current_plan_end_date: user.user_current_plan_end_date,
                    planDetails: user?.user_current_plan_details
                        ? user.user_current_plan_details
                        : {},
                    planAmount: user?.user_current_plan_details?.amount,
                    currentPlanDate: user?.user_current_plan_date,
                    bundleCourse: user.bundleCourse,
                    saved_courses: user.saved_courses || [],
                    createdAt: moment(signupDate).format(),
                    lastActivity: moment(lastActivity).format(),
                    isDisabled: user.hasOwnProperty('isDisabled')
                        ? user.isDisabled
                        : false,
                    planType,
                    credits,
                    userType,
                    onTrial:
                        user.user_subscription_status === 'trialing' ? 1 : 0,
                    ...managerObject,
                    ...teamObject,
                    city: user.user_city,
                    dob: user.user_dob,
                    gender: user.user_gender,
                    occupation: user.user_occupation,
                    totalAmountPaid: user?.totalAmountPaid ?? null,
                    lastAmountPaid: user?.lastAmountPaid ?? null,
                    lastAmountPaidAt: user?.lastAmountPaidAt ?? null,
                };
                addUser(newUserData);
                return newUserData;
            });
            return updatedData;
        },
        [addUser]
    );

    const setNewUsers = useCallback(
        // this function takes page, size, newUsers, totalNumberOfPages, and reset allUser in case of filter or sort
        (
            page,
            sizeOfPage,
            usersToAdd = [],
            totalNumberUsers,
            resetUsers = false
        ) => {
            let newUsers = [];
            const startIndex = (page - 1) * sizeOfPage;
            let usersAdded = 0;
            const usersLength = usersToAdd.length;
            if (users.length !== totalNumberUsers) {
                newUsers = new Array(totalNumberUsers)
                    .fill(null)
                    .map((val, index) => {
                        if (usersAdded < usersLength && index >= startIndex) {
                            const userToBeReturned = usersToAdd[usersAdded];
                            usersAdded++;
                            return userToBeReturned;
                        } else {
                            return null;
                        }
                    });
                setUsers(newUsers);
            } else {
                console.log('resetting-users -> ' + resetUsers);
                let newUsers = resetUsers ? [] : [...users];
                for (let i = 0; i < usersLength; i++) {
                    newUsers[startIndex + i] = usersToAdd[i];
                }
                setUsers(newUsers);
            }
        },
        [users, setUsers]
    );

    const getUsers = useCallback(
        async (providedPage, refreshUsers = false, filters = []) => {
            // fetch request
            setIsLoading(true);
            try {
                console.log('fetching-users...');

                const pageToStartWith = providedPage
                    ? providedPage
                    : pageNumber;
                const userProvided = refreshUsers ? null : totalUsers;
                const filterQuery = createFilterQuery(filters);
                // console.log(
                //     `${ADMIN_URL}?size=${size}&page=${pageToStartWith}&total=${userProvided}&sort=${sortBy}&order=${order}&active=${active}${
                //         filters.length > 0 ? `&filterQuery=${filterQuery}` : ''
                //     }`
                // );
                const { data } = await axios(
                    `${ADMIN_URL}?size=${size}&page=${pageToStartWith}&total=${userProvided}&sort=${sortBy}&order=${order}&active=${active}${
                        filters.length > 0 ? `&filterQuery=${filterQuery}` : ''
                    }`
                );
                // group all these
                setTotalUsers(data.totalUsers);
                const formattedData = organizeData({
                    usersRawData: data.users,
                });
                setNewUsers(
                    pageNumber,
                    size,
                    formattedData,
                    data.totalUsers,
                    filterQuery ? true : false
                );
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [
            size,
            pageNumber,
            totalUsers,
            sortBy,
            order,
            active,
            organizeData,
            setNewUsers,
            createFilterQuery,
        ]
    );

    useEffect(() => {
        if (users.length) {
            const start = (pageNumber - 1) * size;
            const userRequired = users.slice(start, start + size);
            if (userRequired.some((val) => val === null)) {
                getUsers();
            }
        } else {
            getUsers();
        }
        // eslint-disable-next-line
    }, [pageNumber, size]);

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, [active]);

    const fetchAll = useCallback(async () => {
        setFetchingAll(true);
        try {
            if (!allUsers.length) {
                const { data } = await axios.get('/api/admin/user/short/all/');
                data.users.forEach((userData) => addUser(userData));
                setAllUsers(data.users);
                setFetchingAll(false);
                return data.users;
            }
        } catch (error) {
            console.log('error-in-fetching-all-users');
        }
        setFetchingAll(false);
    }, [allUsers, addUser]);

    const new_userToReturn = useMemo(() => {
        if (users.length) {
            const start = (pageNumber - 1) * size;
            const userRequired = users.slice(start, start + size);
            return userRequired.filter((val) => val !== null);
            // if (userRequired.some((val) => val === null)) {
            //     return [];
            // } else {
            //     return userRequired;
            // }
        } else {
            return [];
        }
    }, [users, pageNumber, size]);

    const totalPages = useMemo(() => {
        return Math.ceil(totalUsers / size);
    }, [totalUsers, size]);

    return {
        // users lined with the page
        users: new_userToReturn,
        fullFetchedUsers: users,
        totalUsers,
        isLoading,
        updateUsers: getUsers,
        fetchAll,
        allUsers,
        fetchingAll,
        totalPages,
        setAllUsers,
    };
};
