import { atom } from 'recoil';

export const instructorFirstNameState = atom({
    key: 'instructorFirstNameState',
    default: '',
});

export const instructorLastNameState = atom({
    key: 'instructorLastNameState',
    default: '',
});
export const instructorSkypeIdState = atom({
    key: 'instructorSkypeIdState',
    default: '',
});
/* export const instructorState = atom({
    key: 'instructorState',
    default: '',
}); */
export const instructorCountryState = atom({
    key: 'instructorCountryState',
    default: '',
});
export const instructorTimeZoneState = atom({
    key: 'instructorTimeZoneState',
    default: '',
});
export const instructorMobileNumberState = atom({
    key: 'instructorMobileNumberState',
    default: '',
});
export const instructorCityState = atom({
    key: 'instructorCityState',
    default: '',
});
export const instructorStateState = atom({
    key: 'instructorStateState',
    default: '',
});
export const instructorAddress1State = atom({
    key: 'instructorAddress1State',
    default: '',
});
export const instructorAddress2State = atom({
    key: 'instructorAddress2State',
    default: '',
});
export const instructorPinCodeState = atom({
    key: 'instructorPinCodeState',
    default: '',
});
export const instructorCompanyState = atom({
    key: 'instructorCompanyState',
    default: '',
});

export const instructorTagLineState = atom({
    key: 'instructorTagLineState',
    default: '',
});
export const instructorAboutMeState = atom({
    key: 'instructorAboutMeState',
    default: '',
});
export const instructorLinkedinState = atom({
    key: 'instructorLinkedinState',
    default: '',
});
export const instructorTwitterState = atom({
    key: 'instructorTwitterState',
    default: '',
});
export const instructorTopicsState = atom({
    key: 'instructorTopicsState',
    default: [],
});

export const instructorSelectedTopicsState = atom({
    key: 'instructorSelectedTopicsState',
    default: [],
});

export const instructorAddedSearchTermsState = atom({
    key: 'instructorAddedSearchTermsState',
    default: [],
});

export const instructorRolesState = atom({
    key: 'instructorRolesState',
    default: [],
});
export const instructorServicesState = atom({
    key: 'instructorServicesState',
    default: [],
});
export const instructorCoursesState = atom({
    key: 'instructorCoursesState',
    default: [],
});
export const instructorCourseLinksState = atom({
    key: 'instructorCourseLinksState',
    default: [],
});
export const instructorEducationState = atom({
    key: 'instructorEducationState',
    default: [],
});
export const instructorWorkExperienceState = atom({
    key: 'instructorWorkExperienceState',
    default: [],
});
