import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

const useCommentsUserMetaData = (courseIds) => {
    const [commentsReadStatus, setCommentsReadStatus] = useState({});
    const [commentsCount, setCommentsCount] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCommentsUserMetaData = async () => {
            setLoading(true);
            try {
                const courseIdsString = courseIds.join(',');

                const lastSeenResponse = await axios.get(
                    '/api/content/comments/last-seen',
                    {
                        params: { courseIds: courseIdsString },
                    }
                );

                const lastCommentResponse = await axios.get(
                    '/api/content/comments/last-comment',
                    {
                        params: { courseIds: courseIdsString },
                    }
                );

                const lastSeenMap = lastSeenResponse.data.lastSeen;
                const lastCommentMap = lastCommentResponse.data.lastComment;

                const statusMap = courseIds.reduce((acc, courseId) => {
                    const lastSeen = lastSeenMap[courseId];
                    const lastComment = lastCommentMap[courseId];

                    if (!lastComment) {
                        acc[courseId] = true;
                    } else {
                        acc[courseId] = lastSeen
                            ? moment(lastSeen).isSameOrAfter(
                                  moment(lastComment)
                              )
                            : false;
                    }
                    return acc;
                }, {});

                setCommentsReadStatus(statusMap);
                setError(null);
            } catch (err) {
                setError('Error fetching comments user metadata');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        if (courseIds && courseIds.length > 0) {
            fetchCommentsUserMetaData();
        } else {
            setCommentsReadStatus({});
            // setLoading(false);
        }
    }, [courseIds.join(',')]);

    useEffect(() => {
        const fetchCommentsCount = async () => {
            // setLoading(true);
            try {
                const courseIdsString = courseIds.join(',');

                const commentsCountResponse = await axios.get(
                    '/api/content/comments/count',
                    {
                        params: { courseIds: courseIdsString },
                    }
                );

                const commentsCountArray =
                    commentsCountResponse.data.commentsCount;
                const commentsCountMap = commentsCountArray.reduce(
                    (acc, item) => {
                        acc[item.course_id] = item.count;
                        return acc;
                    },
                    {}
                );

                setCommentsCount(commentsCountMap);
                setError(null);
            } catch (err) {
                setError('Error fetching comments count');
                console.error(err);
            } finally {
                // setLoading(false);
            }
        };

        if (courseIds && courseIds.length > 0) {
            fetchCommentsCount();
        } else {
            setCommentsCount({});
            // setLoading(false);
        }
    }, [courseIds.join(',')]);

    const updateCommentLastSeen = async (courseId) => {
        try {
            await axios.post('/api/content/comments/last-seen', {
                courseId,
            });
            setCommentsReadStatus((prevState) => ({
                ...prevState,
                [courseId]: true,
            }));
        } catch (error) {
            console.error('Error updating last seen timestamp', error);
        }
    };

    return {
        commentsReadStatus,
        commentsCount,
        loading,
        error,
        updateCommentLastSeen,
    };
};

export default useCommentsUserMetaData;
