import { useState } from 'react';
import {
    Box,
    Button,
    Tooltip,
    Dialog,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '../ui';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import { CloseIcon } from '../icons';
import * as XLSX from 'xlsx';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    paper: {
        minWidth: '90vw',
        padding: 40,
    },
    // tableContainer: {
    //     height: 280,
    //     width: '100%',
    // },
    dialogAction: {
        display: 'flex',
    },
    dialogContent: {
        minHeight: 500,
    },
}));

export const ClientRequirementsBulkUpload = ({ disabled, userDetails }) => {
    const { classes } = useStyles();

    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'title'],
    });

    const [rows, setRows] = useState([
        // {
        //     id: 0,
        //     title: 'Imported: Corporate Communication',
        //     description:
        //         'This course explores effective communication strategies within the corporate environment. Topics include written and verbal communication, interpersonal skills, and delivering presentations. Students will learn how to convey information clearly and professionally. In addition, they will develop the skills to adapt their communication style to different audiences and utilize effective communication tools and technologies. Strong emphasis will be placed on building and maintaining strong professional relationships to enhance collaboration and teamwork. Through interactive exercises and practical examples, students will gain confidence in their verbal and written communication abilities, enabling them to excel in their professional roles.',
        //     problemStatement:
        //         'Develop persuasive communication skills; Enhance interpersonal skills; Deliver effective presentations; Build strong professional relationships; Adapt communication style to different audiences; Utilize effective communication tools and technologies',
        //     topics: [
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: 'Written communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' Verbal communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' interpersonal skills',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' Presentation skills',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' professional relationships',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' communication style',
        //         },
        //     ],
        // },
        // {
        //     id: 1,
        //     title: 'Imported: Cross-functional Collaboration',
        //     description:
        //         'This course focuses on the principles and strategies for collaborating effectively with colleagues from different functional areas. Students will learn to overcome challenges, foster teamwork, and leverage diverse perspectives to achieve common goals. The course covers various techniques to improve communication and information sharing across departments and develop strategies for managing conflict and resolving disputes. Through case studies and group exercises, students will enhance their problem-solving skills and learn how to make informed decisions through collaborative efforts. The course also emphasizes the importance of building a culture of trust and cooperation among team members. By the end of the course, students will be equipped with the necessary skills to collaborate successfully with colleagues from different backgrounds and functional areas, leading to enhanced productivity and overall organizational success.',
        //     problemStatement:
        //         'Foster collaboration and teamwork; Overcome challenges in cross-functional collaboration; Leverage diverse perspectives to Enhance decision making; Improve communication and information sharing across departments; Develop strategies for managing conflict and resolving disputes; Enhance problem-solving skills through collaborative efforts',
        //     topics: [
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: 'teamwork',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' collaboration strategies',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' cross-functional collaboration',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' decision making',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' communication',
        //         },
        //         {
        //             id: 99999,
        //             term_id: 99999,
        //             name: ' conflict management',
        //         },
        //     ],
        // },
    ]);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [importing, setImporting] = useState(false);

    const gridCols = [
        // {
        //     field: 'slNo',
        //     headerName: '#',
        //     width: 250,
        //     editable: false,
        // },
        {
            field: 'title',
            headerName: 'Course Title',
            width: 250,
            editable: false,
            // valueFormatter: (value ,row) => {
            //     return starweaverAdmin ? value : row._name;
            // },
        },
        {
            field: 'description',
            headerName: 'Course Description',
            width: 250,
            editable: false,
        },

        {
            field: 'problemStatement',
            headerName: 'Learning Outcomes',
            width: 250,
            editable: false,
        },

        {
            field: 'topics',
            headerName: 'Keywords/Topics',
            width: 250,
            editable: false,
            valueFormatter: (value, row) => {
                return value?.map?.((k) => k?.name).join(', ');
            },
        },
    ];

    const getData = (fileData = []) => {
        const titleIndex = fileData[0].indexOf('Course Title');
        const descriptionIndex = fileData[0].indexOf('Course Description');
        const problemStatementIndex = fileData[0].indexOf('Learning Outcomes');
        const keywordsIndex = fileData[0].indexOf('Keywords/Topics');

        const read = [];
        fileData.slice(1).forEach((r, index) => {
            if (r.length > 1) {
                if (!r[titleIndex]) return;
                const rObject = {
                    id: index,
                    title: r[titleIndex],
                    description: r[descriptionIndex],
                    problemStatement: r[problemStatementIndex],
                    topics:
                        r[keywordsIndex]?.split(';')?.map((k) => ({
                            id: 99999,
                            term_id: 99999,
                            name: k,
                        })) || [],
                };
                read.push(rObject);
            }
        });
        return read;
    };

    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileName = selectedFile.name;
            const extension = fileName.substr(fileName.lastIndexOf('.') + 1);

            if (extension === 'xlsx') {
                try {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const data = event.target.result;
                        const readData = XLSX.read(data, { type: 'binary' });
                        const workSheetName = readData.SheetNames[0];
                        const ws = readData.Sheets[workSheetName];
                        /* Convert array to json*/
                        const dataParse = XLSX.utils.sheet_to_json(ws, {
                            header: 1,
                        });
                        const requirements = getData(dataParse);
                        if (requirements.length) {
                            console.log(requirements);
                            setRows(requirements);
                            setOpenPreviewDialog(true);
                        }
                    };
                    reader.readAsBinaryString(selectedFile);
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.log('Please use correct format');
            }
        }
    };

    const handleDialogClose = () => {
        setOpenPreviewDialog(false);
    };

    const importRequirements = async () => {
        try {
            setImporting(true);

            await axios.post(
                `/api/client-requirements/import?clientId=${userDetails.client_id}&userId=${userDetails._id}`,
                rows
            );

            setImporting(false);
            setOpenPreviewDialog(false);
        } catch (e) {
            console.log(e);
            setImporting(false);
        }
    };

    return (
        <>
            <label for="ClientRequirementsBulkUpload">
                <Tooltip
                    title={
                        'Kindly choose an XLSX file that includes columns denoted as "Course Title", "Course Description", "Learning Outcomes" and "Keywords/Topics" (where multiple keywords are separated by ";").'
                    }
                >
                    <Box style={{ cursor: 'pointer' }}>Starweaver</Box>
                </Tooltip>
            </label>
            {!disabled && (
                <input
                    type="file"
                    id="ClientRequirementsBulkUpload"
                    hidden
                    onChange={handleFile}
                />
            )}

            <Box>
                <Dialog
                    onClose={handleDialogClose}
                    aria-labelledby="matching-instructor-data-modal"
                    open={openPreviewDialog}
                    fullWidth
                    classes={{ paper: classes.paper }}
                >
                    <Box display="flex">
                        <Typography
                            variant="h6"
                            style={{ marginRight: 'auto' }}
                        >
                            Preview
                            {/* : {rows.map((r) => r.title).join(', ')} */}
                        </Typography>

                        <IconButton
                            aria-label="close"
                            onClick={handleDialogClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <>
                        <DialogContent className={classes.dialogContent}>
                            <DataGridPremium
                                autoHeight
                                rows={rows}
                                columns={gridCols}
                                pageSizeOptions={[]}
                                pinnedColumns={pinnedColumns}
                                onPinnedColumnsChange={(
                                    updatedPinnedColumns
                                ) => {
                                    if (isLargeScreen) {
                                        setPinnedColumns(updatedPinnedColumns);
                                    } else {
                                        alert(
                                            'Pinning is not available on small screens.'
                                        );
                                    }
                                }}
                                hideFooter={true}
                                hideFooterSelectedRowCount={true}
                                // disableColumnMenu={true}
                                disableColumnFilter={true}
                                disableColumnSelector={true}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Box
                                className={classes.dialogAction}
                                style={{ width: '100%' }}
                            >
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogClose}
                                    >
                                        Cancel
                                    </Button>
                                </Box>

                                <Box marginLeft="auto">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={importRequirements}
                                    >
                                        {importing ? (
                                            <CircularProgress size="1.9em" />
                                        ) : (
                                            <>Import</>
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </>
                </Dialog>
            </Box>
        </>
    );
};
