import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Tooltip,
    useTheme,
    useMediaQuery,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Fullscreen, FullscreenExit, Print } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import { CloseIcon } from '../icons';
import ReactHTMLParser from 'react-html-parser';
import { GetAppIcon } from '../icons';

const useStyles = makeStyles()((theme) => ({
    outlineContent: {
        '& *': {
            backgroundColor: 'transparent',
        },
        '& a': {
            color: 'blue',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& .body': {
            fontSize: '0.875rem !important',
            lineHeight: '1.25rem !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.125rem !important',
                lineHeight: '1.75rem !important',
            },
        },
        '& .body h1': {
            textAlign: 'left !important',
            textDecoration: 'underline',
            fontSize: '1.25rem !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.75rem !important',
            },
        },
        '& .body h2': {
            textDecoration: 'underline',
            fontSize: '1.125rem !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.25rem !important',
            },
        },
        '& .body p': {
            fontSize: '0.9rem !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.1rem !important',
            },
        },
        '& .body ul': {
            fontSize: '0.9rem !important',
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.1rem !important',
            },
        },
    },
    footer: {
        textAlign: 'center',
        padding: theme.spacing(1, 0),
    },
}));

const OutlineDialog = ({
    openOutline,
    outlineName,
    outlineContent,
    handleOutlineClose,
}) => {
    const { classes } = useStyles();
    const printRef = useRef();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (isXSDevice) setIsFullScreen(true);
    }, [isXSDevice]);

    function Export2Word(filename = 'outline') {
        var preHtml =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        var postHtml = '</body></html>';
        var html = preHtml + outlineContent + postHtml;

        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword',
        });

        // Specify link url
        var url =
            'data:application/vnd.ms-word;charset=utf-8,' +
            encodeURIComponent(html);

        // Specify file name
        filename = filename ? filename + '.doc' : 'document.doc';

        // Create download link element
        var downloadLink = document.createElement('a');

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    }

    return (
        <Dialog
            aria-labelledby="outline-dialog"
            open={openOutline}
            maxWidth="md"
            fullScreen={isFullScreen}
            onClose={handleOutlineClose}
        >
            <Box>
                <Box
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="h6"
                        id="outline-dialog"
                        style={{ flexGrow: 1 }}
                    >
                        {outlineName || 'Outline'}
                    </Typography>
                    <Box display="flex">
                        <ReactToPrint
                            trigger={() => (
                                <Tooltip title="Print / Save as pdf">
                                    <IconButton size="small">
                                        <Print fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            content={() => printRef.current}
                        />

                        {!isXSDevice && (
                            <React.Fragment>
                                <Tooltip title="Download as Word document">
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            Export2Word();
                                        }}
                                    >
                                        <GetAppIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                {isFullScreen === false ? (
                                    <Tooltip title="Show full screen">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                setIsFullScreen(true)
                                            }
                                        >
                                            <Fullscreen fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Exit full screen">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                setIsFullScreen(false)
                                            }
                                        >
                                            <FullscreenExit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </React.Fragment>
                        )}

                        <Tooltip
                            title="Close"
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <IconButton
                                size="small"
                                onClick={handleOutlineClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <DialogContent
                    ref={printRef}
                    className={classes.outlineContent}
                >
                    <footer className={classes.footer}>
                        <small>
                            This outline was generated by AI and used SOLELY to
                            provide examples of the content that might be
                            included in this course. This, and the Samples (see
                            details), also helps clients prioritize (rank order:
                            1 = best fit) what AI generated content is most
                            aligned to their need (Note: sometimes clients are
                            not sure, and by generating these samples it may
                            help both clients and SMEs)
                        </small>
                    </footer>
                    <section className="body">
                        {ReactHTMLParser(outlineContent)}
                    </section>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default OutlineDialog;
