import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false,
    email: '',
    requirements: [],
    isOpportunityManagement: false,
    isExpertManagement: false,
};

const expertProjectModalSlice = createSlice({
    name: 'expertProjectModal',
    initialState,
    reducers: {
        setExpertModalState: (state, action) => {
            state.open = action.payload.open;
            state.email = action.payload.email;
            state.requirements = action.payload.requirements;
        },
        resetExpertModalState: (state) => {
            state.open = false;
            state.email = '';
            state.requirements = [];
        },
    },
});

export const {
    resetExpertModalState,
    setExpertModalState,
} = expertProjectModalSlice.actions;
export default expertProjectModalSlice.reducer;
