import { useMemo } from 'react';
import Highcharts from 'highcharts';
import dataModule from 'highcharts/modules/data';
import drillDownModule from 'highcharts/modules/drilldown';
import exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import hcAccess from 'highcharts/modules/accessibility';
import hcSeries from 'highcharts/modules/series-label';
import hcExport from 'highcharts/modules/export-data';

if (typeof Highcharts === 'object') {
    hcSeries(Highcharts);
    dataModule(Highcharts);
    drillDownModule(Highcharts);
    exporting(Highcharts);
    hcAccess(Highcharts);
    hcExport(Highcharts);
}

function MultiLineChart({
    height,
    title,
    subtitle,
    yTitle,
    seriesData,
    categories,
    tickInterval,
    tooltip = undefined,
    yAxisLabelPrefix = '$',
}) {
    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                height,
            },
            title: {
                align: 'center',
                text: title,
            },
            subtitle: {
                align: 'center',
                text: subtitle && subtitle.length > 0 ? subtitle : '',
            },
            xAxis: {
                categories,
            },
            credits: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: yTitle,
                },
                tickInterval:
                    tickInterval === undefined || tickInterval === null
                        ? ''
                        : tickInterval,
                labels: {
                    formatter: function () {
                        return (
                            yAxisLabelPrefix +
                            this.axis.defaultLabelFormatter.call(this)
                        );
                    },
                },
            },
            ...(tooltip !== undefined ? { tooltip } : {}),
            legend: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    },
                    pointStart: 0,
                },
            },
            series: seriesData,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 1000,
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom',
                            },
                        },
                    },
                ],
            },
        };
    }, [
        categories,
        height,
        seriesData,
        subtitle,
        tickInterval,
        title,
        tooltip,
        yTitle,
        yAxisLabelPrefix,
    ]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
}

export { MultiLineChart };
