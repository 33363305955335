import React from 'react';
import EmptyImg from '../../../img/empty.svg';
import { Button, Typography, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'grid',
        placeItems: 'center',
    },
}));

function NoTutorials({ setShowAddTutorials, hideCTA }) {
    const { classes } = useStyles();

    const handleAddTutorials = () => {
        setShowAddTutorials(true);
    };

    return (
        <div className={classes.container}>
            <img
                alt="empty-img"
                src={EmptyImg}
                height={200}
                width={200}
                loading="lazy"
            />

            <Typography variant="body1">No tutorials found</Typography>

            {!hideCTA && (
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleAddTutorials}
                    >
                        Add Tutorials
                    </Button>
                </Box>
            )}
        </div>
    );
}

export default NoTutorials;
