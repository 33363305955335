import React, { useMemo } from 'react';
import { Anchor, Box, Card, Typography } from '..';
import { Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';

const IN_PROGRESS = 'in-progress';
const COMPLETED = 'completed';
const REQUIREMENTS = 'requirements';

const useStyles = makeStyles()((theme) => ({
    linksContainer: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'space-evenly',
        display: 'grid',
        gap: theme.spacing(1),
        gridTemplateColumns: 'repeat(1, 1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        [theme.breakpoints.up('xl')]: {
            rowGap: '1rem',
        },
    },
    /* link: {
        margin: 7,
    }, */

    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        justifyContent: 'center',
        height: '100%',
        cursor: 'pointer',
        marginInline: 'auto',
    },

    iconContainer: {
        marginBottom: 10,
    },
    type: {
        fontWeight: 'bold',
        textAlign: 'center',
        //color: theme.palette.common.lightBlue,
        //textDecoration: 'underline',
        marginBottom: 10,
    },

    info: {
        textAlign: 'center',
        // marginBottom: 40,
    },
}));

export const SelectCards = ({
    cards,
    handleSelect,
    isSelected,
    viewDataTable,
    isPipeline = false,
}) => {
    const { classes } = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    const showBackBtn = useMemo(() => {
        if (isPipeline) {
            return true;
        } else if (
            window.location.pathname.includes(REQUIREMENTS) ||
            window.location.pathname.includes(IN_PROGRESS) ||
            window.location.pathname.includes(COMPLETED)
        ) {
            return true;
        } else {
            return false;
        }
    }, [isPipeline]);

    return (
        <>
            {showBackBtn && (
                <Box
                    display="flex"
                    justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                    style={{ paddingInline: '7px' }}
                    mb={2}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={viewDataTable}
                    >
                        {isMobile ? 'Go Back' : 'View Requirements'}
                    </Button>
                </Box>
            )}

            <Box className={classes.linksContainer}>
                {cards.map(
                    (
                        {
                            icon,
                            text,
                            info,
                            link,
                            isExternal,
                            isNewTab,
                            value,
                            disabled,
                        },
                        index
                    ) =>
                        disabled ? (
                            <TypeCard
                                handleSelect={handleSelect}
                                icon={icon}
                                info={info}
                                isExternal={isExternal}
                                isNewTab={isNewTab}
                                isSelected={isSelected}
                                link={link}
                                text={text}
                                value={value}
                                disabled={disabled}
                            />
                        ) : (
                            <Anchor
                                key={index}
                                to={link}
                                isExternal={isExternal}
                                isNewTab={isNewTab}
                                className={classes.link}
                            >
                                <TypeCard
                                    handleSelect={handleSelect}
                                    icon={icon}
                                    info={info}
                                    isExternal={isExternal}
                                    isNewTab={isNewTab}
                                    isSelected={isSelected}
                                    link={link}
                                    text={text}
                                    value={value}
                                    disabled={disabled}
                                />
                            </Anchor>
                        )
                )}
            </Box>
        </>
    );
};

const TypeCard = ({
    handleSelect,
    icon,
    text,
    info,
    link,
    isExternal,
    isNewTab,
    value,
    isSelected,
    disabled,
}) => {
    const { classes } = useStyles();

    return (
        <Card
            className={classes.card}
            onClick={() => {
                if (handleSelect)
                    handleSelect({
                        icon,
                        text,
                        info,
                        link,
                        isExternal,
                        isNewTab,
                        value,
                    });
            }}
            style={{
                backgroundColor: isSelected
                    ? isSelected({ value })
                        ? 'lightblue'
                        : 'white'
                    : 'white',
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}
        >
            <Box className={classes.iconContainer}>{icon}</Box>

            <Typography
                className={classes.type}
                sx={{
                    color: (theme) =>
                        disabled ? theme.palette.grey['500'] : 'black',
                }}
            >
                {text}
            </Typography>

            <Typography
                variant="body2"
                className={classes.info}
                sx={{
                    color: (theme) =>
                        disabled ? theme.palette.grey['500'] : 'black',
                }}
            >
                {info}
            </Typography>
        </Card>
    );
};
