import * as React from 'react';
import { selectedIdsLookupSelector } from "../rowSelection/index.js";
import { useGridSelector } from "../../utils/useGridSelector.js";
import { gridColumnGroupsHeaderMaxDepthSelector } from "../columnGrouping/gridColumnGroupsSelector.js";
import { useGridPrivateApiContext } from "../../utils/useGridPrivateApiContext.js";
export const useGridRowAriaAttributes = () => {
  const apiRef = useGridPrivateApiContext();
  const selectedIdsLookup = useGridSelector(apiRef, selectedIdsLookupSelector);
  const headerGroupingMaxDepth = useGridSelector(apiRef, gridColumnGroupsHeaderMaxDepthSelector);
  return React.useCallback((rowNode, index) => {
    const ariaAttributes = {};
    const ariaRowIndex = index + headerGroupingMaxDepth + 2; // 1 for the header row and 1 as it's 1-based
    ariaAttributes['aria-rowindex'] = ariaRowIndex;
    if (apiRef.current.isRowSelectable(rowNode.id)) {
      ariaAttributes['aria-selected'] = selectedIdsLookup[rowNode.id] !== undefined;
    }
    return ariaAttributes;
  }, [apiRef, selectedIdsLookup, headerGroupingMaxDepth]);
};