import { Avatar } from '../ui';
import { makeStyles } from 'tss-react/mui';
import { defaultColorArray } from '../util';
import { useMemo } from 'react';
export const UserAvatar = ({ user, size = 60, name = '' }) => {
    const { classes } = useStyles();

    const color = useMemo(() => {
        if (user) {
            const userId = `${user?._id}`;
            return defaultColorArray[
                (userId.charCodeAt(0) + userId.charCodeAt(userId.length - 1)) %
                    defaultColorArray.length
            ];
        } else {
            return defaultColorArray[5];
        }
    }, [user]);

    return user ? (
        user.profilePic ? (
            <Avatar
                style={{
                    width: size,
                    height: size,
                }}
                alt={user.display_name}
                src={user.profilePic}
                className={classes.smallAvatar}
            />
        ) : (
            <Avatar
                style={{
                    width: size,
                    height: size,
                    backgroundColor: color,
                }}
                className={classes.avatarLetter}
            >
                {name.charAt(0).toUpperCase()}
            </Avatar>
        )
    ) : (
        <></>
    );
};

const useStyles = makeStyles()((theme) => ({
    avatarLetter: {
        backgroundColor: theme.palette.common.blue,
        boxShadow: '0px 5px 10px 2px rgba(0,0,0,0.5)',
    },
    smallAvatar: {
        margin: '0 auto',
        boxShadow: '0px 5px 10px 2px rgba(0,0,0,0.5)',
    },
}));
