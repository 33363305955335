import { Paper, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PlayCircleFilled } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import VideoDialog from './VideoDialog';
import { useQueryParams } from '../../../util/useQueryParams';
import { ShareIcon } from '../../../icons';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import { useLocation } from 'react-router-dom';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { ConfirmDialog } from '../../../ui';

const useStyles = makeStyles()((theme) => ({
    card: {
        // maxWidth: '21.25rem',
        width: '100%',
        '& > .img-container': {
            width: '100%',
            overflow: 'hidden',
            '& > .img': {
                width: '100%',
                height: 'auto',
                aspectRatio: 1.8,
                objectFit: 'cover',
            },
        },
        '& > .card-body': {
            padding: theme.spacing(2),
            '& > .card-actions': {
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                '& > .tag': {
                    color: '#fff',
                    padding: '4px 8px',
                    fontWeight: 500,
                    letterSpacing: '0.05rem',
                    backgroundColor: '#70aef8',
                    borderRadius: '0.1rem',
                },
                '& .play-icon': {
                    color: '#4f46e5',
                },
            },
            '& > .card-title': {
                fontSize: '1.15rem',
                letterSpacing: '0.01rem',
                lineHeight: '1.5rem',
                fontWeight: '500',
            },
            '& > .card-desc': {
                fontSize: '1rem',
                letterSpacing: '0.03rem',
                lineHeight: '1.4rem',
                wordSpacing: '0.05rem',
                fontWeight: 400,
            },
        },
    },
}));

export default function Card({
    imgURL,
    tag,
    title,
    description,
    videoURL,
    maxLength = 150,
    id,
    hideShare = false,
    showEditBtn = false,
    handleEdit = undefined,
    showDeleteBtn = false,
    handleDelete = undefined,
    isDeleting = false,
    ...props
}) {
    const { classes } = useStyles();
    const [playVideo, setPlayVideo] = useState(false);
    const { playbackId } = useQueryParams();
    const setAlert = useSetRecoilState(alertState);
    const location = useLocation();
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (parseInt(playbackId) === id) {
            setPlayVideo(true);
        } else {
            setPlayVideo(false);
        }
    }, [id, playbackId]);

    const computedDesc = useMemo(() => {
        if (description.length > maxLength) {
            return description.slice(0, maxLength) + '...';
        }

        return description;
    }, [description, maxLength]);

    const handlePlay = () => {
        setPlayVideo(true);
    };

    const handleShare = async () => {
        const pathname = location.pathname;
        let baseURL = '';
        if (window.location.hostname === 'localhost') {
            baseURL = `http://localhost:3000${pathname}`;
        } else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
            baseURL = `${process.env.REACT_APP_STAGE_DOMAIN}${pathname}`;
        } else {
            baseURL = `${process.env.REACT_APP_DOMAIN}${pathname}`;
        }
        const copyText = baseURL + '?playbackId=' + id;
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(copyText);
        } else {
            document.execCommand('copy', true, copyText);
        }
        setAlert({
            show: true,
            message: 'Copied!',
            severity: severity.SUCCESS,
        });
    };

    const onEditClick = () => {
        if (handleEdit) {
            handleEdit();
        }
    };

    const onDeleteClick = () => {
        if (handleDelete) {
            // show confirmation modal
            setShowConfirm(true);
        }
    };

    const handleCloseConfirm = () => {
        setShowConfirm(false);
    };

    const handleOnConfirmInterest = async () => {
        if (handleDelete) {
            await handleDelete();
            setShowConfirm(false);
        }
    };

    return (
        <Paper elevation={2} className={classes.card} {...props}>
            {playVideo && (
                <VideoDialog
                    open={playVideo}
                    setOpen={setPlayVideo}
                    videoURL={videoURL}
                    title={title}
                />
            )}

            {/* DLETE CONFIRMATION DIALOG */}
            {showConfirm && (
                <ConfirmDialog
                    onClose={handleCloseConfirm}
                    open={showConfirm}
                    onConfirm={handleOnConfirmInterest}
                    title="Are you sure you want to delete this tutorial?"
                    isLoading={isDeleting}
                />
            )}

            <div className="img-container">
                <img
                    className="img"
                    src={imgURL}
                    alt={title}
                    height={100}
                    width={300}
                    loading="lazy"
                />
            </div>

            <div className="card-body">
                <div className="card-actions">
                    <span className="tag">{tag}</span>

                    <Tooltip placement="top" title="play">
                        <IconButton
                            className="play-icon-btn"
                            size="small"
                            onClick={handlePlay}
                        >
                            <PlayCircleFilled className="play-icon" />
                        </IconButton>
                    </Tooltip>
                    {!hideShare && (
                        <Tooltip placement="top" title="share">
                            <IconButton
                                className="play-icon-btn"
                                size="small"
                                onClick={handleShare}
                            >
                                <ShareIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {showEditBtn && (
                        <Tooltip placement="top" title="update">
                            <IconButton
                                className="play-icon-btn"
                                size="small"
                                onClick={onEditClick}
                            >
                                <EditRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {showDeleteBtn && (
                        <Tooltip placement="top" title="delete">
                            <IconButton
                                className="play-icon-btn"
                                size="small"
                                onClick={onDeleteClick}
                            >
                                <DeleteRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>

                <p className="card-title">{title}</p>

                <p className="card-desc">{computedDesc}</p>
            </div>
        </Paper>
    );
}
