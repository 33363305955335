import { useState, useEffect } from 'react';
import axios from 'axios';

export const useSWACompetingCourses = (title) => {
    const [items, setItems] = useState([]);
    const [sortBy, setSortBy] = useState('students');
    const [sortDesc, setSortDesc] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [topicIds, setTopicIds] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                if (items.length) return;
                setIsLoading(true);
                const res = await axios.get(
                    `${process.env.REACT_APP_TG_LLM_DOMAIN}/api/HttpCourseMatch?code=${process.env.REACT_APP_TG_HTTPCOURSEMATCH_CODE}&concepts=${title}&top_n=50`
                );

                const courseIds = res.data.matches
                    ? res.data.matches.map(({ course_id }) => course_id)
                    : [];

                if (courseIds.length) {
                    const response = await axios.get(
                        `${
                            process.env.REACT_APP_TG_API_DOMAIN
                        }/course-top-competing-by-ids?courseIds=${courseIds.join(
                            ','
                        )}`
                    );

                    const courses = response.data || [];

                    setItems(courses);
                    setTopicIds([
                        ...new Set(
                            courses
                                .map((course) => course.topic_id)
                                .filter((id) => id != null && id !== 0)
                        ),
                    ]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (title) fetchItems();
    }, [items.length, title]);

    const handleSortChange = (field) => {
        setSortBy(field);
        setSortDesc((prev) => !prev);
    };

    return {
        items,
        sortBy,
        sortDesc,
        isLoading,
        handleSortChange,
        topicIds,
    };
};
