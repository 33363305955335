import { useCallback, useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import axios from 'axios';

export const useAllCompanies = (noneOption) => {
    const [companies, setCompanies] = useState([]);
    const getData = useCallback(async () => {
        const { data } = await axios.get('/api/client/all/');
        setCompanies(data.companies);
    }, []);

    useEffect(() => {
        if (!companies?.length) {
            getData();
        }
    }, [companies, getData]);

    const companiesOption = useMemo(() => {
        const all = {
            _id: '0',
            client_name: 'Starweaver',
        };
        const currentCompanies = companies || [];
        return [...(noneOption ? [all] : []), ...currentCompanies];
    }, [companies]);

    return { companiesOption };
};
