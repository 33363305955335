import { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import hcMore from 'highcharts/highcharts-more';
import dataModule from 'highcharts/modules/export-data';
import hcAccess from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';
import solidGauge from 'highcharts/modules/solid-gauge';

if (typeof Highcharts === 'object') {
    hcMore(Highcharts);
    dataModule(Highcharts);
    hcAccess(Highcharts);
    exporting(Highcharts);
    solidGauge(Highcharts);
}

function ActivityGauge({
    title,
    name1,
    name2,
    name3,
    value1,
    value2,
    value3,
    valueSuffix,
    legend = {
        enabled: true,
    },
}) {
    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                type: 'solidgauge',
                height: '90%',
                backgroundColor: 'transparent',
                events: {
                    load() {
                        this.tooltip.refresh(this.series[2].points[0]);
                    },
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: title,
                style: {
                    fontSize: '20px',
                },
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                useHTML: true,
                style: {
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: 600,
                },
                valueSuffix: valueSuffix ? valueSuffix : '',
                pointFormat:
                    '{series.name}<br><span style="font-size:1.45em; color: {point.color}; font-weight: bold">{point.y}</span>',
                positioner(labelWidth) {
                    return {
                        x: (this.chart.chartWidth - labelWidth) / 2,
                        y: this.chart.plotHeight / 2 + 15,
                    };
                },
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [
                    {
                        // Track for Move
                        outerRadius: '112%',
                        innerRadius: '88%',
                        backgroundColor: Highcharts.color(
                            Highcharts.getOptions().colors[0]
                        )
                            .setOpacity(0.3)
                            .get(),
                        borderWidth: 0,
                    },
                    {
                        // Track for Exercise
                        outerRadius: '87%',
                        innerRadius: '63%',
                        backgroundColor: Highcharts.color(
                            Highcharts.getOptions().colors[1]
                        )
                            .setOpacity(0.3)
                            .get(),
                        borderWidth: 0,
                    },
                    {
                        // Track for Stand
                        outerRadius: '62%',
                        innerRadius: '38%',
                        backgroundColor: Highcharts.color(
                            Highcharts.getOptions().colors[2]
                        )
                            .setOpacity(0.3)
                            .get(),
                        borderWidth: 0,
                    },
                ],
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: [],
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false,
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                },
            },
            legend,
            series: [
                {
                    name: name1,
                    data: [
                        {
                            color: Highcharts.getOptions().colors[0],
                            radius: '112%',
                            innerRadius: '88%',
                            y:
                                Number(value1?.toFixed(0)) > 100
                                    ? 100
                                    : Number(value1?.toFixed(0)),
                        },
                    ],
                    showInLegend: true,
                    tooltip: {
                        valueSuffix: '',
                    },
                },
                {
                    name: name2,
                    showInLegend: true,
                    data: [
                        {
                            color: Highcharts.getOptions().colors[1],
                            radius: '87%',
                            innerRadius: '63%',
                            y: Number(value2?.toFixed(0)),
                        },
                    ],
                },
                {
                    name: name3,
                    showInLegend: true,
                    data: [
                        {
                            color: Highcharts.getOptions().colors[2],
                            radius: '62%',
                            innerRadius: '38%',
                            y: Number(value3?.toFixed(0)),
                        },
                    ],
                },
            ],
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                        },
                        chartOptions: {
                            chart: {
                                height: '400px',
                            },
                        },
                    },
                ],
            },
        };
    }, [
        legend,
        name1,
        name2,
        name3,
        title,
        value1,
        value2,
        value3,
        valueSuffix,
    ]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
}

export { ActivityGauge };
