import React from 'react';
import {
    Box,
    Button,
    useMediaQuery,
    useTheme,
    Pagination,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TemplateItem from './TemplateItem';

const useStyles = makeStyles()((theme) => ({
    chooseContainer: {
        gap: theme.spacing(1),
        display: 'grid',
        minHeight: '280px',
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
}));

function AllTemplates({
    templates,
    setShowTemplates,
    setCurrentStep,
    setEditTemplate,
    setTemplateName,
    setClients,
    setTaskCategories,
    setStatus,
    setTemplateToUpdate,
    setSelectedTypes,
    requirementId = undefined,
    loadRequirementData = undefined,
    isRequirementCreating = false,
    setOpen,
    setHours,
    setMinutes,
    setTemplates,
    totalPages,
    page,
    setPage,
    handleCloseTemplateSelection,
    existingTemplateId = null,
}) {
    const { classes } = useStyles();
    const theme = useTheme();
    const isXSDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAddNew = () => {
        setCurrentStep(1);
        setShowTemplates(false);
        setEditTemplate(false);
    };

    const handleSelectTemplate = (template) => {
        setEditTemplate(true);
        setTemplateToUpdate(template._id);
        setTemplateName(template.name);
        setClients(template.clientIds);
        setSelectedTypes(
            template.types.map((type) => ({
                name: type.replace(/[^a-zA-Z0-9]/gi, ' '),
                value: type,
            }))
        );
        setTaskCategories(template.taskCategories);
        setStatus(template.status);
        setHours(template?.hours ?? 0);
        setMinutes(template?.minutes ?? 0);
        setCurrentStep(1);
        setShowTemplates(false);
    };

    const handlePageChange = (event, value) => {
        setPage(value - 1);
    };

    return (
        <React.Fragment>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size={isXSDevice ? 'small' : 'medium'}
                    onClick={handleAddNew}
                >
                    CREATE NEW
                </Button>
            </Box>
            <Box className={classes.chooseContainer}>
                {templates.map((template) => (
                    <TemplateItem
                        key={template.name}
                        handleSelectTemplate={handleSelectTemplate}
                        template={template}
                        requirementId={requirementId}
                        loadRequirementData={loadRequirementData}
                        isRequirementCreating={isRequirementCreating}
                        setOpen={setOpen}
                        setTemplates={setTemplates}
                        handleCloseTemplateSelection={
                            handleCloseTemplateSelection
                        }
                        existingTemplateId={existingTemplateId}
                    />
                ))}
            </Box>

            <Box className={classes.paginationContainer}>
                <Pagination
                    count={totalPages}
                    page={page + 1}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                />
            </Box>
        </React.Fragment>
    );
}

export default AllTemplates;
