import { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import hcMore from 'highcharts/highcharts-more';
import dataModule from 'highcharts/modules/export-data';
import hcAccess from 'highcharts/modules/accessibility';
import exporting from 'highcharts/modules/exporting';

if (typeof Highcharts === 'object') {
    hcMore(Highcharts);
    dataModule(Highcharts);
    hcAccess(Highcharts);
    exporting(Highcharts);
}

function GaugeSeries({
    format,
    currentVal,
    title,
    plotBands,
    tooltipSuffix,
    tickLength = 20,
    tickWidth = 2,
    hideLabels = false,
    tickPixelInterval = 20,
}) {
    const memoizedChartOpt = useMemo(() => {
        return {
            chart: {
                type: 'gauge',
                plotBackgroundColor: null,
                backgroundColor: 'transparent',
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
            },
            title: {
                text: title,
            },
            pane: {
                startAngle: -90,
                endAngle: 90,
                background: [
                    {
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                    },
                ],
                size: '80%',
            },
            credits: {
                enabled: false,
            },
            yAxis: {
                min: 0,
                max: 100,
                minorTickInterval: 10,
                minorTickWidth: 0,
                minorTickLength: 0,
                minorTickPosition: 'inside',
                minorTickColor: 'black',
                tickPixelInterval,
                tickWidth,
                tickPosition: 'inside',
                tickLength,
                tickColor: '#fff',
                labels: {
                    distance: 25,
                    style: hideLabels
                        ? { display: 'none' }
                        : {
                              fontSize: '15px',
                          },
                },
                title: {
                    text: title,
                },
                plotBands: plotBands,
            },
            series: [
                {
                    name: title,
                    data: [
                        Number(currentVal?.toFixed(1)) > 100
                            ? 100
                            : Number(currentVal?.toFixed(1)),
                    ],
                    tooltip: {
                        valueSuffix: tooltipSuffix,
                    },
                    dataLabels: {
                        format: format,
                        borderWidth: 0,
                        style: {
                            fontSize: '14px',
                            color: '#333333',
                        },
                    },
                    dial: {
                        radius: '80%',
                        backgroundColor: 'gray',
                        baseWidth: 12,
                        baseLength: '0%',
                        rearLength: '0%',
                    },
                    pivot: {
                        backgroundColor: 'gray',
                        radius: 6,
                    },
                },
            ],
        };
    }, [
        currentVal,
        format,
        hideLabels,
        plotBands,
        tickLength,
        tickPixelInterval,
        tickWidth,
        title,
        tooltipSuffix,
    ]);

    return (
        <HighchartsReact highcharts={Highcharts} options={memoizedChartOpt} />
    );
}

export { GaugeSeries };
