import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { CloseIcon } from '../../../icons';
import ListItemLoading from './ListItemLoading';
import CategoryListItem from './CategoryListItem';
import PurposeListItem from './PurposeListItem';

const useStyles = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    listContainer: {
        backgroundColor: '#f9fafb',
        paddingBlock: theme.spacing(2),
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
}));

function ConfigureDialog({
    open,
    handleClose,
    handleAddCategories,
    handleAddPurposes,
    guidanceOptId,
}) {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [purposes, setPurposes] = useState([]);
    const setAlert = useSetRecoilState(alertState);

    const fetchOptions = useCallback(async () => {
        try {
            setIsLoading(true);
            const {
                data: {
                    allOptions: { categories, purposes },
                },
            } = await axios.get('/api/guidance/all-guidance-options');
            setCategories(categories);
            setPurposes(purposes);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchOptions();
    }, [fetchOptions]);

    const onClose = () => {
        handleClose();
    };

    const handleAddNew = (type) => {
        if (type === 'categories') {
            handleAddCategories();
        } else {
            handleAddPurposes();
        }
        handleClose();
    };

    const getURL = (type) => {
        if (type === 'categories') {
            return '/api/guidance/delete-category';
        } else {
            return '/api/guidance/delete-purpose';
        }
    };

    const showDeleteSuccessAlert = (type) => {
        let message = '';
        if (type === 'categories') message = 'Category deleted successfully';
        else message = 'Purpose deleted successfully';
        setAlert({
            show: true,
            severity: severity.SUCCESS,
            message,
        });
    };

    const createDeleteURL = (type, name) => {
        const baseURL = getURL(type);
        const params = new URLSearchParams();
        params.append('_id', guidanceOptId);
        if (type === 'categories') {
            params.append('categoryName', name);
        } else {
            params.append('purposeName', name);
        }
        return `${baseURL}?${params.toString()}`;
    };

    const handleDelete = async (type, name) => {
        try {
            setIsLoading(true);
            const url = createDeleteURL(type, name);
            await axios.delete(url);
            showDeleteSuccessAlert(type);
            await fetchOptions();
        } catch (e) {
            if (e.response.status === 422) {
                setAlert({
                    show: true,
                    severity: severity.ERROR,
                    message: e.response.data.message,
                });
            } else {
                setAlert({
                    show: true,
                    severity: severity.ERROR,
                    message: "Something wen't worng",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth={'sm'}
            scroll="paper"
            onClose={onClose}
            open={open}
            fullWidth
        >
            <DialogTitle className={classes.header}>
                Configure
                <IconButton size="small" onClick={onClose} disabled={isLoading}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                {/* CATEGORIES */}
                <Box className={classes.sectionHeader}>
                    <Typography variant="h6">Categories</Typography>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => handleAddNew('categories')}
                    >
                        Add New
                    </Button>
                </Box>
                <Box className={classes.listContainer}>
                    <List>
                        {isLoading && <ListItemLoading type={'category'} />}

                        {!isLoading &&
                            categories.map((category) => (
                                <CategoryListItem
                                    key={category}
                                    category={category}
                                    fetchOptions={fetchOptions}
                                    guidanceOptId={guidanceOptId}
                                    setIsLoading={setIsLoading}
                                />
                            ))}
                    </List>
                </Box>

                {/* PURPOSES */}
                <Box mt={2}>
                    <Box className={classes.sectionHeader}>
                        <Typography variant="h6">Purposes</Typography>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => handleAddNew('purposes')}
                        >
                            Add New
                        </Button>
                    </Box>
                    <Box className={classes.listContainer}>
                        <List>
                            {isLoading && <ListItemLoading type={'purposes'} />}

                            {!isLoading &&
                                purposes.map((purpose) => (
                                    <PurposeListItem
                                        key={purpose}
                                        purpose={purpose}
                                        fetchOptions={fetchOptions}
                                        guidanceOptId={guidanceOptId}
                                        setIsLoading={setIsLoading}
                                    />
                                ))}
                        </List>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions></DialogActions>
        </Dialog>
    );
}

export default ConfigureDialog;
