import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ThirdPartyCourseSearch } from './ThirdPartyCourseSearch';
const ThirdPartyCourseSearchDialog = ({
    isOpen,
    setIsOpen,
    handleItemSelect,
}) => {
    const toggleDialog = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {/* <Button variant="contained" color="primary" onClick={toggleDialog}>
                Open Course Search
            </Button> */}
            <Dialog
                open={isOpen}
                onClose={toggleDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    Link published course
                    <IconButton
                        aria-label="close"
                        onClick={toggleDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ThirdPartyCourseSearch
                        handleItemSelect={handleItemSelect}
                    />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={toggleDialog} color="primary">
                        Close
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
};

export default ThirdPartyCourseSearchDialog;
