export const statusOptions = [
    {
        value: 'OPEN',
        label: 'OPEN',
    },
    {
        value: 'IN PROGRESS',
        label: 'IN PROGRESS',
    },
    {
        value: 'COMPLETED',
        label: 'COMPLETED',
    },
    {
        value: 'DRAFT',
        label: 'DRAFT',
    },
];

// export const outlineFormatOptions = [
//     { label: '2025 - Coursera 1 Hr Hands On Learning', value: '2025 - Coursera 1 Hr Hands On Learning' },
//     { label: 'The Hourglass Framework', value: 'The Hourglass Framework' },
//     { label: 'Hands-On Starter Kit', value: 'Hands-On Starter Kit' },
//     {
//         label: 'Step-by-Step Skill Builder',
//         value: 'Step-by-Step Skill Builder',
//     },
//     // {
//     //     label: 'Scenario-Based Learning Journey',
//     //     value: 'Scenario-Based Learning Journey',
//     // },
//     { label: 'Tool-Based Skills Sprint', value: 'Tool-Based Skills Sprint' },
//     // { label: 'Test Format', value: 'Test Format' },
// ];

export const typeOptions = [
    {
        value: 'ON-AIR',
        label: 'ON AIR',
    },
    {
        value: 'LIVE-STREAM',
        label: 'LIVE STREAM',
    },
    {
        value: 'ON-DEMAND',
        label: 'ON DEMAND',
    },
    {
        value: 'LONG-FORM',
        label: 'LONG FORM',
    },
    {
        value: 'ASSESSMENT',
        label: 'ASSESSMENT',
    },
    {
        value: 'LABS',
        label: 'LABS',
    },
    {
        value: 'GUIDED-PROJECTS',
        label: 'GUIDED PROJECTS',
    },
];

export const formatOptions = [
    { value: 'ONLINE', label: 'ONLINE' },
    { value: 'IN_PERSON', label: 'IN PERSON' },
];

export const releaseOptions = [
    { value: 'ALPHA', label: 'ALPHA' },
    { value: 'BETA', label: 'BETA' },
    { value: 'GOLD', label: 'GOLD' },
];
