import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Box, Chip, Typography, Button, Tooltip } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../app/recoil';

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        borderBottom: '1px dashed #bababa',
        marginTop: '0.5rem',
        paddingBottom: '0.5rem',
        flexWrap: 'wrap',
        // width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    left: {
        flex: 0,
        [theme.breakpoints.up('lg')]: {
            textWrap: 'nowrap',
        },
    },
    center: {
        // flex: 3,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        gap: '1%',
        overflow: 'auto',
        paddingBottom: '0.4rem',
        '&::-webkit-scrollbar': {
            height: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#e5e7eb',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d1d5db',
            borderRadius: '1rem',
            '&:hover': {
                backgroundColor: '#9ca3af',
            },
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    content: {
        whiteSpace: 'nowrap',
        border: '2px solid red',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    right: {
        marginLeft: 'auto',
        flex: 0,
        textWrap: 'nowrap',
    },
    chipContainer: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1%',
        },
    },
}));

export const ClientRequirementsSMEInput = ({
    label,
    classes,
    smes,
    onSmeClick,
    handleSMEClick,
    showAddBtn,
    field,
    requirement,
    showWO = false,
    handleReviewBtnClick = undefined,
    showReviewBtn = false,
    ...props
}) => {
    const { classes: styles } = useStyles();
    const setAlert = useSetRecoilState(alertState);

    const getWOnumber = useCallback(
        (idx) => {
            const workOrders = requirement?.workOrders;
            if (!workOrders || !workOrders?.length) return 'N/A';
            const currWO = workOrders[idx];
            if (!currWO) return 'N/A';
            return currWO.workOrder;
        },
        [requirement?.workOrders]
    );

    const getTooltip = useCallback(
        (sme, idx) => {
            const country = sme?.country ? sme.country : 'N/A';
            const email = sme?.email ? sme.email : 'N/A';
            const phone =
                sme?.mobile_number?.toString()?.length > 0
                    ? sme.mobile_number
                    : 'N/A';
            const workOrder = getWOnumber(idx);

            const commonText =
                'Country: ' +
                country +
                '\n' +
                'Email: ' +
                email +
                '\n' +
                'Phone: ' +
                phone;

            const copyText = showWO
                ? commonText + '\nWork order #: ' + workOrder
                : commonText;

            /** Copy sme info callback function */
            const handleCopy = async () => {
                if ('clipboard' in navigator) {
                    await navigator.clipboard.writeText(copyText);
                } else {
                    document.execCommand('copy', true, copyText);
                }
                setAlert({
                    show: true,
                    message: 'Copied!',
                    severity: severity.SUCCESS,
                });
            };

            return (
                <Box fontSize={12} display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography
                            variant="body2"
                            style={{ flexGrow: 1, flexShrink: 0 }}
                        >
                            Country : {country}
                        </Typography>

                        <IconButton
                            size="small"
                            style={{ flexGrow: 0, flexShrink: 1 }}
                            onClick={handleCopy}
                        >
                            <Tooltip placement="left-start" title="Copy">
                                <FileCopy
                                    size="small"
                                    style={{
                                        fontSize: '16px',
                                        color: 'whitesmoke',
                                    }}
                                />
                            </Tooltip>
                        </IconButton>
                    </Box>

                    <Typography variant="body2">Email : {email}</Typography>

                    <Typography variant="body2">Phone : {phone}</Typography>

                    {/* Work Order */}
                    {showWO && (
                        <Typography variant="body2">
                            Work order # : {workOrder}
                        </Typography>
                    )}
                </Box>
            );
        },
        [getWOnumber, setAlert, showWO]
    );

    const onReviewClick = () => {
        if (handleReviewBtnClick) {
            handleReviewBtnClick();
        }
    };

    return (
        <Box className={styles.container} {...props}>
            <div className={styles.left}>
                <Typography
                    variant="body1"
                    className={`${styles.lable} ${classes.label}`}
                >
                    {label || 'Assigned to'}:{' '}
                </Typography>
            </div>

            {smes?.length > 0 && (
                <Box className={styles.center}>
                    {/* <div className={styles.content}> */}
                    {smes?.map((sme, idx) => {
                        const displayName = sme?.firstName
                            ? sme?.firstName + ' ' + sme?.lastName
                            : sme?.email;
                        return (
                            <div
                                key={sme?._id}
                                className={styles.chipContainer}
                            >
                                <Tooltip
                                    placement="top-start"
                                    title={getTooltip(sme, idx)}
                                >
                                    <Chip
                                        style={{
                                            borderRadius: '5px',
                                            fontSize: '15px',
                                        }}
                                        label={displayName}
                                        className={classes.smeChip}
                                        onClick={() => {
                                            if (onSmeClick)
                                                onSmeClick(
                                                    sme?.firstName,
                                                    sme?.lastName,
                                                    sme?.email
                                                );
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        );
                    })}
                    {/* </div> */}
                </Box>
            )}

            {showAddBtn && (
                <div className={styles.right}>
                    <Button
                        className={styles.addBtn}
                        onClick={(e) => handleSMEClick(e, requirement, field)}
                    >
                        Add / Remove
                    </Button>
                </div>
            )}
        </Box>
    );
};
