import { useEffect, useState } from 'react';
import { Box, IconButton, Tabs, Tab } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import OMDFeeds from '.';
import { CommentSection } from '../../../comments/CommentSection';
import { requirementTasksOutputTemplate } from '../../../comments/templates';
import { visibilities } from '../../../util/visibility';
import { OMLogisticSection } from '../../OMLogisticSection';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRequirementById } from '../../slices/requirementSummarySlice';
import Team from './Team';
import OMDGuidance from '../OMDGuidance';
import MarketMetricTooltipInfo from '../ClientRequirements/MarketMetricTooltipInfo';

const useStyles = makeStyles()((theme) => {
    return {
        container: {},
        toolBar: {
            marginTop: theme.spacing(0.5),
            borderBottom: '1px solid',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderColor: theme.palette.divider,
        },
        tabContainer: {
            width: '100%',
        },
    };
});

function StreamDrawerContent({
    handleClose,
    requirementId,
    isInstructor,
    selectedRole,
    hideSwitch,
    onlyComments,
    title,
    mentionables,
    allTasks,
    teams,
    clientId,
    visibilityOptions,
    showCloseBtn = true,
    tasksOutputTemplate = undefined,
    showCkEditor = false,
    existingCommentText,
    showLogistics = false,
    starweaverAdmin = undefined,
    allCollaborators = undefined,
    team = {},
    defaultActiveTab = 0,
    isPipeline = false,
}) {
    const { classes } = useStyles();
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const [activeTabVal, setActiveTabVal] = useState(defaultActiveTab);
    const [isTabClicked, setIsTabClicked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            showLogistics &&
            // starweaverAdmin &&
            requirementSummary?.isPercentageColClicked &&
            !isTabClicked
        ) {
            setActiveTabVal(2);
        } else if (activeTabVal) {
            setActiveTabVal(activeTabVal);
        } else {
            setActiveTabVal(0);
        }
    }, [
        activeTabVal,
        isTabClicked,
        requirementSummary?.isPercentageColClicked,
        showLogistics,
        starweaverAdmin,
    ]);

    const handleTabChange = (_, newValue) => {
        setActiveTabVal(newValue);
        setIsTabClicked(true);
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.toolBar}>
                {/* tabs */}
                <Box className={classes.tabContainer}>
                    <Tabs
                        value={activeTabVal}
                        onChange={handleTabChange}
                        aria-label="Stream tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Comments" value={0} />
                        {!isPipeline && <Tab label="Team" value={3} />}
                        {!isPipeline && <Tab label="Feeds" value={1} />}
                        {!isPipeline && showLogistics && (
                            <Tab label="Logistics" value={2} />
                        )}
                        {!isPipeline && (
                            <Tab
                                label="Guidance"
                                value={4}
                                disabled={
                                    !requirementSummary.requirementData
                                        ?.templateId
                                }
                            />
                        )}
                        {isPipeline && <Tab label="Market Metrics" value={5} />}
                    </Tabs>
                </Box>

                {/* close btn */}
                {showCloseBtn && (
                    <Box>
                        <IconButton size="small" onClick={handleClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Box>
                )}
            </Box>

            <Box p={1}>
                {activeTabVal === 0 && (
                    <CommentSection
                        courseId={`om-${requirementId}`}
                        courseSlug={''}
                        courseName={title} // title of requirement
                        mentionables={mentionables}
                        tasks={allTasks}
                        teams={isInstructor ? [] : teams}
                        tasksOutputTemplate={
                            tasksOutputTemplate
                                ? tasksOutputTemplate
                                : requirementTasksOutputTemplate(requirementId)
                        }
                        visibilityOptions={visibilityOptions}
                        defaultVisibility={
                            isInstructor
                                ? visibilities.STARWEAVER_TEAM.visibility
                                : visibilities.STARWEAVER_TEAM_AND_SMES
                                      .visibility
                        }
                        clientId={clientId} // requirement.clientId
                        showCkEditor={showCkEditor}
                        existingCommentText={existingCommentText}
                        showAttachments={true}
                        showCommentAttachments={true}
                        hideCourseTagging={isInstructor}
                        showRemindOption
                    />
                )}

                {activeTabVal === 3 && <Team team={team} />}

                {activeTabVal === 1 && (
                    <OMDFeeds
                        requirementId={requirementId}
                        isInstructorView={isInstructor}
                        selectedRole={selectedRole}
                        hideSwitch={hideSwitch}
                        onlyComments={onlyComments}
                    />
                )}

                {activeTabVal === 2 && (
                    <OMLogisticSection
                        requirement={requirementSummary.requirementData}
                        loadRequirementData={(id) =>
                            dispatch(fetchRequirementById(id))
                        }
                        isInstructor={isInstructor}
                        starweaverAdmin={starweaverAdmin}
                        allCollaborators={
                            allCollaborators ? allCollaborators : []
                        }
                        isInDrawer={showLogistics}
                        readOnly={isInstructor}
                    />
                )}

                {activeTabVal === 4 && (
                    <OMDGuidance
                        logisticsTemplateId={
                            requirementSummary.requirementData?.templateId
                        }
                        hideCTA={true}
                        isInstructor={isInstructor}
                        hideDatagrid={true}
                        hideDrawer={true}
                        filterStatus={true}
                    />
                )}

                {activeTabVal === 5 && (
                    <Box>
                        <MarketMetricTooltipInfo
                            title={requirementSummary.requirementData.title}
                            isDrawer={true}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default StreamDrawerContent;
