import React, { useMemo, useState } from 'react';

import {
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    Chip,
    Container,
    InputAdornment,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    Switch,
    LinearProgress,
    Popover,
} from '@mui/material';
import { useFetchContainerFiles } from '../util';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    label: { fontWeight: 'bold' },
    statusContainer: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        // flexWrap: 'wrap',
        // gap: '2%',
        // [theme.breakpoints.up('sm')]: {
        //     flexWrap: 'nowrap',
        // },
        // '& > .input-wrapper, & > .progress-wrapper': {
        //     width: '100%',
        //     [theme.breakpoints.up('sm')]: {
        //         width: 'auto',
        //     },
        // },
        // '& > .progress-wrapper': {
        //     gap: '2%',
        // },
    },
    progressContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '250px',
            minWidth: '250px',
            width: '100%',
        },
    },
    progressText: {
        flexShrink: '0',
    },
    channelStatusPopup: {
        // position: 'absolute',
        // top: 35,
        // left: -130,
        // backgroundColor: 'white',
        padding: 15,
        // opacity: 1,
        zIndex: 999999999,
    },
    details: { marginLeft: 10 },
    progressWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        // cursor: 'help',
        gap: '0.875rem',
    },
}));

const channelStatuses = [
    { label: 'Publish', value: 'publish' },
    { label: 'Draft', value: 'draft' },
];

export const ChannelStatus = ({
    userProfile,
    userWPProfile,
    // fileData,
    channelStatus,
    handleChannelStatusChange,
    handleChannelStatusChangeSwitch,
    // setChannelStatusPopupOpen,
    // channelStatusPopupOpen,
    barHeight,
    hideProgressBar,
    hideDetails,
}) => {
    const { classes } = useStyles();

    const [channelStatusPopupOpen, setChannelStatusPopupOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
        setChannelStatusPopupOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setChannelStatusPopupOpen(false);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const {
        fileData,
        // error: fileFetchErr,
        // loading: fetchingFiles,
    } = useFetchContainerFiles(
        userProfile && userProfile.is_instructor
            ? `ins-${userProfile._id}`
            : null
    );

    const isTick = (condition) => {
        return condition ? '✔️' : '❌';
    };

    const channelStatusPercentage = useMemo(() => {
        let percent = 0;
        if (userWPProfile?.bio) percent += 10 / 6;
        if (userWPProfile?.tagLine) percent += 10 / 6;
        if (userProfile?.user_email || userProfile?.email) percent += 10 / 6;
        if (userProfile?.user_phone || userProfile?.phone) percent += 10 / 6;
        if (userProfile?.user_country || userProfile?.country)
            percent += 10 / 6;
        if (userProfile?.user_city || userProfile?.city) percent += 10 / 6;
        if (
            userProfile.user_linkedInUrl ||
            userProfile?.linkedin ||
            userProfile?.instructor_details?.linkedin
        )
            percent += 10;
        if (fileData?.size > 1) percent += 10;
        if (userWPProfile?.bannerPic || true) percent += 10;
        if (userWPProfile?.profilePicNoBackground) percent += 10;
        if (userProfile?.instructor_details?.topics?.length > 10) percent += 10;
        if (userProfile?.instructor_details?.services?.length > 1)
            percent += 10;
        if (userWPProfile?.relatedJobTitles?.length > 4) percent += 10;
        if (userWPProfile?.relatedArticles?.length > 6) percent += 10;
        if (userProfile?.paypal_details) percent += 10;
        // if (percent > 99) percent = 100;
        return percent;
    }, [userProfile, userWPProfile, fileData]);

    const disabled =
        channelStatus === 'draft' && channelStatusPercentage < 70 && false;

    return (
        <Box className={classes.statusContainer} sx={{ overflow: 'visible' }}>
            <Box className="input-wrapper">
                {/*  <Tooltip
                    title={
                        disabled ? 'Completion must be greater that 70%' : ''
                    }
                >
                    <TextField
                        value={channelStatus}
                        onChange={(e) =>
                            handleChannelStatusChange(e.target.value)
                        }
                        select
                        variant="outlined"
                        // margin="normal"

                        name="client"
                        color="secondary"
                        size="small"
                        disabled={disabled}
                    >
                        {channelStatuses.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip> */}
                {handleChannelStatusChangeSwitch && (
                    <Box minHeight={40} display="flex" alignItems="center">
                        <Tooltip
                            title={
                                channelStatus === 'publish' ? 'live' : 'draft'
                            }
                        >
                            <Switch
                                checked={channelStatus === 'publish'}
                                onChange={handleChannelStatusChangeSwitch}
                                color="secondary"
                                inputProps={{
                                    'aria-label': 'collaborator',
                                }}
                                size="small"
                            />
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <Box
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                display="flex"
                alignItems="center"
                className={classes.progressWrapper}
            >
                {!hideProgressBar && (
                    <Box className={classes.progressContainer}>
                        <LinearProgress
                            variant="determinate"
                            value={channelStatusPercentage}
                            color="secondary"
                            style={{
                                height: barHeight || 10,
                                borderRadius: '100vmax',
                            }}
                        />
                    </Box>
                )}

                <Box className={classes.progressText}>
                    <Typography variant="body2" color="textSecondary">
                        {`${Math.round(channelStatusPercentage)}%`} Completed
                    </Typography>
                </Box>

                {channelStatusPopupOpen && !hideDetails && (
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleMouseLeave}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            onMouseLeave: handleMouseLeave,
                        }}
                    >
                        <Card className={classes.channelStatusPopup}>
                            <Box
                                display="flex" /* alignItems="center" */
                                /* minWidth={500} */
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                    sx={{ flexShrink: 0 }}
                                >
                                    About me{'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}tagline
                                    {/* {`[${userWPProfile?.tagLine}]`} */}
                                    {isTick(userWPProfile?.tagLine)}, details
                                    {isTick(userWPProfile?.bio)}, email
                                    {isTick(
                                        userProfile?.user_email ||
                                            userProfile?.email
                                    )}
                                    , phone
                                    {isTick(
                                        userProfile?.user_phone ||
                                            userProfile?.phone
                                    )}
                                    , country
                                    {isTick(
                                        userProfile?.user_country ||
                                            userProfile?.country
                                    )}
                                    , city
                                    {isTick(
                                        userProfile?.user_city ||
                                            userProfile?.city
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    More details{'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}LinkedIn
                                    {isTick(
                                        userProfile?.user_linkedInUrl ||
                                            userProfile?.instructor_details
                                                ?.linkedin
                                    )}
                                    {/* , websites✔️,
                                                        YouTube channels✔️, languages
                                                        spoken✔️ */}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Introductory videos{'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    {'>'}1{isTick(fileData?.size > 1)}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Branding {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    banner image
                                    {isTick(userWPProfile?.bannerPic || true)}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Photo {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    transparent
                                    {isTick(
                                        userWPProfile?.profilePicNoBackground
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Expertise {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    {'>'}10 items
                                    {isTick(
                                        userProfile?.instructor_details?.topics
                                            ?.length > 10
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Services {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    {'>'}1 items
                                    {isTick(
                                        userProfile?.instructor_details
                                            ?.services?.length > 1
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Personas {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    {'>'}4 items
                                    {isTick(
                                        userWPProfile?.relatedJobTitles
                                            ?.length > 4
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Blog {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    {'>'}6 items
                                    {isTick(
                                        userWPProfile?.relatedArticles?.length >
                                            6
                                    )}
                                    {')'}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                /* minWidth={500} */ alignItems="center"
                            >
                                <Typography
                                    /* variant="h6" */ className={classes.label}
                                >
                                    Payout {'  '}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.details}
                                >
                                    {'('}
                                    PayPal
                                    {isTick(userProfile?.paypal_details)}
                                    {')'}
                                </Typography>
                            </Box>
                        </Card>
                    </Popover>
                )}
            </Box>
        </Box>
    );
};
