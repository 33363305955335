import {
    Box,
    Button,
    // Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    // Skeleton,
    // Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CloseIcon } from '../../../icons';
import axios from 'axios';
/* import CategoryIcon from '@mui/icons-material/Category';
import SupportIcon from '@mui/icons-material/Support'; */
import { makeStyles } from 'tss-react/mui';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
import moment from 'moment';
import { PageSearchBox } from '../../../ui';

const status = {
    live: 'LIVE',
    draft: 'DRAFT',
};

const useStyles = makeStyles()((theme) => {
    return {
        guidance: {
            display: 'flex',
            gap: theme.spacing(1),
        },
        infos: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        info: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
            marginTop: theme.spacing(1),
        },
        checkbox: {
            marginTop: '-4px',
        },
        title: {
            fontSize: '1.25rem',
            marginLeft: '0.25rem',
        },
        dialogTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };
});

function GuidancePicker({
    open,
    handleClose,
    logisticsId,
    fetchTemplateGuidance,
    rows,
    total,
    page,
    limit,
    setPage,
    setLimit,
    setTotal,
}) {
    const { classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [guidance, setGuidance] = useState([]);
    const [selectedGuidance, setSelectedGuidance] = useState([]);
    const setAlert = useSetRecoilState(alertState);
    const [searchQuery, setSearchQuery] = useState('');
    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'title']
            : [],
    });
    const fetchGuidance = useCallback(
        async (query = '') => {
            try {
                setLoading(true);
                let url = `/api/guidance/requirement-guidance`;
                const params = new URLSearchParams({
                    columnField: 'status',
                    operatorValue: 'contains',
                    value: 'live',
                    page,
                    limit,
                });

                if (query.trim().length) {
                    params.append('searchValue', query);
                }

                url = `${url}?${params.toString()}`;
                const {
                    data: { guidance, total },
                } = await axios.get(url);
                setGuidance(guidance);
                setTotal(total);
                setSelectedGuidance(rows.map((row) => row._id));
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        },
        [limit, page, rows, setTotal]
    );

    useEffect(() => {
        fetchGuidance();
    }, [fetchGuidance]);

    // useEffect(() => {
    //     if (rows?.length > 0) {
    //         setSelectedGuidance(rows.map((row) => row._id));
    //     }
    // }, [rows]);

    const columns = useMemo(() => {
        return [
            {
                field: 'title',
                headerName: 'Title',
                editable: false,
                width: 300,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'category',
                headerName: 'Category',
                editable: false,
                width: 300,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'purpose',
                headerName: 'Purpose',
                editable: false,
                width: 300,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return value;
                },
            },
            {
                field: 'createdBy',
                headerName: 'Created By',
                editable: false,
                width: 200,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return value?.fullName;
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                editable: false,
                width: 100,
                sortable: false,
                filterable: true,
                valueFormatter: (value) => {
                    return status[value];
                },
            },
            {
                field: 'updatedAt',
                headerName: 'Last Updated',
                editable: false,
                width: 200,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
            },
            {
                field: 'createdAt',
                headerName: 'Created On',
                editable: false,
                width: 200,
                sortable: false,
                filterable: false,
                valueFormatter: (value) => {
                    return moment(value).format('DD-MMM-YYYY');
                },
            },
        ];
    }, []);

    const onClose = () => {
        if (loading) return;
        handleClose();
    };

    const handleAdd = async () => {
        try {
            setIsAdding(true);
            await axios.patch('/api/guidance/associate-logistic', {
                prevGuidanceIds: rows.map((row) => row._id),
                guidanceIds: selectedGuidance,
                logisticsId,
            });
            await fetchTemplateGuidance(logisticsId);
            setAlert({
                message: 'Guidance added',
                severity: severity.SUCCESS,
                show: true,
            });
            onClose();
        } catch (e) {
            console.log(e);
            if (e.response.status === 422 || e.response.status === 400) {
                setAlert({
                    message: e.response.message,
                    severity: severity.ERROR,
                    show: true,
                });
            }
        } finally {
            setIsAdding(false);
        }
    };

    const handleClear = async () => {
        if (searchQuery.length) {
            setSearchQuery('');
            await fetchGuidance();
        }
    };

    const handleSearch = async () => {
        await fetchGuidance(searchQuery);
    };

    const handleKeyPress = (e) => {
        if (e.code === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle className={classes.dialogTitle}>
                Select guidance
                <IconButton size="small" onClick={onClose} disabled={loading}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers={true}>
                <Box mb={2}>
                    <PageSearchBox
                        searchValue={searchQuery}
                        handleClear={handleClear}
                        handleKeyPress={handleKeyPress}
                        handleSearch={handleSearch}
                        placeholder={'Search guidance'}
                        setSearchValue={setSearchQuery}
                    />
                </Box>

                <DataGridPremium
                    autoHeight
                    rows={guidance}
                    columns={columns}
                    paginationModel={{ pageSize: limit, page: page }}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    onPaginationModelChange={({ page, pageSize }) => {
                        setPage(page);
                        setLimit(pageSize);
                    }}
                    pinnedColumns={pinnedColumns}
                    onPinnedColumnsChange={(updatedPinnedColumns) => {
                        if (isLargeScreen) {
                            setPinnedColumns(updatedPinnedColumns);
                        } else {
                            alert('Pinning is not available on small screens.');
                        }
                    }}
                    checkboxSelection={true}
                    rowSelectionModel={selectedGuidance}
                    onRowSelectionModelChange={(param) => {
                        setSelectedGuidance(param);
                    }}
                    disableRowSelectionOnClick={true}
                    hideFooterRowCount={true}
                    // disableColumnMenu={true}
                    disableColumnFilter={true}
                    rowCount={total}
                    pagination
                    paginationMode="server"
                    filterMode="server"
                    loading={loading}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loading || isAdding}
                    onClick={handleAdd}
                >
                    {loading ? 'PLEASE WAIT' : 'SAVE'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GuidancePicker;
