import React, { useCallback, useState, useMemo } from 'react';
import axios from 'axios';
import { BootstrapTooltip, ListSearchAndSelect } from '../../../ui/CustomUI';
import { AvatarGroup, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSetRecoilState } from 'recoil';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions } from '@mui/material';
import { UsersSearchAndSelect } from '../../UsersSearchAndSelect';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { CloseIcon } from '../../../icons';
import { alertState, severity } from '../../../app/recoil';

const useStyles = makeStyles()((theme) => ({
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            width: theme.spacing(5),
            height: theme.spacing(5),
            backgroundColor: '#1976d2',
            fontSize: '0.9rem',
        },
        // cursor: 'pointer',
    },
    dialog: {
        minWidth: '90vw',
        padding: 40,
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
}));

const TaskAssignedTo = ({
    // handleClose,
    taskId,
    setRequirement,
    requirementId,
    categoryId,
    loadRequirementData,
    // isInstructor,
    // isCheckpoint,
    task,
    // allCollaborators,
    disabled = false,
}) => {
    const { classes } = useStyles();

    const [selectedUsers, setSelectedUsers] = useState(task.assignedTo || []);

    const [showUsersDialog, setShowUsersDialog] = useState(false);

    const [updateingTask, setUpdateingTask] = useState(false);

    const setAlert = useSetRecoilState(alertState);

    const getAssignedTooltip = useCallback((users) => {
        return users
            .map((user) => user?.firstName + ' ' + user?.lastName || '')
            .join(', ');
    }, []);

    const handleDialogClose = () => {
        setShowUsersDialog(false);
        // if (onUsersDialogClose) onUsersDialogClose();
    };

    const onClick = () => {
        if (disabled) return;
        setShowUsersDialog(true);
    };

    const handleUpdate = async () => {
        // const { isError, errors } = validateInputs();

        // if (isError) {
        //     return setAlert({
        //         show: true,
        //         severity: severity.ERROR,
        //         message: errors[0].message,
        //     });
        // }

        try {
            const payload = {
                requirementId,
                categoryId,
                taskId: task._id,
                taskData: {
                    assignedTo: selectedUsers.map((user) => user._id),
                },
            };

            setUpdateingTask(true);

            const { data } = await axios.post(
                '/api/client-requirements/edit-task-assigned-to',
                payload
            );

            await loadRequirementData(requirementId);

            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: data?.message,
            });
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: message,
            });
        } finally {
            setUpdateingTask(false);

            handleDialogClose();
        }
    };

    const getDisplayText = (item) => `${item.firstName} ${item.lastName}`;

    return (
        <div>
            {task.isActive && (
                <>
                    <Box onClick={onClick}>
                        {task?.assignedTo?.length > 0 ? (
                            <BootstrapTooltip
                                placement="top-start"
                                title={getAssignedTooltip(task.assignedTo)}
                            >
                                <AvatarGroup
                                    max={3}
                                    className={classes.avatarGroup}
                                    sx={{
                                        cursor: disabled ? 'auto' : 'pointer',
                                    }}
                                    spacing={'medium'}
                                >
                                    {task?.assignedTo?.map((user, idx) => (
                                        <Avatar
                                            alt="img"
                                            key={user._id + '-' + idx}
                                        >
                                            {user?.firstName?.substr(0, 2)}
                                        </Avatar>
                                    ))}
                                </AvatarGroup>
                            </BootstrapTooltip>
                        ) : (
                            <>
                                {disabled ? (
                                    <span>&#8212;</span>
                                ) : (
                                    <Button>Add</Button>
                                )}
                            </>
                        )}
                    </Box>

                    <Dialog
                        open={showUsersDialog}
                        onClose={handleDialogClose}
                        classes={{ paper: classes.dialog }}
                        fullWidth
                    >
                        <div className={classes.dialogContent}>
                            <Box display="flex">
                                <Typography
                                    variant="h6"
                                    style={{ marginRight: 'auto' }}
                                >
                                    Select collaborators
                                </Typography>

                                <IconButton
                                    aria-label="close"
                                    onClick={handleDialogClose}
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <DialogContent>
                                <UsersSearchAndSelect
                                    selected={selectedUsers}
                                    setSelected={setSelectedUsers}
                                    admin
                                    instructor
                                    // clientId={clientId}
                                    searchInputSize="small"
                                />
                                {/* <ListSearchAndSelect
                                    // heading={'Select collaborators'}
                                    // subHeading={subTitle}
                                    list={allCollaborators || []}
                                    selectedList={selectedUsers}
                                    setSelectedList={setSelectedUsers}
                                    idField="_id"
                                    subTextField="email"
                                    searchKeys={['firstName', 'lastName']}
                                    getDisplayText={getDisplayText}
                                    selectedListView={'list'}
                                /> */}
                                <DialogActions>
                                    <Box className={classes.dialogAction}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ margin: 0 }}
                                            onClick={handleUpdate}
                                            disabled={updateingTask}
                                        >
                                            {updateingTask
                                                ? 'Please wait'
                                                : 'Assign'}
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </DialogContent>
                        </div>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default TaskAssignedTo;
