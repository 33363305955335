import { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ListSearchAndSelect } from '../ui';
import usePersonaSearch from './usePersonaSearch';

//  css
const useStyles = makeStyles()((theme) => ({
    container: {
        // marginTop: '0.5rem',
    },
}));

const PersonaSearchAndSelect = ({
    title = 'Add personas',
    selectedPersonas = [],
    setSelectedPersonas,
    readOnly = false,
    CustomComponent = null,
    isVertical = false,
    selectedContainerText = '',
}) => {
    const { classes } = useStyles();
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const { results, hasMore, loading } = usePersonaSearch(
        searchQuery,
        pageNumber,
        9
    );

    const onClickSelected = (data) => {
        console.log(data);
        let slug = data?.title;
        if (!slug || !slug?.length || typeof slug !== 'string') return;
        slug = slug.toLowerCase().replace(/\s/gi, '-');
        window.open(`/job-roles/${slug}`, '_blank');
    };

    const onAddSearchTerm = (data) => {
        window.open(`/admin/content/personas/edit?t=${data}`, '_blank');
    };

    return (
        // <Box className={classes.container}>
        <ListSearchAndSelect
            heading={title}
            list={results}
            selectedList={selectedPersonas}
            setSelectedList={setSelectedPersonas}
            idField="_id"
            selectedListView="list"
            paginationMode="server"
            subTextField="domain"
            search={searchQuery}
            setSearch={setSearchQuery}
            setPageNumber={setPageNumber}
            loading={loading}
            hasMore={hasMore}
            canAddSearchTerm
            readOnly={readOnly}
            onClickSelected={onClickSelected}
            onAddSearchTerm={onAddSearchTerm}
            CustomComponent={
                CustomComponent !== null ? <CustomComponent /> : null
            }
            isVertical={isVertical}
            selectedContainerText={selectedContainerText}
        />
        // </Box>
    );
};

export default PersonaSearchAndSelect;
