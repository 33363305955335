import { useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { new_usersState } from '../recoil/state';
import moment from 'moment';
import { commander_id, voyager_id } from '../../plans/PlanDetails';
import axios from 'axios';
import { debounce } from '../../util/debounce';

import { addUserToAllUserObject } from '../recoil/selectors';

// can be provided as a parameter or prop
const ADMIN_URL = '/api/admin/instructors';

export const useAdminInstructorsMod = ({
    pageNumber = 1,
    // size = 50,
    size = 10,
    sortBy = 'updatedAt',
    order = 'desc',
    active = true,
    value,
    topicNames,
    currentFilters = [],
    isGlobalSearch = false,
}) => {
    const [users, setUsers] = useRecoilState(new_usersState);
    const addUser = useSetRecoilState(addUserToAllUserObject);
    const [allUsers, setAllUsers] = useState([]);
    const [fetchingAll, setFetchingAll] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    // console.log('currentFilters: ', currentFilters);

    const createFilterQuery = useCallback((filters) => {
        const query = [];
        filters.forEach((filter) => {
            query.push(
                `${filter.name}:${filter.type},${filter.operator}:${filter.value}`
            );
        });
        return query.join(',');
    }, []);

    const organizeData = useCallback(
        ({ usersRawData = [] }, usersWPDataByEmail) => {
            const updatedData = usersRawData.map((user) => {
                let teamObject = {
                    teamName: '',
                    teamIds: [],
                    vertical: '',
                };

                if (user.teams?.length) {
                    const names = [];
                    const teamIds = [];
                    const teamVerticals = [];
                    user.teams.forEach(({ name, _id, vertical }) => {
                        names.push(name);
                        teamIds.push(_id);
                        teamVerticals.push(vertical);
                    });
                    teamObject.teamName = names.join(',');
                    teamObject.teamIds = teamIds;
                    teamObject.vertical = teamVerticals.join(',');
                }
                let managerObject = {
                    managers: [],
                    managersName: [],
                };
                if (user.managers?.length) {
                    const allManagers = [];
                    const names = [];
                    user.managers.forEach(({ name, id }) => {
                        names.push(name);
                        allManagers.push(id);
                    });
                    managerObject.managers = allManagers;
                    managerObject.managersName = names;
                }
                // have to do this cause plan_name field is not consistent right now
                const planType =
                    user.user_current_plan === voyager_id
                        ? user.plan_name
                            ? user.plan_name
                            : 'Voyager'
                        : user.user_current_plan === commander_id
                        ? 'Commander'
                        : 'Astronaut';
                const credits = user.credits_id
                    ? user.credits_id.current_points
                    : 0;
                const userType = user.isAdmin
                    ? 'Admin'
                    : user.is_instructor
                    ? 'Instructor'
                    : user.client_id
                    ? 'Client'
                    : 'Student';
                const saves = user.user_save ? user.user_save : [];
                const certification = user.saved_certification_courses
                    ? user.saved_certification_courses
                    : [];
                const journey = user.saved_journey_courses
                    ? user.saved_journey_courses
                    : [];
                const userSaves = [
                    ...new Set([...saves, ...certification, ...journey]),
                ];
                const country = user.user_country || '';
                const signupDate =
                    user.createdAt ||
                    new Date().setFullYear(new Date().getFullYear() - 1);
                const lastActivity = user.last_accessed || signupDate;
                const newUserData = {
                    _id: user._id,
                    user_email: user.user_email || '',
                    slug: user.slug || '',
                    user_firstName: user.user_firstName || '',
                    user_lastName: user.user_lastName || '',
                    user_rsvps_onair: user.user_rsvps_onair || [],
                    user_rsvps_liveLabs: user.user_rsvps_liveLabs || [],
                    user_save: userSaves,
                    phone: user.user_phone,
                    country,
                    current_plan_end_date: user.user_current_plan_end_date,
                    planDetails: user?.user_current_plan_details
                        ? user.user_current_plan_details
                        : {},
                    planAmount: user?.user_current_plan_details?.amount,
                    currentPlanDate: user?.user_current_plan_date,
                    bundleCourse: user.bundleCourse,
                    saved_courses: user.saved_courses || [],
                    createdAt: moment(signupDate).format(),
                    lastActivity: moment(lastActivity).format(),
                    isDisabled: user.hasOwnProperty('isDisabled')
                        ? user.isDisabled
                        : false,
                    planType,
                    plan_name: user.plan_name,
                    projects: user.projects,
                    inProgressCount: user.inProgressCount,
                    completedCount: user.completedCount,
                    requirements: user.requirements,
                    contractNumbers:
                        user?.contractNumbers?.length > 0
                            ? user.contractNumbers
                            : ['N/A'],
                    credits,
                    userType,
                    onTrial:
                        user.user_subscription_status === 'trialing' ? 1 : 0,
                    ...managerObject,
                    ...teamObject,
                    city: user.user_city,
                    dob: user.user_dob,
                    gender: user.user_gender,
                    occupation: user.user_occupation,
                    topics:
                        user?.instructor_details?.topics
                            ?.map(({ name }) => name)
                            ?.join(', ') || '',
                    channelStatus:
                        usersWPDataByEmail[user.user_email]?.status ===
                        'publish'
                            ? 'Published'
                            : usersWPDataByEmail[user.user_email]?.status ||
                              'draft',
                    SMEengagements: user?.SMEengagements ?? [],
                    // bio: usersWPDataByEmail[user.user_email]?.bio,
                    // tagLine: usersWPDataByEmail[user.user_email]?.tagLine,
                    // bannerPic: usersWPDataByEmail[user.user_email]?.bannerPic,
                    // profilePicNoBackground:
                    //     usersWPDataByEmail[user.user_email]
                    //         ?.profilePicNoBackground,
                    // relatedJobTitles:
                    //     usersWPDataByEmail[user.user_email]?.relatedJobTitles,
                    // relatedArticles:
                    //     usersWPDataByEmail[user.user_email]?.relatedArticles,

                    userWPProfile: usersWPDataByEmail[user.user_email],
                    timeZone:
                        user.time_zone || user?.instructor_details?.time_zone,
                    linkedin:
                        user.user_linkedInUrl ||
                        user?.instructor_details?.linkedin,
                    is_instructor: user?.is_instructor,
                    instructorNotes: user?.instructorNotes,
                    instructor_details: user?.instructor_details,
                    paypal_details: user?.paypal_details,
                    tags: user?.tags,
                    expertRating: user?.expertRating,
                };
                addUser(newUserData);
                return newUserData;
            });
            return updatedData;
        },
        [addUser]
    );

    const setNewUsers = useCallback(
        // this function takes page, size, newUsers, totalNumberOfPages, and reset allUser in case of filter or sort
        (
            page,
            sizeOfPage,
            usersToAdd = [],
            totalNumberUsers,
            resetUsers = false
        ) => {
            let newUsers = [];
            const startIndex = (page - 1) * sizeOfPage;
            let usersAdded = 0;
            const usersLength = usersToAdd.length;
            if (users.length !== totalNumberUsers) {
                newUsers = new Array(totalNumberUsers)
                    .fill(null)
                    .map((val, index) => {
                        if (usersAdded < usersLength && index >= startIndex) {
                            const userToBeReturned = usersToAdd[usersAdded];
                            usersAdded++;
                            return userToBeReturned;
                        } else {
                            return null;
                        }
                    });
                setUsers(newUsers);
            } else {
                // console.log('resetting-users -> ' + resetUsers);
                let newUsers = resetUsers ? [] : [...users];
                for (let i = 0; i < usersLength; i++) {
                    newUsers[startIndex + i] = usersToAdd[i];
                }
                setUsers(newUsers);
            }
        },
        [users, setUsers]
    );

    const getFilterName = useCallback((name) => {
        switch (name) {
            case 'firstName':
                return 'user_firstName';
            case 'lastName':
                return 'user_lastName';
            case 'email':
                return 'user_email';
            case 'planType':
                return 'plan_name';
            case 'onTrial':
                return 'user_subscription_status';
            case 'lastActivity':
                return 'last_accessed';
            case 'country':
                return 'user_country';
            case 'topics':
                return 'topics';
            case 'channelStatus':
                return 'channelStatus';
            case 'contractNumbers':
                return 'contractNumbers';
            case 'SMEengagements':
                return 'SMEengagements';
            case 'tags':
                return 'tags';
            default:
                return '';
        }
    }, []);

    const getUsers = useCallback(
        async (
            providedPage,
            refreshUsers = false,
            filters = [],
            addTimeStamp = false,
            sortBy = 'updatedAt',
            order = 'desc'
        ) => {
            // fetch request
            if (isLoading) return;
            setIsLoading(true);
            try {
                console.log('fetching-users...');

                const pageToStartWith = providedPage
                    ? providedPage
                    : pageNumber;
                const userProvided = refreshUsers ? null : totalUsers;
                let filterQuery = '';
                if (typeof filters !== 'undefined' && filters.length > 0) {
                    filterQuery = createFilterQuery(filters);
                }
                // console.log(
                //     `${ADMIN_URL}?size=${size}&page=${pageToStartWith}&total=${userProvided}&sort=${sortBy}&order=${order}&active=${active}${
                //         filters.length > 0 ? `&filterQuery=${filterQuery}` : ''
                //     }`
                // );
                const { data } = await axios(
                    `${ADMIN_URL}?size=${size}&page=${pageToStartWith}&total=${userProvided}&sort=${sortBy}&order=${order}&active=${active}${
                        filterQuery ? `&filterQuery=${filterQuery}` : ''
                    }${
                        isGlobalSearch
                            ? `&isGlobalSearch=${isGlobalSearch}`
                            : ''
                    }${
                        addTimeStamp ? `&timestamp=${Date.now()}` : ''
                    }&instructorsOnly=true${
                        topicNames ? `&topicNames=${topicNames.join('|')}` : ''
                    }`
                );
                // console.log(data.users);
                // const resWP = await axios.get(
                //     `${
                //         process.env.REACT_APP_SW_WORDPRESS_DOMAIN
                //     }/wp-json/headless/instructor/${data.users?.map(
                //         ({ user_email }) => user_email
                //     )}?status=any&returnMany=1&nowprocket=1`
                // );

                const instructorIds = data.users
                    .map(({ _id }) => _id)
                    .join(',');

                const resWP = await axios.get(
                    `/api/channels?ids=${instructorIds}&status=any`
                );

                const usersWPData = resWP.data?.items || [];
                // console.log(usersWPData);
                const usersWPDataByEmail = {};
                usersWPData.forEach((usersWPData) => {
                    if (!usersWPData?.email) return;
                    usersWPDataByEmail[usersWPData?.email] = usersWPData;
                });

                const [techbytesRes, jobRolesRes] = await Promise.all([
                    axios.get(`/api/techbytes?instructorIds=${instructorIds}`),
                    axios.get(
                        `/api/job-roles?instructorIds=${instructorIds}&limit=nolimit`
                    ),
                ]);

                const relatedArticlesById = techbytesRes.data.articles.reduce(
                    (acc, article) => {
                        article.instructors.forEach((instructorId) => {
                            if (!acc[instructorId]) acc[instructorId] = [];
                            acc[instructorId].push(article);
                        });
                        return acc;
                    },
                    {}
                );

                const relatedJobTitlesById = jobRolesRes.data.jobRoles.reduce(
                    (acc, jobRole) => {
                        jobRole.instructors.forEach((instructorId) => {
                            if (!acc[instructorId]) acc[instructorId] = [];
                            acc[instructorId].push(jobRole);
                        });
                        return acc;
                    },
                    {}
                );

                data.users.forEach((user) => {
                    const userId = user._id;
                    if (usersWPDataByEmail[user.user_email]) {
                        usersWPDataByEmail[user.user_email].relatedArticles =
                            relatedArticlesById[userId] || [];
                        usersWPDataByEmail[user.user_email].relatedJobTitles =
                            relatedJobTitlesById[userId] || [];
                        usersWPDataByEmail[user.user_email].tagLine =
                            user?.instructor_details?.tag_line;
                    }
                });

                // group all these
                setTotalUsers(data.totalUsers);
                const formattedData = organizeData(
                    {
                        usersRawData: data.users,
                    },
                    usersWPDataByEmail
                );

                // const draftChannelUserIds = formattedData
                //     .filter((user) => user.channelStatus !== 'publish')
                //     .map((user) => user._id);

                // const publishChannelUserIds = formattedData
                //     .filter((user) => user.channelStatus === 'publish')
                //     .map((user) => user._id);
                // console.log({
                //     draftChannelUserIds,
                //     publishChannelUserIds,
                // });
                // await axios.post('/api/test/sync-channel-status', {
                //     draftChannelUserIds,
                //     publishChannelUserIds,
                // });
                // console.log(formattedData);
                setNewUsers(
                    pageNumber,
                    size,
                    formattedData,
                    data.totalUsers,
                    filterQuery ? true : false
                );
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setIsLoading(false);
            }
        },
        [
            pageNumber,
            totalUsers,
            createFilterQuery,
            size,
            active,
            topicNames,
            organizeData,
            setNewUsers,
            isGlobalSearch,
            isLoading,
        ]
    );

    /* const debouncedGetUsers = useCallback(
        debounce((page, filters) => getUsers(page, false, filters), 500),
        [getUsers]
    ); */

    useEffect(() => {
        // debouncedGetUsers(pageNumber, currentFilters);
        getUsers(pageNumber, false, currentFilters, false, sortBy, order);
        // }, [pageNumber, size, currentFilters]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilters, pageNumber, sortBy, order]);

    // useEffect(() => {
    //     if (users.length) {
    //         const start = (pageNumber - 1) * size;
    //         const userRequired = users.slice(start, start + size);
    //         if (userRequired.some((val) => val === null)) {
    //             getUsers(pageNumber, false, currentFilters);
    //         }
    //     } else {
    //         getUsers(pageNumber, false, currentFilters);
    //     }
    //     // eslint-disable-next-line
    // }, [pageNumber, size, currentFilters, users]);

    // useEffect(() => {
    //     getUsers(pageNumber, false, currentFilters);
    //     // eslint-disable-next-line
    // }, [active, pageNumber, currentFilters]);

    const fetchAll = useCallback(async () => {
        setFetchingAll(true);
        try {
            if (!allUsers.length) {
                const { data } = await axios.get('/api/admin/user/short/all/');
                data.users.forEach((userData) => addUser(userData));
                setAllUsers(data.users);
                setFetchingAll(false);
                return data.users;
            }
        } catch (error) {
            console.log('error-in-fetching-all-users');
        }
        setFetchingAll(false);
    }, [allUsers, addUser]);

    const new_userToReturn = useMemo(() => {
        if (users.length) {
            const start = (pageNumber - 1) * size;
            const userRequired = users.slice(start, start + size);
            return userRequired.filter((val) => val !== null);
            // if (userRequired.some((val) => val === null)) {
            //     return [];
            // } else {
            //     return userRequired;
            // }
        } else {
            return [];
        }
    }, [users, pageNumber, size]);

    const totalPages = useMemo(() => {
        return Math.ceil(totalUsers / size);
    }, [totalUsers, size]);

    return {
        // users lined with the page
        users: new_userToReturn,
        fullFetchedUsers: users,
        totalUsers,
        isLoading,
        updateUsers: getUsers,
        fetchAll,
        allUsers,
        fetchingAll,
        totalPages,
        setAllUsers,
    };
};
