import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: theme.shadows['1'],
        padding: theme.spacing(4),
        borderRadius: '8px',
        outline: 'none',
    },
}));

export default function TimelineResetReasonModal({
    open,
    setOpen,
    expectedEndDate,
    reasonForChangeEndDate,
    handleReasonChange,
    handleDateChangeConfirm,
}) {
    const { classes } = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.container}>
                <Box mb={3}>
                    <Typography gutterBottom>
                        Delivery Date: {expectedEndDate}
                    </Typography>
                </Box>
                <TextField
                    label="Reason"
                    multiline
                    rows={4}
                    value={reasonForChangeEndDate}
                    onChange={handleReasonChange}
                    fullWidth
                    required
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDateChangeConfirm}
                        color="secondary"
                        variant="contained"
                        sx={{ ml: 2 }}
                    >
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
