import { useMemo, useState } from 'react';
import { MoreHorizonIcon } from '../icons';
import { Box, IconButton, Menu, MenuItem } from '../ui';
import RemindModal from './CommentRemindModal';
import RemindMeOption from './RemindMeOption';

export const CommentMoreOptions = ({
    user,
    blockAccess = () => console.log('block-access'),
    toggleOpenReport = () => console.log('comment-report'),
    deleteComment = () => console.log('comment-delete'),
    starweaverAdmin,
    isReviewOwner,
    isCurrentUser,
    editComment,
    commentId,
    showRemindOption = false,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showRemindModal, setShowRemindModal] = useState(false);
    const showDelBtn = useMemo(() => {
        return starweaverAdmin || isReviewOwner || isCurrentUser;
    }, [isCurrentUser, isReviewOwner, starweaverAdmin]);

    const handleClick = (event) => {
        if (!user) {
            blockAccess();
            return;
        }
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReport = () => {
        toggleOpenReport();
        handleClose();
    };

    const handleDelete = () => {
        deleteComment();
        handleClose();
    };

    const handleEdit = () => {
        editComment();
        handleClose();
    };

    const handleRemind = () => {
        setShowRemindModal(true);
        handleClose();
    };

    return (
        <>
            <Box>
                {/* {(!isCurrentUser || starweaverAdmin) && ( */}
                {(isCurrentUser || starweaverAdmin) && (
                    <IconButton
                        size="small"
                        // disableElevation
                        aria-controls="simple-menu"
                        onClick={handleClick}
                    >
                        <MoreHorizonIcon fontSize="small" />
                    </IconButton>
                )}
                {/* --- REMIND MODAL --- */}
                <RemindModal
                    open={showRemindModal}
                    setShowRemindModal={setShowRemindModal}
                    commentId={commentId}
                />
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    disableScrollLock
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {!isCurrentUser && (
                        <MenuItem onClick={handleReport}>
                            Report this comment
                        </MenuItem>
                    )}
                    {isCurrentUser && (
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    )}
                    {showDelBtn && (
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    )}
                    {showRemindOption && Boolean(anchorEl) && (
                        <RemindMeOption
                            handleRemind={handleRemind}
                            commentId={commentId}
                            handleClose={handleClose}
                        />
                    )}
                </Menu>
            </Box>
        </>
    );
};
