import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import EmptyImg from '../../../img/empty.svg';

const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'grid',
        placeItems: 'center',
    },
    progressContainer: {
        width: '100%',
    },
}));

function NoTemplates({ handleAddTemplates, isLoading, hideCTA }) {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <img
                alt="empty-img"
                src={EmptyImg}
                height={200}
                width={200}
                loading="lazy"
            />

            <Typography variant="body1">No templates found</Typography>

            {!hideCTA && (
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleAddTemplates}
                        disabled={isLoading}
                    >
                        Add Templates
                    </Button>
                </Box>
            )}
        </div>
    );
}

export default NoTemplates;
