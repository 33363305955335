import { useMemo } from 'react';
import { TeamCard } from '../../../ui/CustomUI';
import { roleToHeader } from '../../../util';
import { makeStyles } from 'tss-react/mui';
import NoTeamImg from '../../../img/no-team-assigned.png';
import { Typography } from '@mui/material';

const useStyles = makeStyles()((theme) => {
    return {
        cards: {
            marginTop: '1rem',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr) )',
                gap: '1rem',
            },
        },
        noTeam: {
            display: 'grid',
            placeContent: 'center',
            width: '100%',
            gridColumnStart: 1,
            gridColumnEnd: -1,
            textAlign: 'center',
            marginTop: '1rem',
        },
    };
});

function Team({ team }) {
    const { classes } = useStyles();
    const teams = useMemo(() => {
        let result = [];
        for (const [role, users] of Object.entries(team)) {
            if (users.length > 0) {
                result.push(
                    ...users.map((user) => ({
                        ...user,
                        role: roleToHeader[role],
                    }))
                );
            }
        }
        return result;
    }, [team]);

    const handleTeamMemberClick = (userId) => {
        console.log(userId);
    };

    return (
        <div className={classes.cards}>
            {teams.length > 0 ? (
                teams.map((user) => (
                    <TeamCard
                        key={user._id}
                        imgURL={user?.profilePicUrl || ''}
                        linkedin={user?.linkedin || ''}
                        mail={user?.email || ''}
                        name={user.firstName + ' ' + user.lastName}
                        role={user.role}
                        onCardClick={handleTeamMemberClick}
                        _id={user._id}
                    />
                ))
            ) : (
                <div className={classes.noTeam}>
                    <img
                        src={NoTeamImg}
                        alt="no-team-avaliable"
                        height={200}
                        width={300}
                    />
                    <Typography>No team assigned</Typography>
                </div>
            )}
        </div>
    );
}

export default Team;
