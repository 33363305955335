import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DateRangeModal from './DateRangeModal';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

const useStyles = makeStyles()((theme) => ({
    capsuleWrapper: {
        width: 'fit-content',
        marginInline: 'auto',
        padding: '0.3rem 0.8rem',
        borderRadius: '100vmax',
        fontSize: '0.875rem',
        fontWeight: 500,
        color: 'hsl(0deg 0% 100%)',
        cursor: 'pointer',
        textAlign: 'center',
        minHeight: '2rem',
        minWidth: '8.125rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 150ms ease-in',
        gap: '0.25rem',
        '&:hover .hideOnHover': {
            display: 'none',
        },
        '& > .showOnHover': {
            display: 'none',
        },
        '&:hover .showOnHover': {
            display: 'inline',
        },
    },
    capsuleIcon: {
        color: 'hsl(0deg 0% 100%)',
    },
}));

export default function TimelineCapsule({
    startDate,
    endDate,
    duration,
    color,
    isDone,
    isOverDue,
    expectedEndDate,
    taskId,
    requirementId,
    setRequirement,
    isTaskActive,
    starweaverAdmin,
    showAssignedUserView = undefined,
    getAssignedUserGroupData = undefined,
    showRoleView = undefined,
    fetchLogisticsByRole = undefined,
    showChronologyView = undefined,
    fetchLogisticsByChronology = undefined,
    filterBy = '',
    isInDrawer=false,
    loadRequirementData=undefined
}) {
    const { classes } = useStyles();
    const [showDatePicker, setShowDatePicker] = useState(false);

    const handleCapsuleClick = () => {
        setShowDatePicker(true);
    };

    const handleClose = () => {
        setShowDatePicker(false);
    };

    return (
        <div>
            {/* Date range picker */}
            {showDatePicker && (
                <DateRangeModal
                    open={showDatePicker}
                    handleClose={handleClose}
                    timelineStartDate={startDate}
                    timelineEndDate={endDate}
                    expectedEndDate={expectedEndDate}
                    taskId={taskId}
                    requirementId={requirementId}
                    setRequirement={setRequirement}
                    starweaverAdmin={starweaverAdmin}
                    showAssignedUserView={showAssignedUserView}
                    getAssignedUserGroupData={getAssignedUserGroupData}
                    showRoleView={showRoleView}
                    fetchLogisticsByRole={fetchLogisticsByRole}
                    showChronologyView={showChronologyView}
                    fetchLogisticsByChronology={fetchLogisticsByChronology}
                    filterBy={filterBy}
                    isInDrawer={isInDrawer}
                    loadRequirementData={loadRequirementData}
                />
            )}
            <div
                className={classes.capsuleWrapper}
                style={{
                    backgroundColor: color,
                    cursor: 'pointer',
                }}
                onClick={handleCapsuleClick}
            >
                {isDone && (
                    <CheckCircleOutlineIcon
                        fontSize="small"
                        className={classes.capsuleIcon}
                    />
                )}

                {isOverDue && !isDone && (
                    <ErrorOutlineIcon
                        fontSize="small"
                        className={classes.capsuleIcon}
                    />
                )}

                {!isOverDue && !isDone && (
                    <AccessAlarmsIcon
                        fontSize="small"
                        className={classes.capsuleIcon}
                    />
                )}

                {/* start date */}
                <span className="hideOnHover">
                    {moment(startDate).format('MMM DD')}
                </span>

                <span className="hideOnHover">&nbsp;&#8208;&nbsp;</span>

                {/* end date */}
                <span className="hideOnHover">
                    {moment(endDate).format('MMM DD')}
                </span>

                {/* duration */}
                <span className="showOnHover">{duration}d</span>
            </div>
        </div>
    );
}
