import { Box, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import { getBaseURL } from '../../../util';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';
import { ShareModal } from '../../../ui/CustomUI';

function SummaryHeading({ isInstructor }) {
    const requirementSummary = useSelector((state) => state.requirementSummary);
    // const setAlert = useSetRecoilState(alertState);
    const [openModal, setOpenModal] = useState(false);

    /* const getCopyText = () => {
        const requirementId = requirementSummary.requirementData.requirementId;
        const pathname = isInstructor
            ? `/profile/instructor-dashboard/opportunity-management/details/${requirementId}`
            : `/admin/opportunity-management/details/${requirementId}`;
        const baseURL = getBaseURL(pathname);
        return baseURL;
    }; */

    /* const showAlertText = () => {
        setAlert({
            severity: severity.SUCCESS,
            show: true,
            message: 'Link copied',
        });
    }; */

    /* const handleLinkClick = async () => {
        const copyText = getCopyText();

        try {
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(copyText);
            } else {
                document.execCommand('copy', true, copyText);
            }
            showAlertText();
        } catch (e) {
            console.log(e);
        }
    };
 */
    return (
        <Box pt={2} display="flex" alignItems="center">
            {/* share modal */}
            {openModal && (
                <ShareModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    title={requirementSummary.requirementData?.title}
                    id={requirementSummary.requirementData._id}
                />
            )}

            <Typography variant="h5">
                {requirementSummary.requirementData.title}
            </Typography>

            {!isInstructor && (
                <IconButton
                    // onClick={handleLinkClick}
                    onClick={() => setOpenModal(true)}
                    size="small"
                    sx={{ ml: 1 }}
                    color="primary"
                >
                    <ShareIcon color="primary" />
                </IconButton>
            )}
        </Box>
    );
}

export default SummaryHeading;
