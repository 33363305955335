import { Box, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        fieldContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            borderBottom: '1px dashed',
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            borderColor: theme.palette.grey['300'],
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                flexWrap: 'wrap',
            },
        },
        truncateText: {
            overflow: 'hidden',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            // width: '100%',
        },
        summaryLabel: {
            flexShrink: 0,
        },
        link: {
            color: 'blue',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };
});

function RequirementSumamrySimpleField({ value, label, isLoading, ...props }) {
    const { classes } = useStyles();
    return (
        <Box className={classes.fieldContainer} {...props}>
            <Typography
                data-testid="requirement-summary-label"
                className={classes.summaryLabel}
                fontWeight={500}
            >
                {label}:
            </Typography>
            {isLoading ? (
                <Skeleton
                    variant="text"
                    sx={{ fontSize: '1rem', width: '100px' }}
                />
            ) : (
                <>
                    {props.isLink ? (
                        <a
                            className={`${classes.link} ${classes.truncateText}`}
                            href={value}
                            target="_blank"
                            rel="noreferrer"
                            data-testid="requirement-summary-link"
                        >
                            {value}
                        </a>
                    ) : (
                        <Typography
                            data-testid="requirement-summary-value"
                            className={`${classes.summaryLabel} ${classes.truncateText}`}
                        >
                            {value}
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
}

export default RequirementSumamrySimpleField;
