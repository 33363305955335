import React, { useState } from 'react';

import { ListSearchAndSelect } from '../ui';

import { useSMEsSearch } from './useSMEsSearch';

export const SMEsSearchAndSelect = ({
    title,
    subTitle,
    selected,

    setSmeModal,
    onClickSelected,
    selectedListView,
    nextStageSMEsSelected,
    nextStageSMEField,
    // addedSearchTerms,
    // setAddedSearchTerms,
    // skillErr,
    // setSkillErr,
    // resetError = undefined,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const { results, hasMore, loading, error } = useSMEsSearch(
        searchQuery,
        pageNumber,
        5
    );

    const handleSelect = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (prev.smes.findIndex((elem) => elem._id === item._id) === -1) {
                return { ...prev, smes: [item, ...prev.smes] };
            } else {
                return prev;
            }
        });
    };

    const handleDelete = (itemToDelete) => {
        setSmeModal((prev) => ({
            ...prev,
            smes: prev.smes.filter((item) => item._id !== itemToDelete._id),
        }));

        if (nextStageSMEsSelected)
            setSmeModal((prev) => {
                return {
                    ...prev,
                    nextStageSMEs: prev.nextStageSMEs?.filter(
                        (s) => s._id !== itemToDelete._id
                    ),
                };
            });
    };

    const handleSelectWithinSelect = (item) => {
        //console.log(item);
        setSmeModal((prev) => {
            if (
                prev.nextStageSMEs.findIndex(
                    (elem) => elem._id === item._id
                ) === -1
            ) {
                return {
                    ...prev,
                    nextStageSMEs: [item, ...prev.nextStageSMEs],
                };
            } else {
                return {
                    ...prev,
                    nextStageSMEs: prev.nextStageSMEs?.filter(
                        (s) => s._id !== item._id
                    ),
                };
            }
        });
    };

    const getDisplayText = (item) => `${item.firstName} ${item.lastName}`;

    return (
        <ListSearchAndSelect
            heading={title}
            subHeading={subTitle}
            list={results}
            selectedList={selected}
            handleItemSelect={handleSelect}
            handleItemDelete={handleDelete}
            idField="_id"
            subTextField="email"
            paginationMode="server"
            search={searchQuery}
            setSearch={setSearchQuery}
            setPageNumber={setPageNumber}
            loading={loading}
            hasMore={hasMore}
            getDisplayText={getDisplayText}
            onClickSelected={onClickSelected}
            selectedListView={selectedListView}
            selectedWithinSelected={nextStageSMEsSelected}
            showSelectionWithinSelection={nextStageSMEField}
            handleSelectWithinSelect={handleSelectWithinSelect}
            selectedWithinSelectedTooltip={`Check to ${nextStageActions[nextStageSMEField]} this instructor to the requirement.`}
        />
    );
};

const nextStageActions = {
    shortListedSmes: 'shortlist',
    smes: 'assign',
};
