import { useState } from 'react';
import { ListSearchAndSelect } from '../ui';
import { useTerms } from './useTerms';
import axios from 'axios';

export const TagsSearchAndSelect2 = ({
    title,
    subTitle,
    selectedTags,
    setSelectedTags,
    addedSearchTerms,
    setAddedSearchTerms,
    skillErr,
    setSkillErr,
    resetError = undefined,
    readOnly = false,
    CustomComponent = null,
    isVertical = false,
    selectedContainerText = '',
}) => {
    const [tagsSearchQuery, setTagsSearchQuery] = useState('');
    const [tagsPageNumber, setTagsPageNumber] = useState(1);

    const {
        results: tags,
        hasMore: hasMoreTags,
        loading: tagsLoading,
        error,
    } = useTerms(tagsSearchQuery, tagsPageNumber - 1, 10);

    const onAddSearchTerm = async (tagsSearchQuery) => {
        if (!tagsSearchQuery) return;

        try {
            const response = await axios.post('/api/terms/create-terms', {
                name: tagsSearchQuery,
            });
            const newTerm = response.data.term;

            setTagsSearchQuery('');
            setTagsPageNumber(1);

            // Ensure no duplicate selection
            setSelectedTags((prevList) => {
                const exists = prevList.some((tag) => tag._id === newTerm._id);
                return exists
                    ? prevList
                    : [...prevList, { ...newTerm, _id: newTerm._id }];
            });
        } catch (error) {
            console.error('Error adding new term:', error);
        } finally {
        }
    };

    // const handleTagSelect = (item) => {
    //     //console.log(item);
    //     setSelectedTags((prevList) => {
    //         if (
    //             prevList.findIndex((elem) => elem.term_id === item.term_id) ===
    //             -1
    //         ) {
    //             return [item, ...prevList];
    //         } else {
    //             return prevList;
    //         }
    //     });
    // };

    // const handleTagDelete = (itemToDelete) => {
    //     console.log(itemToDelete);
    //     setSelectedTags((items) =>
    //         items.filter((item) => item.term_id !== itemToDelete.term_id)
    //     );
    // };

    return (
        <ListSearchAndSelect
            heading={title || 'Tags'}
            subHeading={subTitle}
            list={tags}
            selectedList={selectedTags}
            setSelectedList={setSelectedTags}
            // handleItemSelect={handleTagSelect}
            // handleItemDelete={(item) => {
            //     handleTagDelete(item);
            // }}
            idField="_id"
            paginationMode="server"
            search={tagsSearchQuery}
            setSearch={setTagsSearchQuery}
            setPageNumber={setTagsPageNumber}
            loading={tagsLoading}
            hasMore={hasMoreTags}
            canAddSearchTerm
            addedSearchTerms={addedSearchTerms}
            setAddedSearchTerms={setAddedSearchTerms}
            onAddSearchTerm={onAddSearchTerm}
            skillErr={skillErr}
            setSkillErr={setSkillErr}
            resetError={resetError}
            readOnly={readOnly}
            CustomComponent={
                CustomComponent !== null ? <CustomComponent /> : null
            }
            isVertical={isVertical}
            selectedContainerText={selectedContainerText}
        />
    );
};
