import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { addTaskData } from '../../slices/requirementSummarySlice';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState, severity } from '../../../app/recoil';
import RequirementSumamryTaskStatusField from './RequirementSummaryTaskStatusField';

const RED = '#ef4444';
const BLUE = '#818cf8';
const GREEN = '#3dc970';

const useStyles = makeStyles()((theme) => {
    return {
        fieldContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            borderBottom: '1px dashed',
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            borderColor: theme.palette.grey['300'],
        },
        summaryLabel: {
            flexShrink: 0,
        },
    };
});

function RequirementSumamryTaskField({ value, label, ...props }) {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const requirementSummary = useSelector((state) => state.requirementSummary);
    const [isLoading, setIsLoading] = useState(false);
    const setAlert = useSetRecoilState(alertState);

    const dateColor = useMemo(() => {
        const metricDate = moment(value);
        const currentDate = moment();
        const isDone = requirementSummary.taskData?.isDone;
        if (isDone) {
            return GREEN;
        }

        if (currentDate.isAfter(metricDate) && !isDone) {
            return RED;
        }

        return BLUE;
    }, [requirementSummary.taskData?.isDone, value]);

    const getUpdatedNumOfDays = (date) => {
        return moment(requirementSummary.requirementData?.expectedEndDate).diff(
            moment(date),
            'days'
        );
    };

    const showAlert = () => {
        setAlert({
            show: true,
            message: 'Successfully updated task date',
            severity: severity.SUCCESS,
        });
    };

    const storeNewUpdatedDate = async (date) => {
        const updatedNumOfDays = getUpdatedNumOfDays(date);
        const payload = {
            taskId: requirementSummary.taskData._id,
            requirementId: requirementSummary.requirementData.requirementId,
            days: updatedNumOfDays,
        };
        await axios.post('/api/client-requirements/update-task-days', payload);
        showAlert();
    };

    const updateTaskData = (date) => {
        dispatch(
            addTaskData({
                ...requirementSummary.taskData,
                metricDate: moment(date).format('DD-MMM-YYYY'),
            })
        );
    };

    const handleChange = async (e) => {
        try {
            setIsLoading(true);
            const date = e.target.value;
            await storeNewUpdatedDate(date);
            updateTaskData(date);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box className={classes.fieldContainer} {...props}>
            <Typography className={classes.summaryLabel} fontWeight={500}>
                {label}:
            </Typography>
            {!requirementSummary.taskData?.isDone ? (
                <input
                    type="date"
                    name="task"
                    className={classes.summaryLabel}
                    value={value}
                    onChange={handleChange}
                    disabled={isLoading}
                    style={{ color: dateColor }}
                />
            ) : (
                <Typography
                    className={classes.summaryLabel}
                    sx={{ color: dateColor }}
                >
                    Done
                </Typography>
            )}

            <RequirementSumamryTaskStatusField
                value={requirementSummary.taskData?.isDone}
            />
        </Box>
    );
}

export default RequirementSumamryTaskField;
