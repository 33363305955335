import {
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ClientRequirementsSMEInput } from '../../ClientRequirementsSMEInput';

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: '49% 49%',
                gap: '2%',
                alignItems: 'center',
            },
        },
        taskContainer: {
            borderBottom: '1px dashed #bababa',
            paddingBottom: theme.spacing(1),
            marginBlock: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                margin: 0,
                alignItems: 'center',
                gap: '2%',
            },
        },
        label: {
            fontWeight: 400,
            fontSize: '1.0625rem',
            letterSpacing: '0.00938em',
            margin: 0,
            padding: 0,
            width: '20%',
            [theme.breakpoints.up('md')]: {
                width: '10%',
            },
        },
        inputWrapper: {
            [theme.breakpoints.up('md')]: {
                width: '90%',
            },
        },
        userContainer: {
            [theme.breakpoints.up('md')]: {
                marginBottom: '0.3rem',
            },
        },
    };
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function TaskUserAssociation({
    selectedRequirementIds = [],
    selectedOptions = [],
    setSelectedOptions,
    styles,
    selectedTaskUsers = [],
    handleSMEClick2,
}) {
    const { classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [taskOptions, setTaskOptions] = useState([]);

    const getTaskOptions = useCallback(async () => {
        try {
            setLoading(true);
            const baseURL = '/api/client-requirements/tasks-by-reqIds';
            const params = new URLSearchParams({
                ids: selectedRequirementIds.join('|'),
            });
            const apiURL = baseURL + '?' + params.toString();
            const {
                data: { taskOptions },
            } = await axios.get(apiURL);
            setTaskOptions(taskOptions);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [selectedRequirementIds]);

    useEffect(() => {
        getTaskOptions();
    }, [getTaskOptions]);

    const handleTaskChange = (event) => {
        setSelectedOptions(event.target.value);
    };

    return (
        <div className={classes.container}>
            <div className={classes.taskContainer}>
                <p className={classes.label}>Tasks:</p>
                <div className={classes.inputWrapper}>
                    <FormControl
                        color="secondary"
                        variant="outlined"
                        size="small"
                        sx={{ width: '100%' }}
                    >
                        <Select
                            multiple={true}
                            value={selectedOptions}
                            onChange={handleTaskChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                                selected.map((s) => s.label).join(', ')
                            }
                            MenuProps={MenuProps}
                            fullWidth
                            disabled={loading}
                        >
                            {taskOptions.map((option) => {
                                return (
                                    <MenuItem
                                        key={option._id}
                                        value={option}
                                        disabled={loading}
                                    >
                                        <Checkbox
                                            checked={
                                                selectedOptions
                                                    .map((o) => o.value)
                                                    .indexOf(option.value) > -1
                                            }
                                            disabled={loading}
                                        />
                                        <ListItemText primary={option.label} />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div className={classes.userContainer}>
                <ClientRequirementsSMEInput
                    label="Users"
                    classes={styles}
                    smes={selectedTaskUsers}
                    handleSMEClick={handleSMEClick2}
                    showAddBtn
                    field="selectedTaskUsers"
                    showReviewBtn={false}
                />
            </div>
        </div>
    );
}

export default TaskUserAssociation;
