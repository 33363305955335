import { makeStyles } from 'tss-react/mui';
import { useAllCompanies } from '../../useAllCompanies';

const useStyles = makeStyles()((theme) => ({
    input: {
        border: 0,
        outline: '1.5px solid #9ca3af',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        padding: '0.5rem',
        borderRadius: '0.25rem',
        width: '100%',
        flexShrink: 0,
        flexGrow: 1,
        '&:focus': {
            outline: '2px solid #f97316',
        },
    },
}));

// This component is only meant to be worked with TableFilterModal.jsx
export default function ClientOptions({
    setSelectedClientName,
    selectedClientName,
}) {
    const { classes } = useStyles();
    const { companiesOption: options } = useAllCompanies();

    const handleChange = (e) => {
        setSelectedClientName(e.target.value);
    };

    return (
        <select
            placeholder="Select client name"
            className={classes.input}
            value={selectedClientName}
            onChange={handleChange}
            required
        >
            {selectedClientName === '' && (
                <option value="" disabled>
                    <em>--- Select client name ---</em>
                </option>
            )}

            {options.map((option) => {
                return (
                    <option value={option.client_name} key={option._id}>
                        {option.client_name}
                    </option>
                );
            })}
        </select>
    );
}
