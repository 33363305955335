import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Card,
    Checkbox,
    Fade,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Modal,
    Select,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    CheckBox,
    CheckBoxOutlineBlank,
    Close,
    Search,
} from '@mui/icons-material';
import moment from 'moment';
/* import { skipWeekend } from '../util'; */
import axios from 'axios';
import { alertState, severity } from '../app/recoil';
import { useSetRecoilState } from 'recoil';
import { Autocomplete } from '../ui';

let timeoutId = null;

const taskTypeMenuItems = [
    {
        id: 'SME-Sourcing',
        value: 'SME-Sourcing',
    },
    {
        id: 'Client Administration',
        value: 'Client Administration',
    },
    {
        id: 'Program Management - Planning',
        value: 'Program Management - Planning',
    },
    {
        id: 'Production - Elearning',
        value: 'Production - Elearning',
    },
    {
        id: 'Program Management - LMS',
        value: 'Program Management - LMS',
    },
    {
        id: 'Distribution',
        value: 'Distribution',
    },
    {
        id: 'Marketing',
        value: 'Marketing',
    },
    {
        id: 'Production - Live Content',
        value: 'Production - Live Content',
    },
];
const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 4, 0),
        [theme.breakpoints.down('md')]: {
            padding: '1rem',
        },
    },
    customTaskContainer: {
        width: '60vw',
        maxWidth: '600px',
        margin: 'auto',
        paddingTop: '1rem',
        [theme.breakpoints.down('md')]: {
            width: '80vw',
            maxWidth: '100vw',
            minHeight: 'unset',
            height: '80vh',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
    },
    stepper: {
        '& .MuiStepConnector-line': {
            borderColor: 'red',
        },
    },
    stepIcon: {
        color: '#00000061 !important',
    },
    stepperBtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
    },
    groupWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
    rowReverse: {
        flexDirection: 'row-reverse',
    },
    inputGroup: {
        marginBottom: '0.5rem',
        flexBasis: '48%',
        '& label': {
            fontSize: '1rem',
            marginBottom: '0.25rem',
        },
        [theme.breakpoints.down('md')]: {
            flexBasis: '100%',
        },
    },
    newTaskFormContainer: {
        height: 'fit-content',
        maxHeight: '70vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingRight: '0.3rem',
        paddingBottom: '1rem',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4bfbf',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px #d6d6d6',
            borderRadius: '10px',
        },
    },
}));

export const CreateNewTaskModal = ({
    openModal,
    setOpenModal,
    categories,
    releaseDate,
    requirementId,
    loadRequirementData,
}) => {
    const { classes } = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [categoryNameSearchString, setCategoryNameSearchString] = useState(
        ''
    );
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [taskName, setTaskName] = useState('');
    const [numOfDaysBeforeDelivery, setNumOfDaysBeforeDelivery] = useState('');
    const [numOfDays, setNumOfDays] = useState(0);
    const [taskType, setTaskType] = useState('SME-Sourcing');
    const [validationErr, setValidationErr] = useState([]);
    const setAlert = useSetRecoilState(alertState);
    const [creatingTask, setCreatingTask] = useState(false);
    const [isCheckpointTask, setIsCheckpointTask] = useState(false);
    const [newCheckpointName, setNewCheckpointName] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [assignedTo, setAssignedTo] = useState([]);
    const [searchingUser, setSearchingUser] = useState(false);
    const [duration, setDuration] = useState(0);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [cost, setCost] = useState('');

    useEffect(() => {
        if (releaseDate) {
            /* setNumOfDaysBeforeDelivery(
                skipWeekend(moment(releaseDate)).format('YYYY-MM-DD')
            ); */
            setNumOfDaysBeforeDelivery(
                moment(releaseDate).format('YYYY-MM-DD')
            );
        } else {
            /* setNumOfDaysBeforeDelivery(
                skipWeekend(moment()).format('YYYY-MM-DD')
            ); */
            setNumOfDaysBeforeDelivery(moment().format('YYYY-MM-DD'));
        }
    }, [releaseDate]);

    useEffect(() => {
        if (timeoutId) window.clearTimeout(timeoutId);
        let cancel = () => {};

        timeoutId = setTimeout(() => {
            setSearchingUser(true);
            axios({
                method: 'GET',
                url: `/api/client-requirements/logistics-users`,
                params: {
                    searchValue,
                },
                cancelToken: new axios.CancelToken((c) => (cancel = c)),
            })
                .then((res) => {
                    setUserOptions(res.data?.users ?? []);
                    setSearchingUser(false);
                })
                .catch((e) => {
                    console.log(e);
                    setSearchingUser(false);
                });
        }, 1000);

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
            cancel();
        };
    }, [searchValue]);

    const categoryList = useMemo(() => {
        return categories.filter((category) => !category.isHidden);
    }, [categories]);

    const searchResults = useMemo(() => {
        if (categoryNameSearchString.trim().length === 0) return categoryList;

        const filteredCategories = categoryList.filter((category) => {
            if (
                category.categoryName
                    .toLowerCase()
                    .includes(categoryNameSearchString.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        });

        return filteredCategories;
    }, [categoryList, categoryNameSearchString]);

    const formattedReleaseDate = useMemo(() => {
        if (releaseDate) {
            return moment(releaseDate).format('DD-MMM-YYYY');
        } else {
            return 'N/A';
        }
    }, [releaseDate]);

    const validationErrObj = useCallback(
        (param) => {
            const filteredError = validationErr.filter(
                (err) => err?.param === param
            );

            if (filteredError.length > 0) {
                return { isError: true, filteredError };
            } else {
                return { isError: false, filteredError };
            }
        },
        [validationErr]
    );

    const onCategorySelect = (category) => {
        setSelectedCategory(category);
        setActiveStep(1);
    };

    const onBackClick = () => {
        setSelectedCategory(null);
        setActiveStep((prev) => prev - 1);
    };

    const resetValidationError = (name) => {
        if (validationErr.length > 0) {
            setValidationErr((prev) => {
                return prev.filter((err) => err.param !== name);
            });
        }
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onTaskNameChange = (e) => {
        setTaskName(e.target.value);
        resetValidationError(e.target.name);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onDateChange = (e) => {
        // const date = skipWeekend(moment(e.target.value));
        const date = moment(e.target.value);
        setNumOfDaysBeforeDelivery(moment(date).format('YYYY-MM-DD'));
        const days = moment(releaseDate).diff(date, 'days');
        setNumOfDays(days);
        setDuration(moment(date).diff(startDate, 'days'));
    };

    const onStartDateChange = (e) => {
        // const date = skipWeekend(moment(e.target.value));
        const date = moment(e.target.value);
        setStartDate(moment(date).format('YYYY-MM-DD'));
        const days = moment(numOfDaysBeforeDelivery).diff(date, 'days');
        setDuration(days);
    };

    const onDurationChange = (e) => {
        const value = e.target.value;
        setDuration(value);
        /* setStartDate(
            skipWeekend(
                moment(numOfDaysBeforeDelivery).subtract(value, 'days')
            ).format('YYYY-MM-DD')
        ); */
        setStartDate(
            moment(numOfDaysBeforeDelivery)
                .subtract(value, 'days')
                .format('YYYY-MM-DD')
        );
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onNumOfDaysChange = (e) => {
        const days = e.target.value;
        setNumOfDays(days);
        /* const date = skipWeekend(moment(releaseDate).subtract(days, 'days')); */
        const date = moment(releaseDate).subtract(days, 'days');
        setNumOfDaysBeforeDelivery(moment(date).format('YYYY-MM-DD'));
        /* setStartDate(
            skipWeekend(moment(date).subtract(duration, 'days')).format(
                'YYYY-MM-DD'
            )
        ); */
        setStartDate(
            moment(date).subtract(duration, 'days').format('YYYY-MM-DD')
        );
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onTaskTypeChange = (e) => {
        setTaskType(e.target.value);
        resetValidationError(e.target.name);
    };

    const validateInputs = () => {
        const errors = [];
        if (taskName.trim().length === 0) {
            errors.push({
                message: 'Task name is required',
                param: 'taskName',
            });
        }

        if (taskType.trim().length === 0) {
            errors.push({
                message: 'Task type is required',
                param: 'taskType',
            });
        }

        if (numOfDaysBeforeDelivery.trim().length === 0) {
            errors.push({
                message: 'Metric date is required',
                param: 'numOfDaysBeforeDelivery',
            });
        }

        if (startDate.trim().length === 0) {
            errors.push({
                message: 'Start date is required',
                param: 'startDate',
            });
        }

        if (isCheckpointTask && newCheckpointName.trim().length === 0) {
            errors.push({
                message: 'New checkpoint name is required',
                param: 'newCheckpointName',
            });
        }

        return { isError: errors.length > 0, errors };
    };

    const onCreate = async () => {
        const { isError, errors } = validateInputs();

        if (isError) {
            return setValidationErr(errors);
        }

        try {
            const payload = {
                requirementId,
                categoryId: selectedCategory._id,
                taskData: {
                    name: taskName,
                    typeOfTask: taskType,
                    isActive: true,
                    numOfDaysBeforeDelivery: numOfDays,
                    completedAt: null,
                    status: null,
                    checkpointName: isCheckpointTask ? newCheckpointName : null,
                    pinToTop: false,
                    assignedTo: assignedTo.map((user) => ({
                        _id: user._id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                    })),
                    duration,
                    cost: cost.trim().length === 0 ? null : cost,
                },
            };

            setCreatingTask(true);

            const { data } = await axios.post(
                '/api/client-requirements/add-task',
                payload
            );

            await loadRequirementData(requirementId);

            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: data?.message,
            });
        } catch (e) {
            const message = e?.response?.data?.message ?? e?.message;
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: message,
            });
        } finally {
            setCreatingTask(false);
            setTaskName('');
            setTaskType('');
            setNumOfDays(0);
            setNumOfDaysBeforeDelivery('');
            setOpenModal(false);
            setNewCheckpointName('');
            setIsCheckpointTask(false);
            setStartDate('');
            setDuration(0);
            setCost('');
            setAssignedTo([]);
        }
    };

    const handleModalClose = () => {
        if (creatingTask) return;
        setOpenModal(false);
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onCheckboxClick = (e) => {
        const checked = e.target.checked;
        setIsCheckpointTask(checked);
        if (checked === false && newCheckpointName.length > 0)
            setNewCheckpointName('');
    };

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onNewCheckpointNameChange = (e) => {
        setNewCheckpointName(e.target.value);
        resetValidationError(e.target.name);
    };

    const onUserNameType = (e) => {
        setSearchValue(e.target.value);
    };

    const onUserSelected = (e, newVal) => {
        setAssignedTo(newVal);
    };

    const onCostChange = (e) => {
        setCost(e.target.value);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-custom-task"
            aria-describedby="transition-modal-custom-task"
            className={classes.modal}
            open={openModal}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={openModal}>
                <div className={classes.paper}>
                    <Box className={classes.customTaskContainer}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <IconButton onClick={handleModalClose} size="small">
                                <Close color="default" />
                            </IconButton>
                        </Box>
                        <Stepper orientation="vertical" activeStep={activeStep}>
                            <Step key="chooseCategory">
                                <StepLabel
                                    StepIconProps={{
                                        classes: { root: classes.stepIcon },
                                    }}
                                >
                                    Select category
                                </StepLabel>
                                <StepContent>
                                    <TextField
                                        id="categoryName"
                                        name="categoryName"
                                        className="search-field"
                                        variant="outlined"
                                        color="secondary"
                                        placeholder="Search category"
                                        value={categoryNameSearchString}
                                        onChange={(e) =>
                                            setCategoryNameSearchString(
                                                e.target.value
                                            )
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                            /* endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button>Add</Button>
                                                </InputAdornment>
                                            ), */
                                        }}
                                        fullWidth
                                    />

                                    <Card className="search-result">
                                        {searchResults?.length > 0 ? (
                                            <List>
                                                {searchResults?.map(
                                                    (category) => {
                                                        return (
                                                            <ListItem
                                                                button
                                                                key={
                                                                    category._id
                                                                }
                                                                onClick={() =>
                                                                    onCategorySelect(
                                                                        category
                                                                    )
                                                                }
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        category.categoryName
                                                                    }
                                                                />
                                                            </ListItem>
                                                        );
                                                    }
                                                )}
                                            </List>
                                        ) : (
                                            <List>
                                                <ListItem>
                                                    No such category exists
                                                </ListItem>
                                            </List>
                                        )}
                                    </Card>
                                </StepContent>
                            </Step>
                            <Step key="create new task">
                                <StepLabel
                                    StepIconProps={{
                                        classes: { root: classes.stepIcon },
                                    }}
                                >
                                    Create new task
                                </StepLabel>
                                <StepContent>
                                    <Box
                                        mt={1}
                                        className={classes.newTaskFormContainer}
                                    >
                                        <Box className={classes.inputGroup}>
                                            <label htmlFor="selectedCategory">
                                                Selected category
                                            </label>
                                            <TextField
                                                id="selectedCategory"
                                                value={
                                                    selectedCategory?.categoryName
                                                }
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Box>

                                        {/* Release date */}

                                        <Box className={classes.inputGroup}>
                                            <label htmlFor="releaseDate">
                                                Release date
                                            </label>
                                            <TextField
                                                id="releaseDate"
                                                value={formattedReleaseDate}
                                                type="date"
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                error={!releaseDate}
                                                helperText={
                                                    !releaseDate
                                                        ? 'Release date is required'
                                                        : ''
                                                }
                                            />
                                        </Box>

                                        {/* New task name */}
                                        <Box
                                            className={`${classes.groupWrapper} ${classes.rowReverse}`}
                                        >
                                            <Box className={classes.inputGroup}>
                                                <label htmlFor="taskName">
                                                    New task name
                                                </label>
                                                <TextField
                                                    id="taskName"
                                                    name="taskName"
                                                    type="text"
                                                    value={taskName}
                                                    onChange={onTaskNameChange}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    placeholder="Enter new task name"
                                                    size="small"
                                                    error={
                                                        validationErrObj(
                                                            'taskName'
                                                        ).isError
                                                    }
                                                    helperText={
                                                        validationErrObj(
                                                            'taskName'
                                                        ).filteredError[0]
                                                            ?.message
                                                    }
                                                />
                                            </Box>

                                            <FormControl
                                                size="small"
                                                className={classes.inputGroup}
                                            >
                                                <label
                                                    htmlFor="taskType"
                                                    style={{ margin: 0 }}
                                                >
                                                    Task Type
                                                </label>
                                                <Select
                                                    labelId="taskType"
                                                    id="taskType"
                                                    value={taskType}
                                                    variant="outlined"
                                                    color="secondary"
                                                    displayEmpty
                                                    onChange={onTaskTypeChange}
                                                    error={
                                                        validationErrObj(
                                                            'taskType'
                                                        ).isError
                                                    }
                                                    inputProps={{
                                                        placeholder: 'test',
                                                    }}
                                                >
                                                    {taskTypeMenuItems.map(
                                                        (item) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.value}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                                {validationErrObj('taskType')
                                                    .filteredError[0]?.message
                                                    ?.length > 0 && (
                                                    <FormHelperText>
                                                        {
                                                            validationErrObj(
                                                                'taskType'
                                                            ).filteredError[0]
                                                                ?.message
                                                        }
                                                    </FormHelperText>
                                                )}
                                                {/* <TextField
                                                    id="taskType"
                                                    name="taskType"
                                                    type="text"
                                                    value={taskType}
                                                    onChange={onTaskTypeChange}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    placeholder="Enter task type"
                                                    error={
                                                        validationErrObj(
                                                            'taskType'
                                                        ).isError
                                                    }
                                                    helperText={
                                                        validationErrObj(
                                                            'taskType'
                                                        ).filteredError[0]
                                                            ?.message
                                                    }
                                                /> */}
                                            </FormControl>
                                        </Box>

                                        {/* Assigned to */}
                                        <Box className={classes.inputGroup}>
                                            <label htmlFor="assignedTo">
                                                Assigned to
                                            </label>
                                            {searchingUser && (
                                                <LinearProgress color="secondary" />
                                            )}
                                            <Autocomplete
                                                options={userOptions}
                                                getOptionLabel={(option) =>
                                                    option?.firstName +
                                                        ' ' +
                                                        option?.lastName || ''
                                                }
                                                multiple
                                                disableCloseOnSelect
                                                id="assignedTo"
                                                size="small"
                                                onInputChange={onUserNameType}
                                                onChange={onUserSelected}
                                                renderInput={(params) => (
                                                    <TextField
                                                        id="users"
                                                        name="users"
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                                renderOption={(
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlank fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBox fontSize="small" />
                                                            }
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                        />
                                                        <Box>
                                                            <Typography>
                                                                {option?.firstName +
                                                                    ' ' +
                                                                    option?.lastName ||
                                                                    ''}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {option?.email}
                                                            </Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                )}
                                            />
                                        </Box>

                                        <Box className={classes.groupWrapper}>
                                            {/* duration */}
                                            <Box className={classes.inputGroup}>
                                                <label htmlFor="numOfDays">
                                                    Duration
                                                </label>
                                                <TextField
                                                    id="numOfDays"
                                                    name="numOfDays"
                                                    type="number"
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    value={duration}
                                                    onChange={onDurationChange}
                                                    size="small"
                                                />
                                            </Box>

                                            <Box className={classes.inputGroup}>
                                                <label htmlFor="startDate">
                                                    start date
                                                </label>
                                                <TextField
                                                    id="startDate"
                                                    name="startDate"
                                                    type="date"
                                                    value={startDate}
                                                    onChange={onStartDateChange}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={classes.groupWrapper}>
                                            {/* Num of days before delivery */}
                                            <Box className={classes.inputGroup}>
                                                <label htmlFor="numOfDays">
                                                    # Days
                                                </label>
                                                <TextField
                                                    id="numOfDays"
                                                    name="numOfDays"
                                                    type="number"
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    value={numOfDays}
                                                    onChange={onNumOfDaysChange}
                                                    size="small"
                                                />
                                            </Box>

                                            <Box className={classes.inputGroup}>
                                                <label htmlFor="numOfDaysBeforeDelivery">
                                                    Metric date
                                                </label>
                                                <TextField
                                                    id="numOfDaysBeforeDelivery"
                                                    name="numOfDaysBeforeDelivery"
                                                    type="date"
                                                    value={
                                                        numOfDaysBeforeDelivery
                                                    }
                                                    onChange={onDateChange}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={classes.inputGroup}>
                                            <label htmlFor="cost">Cost</label>
                                            <TextField
                                                id="cost"
                                                name="cost"
                                                value={cost}
                                                onChange={onCostChange}
                                                variant="outlined"
                                                color="secondary"
                                                fullWidth
                                                size="small"
                                            />
                                        </Box>

                                        {isCheckpointTask && (
                                            <Box className={classes.inputGroup}>
                                                <label htmlFor="numOfDaysBeforeDelivery">
                                                    New Checkpoint Name
                                                </label>
                                                <TextField
                                                    id="newCheckpointName"
                                                    name="newCheckpointName"
                                                    type="text"
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth={true}
                                                    size="small"
                                                    value={newCheckpointName}
                                                    onChange={
                                                        onNewCheckpointNameChange
                                                    }
                                                    error={
                                                        validationErrObj(
                                                            'newCheckpointName'
                                                        ).isError
                                                    }
                                                    helperText={
                                                        validationErrObj(
                                                            'newCheckpointName'
                                                        ).filteredError[0]
                                                            ?.message
                                                    }
                                                />
                                            </Box>
                                        )}

                                        {/* Checkbox */}
                                        <Box className={classes.inputGroup}>
                                            <FormControlLabel
                                                label="Set this task as checkpoint task"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            isCheckpointTask
                                                        }
                                                        onChange={
                                                            onCheckboxClick
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'primary checkbox',
                                                        }}
                                                        color="secondary"
                                                        size="small"
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Box
                                            mt={1}
                                            textAlign={'right'}
                                            className={classes.stepperBtnGroup}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onBackClick}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={onCreate}
                                                disabled={
                                                    !releaseDate || creatingTask
                                                }
                                            >
                                                {creatingTask
                                                    ? 'Please wait'
                                                    : 'Create'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};
