import { useState, useMemo } from 'react';
import {
    Box,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    Paper,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Skeleton from '@mui/material/Skeleton';
import { DeleteForever, GetApp } from '@mui/icons-material';
import { AzureBlobStorage } from '../util/BlobStorage';
import { alertState, severity } from '../app/recoil';
import { useSetRecoilState } from 'recoil';
import { PreviewModal } from './PreviewModal';

const allowedFileTypes = ['IMAGE', 'VIDEO', 'PDF'];

const useStyles = makeStyles()((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    challengePaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    challengeTitle: {
        'font-size': '1.25rem',
        color: '#374151',
        letterSpacing: '0.04rem',
        lineHeight: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    challengeBtnGroup: {
        paddingBlock: '0.5rem',
        marginTop: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '4%',
    },
    fileContainer: {
        backgroundColor: 'white',
        padding: '8px',
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        marginBottom: '1rem',
        border: '1px solid #aeaeae',
        borderRadius: '4px',
        transition: 'all 200ms ease-in-out',
        '&:hover': {
            boxShadow: '0px 2px 4px 1px #aeaeae',
        },
        '& > .icon-container': {
            height: '60px',
            width: '60px',
            flexShrink: '0',
            [theme.breakpoints.down('sm')]: {
                height: '40px',
                width: '40px',
            },
            '& > img': {
                height: '100%',
                width: '100%',
            },
        },
        '& .file-name': {
            fontSize: '17.5px',
            fontWeight: '400',
            letterSpacing: '0.5px',
            overflowWrap: 'anywhere',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        '& .view-details': {
            fontSize: '1rem',
            textDecoration: 'underline',
            color: theme.palette.primary.dark,
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
    },
}));

export const FileViewContainer = ({
    pwd,
    fetchingFiles,
    fileData,
    containerName,
    setFileData,
    setDownloadProgress,
    isInstructor,
    forceHideDelBtn = false,
    showDetails = false,
    viewDetailsClick = undefined,
}) => {
    const { classes } = useStyles();
    const [showDeleteChallenge, setShowDeleteChallenge] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [isDownloading, setIsDownloading] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [currentFile, setCurrentFile] = useState({});
    const setAlert = useSetRecoilState(alertState);
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));

    //  ----------------------- hooks: start ----------------------
    const hideDelBtn = useMemo(() => {
        if (forceHideDelBtn) return true;

        if (pwd?.includes('COMMON') && isInstructor === true) {
            return true;
        } else {
            return false;
        }
    }, [forceHideDelBtn, isInstructor, pwd]);
    //  ----------------------- hooks: end ----------------------

    const onDeleteClick = (file) => {
        setSelectedFile(file);
        setShowDeleteChallenge(true);
    };

    const onCancel = () => {
        setShowDeleteChallenge(false);
    };

    const onDeleteConfirm = async () => {
        try {
            const azureBlobStorage = new AzureBlobStorage();
            setIsDeleting(true);
            const { fileNames, size } = await azureBlobStorage.deleteFile(
                selectedFile?.actual,
                containerName,
                pwd.join('/')
            );

            setFileData({
                size,
                fileNames,
            });

            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: 'File deleted successfully',
            });
        } catch (e) {
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: e?.message,
            });
        } finally {
            setIsDeleting(false);
            onCancel();
        }
    };

    const onDownload = async (file) => {
        try {
            const azureBlobStorage = new AzureBlobStorage();
            setIsDownloading(true);
            const url = await azureBlobStorage.downloadFile(
                file?.actual,
                containerName,
                setDownloadProgress
            );
            if (!url) throw new Error('Something went worng');
            const a = document.createElement('a');
            const id = Math.random();
            a.setAttribute('id', id);
            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.download = file?.actual;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setAlert({
                show: true,
                severity: severity.SUCCESS,
                message: 'Download successful',
            });
        } catch (e) {
            setAlert({
                show: true,
                severity: severity.ERROR,
                message: e?.message,
            });
        } finally {
            setDownloadProgress(0);
            setIsDownloading(false);
        }
    };

    const handlePreview = async (file) => {
        try {
            if (allowedFileTypes.includes(file.type) === false) return;

            setCurrentFile(file);
            setShowPreviewModal(true);
        } catch (e) {
            setShowPreviewModal(false);
        }
    };

    const viewDetails = (e, file) => {
        e.stopPropagation();
        if (viewDetailsClick) {
            viewDetailsClick(file);
        }
    };

    if (fetchingFiles) {
        return (
            <>
                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>

                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>

                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>

                <Box mb={2}>
                    <Skeleton
                        variant="rectangular"
                        animation={'wave'}
                        height={60}
                    />
                </Box>
            </>
        );
    }

    return (
        <Box>
            <Modal
                open={showDeleteChallenge}
                className={classes.modal}
                // BackdropComponent={Backdrop}
                // BackdropProps={{ timeout: 500 }}
                aria-labelledby="challenge-modal"
                aria-describedby="challenge-modal-description"
                closeAfterTransition
            >
                <Fade in={showDeleteChallenge}>
                    <Paper elevation={0} className={classes.challengePaper}>
                        <Box>
                            <Typography
                                variant="h4"
                                className={classes.challengeTitle}
                            >
                                Are you sure you want to delete this file?
                            </Typography>
                            <Box className={classes.challengeBtnGroup}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    onClick={onCancel}
                                    disabled={isDeleting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="secondary"
                                    onClick={onDeleteConfirm}
                                    disabled={isDeleting}
                                >
                                    {isDeleting ? 'Please wait...' : 'Confirm'}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Fade>
            </Modal>

            {/* preview modal: start */}
            {showPreviewModal && (
                <PreviewModal
                    showPreviewModal={showPreviewModal}
                    setShowPreviewModal={setShowPreviewModal}
                    currentFile={currentFile}
                    containerName={containerName}
                />
            )}

            {fileData.fileNames.map((file, idx) => {
                return (
                    <Box
                        key={file.formattedName + idx}
                        className={classes.fileContainer}
                        style={{
                            cursor: allowedFileTypes.includes(file.type)
                                ? 'pointer'
                                : 'default',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handlePreview(file);
                        }}
                    >
                        <div className="icon-container">
                            <img
                                src={file.icon}
                                alt="file icon"
                                height={60}
                                width={60}
                            />
                        </div>

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                        >
                            <Typography className="file-name">
                                {file.formatted}
                            </Typography>
                            {showDetails && (
                                <Typography
                                    className="view-details"
                                    onClick={(e) => viewDetails(e, file)}
                                >
                                    View Details
                                </Typography>
                            )}
                        </Box>

                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            style={{ marginLeft: 'auto' }}
                        >
                            <Tooltip title="download">
                                <IconButton
                                    style={{ padding: 0, margin: 0 }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onDownload(file);
                                    }}
                                    disabled={isDownloading || isDeleting}
                                    size="large"
                                >
                                    <GetApp
                                        fontSize={
                                            isExtraSmall ? 'small' : 'medium'
                                        }
                                    />
                                </IconButton>
                            </Tooltip>

                            {hideDelBtn === false && (
                                <Tooltip title="delete">
                                    <IconButton
                                        style={{
                                            padding: 0,
                                            margin: '0 0 0 4px',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onDeleteClick(file);
                                        }}
                                        disabled={isDownloading || isDeleting}
                                        fontSize={
                                            isExtraSmall ? 'small' : 'medium'
                                        }
                                        size="large"
                                    >
                                        <DeleteForever />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
