import { useEffect, useMemo, useState } from 'react';
import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CommentSection } from '../../../comments/CommentSection';
import { useTADs } from '../../hooks/useTADs';
import { getUniqueObjectsByField } from '../../../util';
import axios from 'axios';
import { COMMENTS } from '../../requirementConstants';
import { useIsAdmin } from '../../useIsAdmin';
import { visibilities } from '../../../util/visibility';
import { requirementTasksOutputTemplate } from '../../../comments/templates';
import FeedCardHeader from './FeedCardHeader';
import DummyInput from './DummyInput';
import profilePicPlaceholder from '../../../img/profilePicPlaceholder.jpg';

const useStyles = makeStyles()((theme) => {
    return {
        card: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
        },
        commentContainer: {
            margin: '0.5rem',
            [theme.breakpoints.up('sm')]: {
                margin: '1.25rem',
            },
        },
        btnGroup: {
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'flex-end',
            gap: '0.5rem',
        },
    };
});

function FeedCard({
    row,
    userDetails,
    setRows,
    isInstructorView,
    selectedRole,
    teams,
}) {
    const { classes } = useStyles();
    const { admins: tads } = useTADs();
    const [showEditor, setShowEditor] = useState(false);
    const [showCkEditor, setShowCkEditor] = useState(false);
    const { starweaverAdmin } = useIsAdmin();

    useEffect(() => {
        if (row?.isCommentAvailable) {
            setShowEditor(true);
        }
    }, [row?.isCommentAvailable]);

    const mentionables = useMemo(() => {
        const { requirement } = row;

        const smes = requirement?.smes || [];

        const allAssociates = [...smes, ...tads];
        const uniqueAssociates = getUniqueObjectsByField(
            allAssociates,
            'email'
        );
        return (
            uniqueAssociates
                .filter(({ email }) => userDetails?.user_email !== email)
                .map(
                    ({
                        _id,
                        firstName,
                        lastName,
                        email,
                        profilePicUrl,
                        profilePicUrlWP,
                    }) => ({
                        id: _id,
                        name: `${firstName} ${lastName}`,
                        _name: `${firstName} ${lastName}`,
                        email,
                        profilePicUrl:
                            profilePicUrl ||
                            profilePicUrlWP ||
                            profilePicPlaceholder,
                    })
                ) || []
        );
    }, [row, tads, userDetails?.user_email]);

    const allTasks = useMemo(() => {
        const tasks = [];

        // Traverse through each category
        row?.requirement?.taskCategories?.forEach((category) => {
            // Traverse through each task in the category
            category?.tasks
                ?.filter(({ isActive, isVisibleToInstructor }) =>
                    isInstructorView
                        ? isActive && isVisibleToInstructor
                        : isActive
                )
                ?.forEach(({ _id, name }) => {
                    tasks?.push({ id: _id, name, _name: name });
                });
        });

        return tasks;
    }, [row, isInstructorView]);

    const handleShowCkEditor = () => {
        setShowCkEditor(true);
    };

    const handleHideCkEditor = () => {
        setShowCkEditor(false);
    };

    const handleToggleEditor = () => {
        setShowEditor((prev) => {
            if (prev === true) {
                setRows((prevRows) => {
                    return prevRows.map((prevRow) => {
                        if (prevRow._id === row._id) {
                            return { ...prevRow, isCommentAvailable: !prev };
                        } else {
                            return prevRow;
                        }
                    });
                });
            }
            return !prev;
        });
    };

    const afterCommentAdded = async (comments = []) => {
        try {
            const isCommentAvailable = comments.length > 0;
            const feedId = row._id;
            const baseURL = '/api/client-requirements/feed-comment';
            const params = new URLSearchParams();
            params.append('isCommentAvailable', isCommentAvailable);
            params.append('commentCount', comments?.length ?? 0);
            params.append('feedId', feedId);
            const url = `${baseURL}?${params.toString()}`;
            await axios.get(url);
        } catch (e) {
            console.log(e);
        }
    };

    const visibilityOptions = isInstructorView
        ? [
              {
                  option: visibilities.STARWEAVER_TEAM.visibility,
                  icon: visibilities.STARWEAVER_TEAM.icon,
              },
          ]
        : [
              {
                  option: visibilities.STARWEAVER_TEAM_AND_SMES.visibility,
                  icon: visibilities.STARWEAVER_TEAM_AND_SMES.icon,
              },
              {
                  option: visibilities.STARWEAVER_TEAM.visibility,
                  icon: visibilities.STARWEAVER_TEAM.icon,
              },
              ...(starweaverAdmin && row?.clientId
                  ? [
                        {
                            option: visibilities.CLIENT.visibility,
                            icon: visibilities.CLIENT.icon,
                        },
                    ]
                  : []),
              {
                  option: visibilities.ADMINS.visibility,
                  icon: visibilities.ADMINS.icon,
              },

              ...(starweaverAdmin
                  ? [
                        {
                            option: visibilities.EVERYONE.visibility,
                            icon: visibilities.EVERYONE.icon,
                        },
                        {
                            option: visibilities.SELECTED_USERS.visibility,
                            icon: visibilities.SELECTED_USERS.icon,
                        },
                        {
                            option: visibilities.ONLY_ME.visibility,
                            icon: visibilities.ONLY_ME.icon,
                        },
                    ]
                  : []),
          ];

    return (
        <Card elevation={2} className={classes.card}>
            <FeedCardHeader
                isInstructorView={isInstructorView}
                row={row}
                selectedRole={selectedRole}
                showEditor={showEditor}
                handleToggleEditor={handleToggleEditor}
                handleHideCkEditor={handleHideCkEditor}
                hideCollapseBtn={row.field !== COMMENTS}
            />
            <div /* className={classes.commentContainer} */>
                {
                    showEditor ? (
                        <div className={classes.commentContainer}>
                            <div className={classes.editor}>
                                <CommentSection
                                    courseId={
                                        row.field === COMMENTS // Since comments tab in a requirement already has CommentSection, should use that
                                            ? `om-${row.requirementId}`
                                            : `om-feed-${row._id}` // This courseId is depricated
                                    }
                                    courseSlug={''}
                                    courseName={row.requirement?.title}
                                    mentionables={mentionables}
                                    tasks={allTasks}
                                    teams={isInstructorView ? [] : teams}
                                    tasksOutputTemplate={requirementTasksOutputTemplate(
                                        row.requirementId
                                    )}
                                    showAllComments={false}
                                    afterCommentAdded={afterCommentAdded}
                                    showCkEditor={showCkEditor}
                                    visibilityOptions={visibilityOptions}
                                    defaultVisibility={
                                        isInstructorView
                                            ? visibilities.STARWEAVER_TEAM
                                                  .visibility
                                            : visibilities
                                                  .STARWEAVER_TEAM_AND_SMES
                                                  .visibility
                                    }
                                    clientId={row?.clientId}
                                    hideSearch
                                    showCommentAttachments={true}
                                    showAttachments={true}
                                    hideCourseTagging={isInstructorView}
                                />
                            </div>
                        </div>
                    ) : null /* (
                    <DummyInput
                        handleShowCkEditor={handleShowCkEditor}
                        handleToggleEditor={handleToggleEditor}
                    />
                ) */
                }
            </div>
        </Card>
    );
}

export default FeedCard;
