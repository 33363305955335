import _extends from "@babel/runtime/helpers/esm/extends";
import { GridWorkspacesIcon, GridGroupWorkIcon, GridFunctionsIcon, GridSendPromptIcon, GridRecordPromptIcon } from "./icons.js";
const iconsSlots = {
  columnMenuUngroupIcon: GridWorkspacesIcon,
  columnMenuGroupIcon: GridGroupWorkIcon,
  columnMenuAggregationIcon: GridFunctionsIcon,
  toolbarPromptSendIcon: GridSendPromptIcon,
  toolbarPromptRecordIcon: GridRecordPromptIcon
};
const materialSlots = _extends({}, iconsSlots);
export default materialSlots;