import React, { useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Actions, Loading, PageSearchBox, Typography } from '../ui';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';
// import { useGetCourse } from './useGetCourse';
import { useSetRecoilState } from 'recoil';
import { useAdminInstructors, useAdminInstructorsMod } from './hooks';

import { alertState, severity } from '../app/recoil';

const useStyles = makeStyles()((theme) => ({
    header: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& button': {
            width: '100%',
            fontSize: '0.87rem',
            padding: theme.spacing(0.5, 1),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(0.5, 2),
            },
        },
    },
    tableContainer2: {
        height: 280,
        width: '100%',
    },
    dialogAction: {
        marginRight: 'auto',
        marginLeft: '1rem',
        paddingBottom: '0.5rem',
    },
    linkTitle: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: '#1976d2',
        },
    },
}));

const InstructorMatchingOnboardedModal = ({
    topicNames,
    requirementId,
    fetchData,
    addSearchParam = undefined,
}) => {
    const { classes } = useStyles();
    // const { courseIdKey, courseProductableKey } = useGetCourse();
    const [selectedRow, setSelectedRow] = useState([]);
    const [customSearchValue, setCustomSearchValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const handleDialogClose = () => {};
    const setAlert = useSetRecoilState(alertState);
    const [filters, setFilters] = useState([]);

    const isLargeScreen = useMediaQuery('(min-width:900px)');
    const [pinnedColumns, setPinnedColumns] = useState({
        left: isLargeScreen
            ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'firstName', 'lastName']
            : [],
    });
    /* const {
        isLoading,
        users,
        // fullFetchedUsers,
        // totalUsers,
        // updateUsers,
        // fetchAll,
        // allUsers,
        // totalPages,
        // fetchingAll,
        // setAllUsers,
    } = useAdminInstructors({
        pageNumber: 1,
        size: 50,
        sortBy: 'last_accessed',
        order: 'desc',
        active: true,
        topicNames: topicNames || [],
    }); */

    const { isLoading, users } = useAdminInstructorsMod({
        pageNumber: 1,
        size: 50,
        sortBy: 'last_accessed',
        order: 'desc',
        active: true,
        currentFilters: filters,
        topicNames: topicNames || [],
    });

    useEffect(() => {
        let willUnmout = false;

        if (willUnmout) return;

        if (searchValue) {
            setFilters((filter) => {
                return [
                    {
                        name: 'user_firstName',
                        operator: 'contains',
                        type: 'text',
                        value: searchValue,
                    },
                    {
                        name: 'user_lastName',
                        operator: 'contains',
                        type: 'text',
                        value: searchValue,
                    },
                    {
                        name: 'user_email',
                        operator: 'contains',
                        type: 'text',
                        value: searchValue,
                    },
                ];
            });
        } else {
            setFilters([]);
        }

        return () => {
            willUnmout = true;
        };
    }, [searchValue]);

    const selectedUsers = useMemo(() => {
        return selectedRow?.map((index) => users[index]);
    }, [selectedRow]);

    // const getCourseNames = useCallback(
    //     (ids) => {
    //         return ids.map((id) =>
    //             courseIdKey[`${id}`]
    //                 ? courseIdKey[`${id}`]?.name
    //                 : courseProductableKey[`${id}`]?.name
    //         );
    //     },
    //     [courseIdKey, courseProductableKey]
    // );
    const formattedPhoneNumber = useCallback((phoneNumber) => {
        return String(phoneNumber).replace(/[^0-9]/gi, '');
    }, []);
    const memoizedColumnData = useMemo(() => {
        // if (users.length > 0 && courseIdKey) {
        // this must be same as in the row object
        return (
            fieldNames
                // .filter((col) => !col.hiddenFromAdmin || starweaverAdmin)
                .map((col) => {
                    if (
                        col.fieldName === 'firstName' ||
                        col.fieldName === 'lastName'
                    ) {
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: 250,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            hide: col.hideColumn ? true : false,
                            display: 'flex',
                            renderCell: ({ row }) => {
                                return (
                                    <Box p={2} m={1}>
                                        <Box
                                            component="span"
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                                handleNameClick(e, row)
                                            }
                                        >
                                            {row[col.fieldName]}
                                        </Box>
                                    </Box>
                                );
                            },
                        };
                    } else if (col.fieldName === 'email') {
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: 280,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            hide: col.hideColumn ? true : false,
                            display: 'flex',
                            renderCell: ({ row }) => {
                                return (
                                    <a
                                        href={`mailto:${row.email}`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {row[col.fieldName]}
                                    </a>
                                );
                            },
                        };
                    } else if (col.fieldName === 'phone') {
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: 180,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            hide: col.hideColumn ? true : false,
                            display: 'flex',
                            renderCell: ({ row }) => {
                                return (
                                    <a
                                        href={`https://api.whatsapp.com/send/?phone=${formattedPhoneNumber(
                                            row.phone
                                        )}&type=phone_number&app_absent=0`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {row[col.fieldName]}
                                    </a>
                                );
                            },
                        };
                    } else if (col.fieldName === 'topics') {
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: 280,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            hide: col.hideColumn ? true : false,
                            display: 'flex',
                            renderCell: ({ row }) => {
                                return (
                                    <Tooltip
                                        placement="top-start"
                                        title={row[col.fieldName]}
                                    >
                                        <span>{row[col.fieldName]}</span>
                                    </Tooltip>
                                );
                            },
                        };
                    } else if (col.fieldName === 'timeZone') {
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: 280,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            hide: col.hideColumn ? true : false,
                            display: 'flex',
                            renderCell: ({ row }) => {
                                return (
                                    <Tooltip
                                        placement="top-start"
                                        title={row[col.fieldName]}
                                    >
                                        <span>{row[col.fieldName]}</span>
                                    </Tooltip>
                                );
                            },
                        };
                    } else if (col.fieldName === 'linkedin') {
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: 300,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            hide: col.hideColumn ? true : false,
                            display: 'flex',
                            renderCell: ({ row }) => {
                                return (
                                    <a
                                        href={row[col.fieldName]}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                        }}
                                    >
                                        {row[col.fieldName]}
                                    </a>
                                );
                            },
                        };
                    } else
                        return {
                            field: col.fieldName,
                            headerName: col.title,
                            type: col.type,
                            description: col.title,
                            width: col.width,
                            sortable: col?.sortable === 0 ? false : true,
                            filterable: col?.filterable,
                            disableColumnMenu: true,
                            valueFormatter: col.valueFormatter,
                            renderCell: col?.renderCell,
                            hide: col.hideColumn ? true : false,
                        };
                })
        );
        // } else {
        //     return [];
        // }
        // }, [users.length, courseIdKey, starweaverAdmin, formattedPhoneNumber]);
    }, [/* starweaverAdmin, */ formattedPhoneNumber]);

    const userTableTransform = (users) =>
        users.map((user, index) => ({
            id: index,
            _id: user._id,
            name: `${user?.user_firstName ?? user?.firstName} ${
                user?.user_lastName ?? user?.lastName ?? ''
            }`,
            country: user.country,
            city: user.city,
            location:
                user?.country && user.city
                    ? user.country + ', ' + user.city
                    : user?.country && !user.city
                    ? user.country
                    : user?.city && !user?.country
                    ? user.city
                    : 'N/A',
            timeZone: user?.timeZone ? user.timeZone : 'N/A',
            linkedin: user?.linkedin ? user?.linkedin : 'N/A',
            dob: user.dob,
            occupation: user.occupation,
            firstName: user?.user_firstName ?? user?.firstName ?? 'N/A',
            lastName: user?.user_lastName ?? user?.lastName ?? 'N/A',
            lastActivity: user.lastActivity,
            current_plan_end_date: user.current_plan_end_date,
            currentPlanDate: user.currentPlanDate,
            planDetails: user.planDetails,
            planAmount: user.planAmount ? user.planAmount : 0,
            signedIn: user.createdAt,
            planType: user.planType,
            noOfCourses: user.user_save?.length,
            email: user?.user_email ?? user?.email ?? 'N/A',
            credits: user.credits,
            phone: user.phone,
            userType: user.userType,
            onTrial: user.onTrial,
            teamName: user.teamName,
            vertical: user.vertical,
            managersName: user.managersName,
            // courses: getCourseNames(user.user_save),
            courses: [],
            user_save: user.user_save,
            topics: user.topics,
            channelStatus: user?.channelStatus,
            // topics:
            //     user?.instructor_details?.topics
            //         ?.map(({ name }) => name)
            //         ?.join(', ') || '',
            _user: user,
        }));

    const memoizedRowData = useMemo(() => {
        if (users.length > 0 /* && courseIdKey  */ && !isLoading) {
            return userTableTransform(users);
        } else {
            return [];
        }
    }, [users, /* courseIdKey, */ isLoading /* , getCourseNames */]);

    const handleNameClick = (e, row) => {
        e.preventDefault();
        e.stopPropagation();
        window.open('/admin/user-profiles/active/' + row._id, '_blank');
    };

    const handleItemSelection = (params) => {
        setSelectedRow(params /* .selectionModel */);
        // this is applied if the select all is used so the document model is not changed due to mui data grid event bubbling on select all header invokes this function too
        // if (!selectAllApplied) {
        //     let newModel = [...documentSelectionModel];
        //     // save the current page model
        //     newModel[page - 1] = params /* .selectionModel */;
        //     // update page model
        //     setDocumentSelectionModel(newModel);
        // }
    };

    const [actionsDisabled, setActionDisabled] = useState(false);

    const actions = [
        {
            name: 'Identify',
            onClick: async () => {
                setActionDisabled(true);
                await axios.post('/api/smes/add-users', {
                    clientRequirementIds: [requirementId],
                    userIds: selectedUsers?.map(({ _id }) => _id),
                    smeField: 'identifiedSmes',
                });
                setActionDisabled(false);
                setAlert({
                    message: 'Instructors marked as identified.',
                    severity: severity.SUCCESS,
                    show: true,
                });
                if (fetchData) await fetchData();
            },
        },
        {
            name: 'Shortlist',
            onClick: async () => {
                setActionDisabled(true);
                await axios.post('/api/smes/add-users', {
                    clientRequirementIds: [requirementId],
                    userIds: selectedUsers?.map(({ _id }) => _id),
                    smeField: 'shortListedSmes',
                });
                setActionDisabled(false);
                setAlert({
                    message: 'Instructors shortlisted.',
                    severity: severity.SUCCESS,
                    show: true,
                });
                if (fetchData) await fetchData();
            },
        },
    ];

    const handleClear = () => {
        setCustomSearchValue('');
        setSearchValue('');
        if (addSearchParam) {
            addSearchParam('');
        }
    };

    const handleSearch = () => {
        if (customSearchValue.trim().length > 0) {
            setSearchValue(customSearchValue);
            if (addSearchParam) {
                addSearchParam(customSearchValue);
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.code === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Box>
            {/* <Dialog
                onClose={handleDialogClose}
                aria-labelledby="matching-instructor-data-modal"
                open={true}
                maxWidth="lg"
                classes={{ paper: classes.paper }}
            > */}

            <Box py={2}>
                <Box className={classes.header}>
                    <Typography component="h6" variant="h6">
                        Onboarded Instructors
                    </Typography>

                    <Box>
                        <Actions
                            actions={actions}
                            disabled={
                                selectedRow.length === 0 || actionsDisabled
                            }
                        />
                    </Box>
                </Box>
                <Box mb={2}>
                    <PageSearchBox
                        searchValue={customSearchValue}
                        setSearchValue={setCustomSearchValue}
                        handleClear={handleClear}
                        handleKeyPress={handleKeyPress}
                        handleSearch={handleSearch}
                        placeholder={'Search instructors'}
                    />
                </Box>
                {isLoading ? (
                    <Loading height="300px" />
                ) : (
                    <Box className={classes.tableContainer2}>
                        <DataGridPremium
                            // autoHeight
                            rows={memoizedRowData}
                            columns={memoizedColumnData}
                            checkboxSelection
                            rowSelectionModel={selectedRow} //added line
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={(updatedPinnedColumns) => {
                                if (isLargeScreen) {
                                    setPinnedColumns(updatedPinnedColumns);
                                } else {
                                    alert(
                                        'Pinning is not available on small screens.'
                                    );
                                }
                            }}
                            hideFooter={true}
                            // components={{
                            //     Toolbar: GridToolbar,
                            //     NoRowsOverlay: GridNoRowsOverlay,
                            //     LoadingOverlay: LoadingSkeleton,
                            // }}
                            // onColumnHeaderClick={(param, event) => {
                            //     headerSelection(param);
                            // }}
                            onRowSelectionModelChange={(param) => {
                                // console.log(param);
                                handleItemSelection(param);
                            }}
                            loading={isLoading}
                        />
                    </Box>
                )}
                {/* </DialogContent> */}
                {/* <DialogActions> */}
                {/* <Box className={classes.dialogAction}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ margin: 0 }}
                                    onClick={handleShowMore}
                                >
                                    {isShowMoreClicked
                                        ? 'SHOW LESS'
                                        : 'SHOW MORE'}
                                </Button>
                            </Box> */}
                {/* </DialogActions> */}
            </Box>

            {/* </Dialog> */}
        </Box>
    );
};

export default InstructorMatchingOnboardedModal;
const fieldNames = [
    {
        fieldName: 'firstName',
        title: 'First name',
        type: 'string',
        width: 130,
        filterable: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'lastName',
        title: 'Last name',
        type: 'string',
        width: 130,
        filterable: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'email',
        title: 'Email',
        type: 'string',
        width: 200,
        filterable: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'phone',
        title: 'Phone',
        type: 'string',
        width: 150,
        hiddenFromAdmin: true,
        filterable: false,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => `${value}`,
    },
    {
        fieldName: 'location',
        title: 'Location',
        type: 'string',
        width: 150,
        hiddenFromAdmin: true,
        filterable: false,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => `${value}`,
    },
    {
        fieldName: 'timeZone',
        title: 'Time Zone',
        type: 'string',
        width: 150,
        filterable: false,
        hiddenFromAdmin: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => `${value}`,
    },
    {
        fieldName: 'linkedin',
        title: 'LinkedIn',
        type: 'string',
        width: 150,
        filterable: false,
        hiddenFromAdmin: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => `${value}`,
    },
    {
        fieldName: 'channelStatus',
        title: 'Channel Status',
        width: 150,
        filterable: false,
        hiddenFromAdmin: true,
        sortable: false,
        valueFormatter: (value) => {
            return value;
        },
        display: 'flex',
        renderCell: (params) => {
            return (
                <span style={{ textTransform: 'capitalize' }}>
                    {params.value}
                </span>
            );
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'topics',
        title: 'Expertise',
        type: 'string',
        width: 300,
        filterable: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'planType',
        title: 'Plan type',
        type: 'string',
        width: 150,
        filterable: false,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'current_plan_end_date',
        title: 'Plan end date',
        type: 'date',
        width: 150,
        filterable: false,
        valueFormatter: (value) => {
            if (value) {
                const valueFormatted = new Date(value).toLocaleDateString(
                    'en-US'
                );
                return `${valueFormatted}`;
            } else {
                return '';
            }
        },
        exportValueFormatter: (value) =>
            new Date(value).toLocaleDateString('en-US'),
    },
    {
        fieldName: 'userType',
        title: 'User type',
        type: 'string',
        width: 130,
        filterable: false,
        valueFormatter: (value) => {
            return value;
        },
        hiddenFromAdmin: true,
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'teamName',
        title: 'Team name',
        type: 'string',
        width: 180,
        filterable: false,
        hideColumn: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'country',
        title: 'Country',
        type: 'string',
        width: 100,
        filterable: true,
        hideColumn: false,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'managersName',
        title: 'Managers',
        sortable: 0,
        hideColumn: true,
        valueFormatter: (value) => {
            return value;
        },
        width: 600,
        filterable: false,
        exportValueFormatter: (value = []) => {
            if (Array.isArray(value)) {
                return value.join(',');
            } else {
                return value;
            }
        },
    },
    {
        fieldName: 'vertical',
        title: 'Vertical',
        type: 'string',
        width: 200,
        filterable: false,
        hideColumn: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'credits',
        title: 'User Credits',
        width: 150,
        filterable: false,
        hideColumn: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'onTrial',
        title: 'On Trial',
        width: 150,
        filterable: false,
        type: 'boolean',
        hiddenFromAdmin: true,
        valueFormatter: (value) => {
            return value;
        },
        exportValueFormatter: (value) => value,
    },
    {
        fieldName: 'signedIn',
        title: 'Registered',
        type: 'date',
        width: 150,
        filterable: false,
        valueFormatter: (value) => {
            const valueFormatted = new Date(value).toLocaleDateString('en-US');
            return `${valueFormatted}`;
        },
        exportValueFormatter: (value) =>
            new Date(value).toLocaleDateString('en-US'),
    },
    {
        fieldName: 'lastActivity',
        title: 'Last activity',
        type: 'date',
        width: 200,
        filterable: false,
        valueFormatter: (value) => {
            const valueFormatted = value
                ? new Date(value).toLocaleDateString('en-US')
                : new Date('06-20-2020').toLocaleDateString('en-US');
            return `${valueFormatted}`;
        },
        exportValueFormatter: (value) =>
            new Date(value).toLocaleDateString('en-US'),
    },
    // {
    //     fieldName: 'currentPlanDate',
    //     title: 'Current Plan Date',
    //     type: 'date',
    //     width: 200,
    //     valueFormatter: (value) => {
    //         const valueFormatted = value
    //             ? new Date(value).toLocaleDateString('en-US')
    //             : new Date('06-20-2020').toLocaleDateString('en-US');
    //         return `${valueFormatted}`;
    //     },
    //     exportValueFormatter: (value) =>
    //         new Date(value).toLocaleDateString('en-US'),
    // },
    // {
    //     fieldName: 'planAmount',
    //     title: 'Payment($)',
    //     type: 'date',
    //     width: 200,
    //     valueFormatter: (value) => {
    //         return value === 0
    //             ? 'N/A'
    //             : Number(value).toLocaleString('en-US');
    //     },
    //     exportValueFormatter: (value) => value,
    // },
    // {
    //     fieldName: 'noOfCourses',
    //     title: 'Total course enrolled',
    //     width: 100,
    //     hideColumn: false,
    //     valueFormatter: (value) => {
    //         return value;
    //     },
    //     exportValueFormatter: (value) => value,
    // },
    {
        fieldName: 'courses',
        title: 'Courses',
        sortable: 0,
        hideColumn: true,
        valueFormatter: (value) => {
            return value;
        },
        width: 600,
        filterable: false,
        exportValueFormatter: (value = []) => {
            if (Array.isArray(value)) {
                return value.join(',');
            } else {
                return value;
            }
        },
    },
    // {
    //     fieldName: 'rsvps',
    //     title: 'Register',
    //     sortable: 0,
    //     valueFormatter: (value) => {
    //         return value;
    //     },
    //     exportValueFormatter: (value = []) => {
    //         if (Array.isArray(value)) {
    //             return value.join(',');
    //         } else {
    //             return value;
    //         }
    //     },
    //     width: 600,
    // },
];
