import { Box, ButtonBase, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
    displayRequirementSummary,
    setPercentageColClickedStatus,
    setTaskColClickedStatus,
} from '../../slices/requirementSummarySlice';
import CommentIcon from '@mui/icons-material/Comment';
import { roleList } from '../../../util';
import { resetExpertModalState } from '../../slices/expertProjectModalSlice';

function OMCommentBtn({
    commentCount,
    row,
    isRead,
    commentsUserMetaDataLoading,
    updateCommentLastSeen,
}) {
    const dispatch = useDispatch();

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            sx={{
                ml: 1.5,
                flexGrow: '1',
                maxWidth: '65px',
                marginLeft: 'auto',
            }}
            onClick={async () => {
                dispatch(
                    displayRequirementSummary({
                        requirementId: row._id,
                        _id: row._id,
                        id: row._id,
                        title: row.title,
                        smes: row?.smes ?? [],
                        instructionalDesigners:
                            row?.instructionalDesigners ?? [],
                        status: row?.status,
                        expectedEndDate: row?.expectedEndDate,
                        clientId: row?.clientId?._id,
                        shortListedSmes: row?.shortListedSmes,
                        collaborators: row?.collaborators,
                        taskCategories: row?.taskCategories,
                        templateId: row?.templateId,
                        templateName: row?.templateDetails?.name,
                        contractNumber: row?.contractNumber,
                        ...roleList.reduce((prev, curr) => {
                            const key = curr.field;
                            prev[key] = row[key] ?? [];
                            return prev;
                        }, {}),
                        outlineFormat: row?.outlineFormat ?? 'Module-Grid',
                        hours: row?.hours ?? 0,
                        minutes: row?.minutes ?? 0,
                        domain: row?.domain,
                    })
                );
                dispatch(setTaskColClickedStatus(false));
                dispatch(setPercentageColClickedStatus(false));
                dispatch(resetExpertModalState());
                await updateCommentLastSeen(`om-${row._id}`);
            }}
        >
            <ButtonBase>
                <CommentIcon
                    fontSize="small"
                    color={
                        commentsUserMetaDataLoading
                            ? 'primary'
                            : isRead
                            ? 'primary'
                            : 'secondary'
                    }
                />
                <Typography
                    variant="button"
                    sx={{ pl: 0.5 }}
                    color={
                        commentsUserMetaDataLoading
                            ? 'primary'
                            : isRead
                            ? 'primary'
                            : 'secondary'
                    }
                >
                    {commentCount}
                </Typography>
            </ButtonBase>
        </Box>
    );
}

export default OMCommentBtn;
