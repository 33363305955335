import { useState, useMemo } from 'react';
import {
    PLANS_CARD_DETAILS,
    COMMANDER,
    astronaut_id,
    VOYAGER_PLUS,
} from '../plans/PlanDetails';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../my-account/useCurrentUser';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { makeStyles } from 'tss-react/mui';
import { resetUser } from '../my-account/Recoil/Selector';
import { useSetRecoilState } from 'recoil';
import { RouteInfo } from '../seo';
import firebase from 'firebase/app';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Tooltip,
    Divider,
} from '../ui';

import { useInstructorProfile } from '../instructor-dashboard/useInstructorProfile';
import { unsubscribeUser } from '../util/unsubscribeUser';
import { profileRoute } from './route-definitions';
import { roleViewState } from './recoil/roleView';

const useStyles = makeStyles()((theme) => ({
    dropDownContainer: {
        position: 'relative',
        marginRight: '20px',
    },
    downContainer: {
        width: 400,
        transition: 'all 0.3s ease-in',
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'absolute',
        bottom: '0px',
        right: '0px',

        [theme.breakpoints.down('md')]: {
            display: 'block',
            width: 200,
            overflow: 'auto',
            height: '400px',
        },
    },
    dropDownListBox: {
        width: '50%',

        backgroundColor: '#ffffff',
        border: '1px solid #CDD0D3',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderBottom: '0px solid #CDD0D3',
        },
    },
    upgradeBox: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    dropDownUpgradeBox: {
        width: '100%',
        backgroundColor: '#ffffff',
        height: '50%',
        borderLeft: '1px solid #CDD0D3',
        borderTop: '1px solid #CDD0D3',
        borderBottom: '1px solid #CDD0D3',

        marginBottom: '0%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginTop: '0px',
            paddingBottom: '20px',
            borderRight: '1px solid #CDD0D3',
        },
    },
    dropDownUpgradeEmptyBox: {
        height: '50%',
        width: '100%',

        [theme.breakpoints.down('md')]: {
            height: '0px',
            width: '0px',
        },
    },
    userProfilePic: {
        width: '20px',
        paddingLeft: '10px',
        cursor: 'pointer',
    },
    textColor: {
        cursor: 'pointer',
        fontSize: '12px',
        '&:hover': {
            color: '#0f7c90',
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        },
    },
    textColorEmail: {
        fontSize: '9px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '7px',
        },
    },
    textColorName: {
        fontSize: '9px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '7px',
        },
    },
    showContainer: {
        zIndex: 10000,
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(87, 86, 86, 0.5)',
        animation: '$myAnimation 100ms 1',
    },
    buttonContainer: {
        position: 'absolute',
        right: '0px',
        width: '200px',
        top: '50%',
        transform: 'translateY(60%)',
        [theme.breakpoints.down('sm')]: {
            width: '170px',
            right: '-15px',
        },
    },
    hideContainer: {
        position: 'absolute',
        right: '0px',
        width: '200px',
        top: '50%',
        transform: 'translateY(-50%)',
        // width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '170px',
            right: '-7px',
        },
    },

    '@keyframes myAnimation': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    dropContentBox: {
        width: 400,
        transition: 'all 0.3s ease-in',
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'absolute',
        top: '50px',
        right: '0px',
        animation: '$myAnimation 1s 1',

        [theme.breakpoints.down('md')]: {
            display: 'block',
            width: 200,
            overflow: 'auto',
            height: '400px',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: 170,
            overflow: 'auto',
            height: '400px',
            right: '-15px',
        },
    },
    pricingBtn: {
        marginLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '12px',
        },
    },
    listItem: {
        paddingBlock: theme.spacing(0.25),
    },
}));

function ProfileDropDown({ isInstructorView, isAdminView }) {
    const [dropDownBox, setDropDownBox] = useState(false);
    const { user: currentUser } = useCurrentUser();
    const resetUserRecoil = useSetRecoilState(resetUser);
    let navigate = useNavigate();
    const { classes } = useStyles();
    const [instructorURLLoading, setIsInstructorURLLoading] = useState(false);
    const { profilePic } = useInstructorProfile(currentUser);
    const setRoleView = useSetRecoilState(roleViewState);

    // memoized account type
    const accountType = useMemo(() => {
        if (currentUser) {
            const plan = PLANS_CARD_DETAILS.filter(
                (item) => item.p_id === currentUser.user_current_plan
            );
            if (plan.length) {
                return plan[0].name;
            } else {
                return currentUser.plan_name;
            }
        } else {
            return 'Voyager';
        }
    }, [currentUser]);

    const adminItems = useMemo(() => {
        return [
            {
                label: 'Admin Dashboard',
                url: '/admin',
                isHidden: false,
            },
            {
                label: 'Profile',
                url: '/profile/information',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Privacy',
                url: '/profile/security',
                isHidden: false,
            },
            {
                label: 'Notifications',
                url: '/admin/notifications',
                isHidden: false,
            },
            {
                label: 'Messages',
                url: '/messaging',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Starweaver for Business',
                url: '/free-enterprise-trial',
                isHidden: false,
                isNewTab: true,
            },
            {
                label: 'Connect with us',
                url: '/admin/program-management',
                isHidden: false,
            },
            {
                label: "Support & FAQ's",
                url: 'https://support.starweaver.com/portal/en/home',
                isHidden: false,
                isNewTab: true,
            },
        ];
    }, []);

    const instructorItems = useMemo(() => {
        return [
            {
                label: 'Instructor Dashboard',
                url: '/profile/instructor-dashboard',
                isHidden: false,
            },
            {
                label: 'Profile',
                url: '/profile/information',
                isHidden: false,
            },
            {
                label: 'My Channel',
                url: '/profile/instructor-dashboard/public',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Privacy',
                url: '/profile/security',
                isHidden: false,
            },
            {
                label: 'Notifications',
                url: '/profile/instructor-dashboard/notifications',
                isHidden: false,
            },
            {
                label: 'Messages',
                url: '/messaging',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Learn How',
                url: '/profile/instructor-dashboard/learn-how/tutorials',
                isHidden: false,
            },
            {
                label: 'Connect with us',
                url: '/profile/instructor-dashboard/connect',
                isHidden: false,
            },
            {
                label: "Support & FAQ's",
                url: 'https://support.starweaver.com/portal/en/home',
                isHidden: false,
                isNewTab: true,
            },
        ];
    }, []);

    const studentItems = useMemo(() => {
        return [
            {
                label: 'Student Dashboard',
                url: '/profile/dashboard',
                isHidden: false,
            },
            {
                label: 'My Courses',
                url: '/profile/dashboard/content/my-courses',
                isHidden: false,
            },
            {
                label: 'Saved Courses',
                url: '/profile/dashboard/content/saved-register',
                isHidden: false,
            },
            {
                label: 'Recommendations',
                url: '/profile/dashboard/recommendations',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Profile',
                url: '/profile/information',
                isHidden: false,
            },
            {
                label: 'Privacy',
                url: '/profile/security',
                isHidden: false,
            },
            {
                label: 'Notifications',
                url: '/profile/dashboard/notifications',
                isHidden: false,
            },
            {
                label: 'Messages',
                url: '/messaging',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Subscriptions',
                url: '/profile/billing',
                isHidden: false,
            },
            {
                label: 'Purchase History',
                url: '',
                isHidden: false,
            },
            {
                isDivider: true,
            },
            {
                label: 'Starweaver for Business',
                url: 'free-enterprise-trial',
                isHidden: false,
                isNewTab: true,
            },
            {
                label: !currentUser?.is_instructor
                    ? 'Teach with Starweaver'
                    : 'Instructor Dashboard',
                url:
                    currentUser?.instructor_onboarding_status === 'Pending'
                        ? ''
                        : !currentUser?.is_instructor
                        ? '/instructor-onboarding'
                        : '/profile/instructor-dashboard',
                isHidden: false,
            },
            {
                label: "Support & FAQ's",
                url: 'https://support.starweaver.com/portal/en/home',
                isHidden: false,
                isNewTab: true,
            },
        ];
    }, [currentUser?.instructor_onboarding_status, currentUser?.is_instructor]);

    const items = useMemo(() => {
        if (isInstructorView) return instructorItems;
        else if (isAdminView) return adminItems;
        else return studentItems;
    }, [
        adminItems,
        instructorItems,
        isAdminView,
        isInstructorView,
        studentItems,
    ]);

    const isPublic = (pathname) => {
        const pathFirst = pathname.split('/')[1];
        return RouteInfo.some(
            (route) => route.path.split('/')[1] === pathFirst && route.isPublic
        );
    };

    const goToProfile = () => {
        navigate('/profile');
    };

    /* const goToDashboard = () => {
        navigate('/profile/dashboard');
    };

    const goToInstructorOnboarding = () => {
        navigate('/instructor-onboarding');
    };

    const goToNotifications = () => {
        navigate('/profile/notifications');
    };

    const goToRecommendations = () => {
        navigate('/profile/dashboard/recommendations');
    };

    const goToMyCourses = () => {
        navigate('/profile/dashboard/content/my-courses');
    };

    const goToMessaging = () => {
        navigate('/profile/dashboard/messages');
    };

    const goToPlanInfo = () => {
        navigate('/profile/billing');
    }; */

    const goToPricing = () => {
        if (accountType === VOYAGER_PLUS) {
            navigate(`/checkout?p_id=${astronaut_id}&t=0`);
        } else {
            navigate('/pricing');
        }
    };

    const goToUpdateProfilePhoto = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isInstructorView)
            navigate(
                '/profile/instructor-dashboard/channel-configuration/branding'
            );
        else navigate('/profile/information');
    };

    /* const goToKnowledge = () => {
        window.open('https://support.starweaver.com/portal/en/home', '_blank');
    };

    const goToFirstTime = () => {
        navigate('/first-time');
    };

    const goToBusiness = () => {
        window.open(
            `${process.env.REACT_APP_SW_WORDPRESS_DOMAIN}/free-enterprise-trial`,
            '_blank'
        );
    };

    const goToInstructorDashboard = () => {
        navigate('/profile/instructor-dashboard');
    };

    const goToInstructorProfile = () => {
        navigate(
            '/profile/instructor-dashboard/channel-configuration/about-me'
        );
    }; */
    /* const goToInstructorContent = () => {
        navigate('/profile/instructor-dashboard/content');
    };
    const goToInstructorNetwork = () => {
        navigate('/profile/instructor-dashboard/network');
    };
    const goToInstructorMessaging = () => {
        navigate('/profile/instructor-dashboard/messaging');
    };

    const goToInstructorReports = () => {
        navigate('/profile/instructor-dashboard/reports');
    };

    const goToInstructorPayouts = () => {
        navigate('/profile/instructor-dashboard/channel-configuration/payouts');
    };

    const goToInstructorContactStarweaver = () => {
        navigate('/profile/instructor-dashboard/program-manager');
    };

    const goToInstructorPublicProfile = async () => {
        window.open('/profile/instructor-dashboard/public', '_blank');
    }; */

    /* const goToAdminDashboard = () => {
        navigate('/admin');
    };

    const goToAdminUserManangement = () => {
        // navigate(('/admin/user-profiles'));
        navigate('/admin/user-profiles/active');
    };
    const goToAdminReports = () => {
        navigate('/admin/reports');
    };
    const goToAdminMessagingSuite = () => {
        // navigate(('/admin/message-suite'));
        navigate('/admin/create/message/');
    };
    const goToAdminGeneralAdministration = () => {
        navigate('/admin/content/all');
    };
    const goToAdminProgramManagement = () => {
        navigate('/admin/program-management');
    }; */

    const handleLogOut = () => {
        if (currentUser) unsubscribeUser(currentUser._id);
        firebase
            .auth()
            .signOut()
            .then((res) => {
                setRoleView({ selectedView: '' });
                const pathName = window.location.pathname;
                isPublic(pathName) ? navigate(pathName) : navigate('/login');
                resetUserRecoil();
            })
            .catch((err) => console.log(err));
    };

    const handleItemClick = (item) => {
        if (item?.url.length === 0) return;

        if (item?.isNewTab) {
            window.open(item.url, '_blank');
            return;
        }

        navigate(item.url);
    };

    return (
        <Box
            className={
                dropDownBox ? classes.showContainer : classes.hideContainer
            }
        >
            {currentUser && (
                <Box
                    className={classes.dropDownContainer}
                    onMouseEnter={() => {
                        setDropDownBox(true);
                    }}
                    onMouseLeave={() => {
                        setDropDownBox(false);
                    }}
                >
                    <Button
                        className={
                            dropDownBox
                                ? classes.buttonContainer
                                : classes.hideContainer
                        }
                        color="secondary"
                        variant="contained"
                        endIcon={<profileRoute.icon />}
                    >
                        My Account
                    </Button>
                    {dropDownBox ? (
                        <Box
                            className={
                                dropDownBox
                                    ? classes.dropContentBox
                                    : classes.downContainer
                            }
                        >
                            <Box className={classes.dropDownListBox}>
                                <List
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                        margin: '0px',
                                        padding: '0px',
                                        cursor: 'pointer',
                                    }}
                                    component="nav"
                                    aria-label="main mailbox folders"
                                >
                                    <ListItem
                                        // onClick={goToProfile}
                                        style={{
                                            padding: '10px 0px 10px 0px',
                                        }}
                                        onClick={goToProfile}
                                    >
                                        <Tooltip
                                            title={
                                                currentUser?.profilePic
                                                    ? 'Update Photo'
                                                    : 'Upload Photo'
                                            }
                                            placement="top"
                                        >
                                            <ListItemIcon
                                                onClick={(e) => {
                                                    goToUpdateProfilePhoto(e);
                                                }}
                                                className={
                                                    classes.userProfilePic
                                                }
                                            >
                                                {(currentUser &&
                                                    currentUser.profilePic) ||
                                                (isInstructorView &&
                                                    profilePic) ? (
                                                    <img
                                                        src={
                                                            isInstructorView
                                                                ? profilePic
                                                                : currentUser.profilePic
                                                        }
                                                        alt="current-user-pic"
                                                        width="40px"
                                                        height="40px"
                                                        style={{
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                ) : (
                                                    <AccountBoxIcon
                                                        style={{
                                                            padding: '0px',
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText
                                            primary={
                                                <Box>
                                                    <Typography
                                                        className={
                                                            classes.textColorName
                                                        }
                                                        variant="body2"
                                                    >
                                                        {currentUser?.user_firstName
                                                            ? currentUser?.user_firstName
                                                            : null}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.textColorEmail
                                                        }
                                                        variant="body2"
                                                        sx={{
                                                            wordWrap:
                                                                'break-word',
                                                            whiteSpace:
                                                                'normal',
                                                        }}
                                                    >
                                                        {
                                                            currentUser?.user_email
                                                        }
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </ListItem>

                                    <ListItem
                                        style={{
                                            borderBottom: '1px solid #CDD0D3',
                                        }}
                                        onClick={goToProfile}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        classes.textColor
                                                    }
                                                    variant="body2"
                                                >
                                                    My Account ({accountType})
                                                </Typography>
                                            }
                                        />
                                    </ListItem>

                                    {/* {isInstructorView ? (
                                        <>
                                            <ListItem
                                                onClick={
                                                    goToInstructorDashboard
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Instructor Dashboard
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={goToInstructorProfile}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Edit My Profile
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={goToInstructorContent}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Content Producer
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                onClick={
                                                    goToInstructorMessaging
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Messaging
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={goToInstructorReports}
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Reports
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={
                                                    goToInstructorPublicProfile
                                                }
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            My Channel
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                onClick={goToInstructorPayouts}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Payouts
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={
                                                    goToInstructorContactStarweaver
                                                }
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Contact Starweaver
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={goToBusiness}
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Starweaver for
                                                            Business
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </>
                                    ) : isAdminView ? (
                                        <>
                                            <ListItem
                                                onClick={goToAdminDashboard}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Administrator
                                                            Dashboard
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={
                                                    goToAdminUserManangement
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            User & Team
                                                            Management
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={goToAdminReports}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Reporting
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={
                                                    goToAdminMessagingSuite
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Messaging Suite
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={
                                                    goToAdminGeneralAdministration
                                                }
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Content Management
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                onClick={
                                                    goToAdminProgramManagement
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Coordinate with
                                                            Starweaver
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                                onClick={goToKnowledge}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Support & FAQs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                onClick={goToBusiness}
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Starweaver for
                                                            Business
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </>
                                    ) : (
                                        <>
                                            <ListItem onClick={goToDashboard}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Student Dashboard
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem
                                                onClick={goToRecommendations}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Recommendations
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem onClick={goToMyCourses}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            My Courses
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem onClick={goToMessaging}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Messaging
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                                onClick={goToPlanInfo}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Plan Info
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem onClick={goToFirstTime}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Walk-Thru
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem
                                                style={{
                                                    borderBottom:
                                                        '1px solid #CDD0D3',
                                                }}
                                                onClick={goToKnowledge}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Support & FAQs
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem onClick={goToBusiness}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            className={
                                                                classes.textColor
                                                            }
                                                            variant="body2"
                                                        >
                                                            Starweaver for
                                                            Business
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            {currentUser.is_instructor ? (
                                                <ListItem
                                                    onClick={
                                                        goToInstructorDashboard
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                className={
                                                                    classes.textColor
                                                                }
                                                                variant="body2"
                                                            >
                                                                Instructor
                                                                Dashboard
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            ) : (
                                                <ListItem
                                                    onClick={
                                                        goToInstructorOnboarding
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                className={
                                                                    classes.textColor
                                                                }
                                                                variant="body2"
                                                            >
                                                                Teach with
                                                                Starweaver
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            )}

                                            {!(
                                                accountType === COMMANDER ||
                                                currentUser.is_instructor
                                            ) && (
                                                <ListItem
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #CDD0D3',
                                                    }}
                                                >
                                                    <Button
                                                        onClick={goToPricing}
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Upgrade Plan
                                                    </Button>
                                                </ListItem>
                                            )}
                                        </>
                                    )} */}

                                    {items.map((item, idx) => {
                                        return (
                                            <>
                                                {!item.isDivider ? (
                                                    <ListItem
                                                        key={item.label}
                                                        className={
                                                            classes.listItem
                                                        }
                                                        onClick={() =>
                                                            handleItemClick(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    className={
                                                                        classes.textColor
                                                                    }
                                                                    variant="body1"
                                                                >
                                                                    {item.label}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                ) : (
                                                    <Divider
                                                        key={`divider-${idx}`}
                                                    />
                                                )}
                                            </>
                                        );
                                    })}

                                    <ListItem onClick={handleLogOut}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        classes.textColor
                                                    }
                                                    variant="subtitle2"
                                                >
                                                    Log out
                                                </Typography>
                                            }
                                        />
                                    </ListItem>

                                    {!(
                                        accountType === COMMANDER ||
                                        currentUser.is_instructor
                                    ) && (
                                        <ListItem
                                            style={{
                                                borderBottom:
                                                    '1px solid #CDD0D3',
                                            }}
                                        >
                                            <Button
                                                onClick={goToPricing}
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                fullWidth
                                            >
                                                Upgrade Plan
                                            </Button>
                                        </ListItem>
                                    )}
                                </List>
                            </Box>
                            {/* {accountType !== COMMANDER && false && (
                                <Box className={classes.upgradeBox}>
                                    <Box
                                        className={
                                            classes.dropDownUpgradeEmptyBox
                                        }
                                        onMouseOver={() => {
                                            setDropDownBox(false);
                                        }}
                                    ></Box>
                                    <Box className={classes.dropDownUpgradeBox}>
                                        <Typography
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 800,
                                                marginTop: '35px',
                                            }}
                                            variant="body2"
                                        >
                                            Upgrade your account
                                        </Typography>
                                        <Typography
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 400,
                                                padding: '20px 10px 10px 10px',
                                            }}
                                            variant="body2"
                                        >
                                            Achieve more. Get connected to
                                            instructors, coaches and much more.
                                        </Typography>
                                        <Button
                                            className={classes.pricingBtn}
                                            onClick={goToPricing}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Upgrade Plan
                                        </Button>
                                    </Box>
                                </Box>
                            )} */}
                        </Box>
                    ) : null}
                </Box>
            )}
        </Box>
    );
}

export default ProfileDropDown;
