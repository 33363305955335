import { Link, useLocation } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/material/styles';
import { SearchIcon } from '../icons';
import {
    dashboardRoute,
    instructorDashboard,
    adminRoute,
    mySiteRoute,
    collaboratorRoute,
} from './route-definitions';
import {
    navSections,
    instructorNavSections,
    adminSections,
    adminCADSections,
    roleNavSection,
} from './navSections';
import { ViewButton } from './ViewButton';
import { SearchBox } from '../search-box';
import { useUser } from '../auth';
import IconButton from '@mui/material/IconButton';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloseIcon from '@mui/icons-material/Close';
import { SignUpButtons } from './SignUpButtons';
import { FormatListBulletedIcon } from '../icons';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip,
    Hidden,
    Typography,
} from '../ui';
import { useIsAdmin } from '../admin/useIsAdmin';
import React, { useMemo } from 'react';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { ChevronRight } from '@mui/icons-material';
import ProfilePicture from '../custom-components/ProfilePicture';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useInstructorProfile } from '../instructor-dashboard/useInstructorProfile';
import { COLLABORATOR } from '../auth/roles';
import { ListItemButton } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    textColorName: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    textColorEmail: {
        fontSize: '14px',
    },
}));

const StyledTooltip = withStyles({
    tooltip: {
        fontSize: '12px',
    },
})(Tooltip);

const HiddenTooltip = withStyles({
    tooltip: {
        visibility: 'hidden',
    },
})(Tooltip);

const ProfileBox = (props) => {
    const {
        onToggleDrawer,
        setDropDownBox,
        dropDownBox,
        isInstructorView,
        mongoUser,
    } = props;
    let navigate = useNavigate();
    const { classes } = useStyles();
    const { profilePic } = useInstructorProfile(mongoUser);

    const goToUpdateProfilePhoto = () => {
        if (isInstructorView)
            navigate(
                '/profile/instructor-dashboard/channel-configuration/about-me'
            );
        else navigate('/profile/information');
        onToggleDrawer();
    };

    return (
        <Box
            display="flex"
            alignItems={'center'}
            paddingX="0.5rem"
            style={{ gap: '0.6rem', cursor: 'pointer' }}
            onClick={(e) => {
                setDropDownBox(!dropDownBox);
                e.stopPropagation();
            }}
        >
            <Box onClick={goToUpdateProfilePhoto}>
                {mongoUser && mongoUser.profilePic ? (
                    <ProfilePicture
                        alt="current-user-pic"
                        src={
                            isInstructorView ? profilePic : mongoUser.profilePic
                        }
                        size={'LARGE'}
                        name={
                            mongoUser?.user_firstName || mongoUser?.user_email
                        }
                    />
                ) : (
                    <AccountBoxIcon
                        style={{
                            padding: '0px',
                        }}
                    />
                )}
            </Box>
            <Box>
                <Typography className={classes.textColorName} variant="body2">
                    {mongoUser?.user_firstName
                        ? mongoUser?.user_firstName
                        : null}
                </Typography>
                <Typography className={classes.textColorEmail} variant="body2">
                    {mongoUser?.user_email}
                </Typography>
            </Box>
            <Hidden mdUp>
                <IconButton style={{ marginLeft: 'auto' }} size="large">
                    <ChevronRight fontSize="small" />
                </IconButton>
            </Hidden>
        </Box>
    );
};

export const NavContents = ({
    expand,
    /* searchBoxValue,
    onSearchBoxChange, */
    onToggleDrawer,
    showClose,
    setDropDownBox,
    dropDownBox,
    isInstructorView,
    isAdminView,
    routeRole,
}) => {
    const { user } = useUser();
    const { isAdmin, starweaverAdmin } = useIsAdmin();
    const { user: mongoUser } = useCurrentUser();
    const { pathname } = useLocation();
    const theme = useTheme();

    const hideSearchBox = useMemo(() => {
        if (!user) return false;
        if (isInstructorView || isAdminView) return true;
        return false;
    }, [isAdminView, isInstructorView, user]);

    return (
        <Box mt={2} pl={1}>
            <Toolbar />
            {showClose ? (
                <Box
                    style={{
                        marginTop: '-55px',
                    }}
                >
                    <IconButton
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            onToggleDrawer(/* !dropDownBox */);
                            setDropDownBox(false);
                            e.stopPropagation();
                        }}
                        size="large"
                    >
                        <CloseIcon
                            style={{
                                width: '25px',
                                height: '25px',
                                color: 'black',
                            }}
                        />
                    </IconButton>
                </Box>
            ) : null}
            <Hidden mdUp>
                {/* search box */}
                {hideSearchBox === false && (
                    <Box p={2}>
                        {expand ? (
                            <SearchBox onToggleDrawer={onToggleDrawer} />
                        ) : (
                            <SearchIcon style={{ marginTop: '19px' }} />
                        )}
                    </Box>
                )}

                {!user && (
                    <Box px={1} my={1}>
                        <SignUpButtons onClickButton={onToggleDrawer} />
                    </Box>
                )}

                {/* my-account button */}
                <Box style={{ marginTop: '10px' }}>
                    {/* <Box mb={2}>
                        <ViewButton
                            user={mongoUser}
                            isAdmin={isAdmin}
                            isAdminRoute={isAdminView}
                            isInstructorRoute={isInstructorView}
                        />
                    </Box> */}
                    {user && (
                        // <ListItem
                        //     button
                        //     onClick={() => {
                        //         setDropDownBox(!dropDownBox);
                        //     }}
                        // >
                        //     <ListItemIcon>
                        //         <AccountBoxIcon />
                        //     </ListItemIcon>
                        //     <ListItemText primary="MY ACCOUNT" />
                        // </ListItem>
                        <>
                            <Box pl={1}>
                                <ProfileBox
                                    onToggleDrawer={onToggleDrawer}
                                    setDropDownBox={setDropDownBox}
                                    dropDownBox={dropDownBox}
                                    isInstructorView={isInstructorView}
                                    mongoUser={mongoUser}
                                />
                            </Box>

                            <Box pl={2} py={2}>
                                <ViewButton
                                    user={mongoUser}
                                    isAdmin={isAdmin}
                                    isAdminRoute={isAdminView}
                                    isInstructorRoute={isInstructorView}
                                    // routeRoles={routeRoles}
                                    routeRole={routeRole}
                                    onToggleDrawer={onToggleDrawer}
                                    isSideNav
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Hidden>

            <List>
                {user && (
                    <Link
                        onClick={onToggleDrawer}
                        to={
                            isAdminView
                                ? adminRoute.path
                                : isInstructorView
                                ? instructorDashboard.path
                                : routeRole === COLLABORATOR
                                ? collaboratorRoute.path
                                : dashboardRoute.path
                        }
                    >
                        <ListItem button>
                            <ListItemIcon>
                                {isInstructorView ? (
                                    <instructorDashboard.icon />
                                ) : (
                                    <dashboardRoute.icon />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    isAdminView
                                        ? adminRoute.name
                                        : isInstructorView
                                        ? instructorDashboard.name
                                        : routeRole === COLLABORATOR
                                        ? collaboratorRoute.name
                                        : dashboardRoute.name
                                }
                                style={{ whiteSpace: 'nowrap' }}
                            />
                        </ListItem>
                    </Link>
                )}

                {/* --- MY-SITE --- */}
                {isAdminView && (
                    <Link to={mySiteRoute.path}>
                        <ListItemButton>
                            <ListItemIcon>
                                <mySiteRoute.icon />
                            </ListItemIcon>
                            <ListItemText
                                primary={mySiteRoute.name}
                                style={{ whiteSpace: 'nowrap' }}
                            />
                        </ListItemButton>
                    </Link>
                )}

                {!(isInstructorView || isAdminView || routeRole) && (
                    <List>
                        <Link onClick={onToggleDrawer} to="/browse">
                            <ListItem button>
                                <ListItemIcon>
                                    {<FormatListBulletedIcon />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={'Browse'}
                                    style={{ whiteSpace: 'nowrap' }}
                                />
                            </ListItem>
                        </Link>
                    </List>
                )}

                {/* <List>
                    <Link onClick={onToggleDrawer} to="/teams-test">
                        <ListItem button>
                            <ListItemIcon>
                                {<FormatListBulletedIcon />}
                            </ListItemIcon>
                            <ListItemText
                                primary={'Test'}
                                style={{ whiteSpace: 'nowrap' }}
                            />
                        </ListItem>
                    </Link>
                </List> */}

                {/* <List>
                    <Link onClick={onToggleDrawer} to="/job-roles">
                        <ListItem button>
                            <ListItemIcon>{<FaceIcon />}</ListItemIcon>
                            <ListItemText
                                primary={'Personas'}
                                style={{ whiteSpace: 'nowrap' }}
                            />
                        </ListItem>
                    </Link>
                </List> */}

                <>
                    {(isAdminView
                        ? starweaverAdmin
                            ? adminSections
                            : adminCADSections
                        : isInstructorView
                        ? instructorNavSections
                        : routeRole
                        ? roleNavSection(routeRole)
                        : navSections
                    ).map((section) => (
                        <React.Fragment key={section.name}>
                            <Box ml={2} height="23px" width="250px">
                                <p
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        opacity: 0.7,
                                        color: expand
                                            ? 'inherit'
                                            : 'transparent',
                                    }}
                                >
                                    {section.name}
                                </p>
                            </Box>
                            <Box mx={2} mb={2}>
                                <Divider />
                            </Box>
                            {section.items.map((item, index) => {
                                const Tooltip = item.releaseDate
                                    ? StyledTooltip
                                    : HiddenTooltip;
                                return item.isExternalLink ? (
                                    <a
                                        onClick={onToggleDrawer}
                                        href={item.path}
                                        target={
                                            item.isNewTab ? '_blank' : '_self'
                                        }
                                        key={index}
                                        rel="noreferrer"
                                    >
                                        <Tooltip
                                            title={`${item.releaseDate}`}
                                            arrow
                                            placement="right"
                                        >
                                            <ListItem button key={item.name}>
                                                <ListItemIcon>
                                                    {item.icon && <item.icon />}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.name}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                />
                                            </ListItem>
                                        </Tooltip>
                                    </a>
                                ) : (
                                    <Link
                                        key={index}
                                        swid={`sidenav-link-${item.name}`}
                                        to={
                                            (item.path?.includes(':')
                                                ? item.defaultPath
                                                : item.path) || '/'
                                        }
                                        onClick={
                                            (item.releaseDate
                                                ? (event) =>
                                                      event.preventDefault()
                                                : () => {},
                                            onToggleDrawer)
                                        }
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <Tooltip
                                            title={`${item.releaseDate}`}
                                            arrow
                                            placement="right"
                                            visible="false"
                                        >
                                            <ListItem
                                                button
                                                key={item.name}
                                                style={
                                                    pathname ===
                                                    (item.path?.includes(':')
                                                        ? item.defaultPath
                                                        : item.path)
                                                        ? {
                                                              backgroundColor:
                                                                  theme.palette
                                                                      .grey[300],

                                                              color: 'black',
                                                          }
                                                        : {}
                                                }
                                            >
                                                <ListItemIcon>
                                                    {item.icon && <item.icon />}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.name}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                />
                                            </ListItem>
                                        </Tooltip>
                                    </Link>
                                );
                            })}
                        </React.Fragment>
                    ))}
                </>
            </List>
        </Box>
    );
};
