import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    VisibilityButton,
} from '../ui';
import { useEffect, useMemo, useState } from 'react';
import { defaultColorArray } from '../util';
import moment from 'moment';
import { MoreHorizonIcon } from '../icons';
import axios from 'axios';

export const UserDisplay = ({
    response,
    // user,
    // blockAccess = () => console.log('block-access'),
    // toggleOpenReport = () => console.log('comment-report'),
    // deleteComment = () => console.log('comment-delete'),
    // starweaverAdmin,
    userId = '',
    // isCurrentUser,
    // visibilityOptions,
}) => {
    // console.log(user);
    // const [anchorEl, setAnchorEl] = useState(null);

    // const [selectedVisbilityOption, setSelectedVisbilityOption] = useState(
    //     response.visibility || 'Everyone'
    // );

    // // console.log(buttonText, mentionables);
    // const [selectedUsers, setSelectedUsers] = useState(
    //     response.selected_users || []
    // );
    // // console.log(response.selected_users);

    // console.log(response.createdAt);
    const timePeriod = useMemo(() => {
        const current = moment();
        const creation = moment(response.createdAt);
        const days = current.diff(creation, 'days');
        if (days === 0) {
            const hours = current.diff(creation, 'hours');
            if (hours === 0) {
                const minutes = current.diff(creation, 'minutes');
                return `${minutes > 0 ? minutes : 'Few'} ${
                    minutes === 0 ? 'seconds' : 'min'
                }${minutes > 1 ? 's' : ''}`;
            } else {
                return `${hours > 0 ? hours : 'Few'} hour${
                    hours > 1 ? 's' : ''
                }`;
            }
        } else {
            return `${days} day${days > 1 ? 's' : ''}`;
        }
    }, [response]);

    const avatar = useMemo(() => {
        const color = response
            ? defaultColorArray[
                  (userId.charCodeAt(0) +
                      userId.charCodeAt(userId.length - 1)) %
                      defaultColorArray.length
              ]
            : defaultColorArray[5];
        // if (response?.user_img) {
        //     return <Avatar src={response.user_img} alt="user_profile" />;
        // }

        return (
            <Avatar
                style={{
                    backgroundColor: color,
                }}
                src={
                    response.user?.profilePic ||
                    response.user?.user_url ||
                    response.user?.profilePicUrlWP
                }
            >
                {response?.user_name?.charAt(0).toUpperCase()}
            </Avatar>
        );
    }, [response, userId]);

    // function
    // menu
    // const handleClick = (event) => {
    //     if (!user) {
    //         blockAccess();
    //         return;
    //     }
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleReport = () => {
    //     toggleOpenReport();
    //     handleClose();
    // };

    // const handleDelete = () => {
    //     deleteComment();
    //     handleClose();
    // };
    // menu

    return (
        <>
            {/* header section */}
            {/* <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            > */}
            {/* name section */}
            <Box mb={1} display="flex" alignItems="center">
                <Box mr={1}>{avatar}</Box>
                <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">
                        {response.user_name || response.user.user_name}
                        {response.user_type && (
                            <Chip
                                label={response.user_type}
                                size="small"
                                sx={{
                                    backgroundColor:
                                        response.user_type === 'Admin'
                                            ? 'primary.main'
                                            : response.user_type ===
                                              'Instructor'
                                            ? 'secondary.main'
                                            : 'text.secondary',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginLeft: 1,
                                }}
                            />
                        )}
                    </Typography>
                    <Typography variant="caption">{timePeriod} ago</Typography>
                    {response?.isEdited && (
                        <Typography
                            variant="caption"
                            sx={{ color: (theme) => theme.palette.error.light }}
                        >
                            Edited
                        </Typography>
                    )}
                </Box>
                {/* {isCurrentUser && (
                        <Box mt={-2}>
                            <VisibilityButton
                                selectedVisbilityOption={
                                    selectedVisbilityOption
                                }
                                setSelectedVisbilityOption={
                                    setSelectedVisbilityOption
                                }
                                selectedUsers={selectedUsers}
                                setSelectedUsers={setSelectedUsers}
                                visibilityOptions={visibilityOptions}
                            />
                        </Box>
                    )} */}
            </Box>
            {/* name section */}
            {/* <Box>
                    {(!isCurrentUser || starweaverAdmin) && (
                        <IconButton
                            size="small"
                            disableElevation
                            aria-controls="simple-menu"
                            onClick={handleClick}
                        >
                            <MoreHorizonIcon fontSize="small" />
                        </IconButton>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        disableScrollLock
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {!isCurrentUser && (
                            <MenuItem onClick={handleReport}>
                                Report this comment
                            </MenuItem>
                        )}
                        {starweaverAdmin && (
                            <MenuItem onClick={handleDelete}>Delete</MenuItem>
                        )}
                    </Menu>
                </Box> */}
            {/* </Box> */}
            {/* header section */}
        </>
    );
};
