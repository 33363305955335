import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { alertState, severity } from '../../../app/recoil';
import { AzureBlobStorage } from '../../../util/BlobStorage';

const useStyles = makeStyles()((theme) => ({
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
    },
    fileInput: {
        display: 'block',
        width: '100%',
        outline: `1px solid ${theme.palette.grey[400]}`,
        padding: theme.spacing(2),
        fontSize: '1rem',
        borderRadius: theme.spacing(0.5),
        '&:focus': {
            outlineWidth: '2px',
            outlineColor: theme.palette.secondary.main,
        },
    },
}));

function AddTutorials({
    setShowAddTutorials,
    guidanceId,
    fetchGuidance,
    logisticsTemplateId,
    selectedTutorial,
    setSelectedTutorial,
}) {
    const { classes } = useStyles();
    const [payload, setPayload] = useState({
        title: '',
        description: '',
        videoURL:
            'https://player.vimeo.com/video/112233?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    });
    const thumbnailImgRef = useRef(null);
    const setAlert = useSetRecoilState(alertState);
    const [imgFile, setImageFile] = useState(null);
    const [, setTotalFilesUploaded] = useState(0);
    const [, setCurrentProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [videoId, setVideoId] = useState(0);

    const isEditTutorial = useMemo(() => {
        return Object.keys(selectedTutorial).length > 0;
    }, [selectedTutorial]);

    const getVideoId = useCallback((videoUrl) => {
        const videoId = videoUrl.split('?')[0].split('/').slice(-1)[0] ?? 0;
        return parseInt(videoId);
    }, []);

    useEffect(() => {
        if (isEditTutorial) {
            setPayload({
                title: selectedTutorial.title,
                videoURL: selectedTutorial.videoURL,
                description: selectedTutorial.description,
            });
            setVideoId(getVideoId(selectedTutorial.videoURL));
        }
    }, [getVideoId, isEditTutorial, selectedTutorial]);

    const handleChange = (event) => {
        const { value, name } = event.target;
        setPayload((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const validateMimeType = (file) => {
        const whitelistFileTypes = ['image/jpeg', 'image/webp', 'image/png'];
        return whitelistFileTypes.includes(file.type);
    };

    const handleChangeCardImg = (event) => {
        const files = event.target.files;
        if (!files) return;
        const file = files[0];
        if (!file) return;
        const isValid = validateMimeType(file);
        // validate mime type
        if (!isValid) {
            thumbnailImgRef.current.value = '';
            setImageFile(null);
            setAlert({
                show: true,
                severity: severity.WARNING,
                message: 'Invalid file type. Only images are allowed.',
            });
            return;
        }
        if (file.size > 4 * 1024 * 1024) {
            thumbnailImgRef.current.value = '';
            setImageFile(null);
            setAlert({
                show: true,
                severity: severity.WARNING,
                message: 'Maximum allowed file size is 4MB.',
            });
            return;
        }
        setImageFile(file);
    };

    const handleCancel = () => {
        setSelectedTutorial({});
        setShowAddTutorials(false);
    };

    const uploadImg = async (files) => {
        try {
            const blobStorage = new AzureBlobStorage();
            const container = 'guidance';
            const folderPath = `${guidanceId}/tutorials`;
            await blobStorage.uploadFiles(
                files,
                container,
                setTotalFilesUploaded,
                setCurrentProgress,
                folderPath
            );
            const actual = `${guidanceId}/tutorials/${files[0].name}`;
            return await blobStorage.getBlobURL(container, actual);
        } catch (e) {
            setAlert({
                show: true,
                message: "Something wen't wrong wile uploading image",
                severity: severity.ERROR,
            });
        }
    };

    const handleSaveTutorial = async () => {
        const imgURL = await uploadImg([imgFile]);
        await axios.post('/api/guidance/add-tutorial', {
            ...payload,
            imgURL,
            guidanceId,
        });
        await fetchGuidance(logisticsTemplateId);
        setAlert({
            show: true,
            severity: severity.SUCCESS,
            message: 'New tutorial created successfully',
        });
        handleCancel();
    };

    const handleEdtiTutorial = async () => {
        let imgURL = '';
        if (imgFile) {
            imgURL = await uploadImg([imgFile]);
        }
        const data = {
            ...payload,
            ...(imgURL.length > 0 ? { imgURL } : {}),
            guidanceId,
            tutorialId: selectedTutorial._id,
        };
        await axios.patch('/api/guidance/update-tutorial', data);
        await fetchGuidance(logisticsTemplateId);
        setAlert({
            show: true,
            severity: severity.SUCCESS,
            message: 'Tutorial updated successfully',
        });
        handleCancel();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            if (isEditTutorial) await handleEdtiTutorial();
            else await handleSaveTutorial();
        } catch (e) {
            if (e.response.status === 422) {
                setAlert({
                    show: true,
                    message: e.response.data.message,
                    severity: severity.SUCCESS,
                });
            }
            console.log(e);
        } finally {
            setIsLoading(false);
            setPayload({
                description: '',
                title: '',
                videoURL: '',
            });
            thumbnailImgRef.current.value = '';
        }
    };

    const handleChangeVideoId = (e) => {
        const id = e.target.value;
        setVideoId(id);
        const videoURL = `https://player.vimeo.com/video/${id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
        setPayload((prev) => {
            return {
                ...prev,
                videoURL,
            };
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <label htmlFor="title">Title</label>
                <TextField
                    fullWidth
                    placeholder="Tutorial title"
                    name="title"
                    type="text"
                    variant="outlined"
                    color="secondary"
                    value={payload.title}
                    onChange={handleChange}
                    required={!isEditTutorial}
                    disabled={isLoading}
                />
            </Box>

            <Box my={2}>
                <label htmlFor="cardImg">Thumbnail image</label>
                <input
                    className={classes.fileInput}
                    ref={thumbnailImgRef}
                    name="cardImg"
                    type="file"
                    onChange={handleChangeCardImg}
                    required={!isEditTutorial}
                    disabled={isLoading}
                />
            </Box>

            <Box my={2}>
                <label htmlFor="description">Description</label>
                <TextField
                    name="description"
                    type="text"
                    multiline
                    fullWidth
                    minRows={5}
                    maxRows={5}
                    variant="outlined"
                    placeholder="Tutorial description"
                    color="secondary"
                    onChange={handleChange}
                    required={!isEditTutorial}
                    value={payload.description}
                    disabled={isLoading}
                />
            </Box>

            <Box my={2}>
                <label htmlFor="videoId">Video ID</label>
                <TextField
                    name="videoId"
                    type="number"
                    fullWidth
                    variant="outlined"
                    placeholder="Tutorial video ID"
                    color="secondary"
                    onChange={handleChangeVideoId}
                    required={!isEditTutorial}
                    value={videoId}
                    disabled={isLoading}
                    // helperText="Video id looks like '871627010'"
                    helperText={payload.videoURL}
                />
            </Box>
            <Box className={classes.buttonGroup}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading
                        ? 'Please wait'
                        : isEditTutorial
                        ? 'Update'
                        : 'Save'}
                </Button>
            </Box>
        </form>
    );
}

export default AddTutorials;
